"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var operators_1 = require("rxjs/operators");
var http_1 = require("@angular/common/http");
var logbook_config_1 = require("../../../../../../../app/core/shared/config/logbook-config");
var flight_tag_model_1 = require("../model/flight-tag.model");
var FlightTagSearchService = /** @class */ (function () {
    function FlightTagSearchService(http, config) {
        this.http = http;
        this.config = config;
    }
    FlightTagSearchService.prototype.suggestFlights = function (query, action, from, to, profile) {
        var options = new http_1.HttpParams()
            .set('action', action)
            .set('from', from)
            .set('to', to)
            .set('profile', profile);
        return this.http.get(this.config.LOGBOOK_API + "/api/flight-tag-search/flight-tag/search/" + query, { params: options }).pipe(operators_1.map(function (suggestFlightList) {
            return suggestFlightList.map(function (flightTagString) { return flight_tag_model_1.FlightTag.createFlightTagFromString(flightTagString); });
        }));
    };
    return FlightTagSearchService;
}());
exports.FlightTagSearchService = FlightTagSearchService;
