"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Societe = /** @class */ (function () {
    function Societe(build) {
        this.isSelected = false;
        if (build) {
            this.id = build.id;
            this.nom = build.nom;
            this.type = build.type;
            this.teamIds = build.teamIds;
            this.salesforceId = build.idSalesforce;
            this.localisation = build.localisation;
            this.logoUri = build.logoUri;
        }
    }
    Societe.prototype.displaySocieteName = function () {
        return this.nom + " " + this.localisation.country;
    };
    return Societe;
}());
exports.Societe = Societe;
