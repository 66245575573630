<dln-simple-notifications [options]="notificationOptions"></dln-simple-notifications>

<div id="body">
    <dln-switch-apps-menu [(collapse)]="collapseMenu" *ngIf="isAuthenticated() && !hideSwitchApps;"></dln-switch-apps-menu>
    <div class="main">
        <dln-check-updates></dln-check-updates>
        <router-outlet name="navbar" *ngIf="!hideNavbar"></router-outlet>
        <router-outlet></router-outlet>
    </div>
</div>
