import {Component} from '@angular/core';

@Component({
    selector: 'dln-success',
    template: `
        <div class="success">
            <div class="fa fa-check"></div>
            <div class="label">success</div>
        </div>
    `,
    styleUrls: [
        './success.component.scss'
    ]
})
export class SuccessComponent {
}
