"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".mobile-navbar-button[_ngcontent-%COMP%]{z-index:150;cursor:pointer;background:0 0;padding:2px;border:0;margin-right:20px;width:30px;height:30px;display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;-ms-flex-align:center;-moz-align-items:center;align-items:center;-ms-flex-pack:center;-moz-justify-content:center;-ms-justify-content:center;justify-content:center;-ms-flex-wrap:nowrap;flex-wrap:nowrap}.mobile-navbar-button[_ngcontent-%COMP%]   i.icon-menu[_ngcontent-%COMP%]{font-size:25px}.mobile-navbar-button[_ngcontent-%COMP%] > span[_ngcontent-%COMP%], .mobile-navbar-button[_ngcontent-%COMP%] > span[_ngcontent-%COMP%]:after, .mobile-navbar-button[_ngcontent-%COMP%] > span[_ngcontent-%COMP%]:before{cursor:pointer;color:#000;height:1px;width:25px;background:#000;position:absolute;display:block;content:'';border-radius:1px;transition:all .5s ease-in-out;-moz-transition:.5s ease-in-out;-o-transition:.5s ease-in-out;-webkit-transition:.5s ease-in-out}.mobile-navbar-button[_ngcontent-%COMP%] > span[_ngcontent-%COMP%]:before{top:-5px}.mobile-navbar-button[_ngcontent-%COMP%] > span[_ngcontent-%COMP%]:after{bottom:-5px}.mobile-navbar-button.toggled[_ngcontent-%COMP%] > span[_ngcontent-%COMP%]{background-color:transparent}.mobile-navbar-button.toggled.toggled[_ngcontent-%COMP%] > span[_ngcontent-%COMP%]:after, .mobile-navbar-button.toggled[_ngcontent-%COMP%] > span[_ngcontent-%COMP%]:before{top:0}.mobile-navbar-button.toggled[_ngcontent-%COMP%] > span[_ngcontent-%COMP%]:before{transform:rotate(45deg)}.mobile-navbar-button.toggled[_ngcontent-%COMP%] > span[_ngcontent-%COMP%]:after{transform:rotate(-45deg)}"];
exports.styles = styles;
