import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {RouterModule} from "@angular/router";
import {DlnAlertComponent} from "./alert/alert.component";
import {TextEditorComponent} from "./text-editor/text-editor.component";
import {AttachmentService} from "./text-editor/attach-file/service/attachment.service";
import {AttachFileComponent} from "./text-editor/attach-file/component/attach-file.component";
import {AttachmentViewerComponent} from "./text-editor/attach-file/component/attachment-viewer/attachment-viewer.component";
import {FileIconComponent} from "./text-editor/attach-file/component/file-icon/file-icon.component";
import {BackButtonComponent} from "./buttons/back-button/back-button.component";
import {ButtonComponent} from "./buttons/button/button.component";
import {SendButtonsComponent} from "./buttons/send-buttons/send-buttons";
import {DlnDropFileComponent} from "./text-editor/attach-file/component/drop-file/drop-file.component";
import {DropdownComponent} from "./dropdown/dropdown.component";
import {DropdownContentComponent} from "./dropdown/dropdown-content/dropdown-content.component";
import {DropdownDirective} from "./dropdown/dropdown.directive";
import {FlightTagModule} from "./flight-tag/flight-tag.module";
import {NgSelectModule} from "./ng-select/ng-select.module";
import {SpinnerComponent} from "./spinner/spinner.component";
import {TooltipDirective} from "./tooltip/tooltip.directive";
import {AvatarComponent} from "./user-info/avatar/avatar.component";
import {UserStatusComponent} from "./user-info/user-status/user-status.component";
import {UserInfoComponent} from "./user-info/user-info.component";
import {SuccessAnimationComponent} from "./success/success-animation/success-animation.component";
import {SuccessComponent} from "./success/success.component";
import {PdfViewerModule} from "ng2-pdf-viewer";
import { DraggableDirective } from './draggable/draggable.directive';
import { DynamicSizeDirective } from './dynamic-height/dynamic-size.directive';
import {ResponsiveModule} from "ngx-responsive";
import {LogbookCoreSharedModule} from "@logbook/core/shared/core-shared.module";

@NgModule({
    imports: [
        CommonModule, RouterModule, PdfViewerModule, ResponsiveModule,
        FlightTagModule, NgSelectModule, LogbookCoreSharedModule
    ],
    providers: [
        AttachmentService
    ],
    declarations: [
        DlnAlertComponent,
        BackButtonComponent, ButtonComponent, SendButtonsComponent,
        DropdownComponent, DropdownContentComponent, DropdownDirective,
        TextEditorComponent, AttachFileComponent,
        AttachmentViewerComponent, FileIconComponent, DlnDropFileComponent,
        TooltipDirective,
        AvatarComponent, UserStatusComponent, UserInfoComponent,
        SpinnerComponent,
        SuccessAnimationComponent, SuccessComponent, DraggableDirective,
        DynamicSizeDirective
    ],
    exports: [
        FlightTagModule, NgSelectModule, ResponsiveModule,
        DlnAlertComponent,
        BackButtonComponent, ButtonComponent, SendButtonsComponent,
        DropdownComponent, DropdownContentComponent, DropdownDirective,
        TextEditorComponent, AttachFileComponent,
        AttachmentViewerComponent, FileIconComponent, DlnDropFileComponent,
        SpinnerComponent,
        TooltipDirective, DraggableDirective,
        AvatarComponent, UserStatusComponent, UserInfoComponent,
        SuccessAnimationComponent, SuccessComponent,
        DynamicSizeDirective
    ]
})
export class DlnCommonModule { }
