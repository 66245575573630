"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var LogbookNotification_1 = require("@logbook/notification-center/shared/model/LogbookNotification");
var NotificationCategoryComponent = /** @class */ (function () {
    function NotificationCategoryComponent() {
    }
    NotificationCategoryComponent.prototype.ngOnInit = function () { };
    NotificationCategoryComponent.prototype.getIcon = function () {
        switch (this.category) {
            case LogbookNotification_1.NotificationCategory.INSTRUCTION:
                return "dln-icon-instructions";
            case LogbookNotification_1.NotificationCategory.LOG:
                return "dln-icon-logs";
            default:
                return "dln-icon-notifications";
        }
    };
    NotificationCategoryComponent.prototype.getLabel = function () {
        switch (this.category) {
            case LogbookNotification_1.NotificationCategory.LOG:
                return "Logs";
            case LogbookNotification_1.NotificationCategory.INSTRUCTION:
                return "Instructions";
            default:
                return "Others";
        }
    };
    return NotificationCategoryComponent;
}());
exports.NotificationCategoryComponent = NotificationCategoryComponent;
