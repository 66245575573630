<h2 mat-dialog-title>
    Add flight <span class="not-bold"> on {{dateSelected.format('ll')}}</span>
</h2>
<mat-dialog-content>
    <form class="flight-tag-form" [formGroup]="flightTagForm">
        <div [ngClass]="{'input-with-label': true, 'error': (airline.touched && airline.invalid)}">
            <label for="airline" class="input-label">Airline</label>
            <input id="airline" name="airline" formControlName="airline" (blur)="airlineChange()"/>
            <label class="bottom">
                <span class="info">2 or 3 char</span>
                <span class="required">required</span>
            </label>
        </div>

        <div class="separator">-</div>

        <div [ngClass]="{'input-with-label': true, 'error': (flightNumber.touched && flightNumber.invalid)}">
            <label for="flightNumber" class="input-label">Flight Number</label>
            <input id="flightNumber" name="flightNumber" formControlName="flightNumber"/>
            <label class="bottom">
                <span class="required">required</span>
            </label>
        </div>

        <div class="separator"></div>

        <div [ngClass]="{'input-with-label': true, 'error': (airportFrom.touched && airportFrom.invalid)}">
            <label for="airportFrom" class="input-label">Airport From</label>
            <input id="airportFrom" name="airportFrom" formControlName="airportFrom"/>
            <label class="bottom">
                <span class="info">3 char</span>
                <span class="required">required</span>
            </label>
        </div>

        <div class="separator">></div>

        <div [ngClass]="{'input-with-label': true, 'error': (airportTo.touched && airportTo.invalid)}">
            <label for="airportTo" class="input-label">Airport To</label>
            <input id="airportTo" name="airportTo" formControlName="airportTo"/>
            <label class="bottom">
                <span class="info">3 char</span>
                <span class="required">required</span>
            </label>
        </div>

    </form>
</mat-dialog-content>

<mat-dialog-actions>
    <div class="buttons">
        <button (click)="cancel()" class="cancel">Cancel</button>
        <button (click)="createFlightTag()" class="confirm">Create flight</button>
    </div>
</mat-dialog-actions>

