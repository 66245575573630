import {ErrorHandler, Injectable, NgZone} from '@angular/core';
import {User} from "../shared/models/user.model";
import {LogbookConfig} from "../shared/config/logbook-config";
import { TrackJS } from 'trackjs';

@Injectable({
    providedIn: 'root'
})
export class TrackJsErrorHandlerService implements ErrorHandler {

    private isEnabled;

    constructor(private config: LogbookConfig, private ngZone: NgZone) {
        this.isEnabled = this.config.production;
        TrackJS.install({
            application: 'Logbook',
            token: 'e60e51483c4a4de397f4d46e9ddc2fc4',
            network: {
                error: false
            },
            enabled: this.isEnabled,
            visitor: {
                enabled: false
            },
        });
    }

    async configure(user: User) {
        this.ngZone.runOutsideAngular(() => {
            if (TrackJS) {
                TrackJS.configure({
                    userId: user.userId,
                    version: this.config.VERSION
                });
            }
        })
    }

    handleError(error: any): void {
        this.ngZone.runOutsideAngular(() => {
            // Assumes we have already loaded and configured TrackJS*
            if (TrackJS && error && this.isEnabled) {
                TrackJS.track(error); // Send the native error object to TrackJS
                console.warn(error);
            } else if (error) {
                console.error(error) // Trackjs disabled we still want to see stacktrace
            }
        })
    }
}
