import {BehaviorSubject, forkJoin as observableForkJoin, of, of as observableOf, Subject, Observable} from 'rxjs';
import {publishReplay, catchError, mergeMap, map, refCount, tap, startWith, first, filter} from 'rxjs/operators';
import {HttpClient, HttpParams, HttpResponse} from "@angular/common/http";
import {Injectable} from '@angular/core';
import {Pageable} from "../../../../logbook/shared/src/lib/model/pageable";
import {UserService} from "../../../../logbook/shared/src/lib/user/service/user.service";
import {LogbookConfig} from "@logbook/core/shared/config/logbook-config";
import {Team} from "../../shared/models/team.model";
import {Page} from "../../../../logbook/shared/src/lib/model";

@Injectable({
    providedIn: 'root',
})
export class CommunityService {
    private resourceUrl;
    private communityCache: Observable<any>;
    private communitiesCache: Map<string, Observable<Team>> = new Map();
    private communityState = new BehaviorSubject<Team>(null);

    constructor(private http: HttpClient, private userService: UserService, private config: LogbookConfig) {
        this.resourceUrl = this.config.LOGBOOK_API + '/api/communaute/'
    }

    getCommunity(force?: boolean): Observable<Team> {
        if (force === true) {
            this.fetchUserCommunity(force).pipe(first()).subscribe()
        }
        return this.communityState.pipe(filter((i) => i !== null));
    }

    cleanCommunityCache() {
        this.communityCache = undefined;
    }

    getCommunityById(id?: string, withDetail = false): Observable<Team> {
        if (!id) {
            return this.getCommunity();
        }
        if (!this.communitiesCache.has(id) || withDetail) {
            this.communitiesCache.set(id, this.http.get(`${this.resourceUrl}${id}${withDetail ? '/detail' : ''}`, {observe: 'response'}).pipe(
                map((res: HttpResponse<any>) => res.body),
                mergeMap(this.enrichCommunity),
                map(this.cleanCommunity),
                catchError((err) => {
                    console.error("Cannot fetch team", err);
                    return observableOf(null)
                }),
                publishReplay(1),
                refCount()
            ));
        }
        return this.communitiesCache.get(id)
    }

    save(teamToSave: Team) {
        if (teamToSave.id) {
            return this.http.put(`${this.resourceUrl}`, teamToSave.convertToJson()).pipe(
                mergeMap((res: Team) => {
                    this.communitiesCache.delete(res.id);
                    if (this.communityState.getValue() && this.communityState.getValue().id === res.id) {
                        return this.fetchUserCommunity(true)
                    } else {
                        return this.getCommunityById(res.id)
                    }
                }),
                tap((res) => {
                    if (this.communityState.getValue() && this.communityState.getValue().id === res.id) {
                        this.communityState.next(res)
                    }
                })
            )
        } else {
            return this.http.post(`${this.resourceUrl}`, teamToSave.convertToJson()).pipe(
                mergeMap((res: Team) => {
                    return this.getCommunityById(res.id)
                }))
        }
    }

    addMember(teamId: string, memberToAdd: string) {
        return this.http.put(`${this.resourceUrl}${teamId}/member`, memberToAdd)
    }

    removeMember(teamId: string, memberToRemove: string) {
        return this.http.delete(`${this.resourceUrl}${teamId}/member/${memberToRemove}`)
    }

    searchCommunity(page: Pageable, searchQuery?) {
        let options: HttpParams = page.toOptions();
        if (searchQuery) {
            options = options.set("filter", searchQuery);
        }
        return this.http.get(this.resourceUrl, {params: options})
            .pipe(
                map((res) => new Page(res))
            )
    }

    private fetchUserCommunity(force?: boolean): Observable<any> {
        if (force === true) {
            this.communityCache = undefined;
        }

        if (!this.communityCache) {
            this.communityCache = this.http.get(`${this.resourceUrl}account`, {observe: 'response'}).pipe(
                map((res: HttpResponse<any>) => res.body),
                mergeMap(this.enrichCommunity),
                map(this.cleanCommunity),
                catchError(() => observableOf(this.getDefaultTeam())),
                tap((community) => {
                    this.communityState.next(community);
                }),
                publishReplay(1),
                refCount()
            );
        }

        return this.communityCache;
    }

    private getDefaultTeam() {
        const team = new Team();
        team.name = "No team";
        team.tags.unshift({
            alias: "Important",
            value: "important",
            color: "red"
        });
        team.tags.unshift({
            alias: "MVT",
            value: "mvt",
            color: "gray"
        });
        team.tags.unshift({
            alias: "Flight delay",
            value: "flightDelayed",
            color: "purple"
        });
        team.tags.unshift({
            alias: "Mail",
            value: "mail",
            color: "green"
        });
        team.profile = [{airlines: [], airport: []}];
        return team;
    }

    enrichCommunity = (community) => {
        // Set members
        const observableCommunity = observableOf(community);
        const observableMembers = community.membres && community.membres.length ? observableForkJoin(
            community.membres.map(
                (membre) => this.userService.find(membre.id).pipe(
                    catchError((err) => {
                        return observableOf(null);
                    })
                )
            )
        ) : observableOf(null);
        return observableForkJoin([observableCommunity, observableMembers]);
    }

    cleanCommunity = (res) => {
        const community = Object.assign(new Team(), res[0]);
        community.membres = res[1] ? res[1].filter((user) => user != null) : [];
        // Set tags
        if (!community.tags.some((tag) => tag.value === 'important')) {
            community.tags.unshift({
                alias: "Important",
                value: "important",
                color: "red"
            });
        }
        if (!community.tags.some((tag) => tag.value === 'flightDelayed')) {
            community.tags.unshift({
                alias: "Flight delay",
                value: "flightDelayed",
                color: "purple"
            });
        }
        if (!community.tags.some((tag) => tag.value === 'mvt')) {
            community.tags.unshift({
                alias: "MVT",
                value: "mvt",
                color: "gray"
            });
        }
        if (!community.tags.some((tag) => tag.value === 'mail')) {
            community.tags.unshift({
                alias: "Mail",
                value: "mail",
                color: "green"
            });
        }
        return community;
    }
}
