export class Useful {
    participantIds: Array<any> = [];
    total = 0;

    constructor(build?) {
        if (build && build.participantIds) {
            this.participantIds = build.participantIds;
            this.total = build.total || 0;
        }
    }
}
