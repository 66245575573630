"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./confirm-modal.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("../../../pipe/safe-html.pipe");
var i3 = require("@angular/platform-browser");
var i4 = require("@angular/common");
var i5 = require("./confirm-modal.component");
var i6 = require("@angular/material/dialog");
var styles_ConfirmModalComponent = [i0.styles];
var RenderType_ConfirmModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ConfirmModalComponent, data: {} });
exports.RenderType_ConfirmModalComponent = RenderType_ConfirmModalComponent;
function View_ConfirmModalComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "message"]], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵppd(1, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 0, 0, _ck(_v, 1, 0, i1.ɵnov(_v.parent, 0), _co.message)); _ck(_v, 0, 0, currVal_0); }); }
function View_ConfirmModalComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "cancel"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Cancel"]))], null, null); }
function View_ConfirmModalComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.SafeHtmlPipe, [i3.DomSanitizer]), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "title"]], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵppd(2, 1), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConfirmModalComponent_1)), i1.ɵdid(4, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 4, "div", [["class", "buttons"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConfirmModalComponent_2)), i1.ɵdid(7, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 1, "button", [["class", "confirm"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.confirm() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["OK"]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.message; _ck(_v, 4, 0, currVal_1); var currVal_2 = !_co.hideCancel; _ck(_v, 7, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v, 0), _co.title)); _ck(_v, 1, 0, currVal_0); }); }
exports.View_ConfirmModalComponent_0 = View_ConfirmModalComponent_0;
function View_ConfirmModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "dln-confirm-modal", [], null, null, null, View_ConfirmModalComponent_0, RenderType_ConfirmModalComponent)), i1.ɵdid(1, 49152, null, 0, i5.ConfirmModalComponent, [i6.MatDialogRef, [2, i6.MAT_DIALOG_DATA]], null, null)], null, null); }
exports.View_ConfirmModalComponent_Host_0 = View_ConfirmModalComponent_Host_0;
var ConfirmModalComponentNgFactory = i1.ɵccf("dln-confirm-modal", i5.ConfirmModalComponent, View_ConfirmModalComponent_Host_0, {}, {}, []);
exports.ConfirmModalComponentNgFactory = ConfirmModalComponentNgFactory;
