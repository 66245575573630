import {Component, ElementRef, EventEmitter, Input, OnChanges, Output, Renderer2, SimpleChange} from '@angular/core';

/**
 * Universal avatar flight-report that
 * generates avatar from different sources
 *
 * export
 * class AvatarComponent
 * implements {OnInit}
 */

@Component({
    selector: 'dln-avatar',
    styles: [`
        :host {
            border-radius: 50%;
        }
    `],
    template: `
        <div class="avatar-container" [ngStyle]="hostStyle">
            <img *ngIf="src"
                 [src]="src"
                 [width]="size"
                 [height]="size"
                 [ngStyle]="avatarStyle"
                 (error)="fetch($event)"
                 class="avatar-content"
            />

            <div *ngIf="data && !src"
                 [ngStyle]="avatarStyle"
                 class="avatar-content">{{data}}
            </div>
        </div>`
})
export class AvatarComponent implements OnChanges {

    @Input() round = true;
    @Input() size = 50;
    @Input() textSizeRatio = 3;
    @Input() bgColor: string;
    @Input() fgColor = "#FFF";
    @Input() borderColor: string;
    @Input() style: any = {};
    @Input() cornerRadius = 0;
    @Input('src') custom: string;
    @Input('name') initials: string;
    @Input('value') value: string;
    @Input('placeholder') placeholder: string;
    @Input('initialsSize') initialsSize = 2;
    @Output() clickOnAvatar: EventEmitter<any> = new EventEmitter<any>();

    _currentSource = 0;
    // avatar img src
    src: string;
    // avatar text value
    data: string;

    avatarStyle: any = {}
    hostStyle: any = {};

    constructor(public renderer: Renderer2, public elementRef: ElementRef) {

    }

    /**
     * Detect inputs change
     *
     * param {{ [propKey: string]: SimpleChange }} changes
     *
     * memberof AvatarComponent
     */
    ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
        // reintialize the avatar flight-report when a source property value has changed
        // the fallback system must be re-invoked with the new values.
        this._initializeAvatar()
    }

    /**
     * Initialize the avatar flight-report and its fallback system
     */
    _initializeAvatar() {
        this._currentSource = 0;
        // Host style
        this.hostStyle = {
            width: this.size + 'px',
            height: this.size + 'px'
        };
        // Fetch avatar source
        this.fetch();
    }

    /**
     * Fetch avatar source
     *
     * param {any} event
     *
     * memberOf AvatarComponent
     */
    fetch(event?: any) {
        this.data = this._getInitials(this.value, this.initialsSize);
        this.src = undefined;
        this.avatarStyle = this._initialsStyle(this.value);
        this._currentSource++;

    }

    /**
     *
     * returns initials style
     *
     * memberOf AvatarComponent
     */
    _initialsStyle(avatarValue: string) {
        return {
            textAlign: 'center',
            borderRadius: this.round ? '100%' : this.cornerRadius + 'px',
            border: this.borderColor ? '1px solid ' + this.borderColor : '',
            textTransform: 'uppercase',
            color: this.fgColor,
            backgroundColor: this.bgColor ? this.bgColor : this.getRandomColor(avatarValue),
            font: Math.floor(this.size / this.textSizeRatio) + 'px Helvetica, Arial, sans-serif',
            lineHeight: this.size + 'px',
            ...this.style
        }

    }

    /**
     *
     * returns image style
     *
     * memberOf AvatarComponent
     */
    _imageStyle() {
        return {
            maxWidth: '100%',
            borderRadius: this.round ? '50%' : this.cornerRadius + 'px',
            border: this.borderColor ? '1px solid ' + this.borderColor : '',
            width: this.size,
            height: this.size,
            ...this.style
        }
    }

    getRandomColor(value: string): string {
        if (!value) {
            return 'transparent';
        }
        const asciiCodeSum = this._calculateAsciiCode(value);
        const colors = [
            "#1abc9c",
            "#3498db",
            "#f1c40f",
            "#8e44ad",
            "#e74c3c",
            "#d35400",
            "#2c3e50",
            "#7f8c8d"
        ];
        return colors[asciiCodeSum % colors.length];
    }

    _calculateAsciiCode(value: string) {
        return value.split('').map((letter) => letter.charCodeAt(0))
            .reduce((previous, current) => previous + current);
    }

    _getInitials(name: string, size: number): string {
        if (name) {
            const initials = name.trim().split(" ");
            if (size && size < initials.length) {
                return this._constructInitials(initials.slice(0, size));
            } else {
                return this._constructInitials(initials);
            }

        }
        return "";
    }

    _constructInitials(elements: string[]) {
        if (elements && elements.length > 0) {
            return elements.filter((element) => element && element.length > 0)
                .map((element) => element[0].toUpperCase()).join('');
        }
        return '';
    }

}
