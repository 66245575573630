import {HttpParameterCodec} from "@angular/common/http/src/params";

/**
 * Encoder custom pour palier au problème d'encodage du caractère +
 * [link] https://github.com/angular/angular/issues/11058
 */
export class CustomHttpEncoder implements HttpParameterCodec {

    constructor() {}

    encodeKey(key: string): string {
        return encodeURIComponent(key);
    }

    encodeValue(value: string): string {
        return encodeURIComponent(value);
    }

    decodeKey(key: string): string {
        return decodeURIComponent(key);
    }

    decodeValue(value: string): string {
        return decodeURIComponent(value);
    }
}
