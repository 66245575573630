"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var FileIconComponent = /** @class */ (function () {
    function FileIconComponent() {
    }
    FileIconComponent.prototype.ngOnInit = function () {
    };
    FileIconComponent.prototype.getFileType = function () {
        if (this.contentType && this.contentType.indexOf("image") > -1) {
            return "IMAGE";
        }
        else if (this.contentType && this.contentType.indexOf("audio") > -1) {
            return "AUDIO";
        }
        else if (this.contentType && this.contentType === 'application/pdf') {
            return "PDF";
        }
        else {
            return "OTHER";
        }
    };
    return FileIconComponent;
}());
exports.FileIconComponent = FileIconComponent;
