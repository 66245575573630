import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ChangeLogService} from "./service/change-log.service";

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [],
    providers: [ChangeLogService]
})
export class ChangeLogModule { }
