import {ErrorHandler, Injector, ModuleWithProviders, NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import {TrackJsErrorHandlerService} from "./track-js-error-handler.service";

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [],
    providers: [
    ]
})
export class LogbookCoreTrackingModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: LogbookCoreTrackingModule,
            providers: [
                {
                    provide: ErrorHandler,
                    useClass: TrackJsErrorHandlerService
                }
            ]
        };
    }

}
