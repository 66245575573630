"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./upload-image-dialog.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("@angular/material/dialog");
var i3 = require("../../../../../../../node_modules/@alyle/ui/resizing-cropping-images/alyle-ui-resizing-cropping-images.ngfactory");
var i4 = require("@alyle/ui/resizing-cropping-images");
var i5 = require("@alyle/ui");
var i6 = require("./upload-image-dialog.component");
var i7 = require("../../common/text-editor/attach-file/service/attachment.service");
var styles_UploadImageDialogComponent = [i0.styles];
var RenderType_UploadImageDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UploadImageDialogComponent, data: {} });
exports.RenderType_UploadImageDialogComponent = RenderType_UploadImageDialogComponent;
function View_UploadImageDialogComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { imgCropping: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 2, "h2", [["class", "mat-dialog-title"], ["mat-dialog-title", ""]], [[8, "id", 0]], null, null, null, null)), i1.ɵdid(2, 81920, null, 0, i2.MatDialogTitle, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], null, null), (_l()(), i1.ɵted(-1, null, ["Position and size your photo"])), (_l()(), i1.ɵeld(4, 0, null, null, 5, "mat-dialog-content", [["class", "mat-dialog-content"]], null, null, null, null, null)), i1.ɵdid(5, 16384, null, 0, i2.MatDialogContent, [], null, null), (_l()(), i1.ɵeld(6, 0, null, null, 3, "ly-cropping", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 7).resize$() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_LyResizingCroppingImages_0, i3.RenderType_LyResizingCroppingImages)), i1.ɵdid(7, 49152, [[1, 4], ["imgCropping", 4]], 0, i4.LyResizingCroppingImages, [i1.Renderer2, i5.LyTheme2, i1.ElementRef, i1.ChangeDetectorRef, i1.NgZone], { config: [0, "config"] }, null), (_l()(), i1.ɵeld(8, 0, null, 0, 1, "span", [["class", "select-image-label"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Select image"])), (_l()(), i1.ɵeld(10, 0, null, null, 11, "mat-dialog-actions", [["class", "mat-dialog-actions"]], null, null, null, null, null)), i1.ɵdid(11, 16384, null, 0, i2.MatDialogActions, [], null, null), (_l()(), i1.ɵeld(12, 0, null, null, 4, "div", [["class", "tools-bar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 1, "div", [["class", "zoom minus"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 7).zoomOut() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["-"])), (_l()(), i1.ɵeld(15, 0, null, null, 1, "div", [["class", "zoom"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 7).zoomIn() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["+"])), (_l()(), i1.ɵeld(17, 0, null, null, 4, "div", [["class", "actions"]], null, null, null, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 1, "div", [["class", "cancel"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Cancel"])), (_l()(), i1.ɵeld(20, 0, null, null, 1, "div", [["class", "save"]], [[2, "disable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.save() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Apply"]))], function (_ck, _v) { var _co = _v.component; _ck(_v, 2, 0); var currVal_1 = _co.cropConfig; _ck(_v, 7, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 2).id; _ck(_v, 1, 0, currVal_0); var currVal_2 = _co.isLoading; _ck(_v, 20, 0, currVal_2); }); }
exports.View_UploadImageDialogComponent_0 = View_UploadImageDialogComponent_0;
function View_UploadImageDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "dln-upload-image-dialog", [], null, null, null, View_UploadImageDialogComponent_0, RenderType_UploadImageDialogComponent)), i1.ɵdid(1, 114688, null, 0, i6.UploadImageDialogComponent, [i2.MatDialogRef, i7.AttachmentService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_UploadImageDialogComponent_Host_0 = View_UploadImageDialogComponent_Host_0;
var UploadImageDialogComponentNgFactory = i1.ɵccf("dln-upload-image-dialog", i6.UploadImageDialogComponent, View_UploadImageDialogComponent_Host_0, {}, {}, []);
exports.UploadImageDialogComponentNgFactory = UploadImageDialogComponentNgFactory;
