"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var operators_1 = require("rxjs/operators");
var http_1 = require("@angular/common/http");
var change_log_ack_model_1 = require("../model/change-log-ack.model");
var change_log_model_1 = require("../model/change-log.model");
var logbook_config_1 = require("@logbook/core/shared/config/logbook-config");
var ChangeLogService = /** @class */ (function () {
    function ChangeLogService(http, config) {
        this.http = http;
        this.config = config;
        this.resourceUrl = config.LOGBOOK_API + '/api/change-log';
    }
    ChangeLogService.prototype.findAllForAdmin = function (pageable) {
        return this.http.get(this.resourceUrl + "/admin", { params: pageable.toOptions() }).pipe(operators_1.map(function (res) { return res.content; }), operators_1.map(function (changeLogs) { return changeLogs.map(function (changeLog) { return new change_log_model_1.ChangeLog(changeLog); }); }));
    };
    ChangeLogService.prototype.findAll = function (pageable) {
        return this.http.get("" + this.resourceUrl, { params: pageable.toOptions() }).pipe(operators_1.map(function (res) { return res.content; }), operators_1.map(function (changeLogs) { return changeLogs.map(function (changeLog) { return new change_log_model_1.ChangeLog(changeLog); }); }));
    };
    ChangeLogService.prototype.getAck = function () {
        return this.http.get(this.resourceUrl + "/ack").pipe(operators_1.map(function (ack) {
            if (ack === null) {
                return null;
            }
            else {
                return new change_log_ack_model_1.ChangeLogAck(ack);
            }
        }));
    };
    ChangeLogService.prototype.countUnread = function () {
        return this.http.get(this.resourceUrl + "/countUnread").pipe(operators_1.map(function (count) { return count; }));
    };
    ChangeLogService.prototype.save = function (changeLog) {
        return this.http.post(this.resourceUrl + "/", changeLog).pipe(operators_1.map(function (changeLogSaved) { return new change_log_model_1.ChangeLog(changeLogSaved); }));
    };
    return ChangeLogService;
}());
exports.ChangeLogService = ChangeLogService;
