"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var services_1 = require("@logbook/logs-view/shared/services");
var MobileNavbarButtonComponent = /** @class */ (function () {
    function MobileNavbarButtonComponent(viewManagementService) {
        this.viewManagementService = viewManagementService;
    }
    MobileNavbarButtonComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.navBarSubscription = this.viewManagementService.subscribeActiveComponents()
            .subscribe(function (activeComponents) {
            return _this.isToggled = activeComponents.rightPanelExpanded;
        });
    };
    MobileNavbarButtonComponent.prototype.toggleNav = function () {
        this.viewManagementService.expandRightPanel();
    };
    MobileNavbarButtonComponent.prototype.ngOnDestroy = function () {
        if (this.navBarSubscription) {
            this.navBarSubscription.unsubscribe();
        }
    };
    return MobileNavbarButtonComponent;
}());
exports.MobileNavbarButtonComponent = MobileNavbarButtonComponent;
