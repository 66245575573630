import {ChangeDetectionStrategy, Component, HostBinding, Input, ViewEncapsulation} from '@angular/core';
import {FlightTag} from "../../model/flight-tag.model";

@Component({
    selector: 'dln-flight-tag',
    templateUrl: './flight-tag.component.html',
    encapsulation: ViewEncapsulation.None,
    styleUrls: [
        'flight-tag.component.scss'
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FlightTagComponent {

    @Input() flightTag: FlightTag;

    @Input() filterItem = false;

    @Input() closable = false;

    @Input() active = false;

    @Input() showDate = false;

    constructor() {
    }
}
