"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var router_1 = require("@angular/router");
var state_storage_service_1 = require("../../auth/services/state-storage.service");
var principal_service_1 = require("../../auth/services/principal.service");
var UserRouteAccessService = /** @class */ (function () {
    function UserRouteAccessService(router, principal, stateStorageService) {
        this.router = router;
        this.principal = principal;
        this.stateStorageService = stateStorageService;
    }
    UserRouteAccessService.prototype.canActivate = function (route, state) {
        var authorities = route.data['authorities'];
        if (!authorities || authorities.length === 0) {
            return true;
        }
        return this.checkLogin(authorities, state.url);
    };
    UserRouteAccessService.prototype.checkLogin = function (authorities, url) {
        var _this = this;
        var principal = this.principal;
        return Promise.resolve(principal.identity().then(function (account) {
            if (account && principal.hasAnyAuthorityDirect(authorities)) {
                return true;
            }
            _this.stateStorageService.storeUrl(url);
            _this.router.navigate(['']);
            return false;
        }));
    };
    return UserRouteAccessService;
}());
exports.UserRouteAccessService = UserRouteAccessService;
