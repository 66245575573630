"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var stomp_config_1 = require("./user/util/stomp-config");
var ɵ0 = stomp_config_1.stompConfig;
exports.ɵ0 = ɵ0;
var LogbookSharedLibsModule = /** @class */ (function () {
    function LogbookSharedLibsModule() {
    }
    return LogbookSharedLibsModule;
}());
exports.LogbookSharedLibsModule = LogbookSharedLibsModule;
