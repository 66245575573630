import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {NotificationApiService} from "@logbook/notification-center/shared/service/notification.api.service";
import {LogbookNotification} from "@logbook/notification-center/shared/model/LogbookNotification";
import {MixpanelService} from "@logbook/core/tracking/mixpanel.service";
import {MixpanelEvent} from "@logbook/core/tracking/mixpanel-event.enum";
import {first} from "rxjs/operators";

@Injectable()
export class NotificationRouteAccessService implements CanActivate {

    constructor(private router: Router,
                private notificationService: NotificationApiService,
                private mixpanelService: MixpanelService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Promise<boolean> {
        this.redirectToNotif(route.queryParams.id);
        return false;
    }

    redirectToNotif(id: string) {
        this.notificationService.get(id).pipe(first()).subscribe((notif: LogbookNotification) => {
            if (notif) {
                notif = Object.assign(new LogbookNotification(), notif);
                this.notificationService.markAsRead(id, 'PUSH_NOTIFICATION').pipe(first()).subscribe(() => {
                    const notifLink = notif.getLink();
                    this.router.navigate(notifLink).then(() =>
                        this.mixpanelService.track(MixpanelEvent.NOTIFICATION.PUSH.CLICKED, {
                            notificationId: notif.id,
                            notificationType: notif.category,
                            notificationLink: notifLink,
                        })
                    );
                });
            } else {
                this.router.navigate(['/logs']);
            }
        });
    }
}
