"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var shared_1 = require("@logbook/shared");
var category_log_filter_model_1 = require("../category-tag/category-log-filter.model");
var LogForm = /** @class */ (function (_super) {
    tslib_1.__extends(LogForm, _super);
    function LogForm(messageId) {
        var _this = _super.call(this, messageId) || this;
        _this.isEditing = false;
        _this.tags = [];
        _this.flightTags = [];
        _this.metadata = new Map();
        _this.metadata = {
            authorType: "human",
            messageType: "TEXT"
        };
        return _this;
    }
    Object.defineProperty(LogForm.prototype, "tagsFilter", {
        get: function () {
            return this.tags.map(function (tag) {
                return new category_log_filter_model_1.CategoryLogFilter(tag.alias, 'messages.tags', 'equals', tag.value);
            });
        },
        enumerable: true,
        configurable: true
    });
    LogForm.prototype.isValid = function () {
        return _super.prototype.isValid.call(this) && this.flightDelayIsValid();
    };
    LogForm.prototype.flightDelayIsValid = function () {
        if (this.isEditing) {
            return true;
        }
        var isFlightDelay = this.tags.some(function (categoryTag) { return categoryTag.value === 'flightDelayed'; });
        if (isFlightDelay) {
            return this.flightTags.length > 0;
        }
        else {
            return true;
        }
    };
    LogForm.prototype.pushFlightTag = function (flightTag) {
        if (!this.flightTagIsAlreadyPresent(flightTag)) {
            this.flightTags.push(flightTag);
        }
    };
    LogForm.prototype.removeFlightTag = function (flightTag) {
        this.flightTags = this.flightTags.filter(function (flightTagInList) {
            return flightTagInList.toString() !== flightTag.toString();
        });
    };
    LogForm.prototype.flightTagIsAlreadyPresent = function (flightTag) {
        return this.flightTags.filter(function (flightTagInList) {
            return flightTagInList.toString() === flightTag.toString();
        }).length > 0;
    };
    return LogForm;
}(shared_1.MessageForm));
exports.LogForm = LogForm;
