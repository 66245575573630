import './vendor.ts';
import {NgModule} from '@angular/core';
import {MatCheckboxModule} from "@angular/material";
import {BrowserModule} from '@angular/platform-browser';
import {HttpClientModule} from "@angular/common/http";
import {ServiceWorkerModule} from "@angular/service-worker";
import {DeviceDetectorModule} from "ngx-device-detector";
import {NgxWebstorageModule} from 'ngx-webstorage';

import {
    CGU_VERSION, GOOGLE_ANALYTICS_TOKEN,
    INTERCOM_TOKEN, LOGBOOK_API,
    LOGBOOK_WEB_URL,
    MIXPANEL_TOKEN,
    TERMS_USER_AGREEMENT_URL,
    TERMS_PRIVACY_POLICY_URL,
    VERSION,
    VAPID_PUBLIC_KEY,
} from "@logbook/app.constants";
import {environment} from "../environments/environment";

import {LogbookSharedModule} from "@logbook/shared";
import {LogbookCoreModule} from "@logbook/core/core.module";
import {DlnMainComponent, ErrorRoutingModule} from "@logbook/core/layouts";

@NgModule({
    imports: [
        BrowserModule,
        HttpClientModule,
        ServiceWorkerModule.register('/ngsw-worker.js', { enabled: environment.production }),
        NgxWebstorageModule.forRoot({prefix: 'dln', separator: '-'}),
        LogbookCoreModule.forRoot({
            LOGBOOK_API,
            LOGBOOK_WEB_URL,
            MIXPANEL_TOKEN,
            GOOGLE_ANALYTICS_TOKEN,
            INTERCOM_TOKEN,
            CGU_VERSION,
            VERSION,
            TERMS_USER_AGREEMENT_URL,
            TERMS_PRIVACY_POLICY_URL,
            production: environment.production,
            hmr: environment.hmr,
            VAPID_PUBLIC_KEY,
        }),
        LogbookSharedModule,
        MatCheckboxModule,
        DeviceDetectorModule.forRoot(),
        ErrorRoutingModule // Must be last item in list
    ],
    providers: [],
    bootstrap: [DlnMainComponent]
})

export class LogbookAppModule {
}
