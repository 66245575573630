"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = ["[_nghost-%COMP%]{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;-ms-flex-align:center;-moz-align-items:center;align-items:center;-ms-flex-pack:start;-moz-justify-content:flex-start;-ms-justify-content:flex-start;justify-content:flex-start;-ms-flex-wrap:nowrap;flex-wrap:nowrap}[_nghost-%COMP%]   i[_ngcontent-%COMP%]{font-size:20px;line-height:25px;margin-right:5px}@media (max-width:768px){[_nghost-%COMP%]   i[_ngcontent-%COMP%]{font-size:35px;line-height:35px}}[_nghost-%COMP%]   span[_ngcontent-%COMP%]{font-weight:600;line-height:25px;font-size:16px}@media (max-width:768px){[_nghost-%COMP%]   span[_ngcontent-%COMP%]{font-size:18px}}"];
exports.styles = styles;
