"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function dlnEmailValidator() {
    return function (control) {
        // tslint:disable-next-line
        if (!control.value) {
            return null;
        }
        var emailRegexp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        var isEmail = emailRegexp.test(control.value);
        return !isEmail ? { 'isEmail': { value: control.value } } : null;
    };
}
exports.dlnEmailValidator = dlnEmailValidator;
function isEmailValid(val) {
    var emailRegexp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailRegexp.test(val);
}
exports.isEmailValid = isEmailValid;
