import {ComponentType} from "@angular/cdk/portal";

export class ModalConfig {
    width = '760px';
    height = 'auto';
    componentType: ComponentType<any>;
    data: any;
    panelClass: string | Array<string>;
    hasBackdrop = true;

    constructor(componentType, data?, width?, height?, panelClass?, hasBackdrop = true) {
        this.componentType = componentType;

        if (data) {
            this.data = data;
        }

        if (width) {
            this.width = width;
        }

        if (height) {
            this.height = height;
        }

        if (panelClass) {
            this.panelClass = panelClass;
        }

        this.hasBackdrop = hasBackdrop;
    }

}
