"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var community_service_1 = require("../../../../../../app/core/auth/services/community.service");
var operators_1 = require("rxjs/operators");
var CategoryTagComponent = /** @class */ (function () {
    function CategoryTagComponent(communityService, changeDetector) {
        this.communityService = communityService;
        this.changeDetector = changeDetector;
        this.closable = false;
        this.withBG = true;
    }
    CategoryTagComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (!this.color) {
            this.communityService.getCommunity().pipe(operators_1.first())
                .subscribe(function (community) {
                var completeTag = community.tags.find(function (tag) {
                    return tag.value && _this.tag && tag.value.toUpperCase() === _this.tag.toUpperCase();
                });
                _this.color = "";
                if (completeTag) {
                    _this.color = completeTag.color;
                    _this.tagAlias = completeTag.alias;
                }
                _this.changeDetector.markForCheck();
            });
        }
    };
    return CategoryTagComponent;
}());
exports.CategoryTagComponent = CategoryTagComponent;
