"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("@angular/common/http");
var app_constants_1 = require("../../../../app.constants");
var ExportLogsService = /** @class */ (function () {
    function ExportLogsService(http) {
        this.http = http;
    }
    ExportLogsService.prototype.export = function (type, params) {
        return this.http.get(app_constants_1.LOGBOOK_API + "/api/export/" + type, { params: params });
    };
    return ExportLogsService;
}());
exports.ExportLogsService = ExportLogsService;
