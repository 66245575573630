"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * Encoder custom pour palier au problème d'encodage du caractère +
 * [link] https://github.com/angular/angular/issues/11058
 */
var CustomHttpEncoder = /** @class */ (function () {
    function CustomHttpEncoder() {
    }
    CustomHttpEncoder.prototype.encodeKey = function (key) {
        return encodeURIComponent(key);
    };
    CustomHttpEncoder.prototype.encodeValue = function (value) {
        return encodeURIComponent(value);
    };
    CustomHttpEncoder.prototype.decodeKey = function (key) {
        return decodeURIComponent(key);
    };
    CustomHttpEncoder.prototype.decodeValue = function (value) {
        return decodeURIComponent(value);
    };
    return CustomHttpEncoder;
}());
exports.CustomHttpEncoder = CustomHttpEncoder;
