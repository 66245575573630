"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var operators_1 = require("rxjs/operators");
var http_1 = require("@angular/common/http");
var contact_filter_model_1 = require("../model/contact-filter.model");
var contact_model_1 = require("../model/contact.model");
var logbook_config_1 = require("../../../../../../app/core/shared/config/logbook-config");
var team_model_1 = require("../../../../../../app/core/shared/models/team.model");
var recipient_model_1 = require("../model/recipient.model");
var RecipientsService = /** @class */ (function () {
    function RecipientsService(http, config) {
        this.http = http;
        this.config = config;
        this.resourceUrl = config.LOGBOOK_API + '/api/recipients';
    }
    RecipientsService.prototype.findRecipients = function (value) {
        var options = new http_1.HttpParams();
        if (value && value.length) {
            options = options.set('search', value);
        }
        return this.http.get("" + this.resourceUrl, { params: options }).pipe(operators_1.map(function (res) {
            var contacts = res.CONTACT.map(function (contact) { return new contact_model_1.Contact(contact); });
            var teams = res.TEAM.map(function (team) { return Object.assign(new team_model_1.Team(), team); });
            var groups = res.GROUP.map(function (group) { return new contact_filter_model_1.ContactGroup(group, contacts); });
            return {
                "TEAM": teams,
                "CONTACT": contacts,
                "GROUP": groups
            };
        }));
    };
    RecipientsService.prototype.convertToConsigneRecipient = function (item) {
        var userRecipient = new recipient_model_1.Recipient();
        userRecipient.type = item.type;
        switch (item.type) {
            case recipient_model_1.RecipientType.GROUP:
                userRecipient.recipientId = item.params.label;
                break;
            case recipient_model_1.RecipientType.TEAM:
                userRecipient.recipientId = item.params.id;
                break;
            case recipient_model_1.RecipientType.USER:
                userRecipient.recipientId = item.params.userInfo.userId;
                break;
            case recipient_model_1.RecipientType.CONTACT:
                userRecipient.recipientId = item.params.email;
                userRecipient.contactId = item.params.id;
                break;
            case recipient_model_1.RecipientType.EMAIL:
                userRecipient.recipientId = item.id;
                break;
        }
        return userRecipient;
    };
    return RecipientsService;
}());
exports.RecipientsService = RecipientsService;
