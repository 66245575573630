<div class="title" mat-dialog-title>
    <h2 *ngIf="currentUser">
        Hello <span class="not-bold">{{currentUser.displayUserName()}}!</span>
    </h2>
    <div class="subtitle" modal-subtitle>
        We updated our Terms & Conditions and their access to improve our GDPR compliance and protect your personal information the best way possible.
    </div>
</div>
<mat-dialog-content>
    <div class="grid" *hideItBootstrap="['xs','sm']">
        <div class="item" (click)="showAccount()">
            <i class="icon-user"></i>
            <b>Your profile</b>
            Tell us about you
        </div>
        <div class="item" (click)="showIntercom()">
            <i class="icon-bubble"></i>
            <b>Live chat</b>
            We are here to help
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions>
    <div class="buttons" *hideItBootstrap="['xs','sm']">
        <button (click)="cancel()" *ngIf="signAsUser" class="cancel">Close</button>
        <div class="checkbox-container" *ngIf="!signAsUser && currentUser">
            <mat-checkbox [(ngModel)]="iRead">
                <span>
                     <ng-container *ngTemplateOutlet="cguLabel"></ng-container>
                </span>
            </mat-checkbox>

            <ng-template #cguLabel>
                I agree and I accept the
                <a [href]="userAgreementUrl" target="_blank">user agreement</a>
                <span> and </span>
                <a [href]="privacyPolicyUrl" target="_blank">privacy policy</a>
            </ng-template>

            <dln-button [label]="'Send'" (onClick)="readCGU()" color="white"
                        [isDisabled]="!iRead" [isLoadingLabel]="'Signing'"></dln-button>
        </div>
    </div>
    <div class="buttons" *showItBootstrap="['xs','sm']">
        <a [href]="userAgreementUrl" target="_blank" class="view-terms-button">
            View User Agreement
        </a>
        <a [href]="privacyPolicyUrl" target="_blank" class="view-terms-button">
            View Privacy Policy
        </a>
        <button (click)="cancel()" *ngIf="signAsUser" class="cancel">Close</button>
        <div class="checkbox-container" *ngIf="!signAsUser && currentUser">
            <mat-checkbox [(ngModel)]="iRead">
            </mat-checkbox>
            <div class="label" (click)="iRead = !iRead">
                <ng-container *ngTemplateOutlet="cguLabelMobile"></ng-container>
            </div>

            <ng-template #cguLabelMobile>
                I agree and I accept
            </ng-template>

            <dln-button [label]="'Send'" (onClick)="readCGU()" color="white"
                        [isDisabled]="!iRead" [isLoadingLabel]="'Signing'"></dln-button>
        </div>
    </div>
</mat-dialog-actions>
