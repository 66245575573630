"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var ngx_webstorage_1 = require("ngx-webstorage");
var http_1 = require("@angular/common/http");
var auth_interceptor_1 = require("@logbook/core/interceptor/auth.interceptor");
var auth_expired_interceptor_1 = require("@logbook/core/interceptor/auth-expired.interceptor");
var errorhandler_interceptor_1 = require("@logbook/core/interceptor/errorhandler.interceptor");
var notification_interceptor_1 = require("@logbook/core/interceptor/notification.interceptor");
var logbook_config_1 = require("./shared/config/logbook-config");
var LogbookCoreModule = /** @class */ (function () {
    function LogbookCoreModule(parentModule) {
        if (parentModule) {
            throw new Error("LogbookCoreModule has already been loaded. Import Core modules in the AppModule only.");
        }
    }
    LogbookCoreModule.forRoot = function (config) {
        return {
            ngModule: LogbookCoreModule,
            providers: [
                {
                    provide: logbook_config_1.LogbookConfig,
                    useValue: config
                },
                {
                    provide: http_1.HTTP_INTERCEPTORS,
                    useClass: auth_interceptor_1.AuthInterceptor,
                    multi: true,
                    deps: [
                        ngx_webstorage_1.LocalStorageService,
                        ngx_webstorage_1.SessionStorageService
                    ]
                }, {
                    provide: http_1.HTTP_INTERCEPTORS,
                    useClass: auth_expired_interceptor_1.AuthExpiredInterceptor,
                    multi: true,
                    deps: [
                        core_1.Injector
                    ]
                }, {
                    provide: http_1.HTTP_INTERCEPTORS,
                    useClass: errorhandler_interceptor_1.ErrorHandlerInterceptor,
                    multi: true,
                    deps: []
                }, {
                    provide: http_1.HTTP_INTERCEPTORS,
                    useClass: notification_interceptor_1.NotificationInterceptor,
                    multi: true,
                    deps: []
                }
            ]
        };
    };
    return LogbookCoreModule;
}());
exports.LogbookCoreModule = LogbookCoreModule;
