"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var router_1 = require("@angular/router");
var community_service_1 = require("../../auth/services/community.service");
var principal_service_1 = require("../../auth/services/principal.service");
var operators_1 = require("rxjs/operators");
var UserAlreadyLoggedRouteAccessService = /** @class */ (function () {
    function UserAlreadyLoggedRouteAccessService(router, principal, communityService) {
        this.router = router;
        this.principal = principal;
        this.communityService = communityService;
    }
    UserAlreadyLoggedRouteAccessService.prototype.canActivate = function (route, state) {
        return this.checkLogin();
    };
    UserAlreadyLoggedRouteAccessService.prototype.checkLogin = function () {
        var _this = this;
        return Promise.resolve(this.principal.identity().then(function (account) {
            if (!account || !_this.principal.hasAnyAuthorityDirect(["ROLE_USER"])) {
                return true;
            }
            else {
                return _this.communityService.getCommunity().pipe(operators_1.first()).toPromise().then(function (community) {
                    if (community && community.id) {
                        _this.router.navigate(['logs']).then();
                        return false;
                    }
                    else {
                        _this.router.navigate(['instructions']).then();
                        return false;
                    }
                }).catch(function () { return true; });
            }
        }).catch(function () {
            return false;
        }));
    };
    return UserAlreadyLoggedRouteAccessService;
}());
exports.UserAlreadyLoggedRouteAccessService = UserAlreadyLoggedRouteAccessService;
