"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var societe_model_1 = require("./societe.model");
var User = /** @class */ (function () {
    function User(userId, email, firstName, lastName, title, phones, company, typeCompany, positionInCompany, pendingCompany, enabled, authorities, localisation, password, anonymousUserId) {
        this.isSelected = false;
        this.acceptActivityTracking = true;
        this.userId = userId ? userId : null;
        this.firstName = firstName ? firstName : null;
        this.lastName = lastName ? lastName : null;
        this.title = title ? title : null;
        this.email = email ? email : null;
        this.phones = phones ? phones : null;
        this.company = company ? new societe_model_1.Societe(company) : null;
        this.typeCompany = typeCompany ? typeCompany : null;
        this.positionInCompany = positionInCompany ? positionInCompany : null;
        this.pendingCompany = pendingCompany ? pendingCompany : null;
        this.localisation = localisation ? localisation : null;
        this.enabled = enabled ? enabled : false;
        this.authorities = authorities ? authorities : ["ROLE_USER"];
        this.password = password ? password : null;
        this.anonymousUserId = anonymousUserId ? anonymousUserId : null;
        this.acceptActivityTracking = true;
    }
    User.prototype.displayUserName = function () {
        if (this.firstName && this.lastName) {
            return this.firstName + " " + this.lastName;
        }
        else if (this.positionInCompany) {
            return this.positionInCompany;
        }
        else if (this.email) {
            return this.email;
        }
        else if (this.userId) {
            return this.userId;
        }
        else {
            return undefined;
        }
    };
    User.prototype.displayUserNameWithStyle = function () {
        if (this.firstName && this.lastName) {
            return this.firstName + " <b> " + this.lastName + " </b>";
        }
        else if (this.positionInCompany) {
            return this.positionInCompany;
        }
        else if (this.email) {
            return this.email;
        }
        else {
            return this.userId;
        }
    };
    User.prototype.getCompanyName = function () {
        return this.company != null ? this.company.nom : "";
    };
    User.prototype.displayAuthorities = function () {
        return this.authorities ?
            this.authorities.filter(function (authority) { return ["ROLE_USER", "ROLE_ADMIN", "ROLE_TEAM_MANAGER"].indexOf(authority) >= 0; })
                .map(function (authority) {
                switch (authority) {
                    case "ROLE_ADMIN":
                        return "admin";
                    case "ROLE_TEAM_MANAGER":
                        return "manager";
                    default:
                        return "user";
                }
            })
                .join(", ")
            : null;
    };
    User.prototype.isAdmin = function () {
        return this.authorities ? this.authorities.some(function (role) { return role === 'ROLE_TEAM_MANAGER'; }) : false;
    };
    User.prototype.isSuperAdmin = function () {
        return this.authorities ? this.authorities.some(function (role) { return role === 'ROLE_ADMIN'; }) : false;
    };
    return User;
}());
exports.User = User;
