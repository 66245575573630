import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import {Color} from "../../model/color.model";
import {MessageForm} from "../../model/message-form.model";

import {WindowRef} from "../../service/window.service";
import {KeyCode} from "../ng-select/ng-select.types";
import {AttachmentService} from "./attach-file/service/attachment.service";
import {PieceJointe} from "./attach-file/model/piece-jointe.model";
import {first} from "rxjs/operators";

@Component({
    selector: 'dln-text-editor',
    templateUrl: './text-editor.component.html',
    styleUrls: ['./text-editor.scss']
})
// TODO test + design
export class TextEditorComponent implements AfterViewInit, OnChanges {

    colors: Array<Color>;
    colorSelected: Color;
    isFocused = false;
    activeCommands: Array<string>;
    @ViewChild('textarea') textArea: ElementRef;
    @ViewChild('inputFile') inputFile: any;
    @Input() messageForm: MessageForm;
    @Input() disabled: boolean;
    @Input() invalid: boolean;
    @Input() withAttachFile = true;
    @Input() multipleAttachFile = false;
    @Input() tabIndex: number;
    @Input() placeholder = 'Write here...';
    @Output() messageFormChange = new EventEmitter<MessageForm>();

    constructor(private $window: WindowRef, private attachmentService: AttachmentService) {
        this.colors = Color.getDefaultColors();
        this.activeCommands = [];
        this.colorSelected = this.colors[0];
        if (this.messageForm == null) {
            this.messageForm = new MessageForm();
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        this.textAreaInit();
    }

    ngAfterViewInit() {
        this.textAreaInit();
    }

    public onFileChange(event) {
        const target = event.target || event.srcElement;
        this.uploadFiles(target.files);

        this.inputFile.nativeElement.value = "";
    }

    private uploadFiles(files: any[]) {
        if (this.multipleAttachFile) {
            Array.from(files).forEach((file) => {
                this.attachmentService.postFile(this.messageForm, file).pipe(first()).subscribe(() => {
                    this.messageFormChange.emit(this.messageForm);
                });
            });

        } else {
            if (files && files[0]) {
                this.messageForm.files.push(files[0]);
                this.messageFormChange.emit(this.messageForm);
            }
        }
    }

    public removeFile(indexToRemove) {
        this.messageForm.files = this.messageForm.files.filter((val, index) =>
            index !== indexToRemove
        );
        this.messageFormChange.emit(this.messageForm);
    }

    public fileIsPJ(file) {
        return file instanceof PieceJointe;
    }

    public selectColor(color: Color) {
        this.colorSelected = color;
        this.execCommand('forecolor', 'FONT', color.value);
        this.onBodyChange();
    }

    public execCommand(command, commandTagName, commandValue) {
        this.textArea.nativeElement.focus();
        document.execCommand(command, false, commandValue);
        if (this.isCommandIsSelected(commandTagName)) {
            this.activeCommands = this.activeCommands.filter((cmd) => cmd !== commandTagName);
        } else {
            this.activeCommands.push(commandTagName);
        }
        this.onBodyChange();
    }

    public isCommandIsSelected(commandTagName) {
        return this.activeCommands.some((tagName) => tagName === commandTagName);
    }

    public onBodyChange() {
        this.messageForm.body = this.textArea.nativeElement.innerHTML;
        this.messageFormChange.emit(this.messageForm);
    }

    public updateSelectedCommand($event) {
        this.onBodyChange();
        const eventType = $event.which;
        if (eventType !== KeyCode.Backspace && eventType !== KeyCode.Enter) {
            let node = this.getSelectedNode();
            this.activeCommands = [];
            while (node.parentNode && node.parentNode["id"] !== this.textArea.nativeElement.id) {
                const tagName = node.parentNode["tagName"];
                if (tagName === "FONT") {
                    this.colorSelected = this.colors
                        .find((color) =>
                            color["value"].toUpperCase() === node.parentNode["color"].toUpperCase()
                        );
                }
                this.activeCommands.push(tagName);
                node = node.parentNode;
            }
            if (!this.isCommandIsSelected("FONT")) {
                this.colorSelected = this.colors[0];
            }
        }

    }

    @HostListener('paste', ['$event'])
    onPaste(ev) {
        if (ev.clipboardData && ev.clipboardData.items) {
            for (let i = 0; i < ev.clipboardData.items.length; i++) {
                const item = ev.clipboardData.items[i];
                if (item.kind === 'file') {
                    ev.preventDefault();
                    const asFile = item.getAsFile() ? [item.getAsFile()] : null;
                    if (asFile) {
                        this.uploadFiles(asFile);
                    }
                }
            }
        } else {
            setTimeout(() => this.onBodyChange())
        }
    }

    private getSelectedNode() {
        if (this.$window.nativeWindow.getSelection) {
            const selection = this.$window.nativeWindow.getSelection();
            if (selection.rangeCount > 0) {
                return selection.getRangeAt(0).startContainer;
            }
        }
    }

    private textAreaInit() {
        this.textArea.nativeElement.innerHTML = this.messageForm.body;
    }

}
