"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var environment_1 = require("../environments/environment");
exports.LOGBOOK_API = environment_1.environment.LOGBOOK_API;
exports.LOGBOOK_WEB_URL = environment_1.environment.LOGBOOK_WEB_URL;
exports.MIXPANEL_TOKEN = environment_1.environment.MIXPANEL_TOKEN;
exports.GOOGLE_ANALYTICS_TOKEN = environment_1.environment.GOOGLE_ANALYTICS_TOKEN;
exports.INTERCOM_TOKEN = environment_1.environment.INTERCOM_TOKEN;
exports.CGU_VERSION = environment_1.environment.CGU_VERSION;
exports.TERMS_USER_AGREEMENT_URL = environment_1.environment.TERMS_USER_AGREEMENT_URL;
exports.TERMS_PRIVACY_POLICY_URL = environment_1.environment.TERMS_PRIVACY_POLICY_URL;
exports.VERSION = "" + environment_1.environment.VERSION;
exports.VAPID_PUBLIC_KEY = environment_1.environment.VAPID_PUBLIC_KEY;
