"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var rxjs_1 = require("rxjs");
var operators_1 = require("rxjs/operators");
var http_1 = require("@angular/common/http");
var ngx_webstorage_1 = require("ngx-webstorage");
var logbook_config_1 = require("../../shared/config/logbook-config");
var i0 = require("@angular/core");
var i1 = require("@angular/common/http");
var i2 = require("../../shared/config/logbook-config");
var i3 = require("ngx-webstorage");
var AuthServerProvider = /** @class */ (function () {
    function AuthServerProvider(http, config, $localStorage, $sessionStorage) {
        this.http = http;
        this.config = config;
        this.$localStorage = $localStorage;
        this.$sessionStorage = $sessionStorage;
    }
    AuthServerProvider.prototype.getToken = function () {
        return this.$sessionStorage.retrieve('signAsAuthenticationToken')
            || this.$localStorage.retrieve('authenticationToken') || this.$sessionStorage.retrieve('authenticationToken');
    };
    AuthServerProvider.prototype.login = function (credentials) {
        var data = {
            username: credentials.username,
            password: credentials.password,
            rememberMe: credentials.rememberMe
        };
        return this.http.post(this.config.LOGBOOK_API + "/api/authenticate", data, { observe: "response" }).pipe(operators_1.map(authenticateSuccess.bind(this)), operators_1.catchError(function (err) {
            return rxjs_1.throwError(err);
        }));
        function authenticateSuccess(resp) {
            var bearerToken = resp.headers.get('Authorization');
            if (bearerToken && bearerToken.slice(0, 7) === 'Bearer ') {
                var jwt = bearerToken.slice(7, bearerToken.length);
                this.storeAuthenticationToken(jwt, credentials.rememberMe);
                return jwt;
            }
        }
    };
    AuthServerProvider.prototype.signAsUser = function (email, prevUrl) {
        return this.http.post(this.config.LOGBOOK_API + "/api/sign-as-user", email, { observe: "response" }).pipe(operators_1.map(authenticateSuccess.bind(this)), operators_1.catchError(function (err) {
            return rxjs_1.throwError(err);
        }));
        function authenticateSuccess(resp) {
            var bearerToken = resp.headers.get('Authorization');
            if (bearerToken && bearerToken.slice(0, 7) === 'Bearer ') {
                var jwt = bearerToken.slice(7, bearerToken.length);
                this.storeSignAsAuthenticationToken(jwt, prevUrl);
                return jwt;
            }
        }
    };
    AuthServerProvider.prototype.loginWithToken = function (jwt, rememberMe) {
        if (jwt) {
            this.storeAuthenticationToken(jwt, rememberMe);
            return Promise.resolve(jwt);
        }
        else {
            return Promise.reject('auth-jwt-services Promise reject'); // Put appropriate error message here
        }
    };
    AuthServerProvider.prototype.storeAuthenticationToken = function (jwt, rememberMe) {
        if (rememberMe) {
            this.$localStorage.store('authenticationToken', jwt);
        }
        else {
            this.$sessionStorage.store('authenticationToken', jwt);
        }
    };
    AuthServerProvider.prototype.storeSignAsAuthenticationToken = function (jwt, prevUrl) {
        this.$sessionStorage.store('signAsAuthenticationToken', jwt);
        this.$sessionStorage.store('signAsPrevUrl', prevUrl);
    };
    AuthServerProvider.prototype.logout = function () {
        var _this = this;
        return new rxjs_1.Observable(function (observer) {
            _this.$localStorage.clear('authenticationToken');
            _this.$sessionStorage.clear('authenticationToken');
            _this.$sessionStorage.clear('signAsAuthenticationToken');
            observer.complete();
        });
    };
    AuthServerProvider.prototype.logoutSignAs = function () {
        var _this = this;
        return new rxjs_1.Observable(function (observer) {
            var prevUrl = _this.$sessionStorage.retrieve('signAsPrevUrl');
            _this.$sessionStorage.clear('signAsAuthenticationToken');
            _this.$sessionStorage.clear('signAsPrevUrl');
            observer.next(prevUrl);
        });
    };
    AuthServerProvider.ngInjectableDef = i0.defineInjectable({ factory: function AuthServerProvider_Factory() { return new AuthServerProvider(i0.inject(i1.HttpClient), i0.inject(i2.LogbookConfig), i0.inject(i3.LocalStorageService), i0.inject(i3.SessionStorageService)); }, token: AuthServerProvider, providedIn: "root" });
    return AuthServerProvider;
}());
exports.AuthServerProvider = AuthServerProvider;
