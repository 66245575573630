import {Moment} from "moment";
import * as momentImported from 'moment'; const moment = momentImported;
import {ChangeLogAck} from "./change-log-ack.model";
import {Feature} from "./feature.model";

export class ChangeLog {
    id: string;
    enable = false;
    version: string;
    releaseDate: Date;
    updateDate: Date;
    features: Feature[] = [];

    isSelected = false;
    highlight = false;
    releaseMoment: Moment;
    updateMoment: Moment;

    constructor(build?) {
        this.releaseDate = new Date();
        if (build) {
            this.id = build.id;
            this.enable = build.enable;
            this.version = build.version;
            this.releaseDate = build.version;
            this.releaseDate = build.releaseDate;
            this.updateDate = build.updateDate;
            this.features = build.features.map((feature) => Object.assign(new Feature(feature)));
        }
        this.releaseMoment = moment(this.releaseDate);

        if (this.updateDate) {
            this.updateMoment = moment(this.updateDate);
        }
    }

    isRead(ack: ChangeLogAck): boolean {
        return ack && ack.updateMoment.isAfter(this.updateMoment);
    }
}
