"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var operators_1 = require("rxjs/operators");
var http_1 = require("@angular/common/http");
var NotificationInterceptor = /** @class */ (function () {
    function NotificationInterceptor() {
    }
    NotificationInterceptor.prototype.intercept = function (request, next) {
        return next.handle(request).pipe(operators_1.tap(function (event) {
            if (event instanceof http_1.HttpResponse) {
                var arr = event.headers.keys();
                var alert_1 = null;
                arr.forEach(function (entry) {
                    if (entry.endsWith('app-alert')) {
                        alert_1 = event.headers.get(entry);
                    }
                });
                if (alert_1) {
                }
            }
        }, function (err) {
        }));
    };
    return NotificationInterceptor;
}());
exports.NotificationInterceptor = NotificationInterceptor;
