"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var rxjs_1 = require("rxjs");
var models_1 = require("@logbook/logs-view/shared/models");
var i0 = require("@angular/core");
var LogsViewManagementService = /** @class */ (function () {
    function LogsViewManagementService() {
        this.activeComponents = new models_1.ActiveComponents();
        this.observerRefreshActiveComponents = [];
        this.listenerRefreshActiveComponents = this.createListener();
    }
    LogsViewManagementService.prototype.subscribeActiveComponents = function () {
        return this.listenerRefreshActiveComponents;
    };
    LogsViewManagementService.prototype.expandWriteLogForm = function (clear, forced, forcedValue) {
        if (clear === void 0) { clear = false; }
        if (forced === void 0) { forced = false; }
        if (forcedValue === void 0) { forcedValue = true; }
        if (clear) {
            this.activeComponents.writeLogFormExpanded = false;
            this.activeComponents.writeLogFormClear = true;
        }
        else {
            this.activeComponents.writeLogFormClear = false;
            if (forced) {
                this.activeComponents.writeLogFormExpanded = forcedValue;
            }
            else {
                this.activeComponents.writeLogFormExpanded = !this.activeComponents.writeLogFormExpanded;
            }
        }
        this.notifyObserver();
    };
    LogsViewManagementService.prototype.expandLeftPanel = function () {
        this.activeComponents.leftPanelExpanded = !this.activeComponents.leftPanelExpanded;
        if (this.activeComponents.leftPanelExpanded) {
            this.activeComponents.rightPanelExpanded = false;
        }
        this.notifyObserver();
    };
    LogsViewManagementService.prototype.expandRightPanel = function () {
        this.activeComponents.rightPanelExpanded = !this.activeComponents.rightPanelExpanded;
        if (this.activeComponents.rightPanelExpanded) {
            this.activeComponents.leftPanelExpanded = false;
        }
        this.notifyObserver();
    };
    LogsViewManagementService.prototype.notifyObserver = function () {
        var _this = this;
        this.observerRefreshActiveComponents.forEach(function (observer) {
            return observer.next(_this.activeComponents);
        });
    };
    LogsViewManagementService.prototype.createListener = function () {
        var _this = this;
        return new rxjs_1.Observable(function (observer) {
            _this.observerRefreshActiveComponents.push(observer);
        });
    };
    LogsViewManagementService.ngInjectableDef = i0.defineInjectable({ factory: function LogsViewManagementService_Factory() { return new LogsViewManagementService(); }, token: LogsViewManagementService, providedIn: "root" });
    return LogsViewManagementService;
}());
exports.LogsViewManagementService = LogsViewManagementService;
