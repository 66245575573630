"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@angular/material");
var ConfirmModalComponent = /** @class */ (function () {
    function ConfirmModalComponent(dialogRef, data) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.hideCancel = false;
        this.title = data.title;
        this.message = data.message;
        this.hideCancel = data.hideCancel;
    }
    ConfirmModalComponent.prototype.cancel = function () {
        this.dialogRef.close(false);
    };
    ConfirmModalComponent.prototype.confirm = function () {
        this.dialogRef.close(true);
    };
    return ConfirmModalComponent;
}());
exports.ConfirmModalComponent = ConfirmModalComponent;
