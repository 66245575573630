import {Pageable} from "./pageable";

export class Page<T> {
    page = 0;
    totalPages = 0;
    totalElements = 0;
    content: T[] = [];
    size = 0;
    sort: any;
    isLoading: boolean;

    constructor(res?) {
        if (res) {
            this.page = res.number;
            this.content = res.content;
            this.totalPages = res.totalPages;
            this.totalElements = res.totalElements;
            this.size = res.size;
            this.sort = res.sort;
        }
    }

    toPageRequest(): Pageable {
        return new Pageable(this.page, this.size, this.sort);
    }
}
