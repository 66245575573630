import { Injectable } from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Resolve} from '@angular/router';
import {Observable, of} from 'rxjs';
import {delay, map, tap} from "rxjs/operators";
import {SessionStorageService} from 'ngx-webstorage';

@Injectable({
  providedIn: 'root'
})
export class AccessFromCuteStationGuard implements Resolve<Observable<any>> {

    constructor(private $sessionStorage: SessionStorageService) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Observable<any>> | Promise<Observable<any>> | Observable<any> {
        return of(route.queryParams).pipe(
            map((params) => params ? params.cuteStation : null),
            tap((res) => {
                if (!!res) {
                    this.$sessionStorage.store("disableAccessToFileSystem", true)
                }
            }),
        );
    }
}
