"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var rxjs_1 = require("rxjs");
var operators_1 = require("rxjs/operators");
var DraggableDirective = /** @class */ (function () {
    function DraggableDirective(elementRef, zone) {
        this.elementRef = elementRef;
        this.zone = zone;
        this.delta = { x: 0, y: 0 };
        this.offset = { x: 0, y: 0 };
        this.destroy$ = new rxjs_1.Subject();
    }
    DraggableDirective.prototype.ngAfterViewInit = function () {
        this.handle = this.dragHandle ? document.querySelector(this.dragHandle) : this.elementRef.nativeElement;
        this.target = document.querySelector(this.dragTarget);
        this.setupEvents();
    };
    DraggableDirective.prototype.ngOnDestroy = function () {
        this.destroy$.next();
    };
    DraggableDirective.prototype.setupEvents = function () {
        var _this = this;
        this.zone.runOutsideAngular(function () {
            var mousedown$ = rxjs_1.fromEvent(_this.handle, 'mousedown');
            var mousemove$ = rxjs_1.fromEvent(document, 'mousemove');
            var mouseup$ = rxjs_1.fromEvent(document, 'mouseup');
            var mousedrag$ = mousedown$.pipe(operators_1.switchMap(function (event) {
                var startX = event.clientX;
                var startY = event.clientY;
                return mousemove$.pipe(operators_1.map(function (e) {
                    e.preventDefault();
                    _this.delta = {
                        x: e.clientX - startX,
                        y: e.clientY - startY
                    };
                }), operators_1.takeUntil(mouseup$));
            }), operators_1.takeUntil(_this.destroy$));
            mousedrag$.pipe(operators_1.takeUntil(_this.destroy$)).subscribe(function () {
                if (_this.delta.x === 0 && _this.delta.y === 0) {
                    return;
                }
                _this.translate();
            });
            mouseup$.pipe(operators_1.takeUntil(_this.destroy$)).subscribe(function () {
                _this.offset.x += _this.delta.x;
                _this.offset.y += _this.delta.y;
                _this.delta = { x: 0, y: 0 };
            });
        });
    };
    DraggableDirective.prototype.translate = function () {
        var _this = this;
        requestAnimationFrame(function () {
            _this.target.style.transform = "\n        translate(" + (_this.offset.x + _this.delta.x) + "px,\n                  " + (_this.offset.y + _this.delta.y) + "px)\n      ";
        });
    };
    return DraggableDirective;
}());
exports.DraggableDirective = DraggableDirective;
