import {FlightTag} from "@logbook/shared";
import {Log} from "../logs/log.model";

export class LogFilter {
    name: string;
    key: string;
    operation: string;
    value: any;

    constructor(name?: string, key?: string, operation?: string, value?: any) {
        this.name = name;
        this.key = key;
        this.operation = operation;
        this.value = value;
    }

    isPresentInLog(log: Log) {
        switch (this.operation) {
            case "equals":
                return this.isValueIsEqual(log);
            case "not equals":
                return !this.isValueIsEqual(log);
            case "more than":
                // DATE => return always true
                return true;
            case "less than":
                // DATE => return always true
                return true;
        }
        return false;
    }

    isValueIsEqual(log: Log) {
        const keySplit = this.key.split(".");

        if (this.key.indexOf("metadata") !== -1) {
            const mapKey = keySplit[keySplit.length - 1];
            return log.messages.metadata.get(mapKey) === this.value.toString();
        } else {
            if (this.key.indexOf('tags') !== -1) {
                return log.messages.tags.some((tag) => this.value);
            } else {
                if (this.key === 'tagVol') {
                    const flightTag = FlightTag.createFlightTag(log.tagVol);
                    return flightTag.toStringWithoutDate() === this.value;
                } else {
                    let logValue = log;
                    keySplit.forEach((key) => logValue = logValue[key]);
                    return logValue === this.value;
                }
            }
        }
    }

}
