"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("@angular/common/http");
var Pageable = /** @class */ (function () {
    function Pageable(page, size, sort) {
        this.page = page;
        this.size = size;
        this.sort = sort;
    }
    Pageable.prototype.toOptions = function () {
        var options = new http_1.HttpParams()
            .set('page', this.page.toString())
            .set('size', this.size.toString())
            .set('sort', this.sort);
        return options;
    };
    return Pageable;
}());
exports.Pageable = Pageable;
