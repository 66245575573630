
import {map} from 'rxjs/operators';
import {HttpClient, HttpParams} from "@angular/common/http";
import {Injectable, OnDestroy} from '@angular/core';
import {Moment} from "moment";
import {BehaviorSubject, Observer, Subject, Observable} from "rxjs";
import {LOGBOOK_API} from "@logbook/app.constants";
import {FlightTagWithThreads} from "@logbook/shared";
import {FiltersService} from "../log/filters.service";
import {Filters} from "@logbook/logs-view/shared/models";
import {Subscription} from "rxjs/internal/Subscription";

@Injectable()
export class FlightTagService implements OnDestroy {

    private listenerRefreshFlights: BehaviorSubject<FlightTagWithThreads[]>;
    private previousSelectedDate: string;
    private selectedDate: Moment;
    private flightList: Array<FlightTagWithThreads>;
    private filtersSub: Subscription;

    constructor(private http: HttpClient, private filtersService: FiltersService) {
        this.listenerRefreshFlights = this.createListener();
        this.filtersSub = this.filtersService.subscribeFilters().subscribe((filters: Filters) => {
            // RELOAD FLIGHT ONLY IF DATE CHANGED
            if (this.previousSelectedDate !== filters.date.toISOString()) {
                this.selectedDate = filters.date;
                this.previousSelectedDate = filters.date.toISOString();
                this.findFlights();
            }
        });
    }

    subscribeRefreshFlights(): BehaviorSubject<FlightTagWithThreads[]> {
        return this.listenerRefreshFlights;
    }

    findFlights() {
        const options: HttpParams = new HttpParams()
            .set('startDate', this.selectedDate.format("DD-MM-YYYY"))
            .set('endDate', this.selectedDate.format("DD-MM-YYYY"));
        return this.http.get(`${LOGBOOK_API}/api/flightTag`, {params: options})
            .pipe(map((res: any) => res.flightTags.map((entry) => FlightTagWithThreads.fromJson(entry))))
            .subscribe((flightList: Array<FlightTagWithThreads>) => {
                // HIDE GENERAL TAG
                this.flightList = flightList.filter(
                    (flightTagWithThreads) =>
                        !flightTagWithThreads.flightTag.isGeneralFlight()
                );
                this.notifyObserver();
            });
    }

    private notifyObserver() {
        this.listenerRefreshFlights.next(
            FlightTagWithThreads.sort(this.flightList)
        );
    }

    private createListener(): BehaviorSubject<any> {
        return new BehaviorSubject(null);
    }

    ngOnDestroy() {
        if (this.filtersSub) {
            this.filtersSub.unsubscribe();
        }
    }

}
