"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var momentImported = require("moment");
var moment = momentImported;
var PrettyDatePipe = /** @class */ (function () {
    function PrettyDatePipe() {
    }
    PrettyDatePipe.prototype.transform = function (date) {
        try {
            var utcDate = moment.utc(date);
            var today = moment.utc();
            var yesterday = moment.utc().subtract(1, 'days').startOf('day');
            if (utcDate.isValid()) {
                if (utcDate.isSame(today, 'day')) {
                    // TODAY
                    return 'at ' + utcDate.format("HH:mm") + " GMT";
                }
                else {
                    if (utcDate.isSame(yesterday, 'day')) {
                        // YESTERDAY
                        return "Yesterday at " + utcDate.format("HH:mm") + " GMT";
                    }
                    else {
                        return 'on ' + utcDate.format("ll") + " at " + utcDate.format("HH:mm") + " GMT";
                    }
                }
            }
        }
        catch (e) {
            return null;
        }
    };
    return PrettyDatePipe;
}());
exports.PrettyDatePipe = PrettyDatePipe;
