import {first, map, startWith} from 'rxjs/operators';
import {HttpResponse} from "@angular/common/http";
import {Injectable, OnDestroy} from '@angular/core';
import {
    UserService,
    NotificationWsService,
    FlightTag,
    LogEventType,
    PieceJointe,
    NotificationsService
} from "@logbook/shared";
import {Observer, Observable, Subscription, Subject} from "rxjs";
import {FiltersService} from "./filters.service";
import {LogbookLogsApiService} from "./logs.api.service";
import {Principal} from "../../../../core/auth/services/principal.service";
import {Filters, Log, LogGroup, Message, MessageGroupID} from "@logbook/logs-view/shared/models";

@Injectable({
    providedIn: 'root'
})
export class LogbookLogsService implements OnDestroy {
    private listenerRefreshLogs: Subject<any>;
    private listernersLogUpdate: Map<string, Subject<Log>> = new Map<string, Subject<Log>>();
    private groupedLogs: Map<string, LogGroup>;
    private selectedFilters: Filters;
    private page: number;
    public hasMoreContentToFetch = true;
    public hasFinishLoadNewLogs = true;
    public filtersSub: Subscription;
    public wsMessageEdite: Subscription;
    public wsMessageSupprime: Subscription;
    public wsLogUseful: Subscription;

    private static getLogKey(log: Log): string {
        return (log.messages.metadata && log.messages.metadata['groupedLog']) ?
            log.messages.metadata['groupedLog'] : log.messages.messageId.id;
    }

    constructor(private userService: UserService, private filtersService: FiltersService,
                private logsApiService: LogbookLogsApiService, private notificationsService: NotificationsService,
                private principal: Principal, private notificationWsService: NotificationWsService) {
        this.listenerRefreshLogs = this.createListener();

        this.filtersSub = this.filtersService.subscribeFilters().subscribe((filters: Filters) => {
            this.selectedFilters = filters;
            this.hasMoreContentToFetch = true;
            this.page = 0;
            this.groupedLogs = new Map();
            this.notifyObserver();
            this.fetchLogs();
        });

        this.principal.getAuthenticationState()
            .pipe(startWith(this.principal.identityDirect()))
            .subscribe((identity) => {
                if (identity != null) {
                    const currentUser = identity;

                    this.wsMessageEdite = this.notificationWsService.onEventPublished(LogEventType.MessageEdite)
                        .subscribe((notif) => {
                            if (currentUser.userId !== notif.invoqueurId.id) {
                                this.logsApiService
                                    .findLog(new MessageGroupID(notif.message.messageId, notif.filInformations.id))
                                    .pipe(first())
                                    .subscribe((res) => this.updateLog(res));
                            }
                        });

                    this.wsMessageSupprime = this.notificationWsService.onEventPublished(LogEventType.MessageSupprimeOuFerme)
                        .subscribe((notif) => {
                            if (currentUser.userId !== notif.invoqueurId.id) {
                                this.logsApiService
                                    .findLog(new MessageGroupID(notif.messageId, notif.filInformations.id))
                                    .pipe(first())
                                    .subscribe((res) => {
                                        this.groupedLogs.delete(LogbookLogsService.getLogKey(res));
                                        this.notifyObserver();
                                    });
                            }
                        });

                    this.wsLogUseful = this.notificationWsService.onEventPublished(LogEventType.LogUsefulChanged)
                        .subscribe((notif) => {
                            this.logsApiService
                                .findLog(new MessageGroupID(notif.messageId, notif.filInformationsId))
                                .pipe(first())
                                .subscribe((res) => this.updateLog(res));
                        });
                }
            });
    }

    subscribeLogs(): Observable<any> {
        return this.listenerRefreshLogs;
    }

    subscribeLog(groupId: string) {
        if (!this.listernersLogUpdate.has(groupId)) {
            const subject = new Subject<Log>();
            // TODO Fuite de mémoire potentiel il faudrait reussir à supprimer l'entrée de la map quand toutes les subscriptions ont été stoppées
            this.listernersLogUpdate.set(groupId, subject)
        }
        return this.listernersLogUpdate.get(groupId);
    }

    postLog(formData) {
        return this.logsApiService.postLog(formData).pipe(
            map((res: HttpResponse<Log[]>) => res.body),
            map((logs: Log[]) => {
                logs.forEach((log) => {
                    log.isNew = true;

                    if (this.filtersService.logIsInSelectedFilters(log)) {
                        this.addNewLogsToList([log]);
                    } else {
                        const flightTag = FlightTag.createFlightTag(log.tagVol);
                        let info = "Your log for the flight " + flightTag.toStringWithoutDate() + " has been successfully sent";

                        if (flightTag.isGeneralFlight()) {
                            info = "Your general log has been successfully sent";
                        }

                        this.notificationsService.success("Log created !", info);
                    }
                });

                return logs;
            })
        );
    }

    editLog(threadId, formData) {
        return this.logsApiService.editLog(threadId, formData).pipe(
            map((log: Log) => {
                this.updateLog(log);
                return log;
            })
        );
    }

    removeLog(threadId, messageId) {
        return this.logsApiService.removeLog(threadId, messageId).pipe(
            map((res: HttpResponse<any>) => {
                this.removeLogFromList(threadId, messageId);
                return res;
            }));
    }

    addNewLogsToListById(messageGroupIDS: MessageGroupID[]) {
        let callRemaining = messageGroupIDS.length;
        this.hasFinishLoadNewLogs = false;
        const decountCall = () => {
            callRemaining--;
            if (callRemaining === 0) {
                this.hasFinishLoadNewLogs = true;
            }
        };
        messageGroupIDS.forEach((messageGroupID) => {
            this.logsApiService.findLog(messageGroupID).pipe(first())
                .subscribe(
                    (res) => {
                        res.isNew = true;
                        this.addNewLogsToList([res]);
                        decountCall()
                    },
                    (err) => decountCall())
            ;
        });
    }

    addNewLogsToList(logs: Log[]) {
        this.groupedLogs = this.groupLogs(logs);
        this.notifyObserver();
    }

    updateLog(log: Log) {
        const logGroup: LogGroup = this.findLogGroup(log);
        if (logGroup != null) {
            logGroup.setMessage(log.messages);
            logGroup.update++;
            this.groupedLogs.set(LogbookLogsService.getLogKey(log), Object.assign(new LogGroup(), logGroup));
        }
        this.notifyObserver();
        this.notifyLogObserver(log);
    }

    updateLogComment(log: Log, commentId: string) {
        const logGroup: LogGroup = this.findLogGroup(log);

        if (logGroup != null) {
            const oldComment = this.findComment(logGroup.message, commentId);
            const newComment = this.findComment(log.messages, commentId);
            oldComment.corps = newComment.corps;
            oldComment.metadata = newComment.metadata;
            newComment.pieceJointes = newComment.pieceJointes.map((pj) => new PieceJointe(pj));
            oldComment.pieceJointes = newComment.pieceJointes;
            logGroup.setMessage(log.messages);
            this.groupedLogs.set(LogbookLogsService.getLogKey(log), Object.assign(new LogGroup(), logGroup))
        }

        this.notifyObserver();
        this.notifyLogObserver(log)
    }

    fetchLogs() {
        this.logsApiService.fetchLogs(this.selectedFilters, this.page).pipe(
            map((res) => {
                if (!res || !res.length) {
                    this.hasMoreContentToFetch = false;
                }
                return this.groupLogs(res)
            })
        ).pipe(first()).subscribe((data) => {
            this.groupedLogs = data;
            this.notifyObserver();
        });
    }

    loadMore() {
        this.page++;
        this.fetchLogs();
    }

    getLogByGroupId(date, logGroupedId) {
        return this.logsApiService
            .findLogByGroupedId(date, logGroupedId)
            .pipe(
                map((res: Log[]) => {
                    let logs: LogGroup = null;
                    res.forEach((value, index) => {
                        if (logs == null) {
                            logs = new LogGroup(LogbookLogsService.getLogKey(value), value)
                        } else {
                            logs.addLog(value)
                        }
                        return logs;
                    });
                    return logs
                })
            )
    }

    private createListener(): Subject<any> {
        return new Subject();
    }

    private removeLogFromList(threadId, messageIdBase64) {
        let logKey;
        this.groupedLogs.forEach((value, key) => {
            const filters = value.messageIds.filter((id) =>
                id.threadId === threadId && id.messageIdBase64 === messageIdBase64
            );
            if (filters.length > 0) {
                logKey = key;
            }
        });
        if (logKey) {
            this.groupedLogs.delete(logKey);
            this.notifyObserver();
        }
    }

    private groupLogs(res: Log[]): Map<string, LogGroup> {
        return res.reduce((previousValue: Map<string, LogGroup>, currentValue) => {
            const key = LogbookLogsService.getLogKey(currentValue);
            if (!currentValue.messages.tags) {
                currentValue.messages.tags = [];
            }

            if (previousValue.has(key)) {
                const logGroup = previousValue.get(key);
                logGroup.addLog(currentValue);
            } else {
                const logGroup = new LogGroup(key, currentValue);
                previousValue.set(key, logGroup)
            }
            return previousValue;
        }, this.groupedLogs);
    }

    private notifyObserver() {
        if (this.listenerRefreshLogs) {
            const groups = Array.from(this.groupedLogs.values()).sort((l1: LogGroup, l2: LogGroup) => {
                if (l1.message.dateCreation < l2.message.dateCreation) {
                    return 1;
                }
                if (l1.message.dateCreation > l2.message.dateCreation) {
                    return -1;
                }
                return 0;
            });
            this.listenerRefreshLogs.next(groups);
        }
    }

    private notifyLogObserver(logUpdated: Log) {
        const logKey = LogbookLogsService.getLogKey(logUpdated);
        if (logKey && this.listernersLogUpdate.has(logKey)) {
            this.listernersLogUpdate.get(logKey).next(logUpdated);
        }
    }

    private findLogGroup(log: Log): LogGroup {
        return this.groupedLogs.get(LogbookLogsService.getLogKey(log));
    }

    private findComment(message: Message, commentId: string): Message {
        return message.comments.find((comment) =>
            comment.messageId.id === commentId
        );
    }

    ngOnDestroy() {
        if (this.filtersSub) {
            this.filtersSub.unsubscribe();
        }

        if (this.wsMessageEdite) {
            this.wsMessageEdite.unsubscribe();
        }
        if (this.wsMessageSupprime) {
            this.wsMessageSupprime.unsubscribe();
        }
        if (this.wsLogUseful) {
            this.wsLogUseful.unsubscribe();
        }
    }
}
