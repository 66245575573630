import {Component, Input, OnDestroy, OnInit} from '@angular/core';

@Component({
    selector: 'dln-alert',
    template: `
        <div class="alert" [ngClass]="'msg-'+type" role="alert">
            <ng-content></ng-content>
        </div>`,
    styleUrls: [
        'alert.scss'
    ]
})
export class DlnAlertComponent implements OnInit, OnDestroy {
    @Input()
    msg;

    @Input()
    type = 'error';

    constructor() {
    }

    ngOnInit(): void {
    }

    ngOnDestroy(): void {
    }

}
