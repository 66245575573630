import {User} from "./user.model";

export class VisitorGuest extends User {
    public instructionId?: string;
    public contactId?: string;

    constructor(res) {
        super(res.email, res.email);
        this.instructionId = res.instructionId;
        this.contactId = res.contactId;
        this.authorities = ["ANONYMOUS"]
    }
}
