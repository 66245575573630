"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./notification-center.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("@angular/common");
var i3 = require("../../components/notification/notification.component.ngfactory");
var i4 = require("../../components/notification/notification.component");
var i5 = require("ngx-infinite-scroll");
var i6 = require("../../../../logbook/shared/src/lib/common/spinner/spinner.component.ngfactory");
var i7 = require("../../../../logbook/shared/src/lib/common/spinner/spinner.component");
var i8 = require("../../../../logbook/shared/src/lib/pipe/date/day-date.pipe");
var i9 = require("./notification-center.component");
var i10 = require("../../shared/service/notification.center.service");
var i11 = require("@angular/router");
var i12 = require("ngx-device-detector");
var i13 = require("../../../core/tracking/mixpanel.service");
var styles_NotificationCenterComponent = [i0.styles];
var RenderType_NotificationCenterComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NotificationCenterComponent, data: {} });
exports.RenderType_NotificationCenterComponent = RenderType_NotificationCenterComponent;
function View_NotificationCenterComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [["class", "date"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵppd(2, 1)], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent.parent.parent, 0), _v.parent.context.$implicit.createdAt)); _ck(_v, 1, 0, currVal_0); }); }
function View_NotificationCenterComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "notification"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NotificationCenterComponent_3)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "notification", [], null, [[null, "view"], [null, "read"], [null, "delete"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("view" === en)) {
        var pd_0 = (_co.displayNotification(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } if (("read" === en)) {
        var pd_1 = (_co.markAsRead(_v.context.$implicit) !== false);
        ad = (pd_1 && ad);
    } if (("delete" === en)) {
        var pd_2 = (_co.delete(_v.context.$implicit) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i3.View_NotificationComponent_0, i3.RenderType_NotificationComponent)), i1.ɵdid(4, 49152, null, 0, i4.NotificationComponent, [], { notification: [0, "notification"] }, { read: "read", delete: "delete", view: "view" })], function (_ck, _v) { var currVal_0 = _v.context.$implicit.isFirstNotificationForDay; _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit; _ck(_v, 4, 0, currVal_1); }, null); }
function View_NotificationCenterComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "inner"], ["infiniteScroll", ""]], null, [[null, "scrolled"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("scrolled" === en)) {
        var pd_0 = (_co.fetchNextPage() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 4866048, null, 0, i5.InfiniteScrollDirective, [i1.ElementRef, i1.NgZone], { infiniteScrollDistance: [0, "infiniteScrollDistance"], infiniteScrollThrottle: [1, "infiniteScrollThrottle"], infiniteScrollDisabled: [2, "infiniteScrollDisabled"], scrollWindow: [3, "scrollWindow"] }, { scrolled: "scrolled" }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NotificationCenterComponent_2)), i1.ɵdid(3, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [["class", "markAllAsRead"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.markAllAsRead() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Mark All as Read"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = 5; var currVal_1 = 300; var currVal_2 = _co.noMoreContent; var currVal_3 = false; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_4 = _co.notifications; _ck(_v, 3, 0, currVal_4); }, null); }
function View_NotificationCenterComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "no-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "dln-spinner", [], null, null, null, i6.View_SpinnerComponent_0, i6.RenderType_SpinnerComponent)), i1.ɵdid(2, 49152, null, 0, i7.SpinnerComponent, [], null, null)], null, null); }
function View_NotificationCenterComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "no-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["No new notifications"]))], null, null); }
function View_NotificationCenterComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i8.DayDatePipe, []), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "header-mobile"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [["class", "label"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Notifications "])), (_l()(), i1.ɵeld(4, 0, null, null, 0, "i", [["class", "dln-icon-close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NotificationCenterComponent_1)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NotificationCenterComponent_4)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NotificationCenterComponent_5)), i1.ɵdid(10, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.notifications && _co.notifications.length); _ck(_v, 6, 0, currVal_0); var currVal_1 = (!(_co.notifications && _co.notifications.length) && _co.loadingNotifications); _ck(_v, 8, 0, currVal_1); var currVal_2 = (!(_co.notifications && _co.notifications.length) && !_co.loadingNotifications); _ck(_v, 10, 0, currVal_2); }, null); }
exports.View_NotificationCenterComponent_0 = View_NotificationCenterComponent_0;
function View_NotificationCenterComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "notification-center", [], null, null, null, View_NotificationCenterComponent_0, RenderType_NotificationCenterComponent)), i1.ɵdid(1, 245760, null, 0, i9.NotificationCenterComponent, [i10.NotificationCenterService, i11.Router, i12.DeviceDetectorService, i1.ChangeDetectorRef, i13.MixpanelService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_NotificationCenterComponent_Host_0 = View_NotificationCenterComponent_Host_0;
var NotificationCenterComponentNgFactory = i1.ɵccf("notification-center", i9.NotificationCenterComponent, View_NotificationCenterComponent_Host_0, {}, { closePanel: "closePanel" }, []);
exports.NotificationCenterComponentNgFactory = NotificationCenterComponentNgFactory;
