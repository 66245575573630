"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./main.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("../switch-apps-menu/switch-apps-menu.component.ngfactory");
var i3 = require("../switch-apps-menu/switch-apps-menu.component");
var i4 = require("../../auth/services/login.service");
var i5 = require("@angular/router");
var i6 = require("../../auth/services/principal.service");
var i7 = require("ngx-webstorage");
var i8 = require("../../../notification-center/shared/service/notification.center.service");
var i9 = require("../../tracking/mixpanel.service");
var i10 = require("ng-intercom");
var i11 = require("../../../logs-view/shared/services/view-management.service");
var i12 = require("../../../../logbook/shared/src/lib/notification/components/simple-notifications/simple-notifications.component.ngfactory");
var i13 = require("../../../../logbook/shared/src/lib/notification/components/simple-notifications/simple-notifications.component");
var i14 = require("../../../../logbook/shared/src/lib/notification/services/notifications.service");
var i15 = require("@angular/common");
var i16 = require("../check-updates/check-updates.component.ngfactory");
var i17 = require("../check-updates/check-updates.component");
var i18 = require("@angular/service-worker");
var i19 = require("@angular/material/snack-bar");
var i20 = require("./main.component");
var i21 = require("@angular/platform-browser");
var i22 = require("../../../../logbook/shared/src/lib/user/service/notification-ws.service");
var i23 = require("../../../../logbook/shared/src/lib/user/service/user.service");
var i24 = require("../../auth/services/account.service");
var i25 = require("../../../../logbook/shared/src/lib/modal/service/modal.service");
var i26 = require("../../../../logbook/shared/src/lib/notification/services/push-notifications.service");
var styles_DlnMainComponent = [i0.styles];
var RenderType_DlnMainComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DlnMainComponent, data: {} });
exports.RenderType_DlnMainComponent = RenderType_DlnMainComponent;
function View_DlnMainComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "dln-switch-apps-menu", [], [[2, "collapse", null], [2, "displayMobile", null]], [[null, "collapseChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("collapseChange" === en)) {
        var pd_0 = ((_co.collapseMenu = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_SwitchAppsMenuComponent_0, i2.RenderType_SwitchAppsMenuComponent)), i1.ɵdid(1, 245760, null, 0, i3.SwitchAppsMenuComponent, [i4.LoginService, i5.Router, i6.Principal, i7.LocalStorageService, i8.NotificationCenterService, i9.MixpanelService, i10.Intercom, i1.ChangeDetectorRef, i11.LogsViewManagementService], { collapse: [0, "collapse"] }, { collapseChange: "collapseChange" })], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.collapseMenu; _ck(_v, 1, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).collapse; var currVal_1 = i1.ɵnov(_v, 1).displayMobile; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_DlnMainComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 1, "router-outlet", [["name", "navbar"]], null, null, null, null, null)), i1.ɵdid(1, 212992, null, 0, i5.RouterOutlet, [i5.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, "navbar"], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_DlnMainComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "dln-simple-notifications", [], null, null, null, i12.View_SimpleNotificationsComponent_0, i12.RenderType_SimpleNotificationsComponent)), i1.ɵdid(1, 245760, null, 0, i13.SimpleNotificationsComponent, [i14.NotificationsService], { options: [0, "options"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 9, "div", [["id", "body"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DlnMainComponent_1)), i1.ɵdid(4, 16384, null, 0, i15.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 6, "div", [["class", "main"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "dln-check-updates", [], null, null, null, i16.View_CheckUpdatesComponent_0, i16.RenderType_CheckUpdatesComponent)), i1.ɵdid(7, 245760, null, 0, i17.CheckUpdatesComponent, [i9.MixpanelService, i18.SwUpdate, i1.NgZone, i1.ApplicationRef, i19.MatSnackBar], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DlnMainComponent_2)), i1.ɵdid(9, 16384, null, 0, i15.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(10, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(11, 212992, null, 0, i5.RouterOutlet, [i5.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.notificationOptions; _ck(_v, 1, 0, currVal_0); var currVal_1 = (_co.isAuthenticated() && !_co.hideSwitchApps); _ck(_v, 4, 0, currVal_1); _ck(_v, 7, 0); var currVal_2 = !_co.hideNavbar; _ck(_v, 9, 0, currVal_2); _ck(_v, 11, 0); }, null); }
exports.View_DlnMainComponent_0 = View_DlnMainComponent_0;
function View_DlnMainComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "dln-main", [], [[2, "withCollapseMenu", null]], null, null, View_DlnMainComponent_0, RenderType_DlnMainComponent)), i1.ɵdid(1, 245760, null, 0, i20.DlnMainComponent, [i21.Title, i5.Router, i7.LocalStorageService, i22.NotificationWsService, i6.Principal, i23.UserService, i24.AccountService, i25.ModalService, i26.PushNotificationsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).collapseMenu; _ck(_v, 0, 0, currVal_0); }); }
exports.View_DlnMainComponent_Host_0 = View_DlnMainComponent_Host_0;
var DlnMainComponentNgFactory = i1.ɵccf("dln-main", i20.DlnMainComponent, View_DlnMainComponent_Host_0, {}, {}, []);
exports.DlnMainComponentNgFactory = DlnMainComponentNgFactory;
