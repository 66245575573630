"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var forms_1 = require("@angular/forms");
var authorities_model_1 = require("../../../../../../../app/core/shared/models/authorities.model");
var DlnInputAuthoritiesComponent = /** @class */ (function () {
    function DlnInputAuthoritiesComponent() {
        this.isCurrentUserSuperAdmin = false;
        this.multiple = false;
        this.authorityControl = new forms_1.FormControl();
        this.roleList = authorities_model_1.Authorities;
        this.userRoles = [];
        this.noLogbookRoles = [];
        this.inputFocused = false;
        this.propagateChange = function (_) {
        };
        this.propagateTouched = function () {
        };
    }
    DlnInputAuthoritiesComponent.prototype.ngOnInit = function () {
        if (!this.isCurrentUserSuperAdmin) {
            this.roleList = this.roleList.filter(function (role) { return role.role !== "ROLE_ADMIN"; });
        }
        if (this.multiple) {
            this.roleList = this.roleList.filter(function (role) { return role.role !== "ROLE_USER"; });
        }
    };
    DlnInputAuthoritiesComponent.prototype.writeValue = function (roles) {
        var _this = this;
        if (roles) {
            this.userRoles = roles.filter(function (role) {
                return _this.roleList.some(function (roleItem) { return roleItem.role === role; });
            });
            this.noLogbookRoles = roles.filter(function (role) {
                return !_this.roleList.some(function (roleItem) { return roleItem.role === role; });
            });
            if (!this.multiple) {
                this.authorityControl.setValue(roles.indexOf("ROLE_TEAM_MANAGER") !== -1 ? this.getLabel("ROLE_TEAM_MANAGER") : this.getLabel('ROLE_USER'));
            }
        }
    };
    DlnInputAuthoritiesComponent.prototype.registerOnChange = function (fn) {
        this.propagateChange = fn;
    };
    DlnInputAuthoritiesComponent.prototype.registerOnTouched = function (fn) {
        this.propagateTouched = fn;
    };
    DlnInputAuthoritiesComponent.prototype.selectRole = function ($event) {
        if (this.multiple) {
            if (!this.userRoles.some(function (value) { return value === $event.option.value; })) {
                this.userRoles.push($event.option.value);
            }
            this.authorityControl.setValue('');
        }
        else {
            this.userRoles = [$event.option.value];
            this.authorityControl.setValue(this.getLabel($event.option.value));
        }
        this.propagateChange(this.noLogbookRoles.concat(this.userRoles));
        this.propagateTouched();
    };
    DlnInputAuthoritiesComponent.prototype.removeRole = function (role) {
        this.userRoles = this.userRoles.filter(function (value) { return value !== role; });
        this.propagateChange(this.noLogbookRoles.concat(this.userRoles));
        this.propagateTouched();
    };
    DlnInputAuthoritiesComponent.prototype.getLabel = function (role) {
        return this.roleList.find(function (value) { return value.role === role; }).label;
    };
    return DlnInputAuthoritiesComponent;
}());
exports.DlnInputAuthoritiesComponent = DlnInputAuthoritiesComponent;
