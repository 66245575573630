import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';
import {Observable} from 'rxjs';
import {LOGBOOK_API} from "../../app.constants";
import {Injectable} from "@angular/core";

export class AuthInterceptor implements HttpInterceptor {

    constructor(private localStorage: LocalStorageService,
                private sessionStorage: SessionStorageService) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!request || !request.url || (/^http/.test(request.url) && !(LOGBOOK_API && request.url.startsWith(LOGBOOK_API)))) {
            return next.handle(request);
        }
        const token = this.sessionStorage.retrieve('signAsAuthenticationToken')
            || this.localStorage.retrieve('authenticationToken')
            || this.sessionStorage.retrieve('authenticationToken');
        if (!!token) {
            request = request.clone({
                setHeaders: {
                    Authorization: 'Bearer ' + token
                }
            });
        }
        return next.handle(request);
    }
}
