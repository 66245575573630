import {Component, forwardRef, Input, OnDestroy, OnInit} from '@angular/core';
import {
    AbstractControl,
    ControlValueAccessor,
    FormControl,
    FormGroup, NG_VALIDATORS,
    NG_VALUE_ACCESSOR, ValidationErrors, Validator,
    Validators
} from "@angular/forms";
import {CountryCallingCode} from "../../../model/country-calling-code";
import {parseNumber} from "libphonenumber-js"
import {Subject} from "rxjs/index";
import {takeUntil} from "rxjs/operators";

@Component({
    selector: 'dln-input-phone',
    templateUrl: './input-phone.component.html',
    styleUrls: [
        './input-phone.component.scss'
    ],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DlnInputPhoneComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => DlnInputPhoneComponent),
            multi: true,
        }
    ]
})
export class DlnInputPhoneComponent implements OnInit, ControlValueAccessor, Validator, OnDestroy {
    @Input() inputName: "phoneInput";

    inputError: 'code' | 'number';
    phoneForm: FormGroup;
    countryCode: CountryCallingCode;

    private maskPhonePattern = [/[1-9]/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
    maskPhone = {mask: this.maskPhonePattern, guide: false};
    countryCallingCodesList = CountryCallingCode.getCountryCodes();

    private destroy$ = new Subject<void>();
    private propagateChange = (_: any) => {};
    private propagateTouched = () => {};

    ngOnInit(): void {
        const {required, minLength, maxLength} = Validators;

        this.phoneForm = new FormGroup({
            phoneNumber: new FormControl(null, [
                required,
                minLength(4),
                maxLength(20)
            ]),
            countryCode: new FormControl(CountryCallingCode.getDefault(), [
                required,
                minLength(1),
                maxLength(3)
            ]),
            flatPhoneNumber: new FormControl(null)
        });

        this.phoneForm.valueChanges.pipe(takeUntil(this.destroy$))
            .subscribe((form) => {
                if (form.phoneNumber && form.countryCode && form.countryCode.callingCode) {
                    const phoneNumber = "+" + form.countryCode.callingCode + form.phoneNumber;
                    this.propagateChange(phoneNumber);
                    this.propagateTouched()
                } else if (form.flatPhoneNumber) {
                    this.propagateChange(form.flatPhoneNumber);
                    this.propagateTouched()
                }
            })
    }

    writeValue(val: any): void {
        if (val) {
            const parsedNumber = parseNumber(val);
            if (parsedNumber.country) {
                this.phoneForm.get('countryCode').setValue(CountryCallingCode.findByCode(parsedNumber.country));
                this.phoneForm.get('phoneNumber').setValue(parsedNumber.phone);
            } else {
                this.phoneForm.get('flatPhoneNumber').setValue(val);
            }
        }
    }

    registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.propagateTouched = fn;
    }

    validate(c: AbstractControl): ValidationErrors | null {
        if (this.phoneForm.get('phoneNumber').value) {
            const phoneNumber = "+" + this.phoneForm.get('countryCode').value.callingCode + this.phoneForm.get('phoneNumber').value;
            const parsedNumber = parseNumber(phoneNumber, {extended: true});
            let parseError = false;
            if (!parsedNumber.valid) {
                parseError = true
            }
            return (!parseError) ? null : {
                phoneInvalid: true
            };
        } else {
            return null
        }
    }

    ngOnDestroy() {
        this.destroy$.next();
}
}
