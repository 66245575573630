import {NgModule} from "@angular/core";
import {RouterModule} from "@angular/router";
import {ErrorComponent} from "./error.component";

const APP_ERROR_ROUTES = [
    {
        path: 'error',
        component: ErrorComponent,
        data: {
            authorities: [],
            pageTitle: 'Error page!'
        }
    },
    {
        path: 'accessdenied',
        component: ErrorComponent,
        data: {
            authorities: [],
            pageTitle: 'Error page!',
            error403: true
        }
    },
    {
        path: '**',
        component: ErrorComponent,
        data: {
            authorities: [],
            pageTitle: 'Page not found',
            errorMessage: 'This page cannot be found.'
        }
    }
];

@NgModule({
    imports: [
        RouterModule.forChild(APP_ERROR_ROUTES)
    ],
    declarations: [ErrorComponent],
    exports: [
        RouterModule
    ]
})
export class ErrorRoutingModule {
}
