"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var phone_model_1 = require("../../model/phone.model");
var user_model_1 = require("../../../../../../app/core/shared/models/user.model");
var Contact = /** @class */ (function () {
    function Contact(build) {
        this.isEdited = false;
        this.isShow = false;
        this.isSelected = false;
        this.showLetter = "";
        if (build) {
            this.id = build.id;
            this._email = build.email;
            this._prenom = build.firstName;
            this._nom = build.lastName;
            this.surnom = build.nickname;
            this._civilite = build.title;
            this.notes = build.notes;
            this.owners = build.owners;
            this._posteDansLaSociete = build.positionInCompany;
            this._societe = build.company;
            this._localisation = build.localisation;
            this._telephones = [];
            if (build.phones && build.phones.length) {
                this._telephones = build.phones.map(function (phone) { return new phone_model_1.Phone(phone); });
            }
            this.typeSociete = build.typeCompany;
            this.tags = build.tags;
            this.userInfo = build.userInfo ? Object.assign(new user_model_1.User(), build.userInfo) : null;
        }
    }
    Object.defineProperty(Contact.prototype, "email", {
        get: function () {
            return this._email || (this.userInfo && this.userInfo.email);
        },
        set: function (email) {
            this._email = email;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Contact.prototype, "prenom", {
        get: function () {
            return this._prenom || (this.userInfo && this.userInfo.firstName);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Contact.prototype, "nom", {
        get: function () {
            return this._nom || (this.userInfo && this.userInfo.lastName);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Contact.prototype, "societe", {
        get: function () {
            return this._societe || (this.userInfo && this.userInfo.company);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Contact.prototype, "civilite", {
        get: function () {
            return this._civilite || (this.userInfo && this.userInfo.title);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Contact.prototype, "posteDansLaSociete", {
        get: function () {
            return this._posteDansLaSociete || (this.userInfo && this.userInfo.positionInCompany);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Contact.prototype, "localisation", {
        get: function () {
            return this._localisation || (this.userInfo && this.userInfo.localisation);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Contact.prototype, "telephones", {
        get: function () {
            if (this.userInfo && this.userInfo.phones) {
                var userPhones = this.userInfo.phones.map(function (tel) { return new phone_model_1.Phone({
                    type: 'MOBILE',
                    numero: tel
                }); });
                if (this._telephones && this._telephones.length) {
                    return this._telephones.concat(userPhones);
                }
                else {
                    return userPhones;
                }
            }
            else {
                return this._telephones;
            }
        },
        enumerable: true,
        configurable: true
    });
    Contact.prototype.displayUserName = function () {
        if (this._prenom || this._nom) {
            var output = "";
            if (this._prenom) {
                output += this._prenom;
            }
            if (this._nom) {
                if (this._prenom) {
                    output += " ";
                }
                output += this._nom;
            }
            return output;
        }
        else if (this.userInfo) {
            return this.userInfo.displayUserName();
        }
        else if (this.email) {
            return this.email;
        }
        else if (this.posteDansLaSociete) {
            return this.posteDansLaSociete;
        }
        return "No name";
    };
    Contact.prototype.displayUserNameWithStyle = function () {
        if (this._prenom || this._nom) {
            var output = "";
            if (this._prenom) {
                output += this._prenom;
            }
            if (this._nom) {
                if (this._prenom) {
                    output += " ";
                }
                output += "<b>" + this._nom + "</b>";
            }
            return output;
        }
        else if (this.userInfo) {
            return this.userInfo.displayUserNameWithStyle();
        }
        else if (this.email) {
            return this.email;
        }
        else if (this.posteDansLaSociete) {
            return this.posteDansLaSociete;
        }
        return "No name";
    };
    Contact.prototype.displayCompany = function () {
        if (this.userInfo && this.userInfo.company && this.userInfo.company.nom) {
            return "" + this.userInfo.getCompanyName();
        }
        else if (this._societe && this._societe.nom) {
            return this._societe.nom;
        }
        return null;
    };
    Contact.prototype.displayPositionCompany = function () {
        if (this.userInfo && this.userInfo.positionInCompany) {
            return "" + this.userInfo.positionInCompany;
        }
        else if (this._posteDansLaSociete) {
            return this._posteDansLaSociete;
        }
        return null;
    };
    Contact.prototype.displayLocation = function () {
        if (this.userInfo && this.userInfo.localisation) {
            return "" + this.userInfo.localisation;
        }
        else if (this._localisation) {
            return this._localisation;
        }
        return null;
    };
    Contact.prototype.getEmail = function () {
        return this.email;
    };
    Contact.prototype.getMobilePhone = function () {
        if (this.telephones && this.telephones.length) {
            var mobile = this.telephones.find(function (telephone) { return telephone.type === 'MOBILE'; });
            if (mobile) {
                return mobile.numero;
            }
        }
        return null;
    };
    Contact.prototype.displayForContactList = function () {
        return this.displayUserName() + " " + (this._societe && this._societe._nom ? " - " + this._societe._nom : '') + "\n        " + (this._localisation && this._localisation.code ? " - " + this._localisation.code : '');
    };
    Contact.prototype.getPhone = function () {
        if (this.telephones && this.telephones.length) {
            var phone = this.telephones.find(function (telephone) { return telephone.type === 'OTHER'; });
            if (phone) {
                return phone.numero;
            }
        }
        return null;
    };
    Contact.prototype.hasTag = function (tag) {
        return this.tags && this.tags.indexOf(tag) > -1;
    };
    return Contact;
}());
exports.Contact = Contact;
