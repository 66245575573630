"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var moment = require("moment");
var Filters = /** @class */ (function () {
    function Filters() {
        this.date = moment.utc().startOf("day");
        this.categoryTags = [];
        this.flightTags = [];
    }
    Filters.prototype.isCategoryTagIsSelected = function (categoryTag) {
        return this.categoryTags.filter(function (tagInList) { return tagInList.key === categoryTag.key && tagInList.value === categoryTag.value; }).length > 0;
    };
    Filters.prototype.isFlightTagIsSelected = function (flightTag) {
        return this.flightTags.filter(function (flightTagInList) {
            return flightTagInList.flightTag.toString() === flightTag.flightTag.toString();
        }).length > 0;
    };
    Filters.prototype.selectCategoryTag = function (categoryTag) {
        // Check if filter already selected
        var categoryTagInList = this.categoryTags.filter(function (tagInList) {
            return tagInList.key === categoryTag.key && tagInList.value === categoryTag.value;
        });
        if (categoryTagInList.length === 0) {
            // If a filter already selected on same property replace it  but not for messages tag filters
            if (this.categoryTags
                .filter(function (tagInList) { return tagInList.key === categoryTag.key; })
                .length > 0
                && categoryTag.key !== "messages.tags") {
                this.categoryTags = this.categoryTags.map(function (tagInList) {
                    if (tagInList.key === categoryTag.key) {
                        return categoryTag;
                    }
                    else {
                        return tagInList;
                    }
                });
            }
            else {
                this.categoryTags.push(categoryTag);
            }
        }
        else {
            this.categoryTags.splice(this.categoryTags.indexOf(categoryTagInList[0]), 1);
        }
    };
    Filters.prototype.selectFlightTag = function (flightTag) {
        var flightTagsInList = this.flightTags.filter(function (flightTagInList) {
            return flightTagInList.flightTag.toString() === flightTag.flightTag.toString();
        });
        if (flightTagsInList.length === 0) {
            this.flightTags.push(flightTag);
        }
        else {
            this.flightTags.splice(this.flightTags.indexOf(flightTagsInList[0]), 1);
        }
    };
    Filters.prototype.getSelectedThreads = function () {
        var threads = this.flightTags.map(function (flightTag) { return flightTag.threadIds; });
        // CONVERT ARRAY OF ARRAY TO ARRAY (FLATMAP)
        return threads.concat.apply([], threads);
    };
    Filters.prototype.isActiveFilters = function () {
        return this.flightTags.length !== 0 || this.categoryTags.length !== 0;
    };
    Filters.prototype.countActiveFilters = function () {
        return this.flightTags.length + this.categoryTags.length;
    };
    return Filters;
}());
exports.Filters = Filters;
