"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./button.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("../../spinner/spinner.component.ngfactory");
var i3 = require("../../spinner/spinner.component");
var i4 = require("@angular/common");
var i5 = require("./button.component");
var i6 = require("ngx-device-detector");
var styles_ButtonComponent = [i0.styles];
var RenderType_ButtonComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ButtonComponent, data: {} });
exports.RenderType_ButtonComponent = RenderType_ButtonComponent;
function View_ButtonComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "dln-spinner", [], null, null, null, i2.View_SpinnerComponent_0, i2.RenderType_SpinnerComponent)), i1.ɵdid(1, 49152, null, 0, i3.SpinnerComponent, [], { label: [0, "label"], isButton: [1, "isButton"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isLoadingLabel; var currVal_1 = true; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_ButtonComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "button-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [["class", "label"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 0, "i", [], [[8, "className", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.label; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.icon; _ck(_v, 3, 0, currVal_1); }); }
function View_ButtonComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "button", [], [[8, "type", 0], [8, "disabled", 0], [8, "id", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clickEmit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 278528, null, 0, i4.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(2, { "withIcon": 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ButtonComponent_1)), i1.ɵdid(4, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["button_label", 2]], null, 0, null, View_ButtonComponent_2))], function (_ck, _v) { var _co = _v.component; var currVal_3 = ((((_co.color + " border-") + _co.border) + " icon-") + _co.iconPosition); var currVal_4 = _ck(_v, 2, 0, _co.icon); _ck(_v, 1, 0, currVal_3, currVal_4); var currVal_5 = _co.isLoading; var currVal_6 = i1.ɵnov(_v, 5); _ck(_v, 4, 0, currVal_5, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.type; var currVal_1 = (_co.isDisabled || _co.isLoading); var currVal_2 = _co.idBtn; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
exports.View_ButtonComponent_0 = View_ButtonComponent_0;
function View_ButtonComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "dln-button", [], [[4, "height", null]], null, null, View_ButtonComponent_0, RenderType_ButtonComponent)), i1.ɵdid(1, 114688, null, 0, i5.ButtonComponent, [i6.DeviceDetectorService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).heightStyle; _ck(_v, 0, 0, currVal_0); }); }
exports.View_ButtonComponent_Host_0 = View_ButtonComponent_Host_0;
var ButtonComponentNgFactory = i1.ɵccf("dln-button", i5.ButtonComponent, View_ButtonComponent_Host_0, { label: "label", color: "color", border: "border", isDisabled: "isDisabled", type: "type", idBtn: "idBtn", icon: "icon", iconPosition: "iconPosition", isLoading: "isLoading", isLoadingLabel: "isLoadingLabel", height: "height" }, { onClick: "onClick" }, []);
exports.ButtonComponentNgFactory = ButtonComponentNgFactory;
