"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var platform_browser_1 = require("@angular/platform-browser");
var ngx_device_detector_1 = require("ngx-device-detector");
var MyHammerConfig = /** @class */ (function (_super) {
    tslib_1.__extends(MyHammerConfig, _super);
    function MyHammerConfig(deviceService) {
        var _this = _super.call(this) || this;
        _this.deviceService = deviceService;
        return _this;
    }
    MyHammerConfig.prototype.buildHammer = function (element) {
        var isMobile = this.deviceService.isMobile() || this.deviceService.isTablet();
        return new Hammer(element, {
            touchAction: "pan-y",
            enable: isMobile,
            cssProps: {
                userSelect: true
            }
        });
    };
    return MyHammerConfig;
}(platform_browser_1.HammerGestureConfig));
exports.MyHammerConfig = MyHammerConfig;
