"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// http://tobiasahlin.com/spinkit/
var SpinnerComponent = /** @class */ (function () {
    function SpinnerComponent() {
        this.label = 'loading';
        this.isButton = false;
        this.isBlack = false;
    }
    return SpinnerComponent;
}());
exports.SpinnerComponent = SpinnerComponent;
