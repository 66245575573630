"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var logbook_config_1 = require("@logbook/core/shared/config/logbook-config");
var i0 = require("@angular/core");
var i1 = require("../shared/config/logbook-config");
var GoogleAnalyticsService = /** @class */ (function () {
    function GoogleAnalyticsService(config, ngZone) {
        this.config = config;
        this.ngZone = ngZone;
        ga('create', this.config.GOOGLE_ANALYTICS_TOKEN, 'auto'); //  paste the code till here, have to remove below line.
        ga('set', 'anonymizeIp', true);
        ga('send', 'pageview');
    }
    GoogleAnalyticsService.prototype.setUser = function (user, team) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.ngZone.runOutsideAngular(function () {
                    if (!user.acceptActivityTracking) {
                        return;
                    }
                    try {
                        ga('set', '&uid', user.anonymousUserId);
                        ga('set', 'userId', user.anonymousUserId);
                        ga('set', 'dimension1', user.getCompanyName().toLowerCase());
                        ga('set', 'dimension5', user.anonymousUserId);
                        if (team) {
                            ga('set', 'dimension3', team);
                        }
                        ga('send', 'pageview');
                    }
                    catch (Exception) {
                        console.warn(Exception);
                    }
                });
                return [2 /*return*/];
            });
        });
    };
    GoogleAnalyticsService.ngInjectableDef = i0.defineInjectable({ factory: function GoogleAnalyticsService_Factory() { return new GoogleAnalyticsService(i0.inject(i1.LogbookConfig), i0.inject(i0.NgZone)); }, token: GoogleAnalyticsService, providedIn: "root" });
    return GoogleAnalyticsService;
}());
exports.GoogleAnalyticsService = GoogleAnalyticsService;
