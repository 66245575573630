"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var service_worker_1 = require("@angular/service-worker");
var rxjs_1 = require("rxjs");
var operators_1 = require("rxjs/operators");
var error_1 = require("tslint/lib/error");
var app_constants_1 = require("../../../app.constants");
var first_1 = require("rxjs/internal/operators/first");
var mixpanel_service_1 = require("../../tracking/mixpanel.service");
var mixpanel_event_enum_1 = require("../../tracking/mixpanel-event.enum");
var material_1 = require("@angular/material");
var CheckUpdatesComponent = /** @class */ (function () {
    function CheckUpdatesComponent(mixpanelService, updates, ngZone, appRef, snackBar) {
        this.mixpanelService = mixpanelService;
        this.updates = updates;
        this.ngZone = ngZone;
        this.appRef = appRef;
        this.snackBar = snackBar;
        this.INTERVAL_IN_MINUTES = 2;
    }
    CheckUpdatesComponent.prototype.ngOnInit = function () {
        var _this = this;
        var intervalInMilliseconds = this.INTERVAL_IN_MINUTES * 60000;
        this.checkUpdatesInCode();
        this.appRef.isStable.pipe(operators_1.filter(function (res) { return res === true; }), first_1.first())
            .subscribe(function (res) {
            console.log("App is stable :", res);
            if (res && !_this.subscriptionOnUpdate) {
                _this.subscriptionOnUpdate = rxjs_1.interval(intervalInMilliseconds)
                    .subscribe(function () {
                    _this.checkUpdatesInCode();
                }, function (error) {
                    console.error(error);
                    return;
                });
            }
        });
        if (this.updates.isEnabled) {
            console.log("sw is enabled");
            this.updateAvailableSub = this.updates.available.subscribe(function (event) {
                console.log('current version is', event.current);
                console.log('available version is', event.available);
                _this.showUpdateSnackBar();
            });
            this.updateActivatedSub = this.updates.activated.subscribe(function (event) {
                console.log('old version was', event.previous);
                console.log('new version is', event.current);
            });
        }
    };
    CheckUpdatesComponent.prototype.ngOnDestroy = function () {
        if (this.subscriptionOnUpdate) {
            this.subscriptionOnUpdate.unsubscribe();
        }
        if (this.updateAvailableSub) {
            this.updateAvailableSub.unsubscribe();
        }
        if (this.updateActivatedSub) {
            this.updateActivatedSub.unsubscribe();
        }
    };
    CheckUpdatesComponent.prototype.checkUpdatesInCode = function () {
        var _this = this;
        if (this.updates.isEnabled) {
            this.updates.checkForUpdate();
        }
        else {
            var headers = new Headers();
            headers.append('pragma', 'no-cache');
            headers.append('cache-control', 'no-cache');
            return fetch(app_constants_1.LOGBOOK_WEB_URL + "/index.html", {
                method: 'GET',
                headers: headers
            })
                .then(function (response) {
                if (response.status !== 200) {
                    throw new error_1.Error('offline');
                }
                return response.text();
            })
                .then(function (html) {
                if (!_this.previousHtml) {
                    _this.previousHtml = html;
                    return;
                }
                if (_this.previousHtml !== html) {
                    _this.previousHtml = html;
                    _this.showUpdateSnackBar();
                    _this.mixpanelService.track(mixpanel_event_enum_1.MixpanelEvent.NEED_UPDATE);
                }
            })
                .catch(function (err) {
            });
        }
    };
    CheckUpdatesComponent.prototype.reload = function () {
        if (this.updates.isEnabled) {
            this.updates.activateUpdate()
                .then(function () { return document.location.reload(); })
                .catch(function () { return document.location.reload(); });
        }
        else {
            document.location.reload();
        }
    };
    CheckUpdatesComponent.prototype.showUpdateSnackBar = function () {
        var _this = this;
        this.snackBar.open("Logbook update available", "Reload now to get the latest version", {
            duration: null,
            horizontalPosition: "center",
            panelClass: "red-snackbar"
        }).onAction().pipe(first_1.first()).subscribe(function () {
            _this.reload();
        });
    };
    return CheckUpdatesComponent;
}());
exports.CheckUpdatesComponent = CheckUpdatesComponent;
