import {Component, Inject, OnInit, Optional, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";
import {AttachmentService} from "../../common/text-editor/attach-file/service/attachment.service";
import {PieceJointe} from "../../common/text-editor/attach-file/model/piece-jointe.model";
import { LyResizingCroppingImages} from '@alyle/ui/resizing-cropping-images';
import {first} from "rxjs/operators";

@Component({
    selector: 'dln-upload-image-dialog',
    templateUrl: './upload-image-dialog.component.html',
    styleUrls: ['./upload-image-dialog.component.scss']
})
export class UploadImageDialogComponent implements OnInit {

    @ViewChild('imgCropping') imgCropping: LyResizingCroppingImages;
    public isLoading = false;

    public cropConfig = {
        width : 200,
        height : 200
    };

    constructor(public dialogRef: MatDialogRef<UploadImageDialogComponent>,
                public attachmentService: AttachmentService) {}

    ngOnInit() {}

    close() {
        this.dialogRef.close(null);
    }

    save() {
        this.isLoading = true;
        const croppedImage = this.imgCropping.crop();
        const imageBase64 = croppedImage.base64;
        const contentType = croppedImage.type;
        const blob = UploadImageDialogComponent.dataURItoBlob(imageBase64, contentType);
        const formData = new FormData();
        formData.append("file", blob, croppedImage.name);

        this.attachmentService.postAttachement(formData).pipe(first()).subscribe((pj: PieceJointe) => {
            this.isLoading = false;
            this.dialogRef.close(pj);
        });
    }

    private static dataURItoBlob(dataURI, contentType) {
        const byteString = atob(dataURI.toString().split(',')[1]);
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ab], {type: contentType});
    }
}
