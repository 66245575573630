export enum FeatureType {
    NEW = "NEW",
    CHANGE = "CHANGE",
    FIX = "FIX",
    REMOVE = "REMOVE",
    SECURITY = "SECURITY",
}

export class Feature {
    type: FeatureType;
    colorType: string;
    description = "";

    constructor(build?) {
        if (build) {
            this.type = build.type;
            this.description = build.description;

            switch (this.type) {
                case FeatureType.NEW:
                    this.colorType = 'green';
                    break;
                case FeatureType.CHANGE:
                    this.colorType = 'blue';
                    break;
                case FeatureType.FIX:
                    this.colorType = 'purple';
                    break;
                case FeatureType.REMOVE:
                    this.colorType = 'red';
                    break;
                case FeatureType.SECURITY:
                    this.colorType = 'orange';
                    break;
            }
        }
    }
}
