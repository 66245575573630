import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MatDialogModule} from "@angular/material";
import {ConfirmModalComponent} from "./components/confirm/confirm-modal.component";
import {ModalService} from "./service/modal.service";
import {ZoomImageComponent} from "./components/zoom-image/zoom-image.component";
import {ModalHeaderComponent} from "./components/modal-header/modal-header.component";
import {ModalFooterComponent} from "./components/modal-footer/modal-footer.component";
import {DlnCommonModule} from "../common/common.module";
import {SharedPipeModule} from "../pipe/shared-pipe.module";

@NgModule({
    imports: [
        DlnCommonModule,
        SharedPipeModule,
        CommonModule,
        MatDialogModule
    ],
    declarations: [
        ConfirmModalComponent,
        ZoomImageComponent,
        ModalHeaderComponent,
        ModalFooterComponent
    ],
    providers: [
        ModalService
    ],
    entryComponents: [
        ConfirmModalComponent,
        ZoomImageComponent
    ],
    exports: [
        MatDialogModule,
        ConfirmModalComponent,
        ZoomImageComponent,
        ModalHeaderComponent,
        ModalFooterComponent
    ]
})
export class ModalModule { }
