import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HasFeatureEnableDirective} from "@logbook/core/shared/directive/has-feature-enable.directive";
import {HasAnyAuthorityDirective} from "@logbook/core/shared/directive/has-any-authority.directive";
import {FeatureEnabledAccessService} from "@logbook/core/shared/guards/feature-enabled-access-service";
import {UserAlreadyLoggedRouteAccessService} from "@logbook/core/shared/guards/user-already-logged-route-acces-service";
import {UserRouteAccessService} from "@logbook/core/shared/guards/user-route-access-service";
import {TokenAuthRouteAccessService} from "@logbook/core/shared/guards/visitor-auth-route-access-service";
import { HasAccessToFileSystemDirective } from './directive/has-access-to-file-system.directive';

/**
 * CoreSharedModule exports
 * - Directives related to user permission and features enables
 * - Route Guards
 */
@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        HasAnyAuthorityDirective,
        HasFeatureEnableDirective,
        HasAccessToFileSystemDirective
    ],
    providers: [
        FeatureEnabledAccessService,
        UserAlreadyLoggedRouteAccessService,
        UserRouteAccessService,
        TokenAuthRouteAccessService
    ],
    exports: [
        HasAnyAuthorityDirective,
        HasFeatureEnableDirective,
        HasAccessToFileSystemDirective
    ]
})
export class LogbookCoreSharedModule {
}
