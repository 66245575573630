"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var rxjs_1 = require("rxjs");
var models_1 = require("@logbook/logs-view/shared/models");
var LogFormService = /** @class */ (function () {
    function LogFormService() {
        this.listenerRefreshLogForm = this.createListener();
        this.logForm = new models_1.LogForm();
    }
    LogFormService.prototype.subscribeLogForm = function () {
        return this.listenerRefreshLogForm;
    };
    LogFormService.prototype.setLogForm = function (logForm) {
        this.logForm = logForm;
    };
    LogFormService.prototype.logFormChange = function (logForm) {
        this.logForm = logForm;
        this.notifyObserver();
    };
    LogFormService.prototype.flightTagsChange = function (flightTags) {
        this.logForm.flightTags = flightTags;
        this.notifyObserver();
    };
    LogFormService.prototype.addFlightTag = function (flightTag) {
        this.logForm.pushFlightTag(flightTag);
        this.notifyObserver();
    };
    LogFormService.prototype.removeFlightTag = function (flightTag) {
        this.logForm.removeFlightTag(flightTag);
        this.notifyObserver();
    };
    LogFormService.prototype.notifyObserver = function () {
        this.observerRefreshLogForm.next(this.logForm);
    };
    LogFormService.prototype.createListener = function () {
        var _this = this;
        return new rxjs_1.Observable(function (observer) {
            _this.observerRefreshLogForm = observer;
        });
    };
    return LogFormService;
}());
exports.LogFormService = LogFormService;
