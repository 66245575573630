import {CommonModule, DatePipe} from '@angular/common';
import {LOCALE_ID, NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {InjectableRxStompConfig, RxStompService, rxStompServiceFactory, StompRService} from "@stomp/ng2-stompjs";
import {IntercomModule} from 'ng-intercom';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {Title} from "@angular/platform-browser";
import {MATERIAL_SANITY_CHECKS} from "@angular/material";
import {stompConfig} from "./user/util/stomp-config";

@NgModule({
    imports: [
        CommonModule,
        InfiniteScrollModule,
        IntercomModule
    ],
    providers: [
        {
            provide: InjectableRxStompConfig,
            useValue: stompConfig
        },
        RxStompService,
        DatePipe,
        Title,
        {
            provide: LOCALE_ID,
            useValue: 'en'
        },
        {provide: MATERIAL_SANITY_CHECKS, useValue: false} // disable warning when material has not stylesheet imported

    ],
    exports: [
        FormsModule,
        CommonModule,
        InfiniteScrollModule,
        IntercomModule,
        DatePipe,
    ]
})
export class LogbookSharedLibsModule {
}
