"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var router_1 = require("@angular/router");
var notification_api_service_1 = require("@logbook/notification-center/shared/service/notification.api.service");
var LogbookNotification_1 = require("@logbook/notification-center/shared/model/LogbookNotification");
var mixpanel_service_1 = require("@logbook/core/tracking/mixpanel.service");
var mixpanel_event_enum_1 = require("@logbook/core/tracking/mixpanel-event.enum");
var operators_1 = require("rxjs/operators");
var NotificationRouteAccessService = /** @class */ (function () {
    function NotificationRouteAccessService(router, notificationService, mixpanelService) {
        this.router = router;
        this.notificationService = notificationService;
        this.mixpanelService = mixpanelService;
    }
    NotificationRouteAccessService.prototype.canActivate = function (route, state) {
        this.redirectToNotif(route.queryParams.id);
        return false;
    };
    NotificationRouteAccessService.prototype.redirectToNotif = function (id) {
        var _this = this;
        this.notificationService.get(id).pipe(operators_1.first()).subscribe(function (notif) {
            if (notif) {
                notif = Object.assign(new LogbookNotification_1.LogbookNotification(), notif);
                _this.notificationService.markAsRead(id, 'PUSH_NOTIFICATION').pipe(operators_1.first()).subscribe(function () {
                    var notifLink = notif.getLink();
                    _this.router.navigate(notifLink).then(function () {
                        return _this.mixpanelService.track(mixpanel_event_enum_1.MixpanelEvent.NOTIFICATION.PUSH.CLICKED, {
                            notificationId: notif.id,
                            notificationType: notif.category,
                            notificationLink: notifLink,
                        });
                    });
                });
            }
            else {
                _this.router.navigate(['/logs']);
            }
        });
    };
    return NotificationRouteAccessService;
}());
exports.NotificationRouteAccessService = NotificationRouteAccessService;
