"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".back-btn[_ngcontent-%COMP%]{position:relative;display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;-ms-flex-align:center;-moz-align-items:center;align-items:center;margin-left:15px;width:-webkit-fit-content;width:-moz-fit-content;width:fit-content;-ms-flex-item-align:left;align-self:left;color:#fff;height:25px;border-radius:20px;background:#1982ee;text-decoration:none;padding:0 10px;font-weight:100;line-height:25px;font-size:12px;cursor:pointer}.back-btn[_ngcontent-%COMP%]:hover{text-decoration:none}.back-btn.no-background[_ngcontent-%COMP%]{border-radius:0;background-color:#fff;color:#a5a9bb;border-right:1px solid #a5a9bb;font-size:16px;font-weight:400}.back-btn.no-background[_ngcontent-%COMP%]   i[_ngcontent-%COMP%]{font-size:18px;font-weight:400}.back-btn[_ngcontent-%COMP%]   .desktop[_ngcontent-%COMP%]{display:inline-block;margin:0 10px}.back-btn[_ngcontent-%COMP%]   .mobile[_ngcontent-%COMP%]{display:none}.back-btn[_ngcontent-%COMP%]   i.icon-chevron-left[_ngcontent-%COMP%]{font-size:10px;text-decoration:none}@media (max-width:768px){.back-btn[_ngcontent-%COMP%]   .desktop[_ngcontent-%COMP%]{display:none}.back-btn[_ngcontent-%COMP%]   .mobile[_ngcontent-%COMP%]{display:inline-block}}"];
exports.styles = styles;
