"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./input-time.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("../../../../../../../../node_modules/dln-datetime-picker/dln-datetime-picker.ngfactory");
var i3 = require("@angular/forms");
var i4 = require("dln-datetime-picker");
var i5 = require("./input-time.component");
var styles_DlnInputTimeComponent = [i0.styles];
var RenderType_DlnInputTimeComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DlnInputTimeComponent, data: {} });
exports.RenderType_DlnInputTimeComponent = RenderType_DlnInputTimeComponent;
function View_DlnInputTimeComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "dln-date-time-inline", [["class", "inline-timer"], ["pickerType", "timer"]], null, [[null, "selectedDate"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selectedDate" === en)) {
        var pd_0 = (_co.setNewDate((($event == null) ? null : $event.value)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_DlnDateTimeInlineComponent_0, i2.RenderType_DlnDateTimeInlineComponent)), i1.ɵprd(5120, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i4.DlnDateTimeInlineComponent]), i1.ɵdid(2, 114688, null, 0, i4.DlnDateTimeInlineComponent, [i1.ChangeDetectorRef, [2, i4.DateTimeAdapter], [2, i4.DLN_DATE_TIME_FORMATS]], { pickerType: [0, "pickerType"], startAt: [1, "startAt"] }, { selectedDate: "selectedDate" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = "timer"; var currVal_1 = _co.getDate(); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
exports.View_DlnInputTimeComponent_0 = View_DlnInputTimeComponent_0;
function View_DlnInputTimeComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "dln-input-time", [], null, null, null, View_DlnInputTimeComponent_0, RenderType_DlnInputTimeComponent)), i1.ɵdid(1, 49152, null, 0, i5.DlnInputTimeComponent, [], null, null)], null, null); }
exports.View_DlnInputTimeComponent_Host_0 = View_DlnInputTimeComponent_Host_0;
var DlnInputTimeComponentNgFactory = i1.ɵccf("dln-input-time", i5.DlnInputTimeComponent, View_DlnInputTimeComponent_Host_0, { label: "label", control: "control", defaultDate: "defaultDate" }, {}, []);
exports.DlnInputTimeComponentNgFactory = DlnInputTimeComponentNgFactory;
