"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var environment_1 = require("../environments/environment");
var core_1 = require("@angular/core");
var hmr_1 = require("@angularclass/hmr");
var __NgCli_bootstrap_1 = require("./app.module.ngfactory");
var __NgCli_bootstrap_2 = require("@angular/platform-browser");
var hmrBootstrap = function (module, boot) {
    var ngModule;
    module.hot.accept();
    boot().then(function (mod) { return ngModule = mod; });
    module.hot.dispose(function () {
        var appRef = ngModule.injector.get(core_1.ApplicationRef);
        var elements = appRef.components.map(function (c) { return c.location.nativeElement; });
        var makeVisible = hmr_1.createNewHosts(elements);
        ngModule.destroy();
        makeVisible();
    });
};
var ɵ0 = hmrBootstrap;
exports.ɵ0 = ɵ0;
if (environment_1.environment.production) {
    core_1.enableProdMode();
}
var bootstrap = function () { return __NgCli_bootstrap_2.platformBrowser().bootstrapModuleFactory(__NgCli_bootstrap_1.LogbookAppModuleNgFactory); };
var ɵ1 = bootstrap;
exports.ɵ1 = ɵ1;
if (environment_1.environment.hmr) {
    if (module['hot']) {
        console.log('HMR is enabled');
        hmrBootstrap(module, bootstrap);
    }
    else {
        console.error('HMR is not enabled for webpack-dev-server!');
        console.log('Are you using the --hmr flag for ng serve?');
    }
}
else {
    console.log('HMR is disabled');
    bootstrap().then(function () { }).catch(function (err) { return console.log(err); });
}
