import {ChangeDetectorRef, Pipe, PipeTransform} from "@angular/core";
import {User} from "../../../../../../app/core/shared/models/user.model";

@Pipe({
    name: 'userOrder',
    pure: true
})
export class UserOrderPipe implements PipeTransform {

    transform(users: Array<User>): User[] {
        if (users) {
            return users.sort((a: User, b: User) => {
                const fieldA = a.lastName || a.positionInCompany || a.userId;
                const fieldB = b.lastName || b.positionInCompany || b.userId;
                if (fieldA.toUpperCase() < fieldB.toUpperCase()) {
                    return -1;
                } else if (fieldA.toUpperCase() > fieldB.toUpperCase()) {
                    return 1;
                } else {
                    return 0;
                }
            });
        } else {
            return users;
        }
    }
}
