import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {PieceJointe} from "../model/piece-jointe.model";
import {MessageForm} from "../../../../model/message-form.model";
import {AttachmentService} from "../service/attachment.service";
import {first} from "rxjs/operators";

@Component({
    selector: 'dln-attach-file',
    templateUrl: './attach-file.component.html',
    styleUrls: ['./attach-file.scss'],
})
// TODO Use this flight-report in text-editor
export class AttachFileComponent {

    @ViewChild('inputFile') inputFile: any;
    @Input() messageForm: MessageForm;
    @Input() accept = "*";
    @Input() showDragAndDrop = true;
    @Input() multipleAttachFile = false;
    @Output() messageFormChange = new EventEmitter<MessageForm>();

    constructor(private attachmentService: AttachmentService) {
        if (this.messageForm == null) {
            this.messageForm = new MessageForm();
        }
    }

    public onFileChange(event) {
        const target = event.target || event.srcElement;

        if (this.multipleAttachFile) {
            Array.from(target.files).forEach((file) => {
                this.attachmentService.postFile(this.messageForm, file).pipe(first()).subscribe(() => {
                    this.messageFormChange.emit(this.messageForm);
                });
            });

        } else {
            if (target.files && target.files[0]) {
                this.messageForm.files = [];
                this.attachmentService.postFile(this.messageForm, target.files[0]).pipe(first()).subscribe(() => {
                    this.messageFormChange.emit(this.messageForm);
                });
            }
        }

        this.inputFile.nativeElement.value = "";
    }

    public removeFile(indexToRemove) {
        this.messageForm.files = this.messageForm.files.filter((val, index) =>
            index !== indexToRemove
        );
        this.messageFormChange.emit(this.messageForm);
    }

    public fileIsPJ(file) {
        return file instanceof PieceJointe;
    }
}
