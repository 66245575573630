<h2 mat-dialog-title>Switch Team</h2>
<mat-dialog-content *ngIf="!openNewTeamForm">
    <dln-input [placeholder]="'Search team'" [control]="teamSearchControl"></dln-input>
    <ngx-datatable *ngIf="teamPage.content && teamPage.content.length"
                   #table
                   class='material dln-table'
                   [loadingIndicator]="teamPage.isLoading"
                   [virtualization]="false"
                   [reorderable]="false"
                   [rows]="teamPage.content"
                   [columnMode]="'flex'"
                   [scrollbarH]="false"
                   [scrollbarV]="false"
                   [headerHeight]="50"
                   [footerHeight]="50"
                   [rowHeight]="50"
                   [externalPaging]="true"
                   [selectionType]="'single'"
                   [count]="teamPage.totalElements"
                   [offset]="teamPage.page"
                   [limit]="teamPage.size"
                   (select)='selectTeam($event)'
                   (page)='setPage($event)'>
        <ngx-datatable-column name="Name" prop="name" [flexGrow]="4" [resizeable]="false">
            <ng-template let-value="value" ngx-datatable-cell-template>
                {{value}}
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Members" prop="membres" [flexGrow]="1" [resizeable]="false">
            <ng-template let-value="value" ngx-datatable-cell-template>
                {{value?.length}} <i class="dln-icon-peoples"></i>
            </ng-template>
        </ngx-datatable-column>
    </ngx-datatable>
</mat-dialog-content>
<mat-dialog-actions *ngIf="!openNewTeamForm">
    <dln-button [label]="'Create a new team'" [icon]="'dln-icon-add'" (onClick)="createNewTeam()"></dln-button>
</mat-dialog-actions>

<mat-dialog-content *ngIf="openNewTeamForm">
    <dln-input [placeholder]="'Team name'" [control]="teamNameControl"></dln-input>
</mat-dialog-content>
<mat-dialog-actions *ngIf="openNewTeamForm">
    <dln-button [label]="'Cancel'" (onClick)="openNewTeamForm = false" [color]="'gray'"></dln-button>
    <dln-button [label]="'Create team'" (onClick)="saveTeam()"></dln-button>
</mat-dialog-actions>
