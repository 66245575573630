<button [type]="type" [disabled]="isDisabled || isLoading" (click)="clickEmit()" [id]="idBtn"
        [class]="color +' border-' + border + ' icon-'+iconPosition" [ngClass]="{'withIcon': icon}">
    <dln-spinner *ngIf="isLoading; else button_label" [label]="isLoadingLabel" [isButton]="true">
    </dln-spinner>
    <ng-template #button_label>
        <div class="button-container">
            <span class="label">{{label}}</span>
            <i [class]="icon"></i>
        </div>
    </ng-template>
</button>
