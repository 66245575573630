import {Component, Input} from '@angular/core';

@Component({
    selector: 'dln-drop-file',
    templateUrl: './drop-file.component.html',
    styleUrls: [
        './drop-file.component.scss'
    ]
})
export class DlnDropFileComponent {
    @Input() isDroppingFile = false;
}
