"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var SortPipe = /** @class */ (function () {
    function SortPipe() {
    }
    SortPipe.prototype.transform = function (ary, fn) {
        if (fn === void 0) { fn = function (a, b) { return a > b ? 1 : -1; }; }
        return ary.sort(fn);
    };
    return SortPipe;
}());
exports.SortPipe = SortPipe;
