
import {map} from 'rxjs/operators';
import {HttpClient, HttpParams} from "@angular/common/http";
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {LogbookConfig} from "../../../../../../../app/core/shared/config/logbook-config";
import {FlightTag} from "../model/flight-tag.model";

@Injectable()
export class FlightTagSearchService {

    constructor(private http: HttpClient, private config: LogbookConfig) {
    }

    suggestFlights(query: string, action: string, from: string, to: string, profile: string): Observable<Array<FlightTag>> {

        const options: HttpParams = new HttpParams()
            .set('action', action)
            .set('from', from)
            .set('to', to)
            .set('profile', profile);

        return this.http.get(`${this.config.LOGBOOK_API}/api/flight-tag-search/flight-tag/search/` + query, {params: options}).pipe(
            map((suggestFlightList: any) => {
                return suggestFlightList.map(
                    (flightTagString) => FlightTag.createFlightTagFromString(flightTagString)
                );
            }));
    }

}
