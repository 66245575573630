import {Injectable} from '@angular/core';
import {Intercom} from "ng-intercom";
import {Observable, Subject} from 'rxjs';
import {SignAsUserAccess} from "../../shared/models/sign-as-user-access.model";
import {User} from "../../shared/models/user.model";
import {VisitorGuest} from "../../shared/models/visitor-access.model";
import {AccountService} from './account.service';

@Injectable({
    providedIn: 'root',
})
export class Principal {
    private userIdentity: any;
    private authenticated = false;
    private authenticationState = new Subject<any>();

    constructor(private account: AccountService, private intercomService: Intercom) {
    }

    authenticate(identity) {
        this.userIdentity = identity;
        this.authenticated = identity !== null;
        this.authenticationState.next(this.userIdentity);
    }

    hasAnyAuthority(authorities: string[]): Promise<boolean> {
        return Promise.resolve(this.hasAnyAuthorityDirect(authorities));
    }

    hasAnyAuthorityDirect(authorities: string[]): boolean {
        if (!this.authenticated || !this.userIdentity || !this.userIdentity.authorities) {
            return false;
        }

        for (let i = 0; i < authorities.length; i++) {
            if (this.userIdentity.authorities
                    .filter((userAuthorities) => userAuthorities === authorities[i])
                    .length
                !== 0) {
                return true;
            }
        }

        return false;
    }

    hasFeatureEnable(features: string[]): Promise<boolean> {
        return Promise.resolve(this.hasFeatureEnableDirect(features));
    }

    hasFeatureEnableDirect(features: string[]): boolean {
        if (!this.authenticated || !this.userIdentity || !this.userIdentity.featuresEnabled) {
            return false;
        }

        for (let i = 0; i < features.length; i++) {
            if (this.userIdentity.featuresEnabled
                    .filter((feature) => feature === features[i])
                    .length
                !== 0) {
                return true;
            }
        }

        return false;
    }

    identity(force?: boolean): Promise<User> {
        if (force === true) {
            this.userIdentity = undefined;
        }

        // check and see if we have retrieved the userIdentity data from the server.
        // if we have, reuse it by immediately resolving
        if (this.userIdentity) {
            return Promise.resolve(this.userIdentity);
        }

        // retrieve the userIdentity data from the server, update the identity object, and then resolve.
        return this.account.get().toPromise().then((account: User) => {
            if (account) {
                this.userIdentity = account;
                this.authenticated = true;
                if (!(account instanceof VisitorGuest || account instanceof SignAsUserAccess)) {
                    this.intercomService.boot({
                        email: account.email,
                        name: account.displayUserName(),
                        user_id: account.userId,
                        phone: account.phones,
                        companies: [{
                            name: account.getCompanyName(),
                            company_id: account.getCompanyName()
                        }]
                    })
                }
            } else {
                this.userIdentity = null;
                this.authenticated = false;
            }
            this.authenticationState.next(this.userIdentity);

            return this.userIdentity;
        }).catch((err) => {
            this.userIdentity = null;
            this.authenticated = false;
            this.authenticationState.next(this.userIdentity);
            return null;
        });
    }

    identityDirect() {
        return this.userIdentity;
    }

    isAuthenticated(): boolean {
        return this.authenticated;
    }

    isIdentityResolved(): boolean {
        return this.userIdentity !== undefined;
    }

    getAuthenticationState(): Observable<any> {
        return this.authenticationState.asObservable();
    }
}
