import {Component, ElementRef, HostListener, Input} from "@angular/core";

@Component({
    selector: 'dln-dropdown-content',
    templateUrl: './dropdown-content.component.html',
    styleUrls: ['./dropdown-content.scss']
})
export class DropdownContentComponent {
    @Input() hostElement: HTMLElement;
    @Input() content: string;
    @Input() placement: "top" | "bottom" | "left" | "right" = "bottom";
    @Input() placementArrow: "top" | "bottom" | "left" | "right";
    @Input() displayOnHover = false;
    @Input() hoverDelay = 500;

    currentTop = 0;
    top = -100000;
    left = -100000;
    isActive = false;

    constructor(private element: ElementRef) {
    }

    @HostListener("focusout")
    @HostListener("mouseleave")
    hideListener(): void {
        if (this.displayOnHover) {
            this.hide();
        }
    }

    show(): void {
        if (!this.hostElement) {
            return;
        }
        const p = this.positionElements(this.hostElement, this.element.nativeElement.children[0], this.placement);
        this.top = p.top;
        this.left = p.left;
        this.isActive = true;
    }

    hide(): void {
        this.top = -100000;
        this.left = -100000;
        this.isActive = false;
    }

    private positionElements(hostEl: HTMLElement, targetEl: HTMLElement, positionStr: string, appendToBody = false): { top: number, left: number } {
        const positionStrParts = positionStr.split("-");
        const pos0 = positionStrParts[0];
        const pos1 = positionStrParts[1] || "center";
        const hostElPos = appendToBody ? DropdownContentComponent.offset(hostEl) : DropdownContentComponent.position(hostEl);
        const targetElWidth = targetEl.offsetWidth;
        const targetElHeight = targetEl.offsetHeight;
        const dropdownMargin = 10;

        const shiftWidth: any = {
            center: () => hostElPos.left + hostElPos.width / 2 - targetElWidth / 2,
            left: () => hostElPos.left,
            right: () => hostElPos.left + hostElPos.width
        };

        const shiftHeight: any = {
            center: () => hostElPos.top + hostElPos.height / 2 - targetElHeight / 2,
            top: () => hostElPos.top,
            bottom: () => hostElPos.top + hostElPos.height
        };

        let targetElPos: { top: number, left: number };

        switch (pos0) {
            case "right":
                targetElPos = {
                    top: shiftHeight[pos1](),
                    left: shiftWidth[pos0]() + dropdownMargin
                };
                break;

            case "left":
                targetElPos = {
                    top: shiftHeight[pos1](),
                    left: hostElPos.left - targetElWidth - dropdownMargin
                };
                break;

            case "bottom":
                targetElPos = {
                    top: shiftHeight[pos0]() + dropdownMargin,
                    left: shiftWidth[pos1]()
                };
                break;

            default:
                targetElPos = {
                    top: hostElPos.top - targetElHeight - dropdownMargin,
                    left: shiftWidth[pos1]()
                };
                break;
        }

        return targetElPos;
    }

    private static position(nativeEl: HTMLElement): { width: number, height: number, top: number, left: number } {
        let offsetParentBCR = {top: 0, left: 0};
        const elBCR = DropdownContentComponent.offset(nativeEl);
        const offsetParentEl = DropdownContentComponent.parentOffsetEl(nativeEl);
        if (offsetParentEl !== window.document) {
            offsetParentBCR = DropdownContentComponent.offset(offsetParentEl);
            offsetParentBCR.top += offsetParentEl.clientTop - offsetParentEl.scrollTop;
            offsetParentBCR.left += offsetParentEl.clientLeft - offsetParentEl.scrollLeft;
        }

        const boundingClientRect = nativeEl.getBoundingClientRect();
        return {
            width: boundingClientRect.width || nativeEl.offsetWidth,
            height: boundingClientRect.height || nativeEl.offsetHeight,
            top: elBCR.top - offsetParentBCR.top,
            left: elBCR.left - offsetParentBCR.left
        };
    }

    private static offset(nativeEl: any): { width: number, height: number, top: number, left: number } {
        const boundingClientRect = nativeEl.getBoundingClientRect();
        return {
            width: boundingClientRect.width || nativeEl.offsetWidth,
            height: boundingClientRect.height || nativeEl.offsetHeight,
            top: boundingClientRect.top + (window.pageYOffset || window.document.documentElement.scrollTop),
            left: boundingClientRect.left + (window.pageXOffset || window.document.documentElement.scrollLeft)
        };
    }

    private static parentOffsetEl(nativeEl: HTMLElement): any {
        let offsetParent: any = nativeEl.offsetParent || window.document;
        while (offsetParent && offsetParent !== window.document && DropdownContentComponent.isStaticPositioned(offsetParent)) {
            offsetParent = offsetParent.offsetParent;
        }
        return offsetParent || window.document;
    }

    private static getStyle(nativeEl: HTMLElement, cssProp: string): string {
        if ((nativeEl as any).currentStyle) {
            return (nativeEl as any).currentStyle[cssProp]; // IE
        }

        if (window.getComputedStyle) {
            return (window.getComputedStyle(nativeEl) as any)[cssProp];
        }

        // finally try and get inline style
        return (nativeEl.style as any)[cssProp];
    }

    private static isStaticPositioned(nativeEl: HTMLElement): boolean {
        return (DropdownContentComponent.getStyle(nativeEl, "position") || "static") === "static";
    }

}
