import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot} from '@angular/router';
import {LoginService} from "../../auth/services/login.service";

@Injectable()
export class TokenAuthRouteAccessService implements CanActivate {

    constructor(private loginService: LoginService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Promise<boolean> {
        if (route.queryParams.token) {
            return this.loginService.loginWithToken(route.queryParams.token, true).then(() => {
                return true
            }).catch(() => false);
        } else {
            return true;
        }
    }
}
