"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var momentImported = require("moment");
var moment = momentImported;
var DayDatePipe = /** @class */ (function () {
    function DayDatePipe() {
    }
    DayDatePipe.prototype.transform = function (date) {
        var dateMoment = moment.utc(date);
        var today = moment.utc();
        var yesterday = moment.utc().subtract(1, 'days').startOf('day');
        if (dateMoment.isSame(today, 'day')) {
            // TODAY
            return "Today";
        }
        else {
            if (dateMoment.isSame(yesterday, 'day')) {
                // YESTERDAY
                return "Yesterday";
            }
            else {
                return today.diff(date, 'days') + " days ago";
            }
        }
    };
    return DayDatePipe;
}());
exports.DayDatePipe = DayDatePipe;
