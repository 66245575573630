import {Societe} from "./societe.model";
import {User} from "./user.model";

export interface Invitation {
    id: string;
    guest: string;
    host: string;
    token: string;
    hostCommunityId: string;
    createdAt: Date;
    status: string;
    statusChangedAt?: Date
}

export interface ImportParams {
    id?: string;
    smi: string;
    airline?: string[];
    airportFrom?: string[];
    airportTo?: string[];
    sender?: string[];
    codeDelays?: string[];
    mvtMinDuration?: number;
    communaute: any;
}

export class Team {
    public id?: string;
    public name?: string;
    public membres ?: any[] = [];
    public profile ?: any[] = [];
    public tags ?: any[] = [];
    public invitations?: Invitation[] = [];
    public importParams?: ImportParams[] = [];
    public company?: Societe;

    public isUserMember(userId) {
        console.log(this.membres)
        return this.membres && this.membres.some((user) => user.userId === userId)
    }

    public convertToJson() {
        return {
            id: this.id,
            name: this.name,
            profile: this.profile,
            tags: this.tags,
            membres: this.membres.map((membre: User) => {
                return {
                    id: membre.userId
                }
            })
        }
    }
}
