import {Component, Inject, Optional} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";

@Component({
    selector: 'dln-confirm-modal',
    templateUrl: './confirm-modal.component.html',
    styleUrls: ['./confirm-modal.scss']
})
export class ConfirmModalComponent {
    title: string;
    message: string;
    hideCancel = false;

    constructor(public dialogRef: MatDialogRef<ConfirmModalComponent>,
                @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
        this.title = data.title;
        this.message = data.message;
        this.hideCancel = data.hideCancel;
    }

    cancel() {
        this.dialogRef.close(false);
    }

    confirm() {
        this.dialogRef.close(true);
    }
}
