
import {distinctUntilChanged, debounceTime, first, takeUntil} from 'rxjs/operators';
import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    forwardRef,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import {ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR} from "@angular/forms";
import {Pageable} from "../../../model/pageable";
import {NgSelectComponent} from "../../../common/ng-select/ng-select.component";
import {NgOption} from "../../../common/ng-select/ng-select.types";
import {Societe} from "../../../../../../../app/core/shared/models/societe.model";
import {SocieteService} from "../../../user/service/societe.service";
import {Subject} from "rxjs/index";

@Component({
    selector: 'dln-input-company',
    templateUrl: './input-company.component.html',
    styleUrls: [
        './input-company.component.scss'
    ],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DlnInputCompanyComponent),
            multi: true,
        }
    ]
})
export class DlnInputCompanyComponent implements ControlValueAccessor, OnInit, OnDestroy {
    @Input() labelId: string;
    @Input() email: string;
    @Input() loggedUser: any;
    @Input() contactEmail: string;

    @ViewChild(NgSelectComponent) ngSelect: NgSelectComponent;

    @Output()
    public onCompanySelected = new EventEmitter<Societe>();

    @Input() public companyPreSelected: Societe;
    public companySearchControl = new EventEmitter<string>();
    public companiesSuggestion: NgOption[] = [];
    public companiesSuggestionByEmail: NgOption[] = [];
    public companiesSuggestionBySearch: NgOption[] = [];
    disabled = false;
    private destroy$ = new Subject<void>();

    private propagateChange = (_: any) => {};

    constructor(private societeService: SocieteService, private changeDetection: ChangeDetectorRef) {}

    ngOnInit() {
        if (this.loggedUser != null) {
            if (this.loggedUser.userId != null) {
            this.suggestSocieteByEmail(this.loggedUser.userId);
            }
        } else {
            if (this.contactEmail != null) {
                this.suggestSocieteByEmail(this.contactEmail);
            }
        }

        this.companySearchControl.pipe(
            debounceTime(250),
            distinctUntilChanged(),
            takeUntil(this.destroy$)
        ).subscribe((search) => this.searchCompanies(search));

        if (this.companyPreSelected != null) {
            const companyItem = {
                id: this.companyPreSelected.id,
                name: this.companyPreSelected.nom,
                params: this.companyPreSelected
            };
            this.companiesSuggestion.push(companyItem);
            this.writeValue(this.companyPreSelected);
        }
    }

    writeValue(obj: any): void {
        if (obj) {
            setTimeout(() => {
                this.ngSelect.toggle({
                    id: obj.id,
                    name: obj.nom,
                    params: obj,
                });
            }, 0)
        }
    }

    registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }

    registerOnTouched(fn: any): void {
    }

    setDisabledState(isDisabled: boolean) {
        this.disabled = isDisabled;
    }

    updateCompany($event) {
        const companySelected = $event ? $event.params : null;
        this.onCompanySelected.emit(companySelected);
        this.propagateChange(companySelected);
    }

    private searchCompanies(search?) {
        this.societeService.findAll(new Pageable(0, 25, "nom"), search).pipe(first())
            .subscribe((res) => {
                this.companiesSuggestionBySearch = res.content.map((i) => {
                    return {
                        id: i.id,
                        name: i.nom,
                        params: i,
                        parentId: 1,
                    }
                }).filter((i) => this.companiesSuggestionByEmail
                    .filter((societe) => societe.id === i.id)
                    .length === 0
                );

                this.companiesSuggestionBySearch.unshift({id: 1, name: 'Company', isParent: true});
                this.companiesSuggestion = this.companiesSuggestionByEmail.concat(this.companiesSuggestionBySearch);
                this.changeDetection.markForCheck();
            })
    }

    private suggestSocieteByEmail(email: string) {
        this.societeService.suggestSocieteByEmail(email).pipe(first())
            .subscribe((res) => {
            this.companiesSuggestionByEmail = res.map((i) => {
                    return {
                        id: i.id,
                        name: i.nom,
                        params: i,
                        parentId: 0,
                    }
                })
            this.companiesSuggestionByEmail.unshift({id: 0, name: 'Suggestion', isParent: true});
            this.searchCompanies();
        })
    }

    ngOnDestroy() {
        this.destroy$.next();
    }

}
