"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.environment = {
    production: true,
    hmr: false,
    LOGBOOK_API: 'https://api.logbook.teamlogs.net',
    LOGBOOK_WEB_URL: 'http://logbook.teamlogs.net',
    MIXPANEL_TOKEN: 'fed81d3cfa3e86644e8e699d4ee1f918',
    GOOGLE_ANALYTICS_TOKEN: 'UA-71619570-8',
    INTERCOM_TOKEN: '3T1h4ChZ1EdyPeziOGZTD2nk3cAPCZQ3V-pzCU2U',
    CGU_VERSION: '2.0',
    TERMS_USER_AGREEMENT_URL: 'https://s3-eu-west-1.amazonaws.com/teamlogs-prod-logbook/docs-public/Logbook-User_Agreement_2018-07-06.pdf',
    TERMS_PRIVACY_POLICY_URL: 'https://s3-eu-west-1.amazonaws.com/teamlogs-prod-logbook/docs-public/Logbook-Privacy_Policy_2018-07-06.pdf',
    VERSION: require('../../package.json').version,
    VAPID_PUBLIC_KEY: 'BC93c-bDC42GcR8LlQggTMpflKhXU0SXeefHtElXZozla3I4RdXwWJZZL0zMU0n6knXLkDKETIsVKDk4waR0pyA'
};
