import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {Contact} from "../../user/model/contact.model";
import {User} from "../../../../../../app/core/shared/models/user.model";
import {DropdownComponent} from "../dropdown/dropdown.component";

@Component({
    selector: 'dln-user-info',
    templateUrl: './user-info.component.html',
    exportAs: 'userInfo',
    styleUrls: [
        'user-info.scss'
    ]
})
export class UserInfoComponent implements OnInit {

    @Input() user: User;
    @Input() contact: Contact;
    @Input() placement: "top" | "bottom" | "left" | "right" = "bottom";
    @ViewChild('dropdown') private dropdown: DropdownComponent;

    constructor() {
    }

    ngOnInit() {
    }

    closeDropdown() {
        this.dropdown.closeDropdown();
    }

    isDropdownActive() {
        return this.dropdown.dropdownContent.isActive;
    }

}
