"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var notification_api_service_1 = require("@logbook/notification-center/shared/service/notification.api.service");
var principal_service_1 = require("@logbook/core/auth/services/principal.service");
var operators_1 = require("rxjs/operators");
var shared_1 = require("@logbook/shared");
var LogbookNotification_1 = require("@logbook/notification-center/shared/model/LogbookNotification");
var rxjs_1 = require("rxjs");
var NotificationCenterService = /** @class */ (function () {
    function NotificationCenterService(notificationApiService, principal, notificationWsService) {
        var _this = this;
        this.notificationApiService = notificationApiService;
        this.principal = principal;
        this.notificationWsService = notificationWsService;
        this.unreadNotifications = [];
        this.notificationSubscribe = new rxjs_1.Subject();
        this.principal.getAuthenticationState().pipe(operators_1.startWith(this.principal.identityDirect()))
            .subscribe(function (user) {
            if (user) {
                _this.notificationApiService.getUnSeen().pipe(operators_1.first())
                    .subscribe(function (notifications) { return _this.unreadNotifications = notifications; });
            }
        });
        this.wsSub = this.notificationWsService.onEventPublished(shared_1.NotificationEventType.NotificationCreated)
            .pipe(operators_1.map(function (notif) { return Object.assign(new LogbookNotification_1.LogbookNotification(), notif.notification); }))
            .subscribe(function (notif) {
            if (!_this.notificationIsInUnSeeList(notif.id)) {
                _this.unreadNotifications.push(notif);
                _this.notificationSubscribe.next(notif);
            }
        });
    }
    NotificationCenterService.prototype.onNewNotification = function () {
        return this.notificationSubscribe;
    };
    NotificationCenterService.prototype.getAll = function (pageable) {
        this.setNotificationsAsSee();
        return this.notificationApiService.getAll(pageable);
    };
    NotificationCenterService.prototype.markAsRead = function (notification) {
        this.notificationApiService.markAsRead(notification.id, 'LOGBOOK').pipe(operators_1.first())
            .subscribe(function () { return notification.readAt = new Date(); });
    };
    NotificationCenterService.prototype.remove = function (id) {
        return this.notificationApiService.remove(id);
    };
    NotificationCenterService.prototype.markAllAsRead = function () {
        this.notificationApiService.markAllAsRead().pipe(operators_1.first()).subscribe();
    };
    NotificationCenterService.prototype.setNotificationsAsSee = function () {
        this.unreadNotifications = [];
    };
    NotificationCenterService.prototype.notificationIsInUnSeeList = function (notificationId) {
        return this.unreadNotifications.some(function (notification) {
            return notification.id === notificationId;
        });
    };
    NotificationCenterService.prototype.ngOnDestroy = function () {
        if (this.wsSub) {
            this.wsSub.unsubscribe();
        }
    };
    return NotificationCenterService;
}());
exports.NotificationCenterService = NotificationCenterService;
