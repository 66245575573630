<div class="notification-card" [class.isRead]="notification?.readAt" (click)="viewNotification()">
    <div class="header">
        <notification-category [category]="notification?.category"></notification-category>
        <div class="date">
            - {{notification?.createdAt | timeAgo}}
        </div>
    </div>

    <div class="body">
        <div class="title">
            {{notification?.title}}
        </div>

        <div class="description" [innerHTML]="notification?.description | safeHtml">
        </div>
    </div>
</div>

<span class="dropdown-toggle" [class.isRead]="notification?.readAt" [matMenuTriggerFor]="actionMenu">
    <i class="dln-icon-see_more"></i>
</span>

<mat-menu #actionMenu="matMenu" [class]="'blue-menu-background'" [yPosition]="'below'" [xPosition]="'before'" [overlapTrigger]="false">
    <button mat-menu-item (click)="readNotification()" *ngIf="!notification?.readAt">
        Mark as read
    </button>
    <button mat-menu-item (click)="deleteNotification()">
        Delete
    </button>
</mat-menu>

