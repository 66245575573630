"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var log_filter_model_1 = require("./log-filter.model");
var CategoryLogFilter = /** @class */ (function (_super) {
    tslib_1.__extends(CategoryLogFilter, _super);
    function CategoryLogFilter() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return CategoryLogFilter;
}(log_filter_model_1.LogFilter));
exports.CategoryLogFilter = CategoryLogFilter;
