import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'modal-header',
    templateUrl: './modal-header.component.html',
    styleUrls: ['./modal-header.component.scss']
})
export class ModalHeaderComponent implements OnInit {

    @Input() dragClass: string;
    @Input() title: string;
    @Output() close = new EventEmitter<void>();

    constructor() { }

    ngOnInit() {}

}
