"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Color = /** @class */ (function () {
    function Color(name, value) {
        this.name = name;
        this.value = value;
    }
    Color.getDefaultColors = function () {
        var defaultColors = [];
        defaultColors.push(new Color("black", "#27273b"));
        defaultColors.push(new Color("blue", "#1982ee"));
        defaultColors.push(new Color("red", "#E63A20"));
        defaultColors.push(new Color("green", "#46c977"));
        defaultColors.push(new Color("purple", "#52489c"));
        defaultColors.push(new Color("orange", "#ffae71"));
        defaultColors.push(new Color("gray", "#848499"));
        defaultColors.push(new Color("grayDark", "#4f5d75"));
        defaultColors.push(new Color("grayLight", "#f8f8f9"));
        return defaultColors;
    };
    return Color;
}());
exports.Color = Color;
