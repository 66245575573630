"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var router_1 = require("@angular/router");
var navbar_component_1 = require("@logbook/core/layouts/navbar/navbar.component");
var routes_definitions_1 = require("@logbook/support/routes.definitions");
var user_already_logged_route_acces_service_1 = require("@logbook/core/shared/guards/user-already-logged-route-acces-service");
var access_from_cute_station_guard_service_1 = require("@logbook/core/shared/guards/access-from-cute-station-guard.service");
var APP_ROUTES = [
    {
        path: '',
        component: navbar_component_1.NavbarComponent,
        outlet: 'navbar',
        resolve: {
            onCuteStation: access_from_cute_station_guard_service_1.AccessFromCuteStationGuard
        }
    },
    {
        path: 'account',
        redirectTo: '/management/account/profile'
    },
    {
        path: 'change-log',
        loadChildren: './change-log/change-log.module#ChangeLogModule'
    },
    {
        path: 'logs',
        loadChildren: '../../logs-view/logs-view.module#LogsViewModule'
    },
    {
        path: 'management',
        loadChildren: '../../../logbook/settings/src/lib/logbook-settings.module#LogbookSettingsModule',
    },
    {
        path: 'contact',
        loadChildren: '../../contact/contact.module#LogbookContactModule'
    },
    {
        path: 'instructions',
        loadChildren: '../../consigne/instructions/instructions.module#LogbookInstructionsModule'
    },
    {
        path: 'flight-report',
        loadChildren: '../../../logbook/flight-report/src/lib/flight-report.module#FlightReportModule'
    },
    {
        path: 'dashboard',
        loadChildren: '../../dashboard/dashboard.module#DashboardModule'
    },
    {
        path: routes_definitions_1.SUPPORT_MAIN_ROUTE,
        loadChildren: '../../support/support.module#LogbookSupportModule'
    },
    {
        path: routes_definitions_1.SUPPORT_TERMS_AND_CONTITIONS,
        loadChildren: '../../support/help-and-condition.module#LogbookHelpAndConditionModule'
    },
    {
        path: '',
        loadChildren: '../../landing/account.module#LogbookAccountModule',
        canActivate: [user_already_logged_route_acces_service_1.UserAlreadyLoggedRouteAccessService],
        runGuardsAndResolvers: "always"
    }
];
var LayoutRoutingModule = /** @class */ (function () {
    function LayoutRoutingModule() {
    }
    return LayoutRoutingModule;
}());
exports.LayoutRoutingModule = LayoutRoutingModule;
