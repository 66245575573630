import {Injectable} from "@angular/core";
import {HammerGestureConfig} from "@angular/platform-browser";
import {DeviceDetectorService} from "ngx-device-detector";

declare let Hammer: any;

@Injectable()
export class MyHammerConfig extends HammerGestureConfig {

    constructor(private deviceService: DeviceDetectorService) {
        super();
    }

    buildHammer(element: HTMLElement) {
        const isMobile = this.deviceService.isMobile() || this.deviceService.isTablet();
        return new Hammer(element, {
            touchAction: "pan-y",
            enable: isMobile,
            cssProps: {
                userSelect: true
            }
        });
    }
}
