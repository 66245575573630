import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRouteSnapshot, NavigationEnd, Router} from '@angular/router';
import {ChangeLogService} from '@logbook/shared';
import {Intercom} from "ng-intercom";
import {Principal} from "../../auth/services/principal.service";
import {LoginService} from "../../auth/services/login.service";
import {MixpanelService} from "../../tracking/mixpanel.service";
import {MixpanelEvent} from "../../tracking/mixpanel-event.enum";
import {User} from "../../shared/models/user.model";
import {SignAsUserAccess} from "../../shared/models/sign-as-user-access.model";
import {first, startWith} from "rxjs/operators";
import {Subscription} from "rxjs/internal/Subscription";

@Component({
    selector: 'dln-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: [
        'navbar.component.scss'
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavbarComponent implements OnInit, OnDestroy {

    isNavbarCollapsed: boolean;
    version: string;
    account: User;
    prevUserId: string;
    isSignAsUser: boolean;
    showBackButton = false;
    userGuideLink: string;
    navBarTitle: string;
    newChangeLogCount: number;
    routerSub: Subscription;

    constructor(private principal: Principal, private loginService: LoginService,
                private router: Router, private mixpanelService: MixpanelService,
                private changeDetector: ChangeDetectorRef,
                private intercomService: Intercom, private changeLogService: ChangeLogService) {
        this.isNavbarCollapsed = true;
        if (navigator.language && navigator.language === 'fr') {
            this.userGuideLink = "https://s3-eu-west-1.amazonaws.com/teamlogs-prod-logbook/docs-public/Logbook_UserGuide_fr.pdf"
        } else {
            this.userGuideLink = "https://s3-eu-west-1.amazonaws.com/teamlogs-prod-logbook/docs-public/Logbook_UserGuide_en.pdf"
        }
    }

    ngOnInit() {
        this.principal.getAuthenticationState().pipe(startWith(this.principal.identityDirect()))
            .subscribe((identity) => {
                this.updateAccount(identity);
            });

        this.showBackButton = this.getRouteData(this.router.routerState.snapshot.root, 'backButton');
        this.navBarTitle = this.getRouteData(this.router.routerState.snapshot.root, 'navBarTitle');
        this.routerSub = this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.showBackButton = this.getRouteData(this.router.routerState.snapshot.root, 'backButton');
                this.navBarTitle = this.getRouteData(this.router.routerState.snapshot.root, 'navBarTitle');
                this.principal.identity().then((identity) => {
                    this.updateAccount(identity);
                });
            }
        });
    }

    private getRouteData(routeSnapshot: ActivatedRouteSnapshot, data) {
        let resData: boolean = (routeSnapshot.data && routeSnapshot.data[data]) ? routeSnapshot.data[data] : false;
        if (routeSnapshot.firstChild) {
            resData = this.getRouteData(routeSnapshot.firstChild, data) || resData;
        }
        return resData;
    }

    updateAccount(identity) {
        this.account = identity;
        this.isSignAsUser = this.account instanceof SignAsUserAccess;

        if (this.account) {
            if ((!this.prevUserId || this.prevUserId !== this.account.userId) && this.principal.hasAnyAuthorityDirect(['ROLE_USER'])) {
                this.changeLogService.countUnread().pipe(first()).subscribe((count) => {
                    this.newChangeLogCount = count;
                    this.changeDetector.markForCheck();
                })
            }

            this.prevUserId = this.account.userId;
        }

        this.changeDetector.markForCheck();
    }

    isAuthenticated() {
        return this.principal.isAuthenticated() && this.principal.hasAnyAuthorityDirect(['ROLE_USER']);
    }

    logoutSignAs() {
        this.loginService.logoutSignAs();
    }

    goToChangeLogs() {
        this.newChangeLogCount = 0;
        this.router.navigate(["/change-log"]);
    }

    goToSupportPage() {
        this.isNavbarCollapsed = true;
        this.router.navigate(["/support/learn-how-to-use-instructions"]);
    }

    logout() {
        this.loginService.logout()
            .then(() => {
                this.mixpanelService.track(MixpanelEvent.LOG_OUT, {});
                this.router.navigate(['logout', {queryParams: {logout: true}, queryParamsHandling: "merge"}]);
            });

    }

    ngOnDestroy() {
        if (this.routerSub) {
            this.routerSub.unsubscribe();
        }
    }
}
