
import {first, map} from 'rxjs/operators';
import {HttpClient} from "@angular/common/http";
import {Injectable} from '@angular/core';
import {Observable, Observer} from "rxjs";
import {PieceJointe} from "../model/piece-jointe.model";
import {MessageForm} from "../../../../model/message-form.model";
import {LogbookConfig} from "../../../../../../../../app/core/shared/config/logbook-config";
import {NotificationsService} from "../../../../notification/services/notifications.service";

@Injectable()
export class AttachmentService {

    constructor(private http: HttpClient, private notificationsService: NotificationsService, private config: LogbookConfig) {
    }

    postFile(messageForm: MessageForm, file): Observable<any> {
        let listenerObserver: Observer<any>;
        const listener: Observable<any> = new Observable((observer) => listenerObserver = observer);

        const formData = new FormData();
        formData.append("file", file, file.name);
        let pieceJointe = new PieceJointe();

        pieceJointe.filename = file.name;
        pieceJointe.isPosting = true;
        const currentIndex = messageForm.files.length;
        messageForm.files.push(pieceJointe);

        this.postAttachement(formData).pipe(first()).subscribe((pj: PieceJointe) => {
            pieceJointe = pj;
            messageForm.files[currentIndex] = new PieceJointe(pj);
            return pj;
        }, (err) => {

            messageForm.files = messageForm.files.filter((val, index) =>
                index !== currentIndex
            );
            this.notificationsService.error(
                "An error occurred",
                "The size of your file excesses the authorized size (10Mb)"
            );
            return err;
        }, () => {
            listenerObserver.next(null);
        });

        return listener;
    }

    postAttachement(formData) {
        return this.http.post(`${this.config.LOGBOOK_API}/api/attachment/upload`, formData).pipe(
            map((pj: PieceJointe) => pj));
    }
}
