"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var TooltipDirective = /** @class */ (function () {
    function TooltipDirective(elementRef) {
        this.elementRef = elementRef;
        this.tooltipOffset = 8;
        this.tooltipText = "";
        this.tooltipActive = true;
        this.tooltipClass = "";
        this.placement = "top";
        this.delay = 0;
        this.showDelay = 0;
        this.hideDelay = 300;
        this.zIndex = false;
    }
    TooltipDirective.prototype.onMouseEnter = function () {
        this.getElemPosition();
        if (this.tooltipActive && !this.tooltip && this.tooltipText && this.tooltipText.length) {
            this.create();
            this.setPosition();
            this.show();
        }
    };
    TooltipDirective.prototype.onMouseLeave = function () {
        this.hide();
    };
    TooltipDirective.prototype.getElemPosition = function () {
        this.elemPosition = this.elementRef.nativeElement.getBoundingClientRect();
    };
    TooltipDirective.prototype.create = function () {
        this.showDelay = this.delay || this.showDelay;
        this.tooltip = document.createElement('span');
        this.tooltip.className += "ng-tooltip ng-tooltip-" + this.placement + " " + this.tooltipClass;
        this.tooltip.innerHTML = this.tooltipText;
        if (this.zIndex) {
            this.tooltip.style.zIndex = this.zIndex;
        }
        document.body.appendChild(this.tooltip);
    };
    TooltipDirective.prototype.show = function () {
        var _this = this;
        if (this.showTimeoutId) {
            clearTimeout(this.showTimeoutId);
        }
        this.showDelay = this.delay || this.showDelay;
        this.showTimeoutId = setTimeout(function () {
            if (_this.tooltip) {
                _this.tooltip.className += " ng-tooltip-show";
            }
        }, this.showDelay);
    };
    TooltipDirective.prototype.hide = function () {
        var _this = this;
        if (this.hideTimeoutId) {
            clearTimeout(this.hideTimeoutId);
        }
        if (this.tooltip) {
            this.tooltip.classList.remove("ng-tooltip-show");
            this.hideTimeoutId = setTimeout(function () {
                _this.tooltip.parentNode.removeChild(_this.tooltip);
                _this.tooltip = null;
            }, this.hideDelay);
        }
    };
    TooltipDirective.prototype.setPosition = function () {
        var elemHeight = this.elementRef.nativeElement.offsetHeight;
        var elemWidth = this.elementRef.nativeElement.offsetWidth;
        var tooltipHeight = this.tooltip.clientHeight;
        var tooltipWidth = this.tooltip.offsetWidth;
        var scrollY = window.pageYOffset;
        if (this.placement === 'top') {
            this.tooltip.style.top = (this.elemPosition.top + scrollY) - (tooltipHeight + this.tooltipOffset) + 'px';
        }
        if (this.placement === 'bottom') {
            this.tooltip.style.top = (this.elemPosition.top + scrollY) + elemHeight + this.tooltipOffset + 'px';
        }
        if (this.placement === 'top' || this.placement === 'bottom') {
            this.tooltip.style.left = (this.elemPosition.left + elemWidth / 2) - tooltipWidth / 2 + 'px';
        }
        if (this.placement === 'left') {
            this.tooltip.style.left = this.elemPosition.left - tooltipWidth - this.tooltipOffset + 'px';
        }
        if (this.placement === 'right') {
            this.tooltip.style.left = this.elemPosition.left + elemWidth + this.tooltipOffset + 'px';
        }
        if (this.placement === 'left' || this.placement === 'right') {
            this.tooltip.style.top = (this.elemPosition.top + scrollY) + elemHeight / 2 - this.tooltip.clientHeight / 2 + 'px';
        }
    };
    TooltipDirective.prototype.ngOnDestroy = function () {
        this.hide();
    };
    return TooltipDirective;
}());
exports.TooltipDirective = TooltipDirective;
