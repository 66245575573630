import {Component, Input} from '@angular/core';
import {Router} from "@angular/router";
import { Location } from '@angular/common';

@Component({
    selector: 'dln-back-button',
    template: `
        <a class="back-btn {{className}}" (click)="onClick()">
            <i class="dln-icon-retour"></i>
            <span class="desktop">{{desktopLabel}}</span>
            <span class="mobile">{{mobileLabel}}</span>
            <ng-content></ng-content>
        </a>
  `,
    styleUrls: [
        './back-button.component.scss'
    ]
})
export class BackButtonComponent {
    @Input() className: string;
    @Input() url: any;
    @Input() desktopLabel: string;
    @Input() mobileLabel: string;
    @Input() onClick: Function = () => {
        if (this.url) {
            this.router.navigate([this.url]);
        } else {
            this.location.back()
        }
    };

    constructor(private router: Router, private location: Location) {

    }
}
