<div class="simple-notification-wrapper" [ngClass]="position">
    <dln-simple-notification
        *ngFor="let a of notifications; let i = index"
        [item]="a"
        [timeOut]="timeOut"
        [clickToClose]="clickToClose"
        [maxLength]="maxLength"
        [showProgressBar]="showProgressBar"
        [pauseOnHover]="pauseOnHover"
        [theClass]="theClass"
        [rtl]="rtl"
        [animate]="animate"
        [position]="i">
    </dln-simple-notification>
</div>
