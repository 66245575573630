import {ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {NotificationCenterService} from "@logbook/notification-center/shared/service/notification.center.service";
import {Pageable} from "@logbook/shared";
import {LogbookNotification} from "@logbook/notification-center/shared/model/LogbookNotification";
import * as moment from 'moment';
import {Router} from "@angular/router";
import {Subscription} from "rxjs";
import {first} from "rxjs/operators";
import {MixpanelService} from "@logbook/core/tracking/mixpanel.service";
import {MixpanelEvent} from "@logbook/core/tracking/mixpanel-event.enum";
import {DeviceDetectorService} from "ngx-device-detector";

@Component({
    selector: 'notification-center',
    templateUrl: './notification-center.component.html',
    styleUrls: ['./notification-center.component.scss']
})
export class NotificationCenterComponent implements OnInit, OnDestroy {

    @Output() closePanel = new EventEmitter<void>();
    public noMoreContent = false;
    public notifications: LogbookNotification[] = [];
    public loadingNotifications = false;
    private pageable: Pageable;
    private PAGE_SIZE = 20;
    private datesShowed: Set<string>;
    private newNotifSubscribtion: Subscription;

    constructor(private notificationCenterService: NotificationCenterService,
                private router: Router, private deviceDetectorService: DeviceDetectorService,
                private changeDetector: ChangeDetectorRef,
                private mixpanelService: MixpanelService) {
    }

    ngOnInit() {
        this.datesShowed = new Set();
        this.pageable = new Pageable(-1, this.PAGE_SIZE, "createdAt,desc");
        this.fetchNextPage();
        this.newNotifSubscribtion = this.notificationCenterService.onNewNotification()
            .subscribe((notif) => {
                notif.isFirstNotificationForDay = true;
                this.notifications = this.notifications.map((i) => {
                    if ( moment.utc(i.createdAt).startOf('day').toISOString() === moment.utc(notif.createdAt).startOf('day').toISOString()) {
                        i.isFirstNotificationForDay = false;
                    }
                    return i;
                });
                this.notifications.unshift(notif);
            });
    }

    fetchNextPage() {
        this.pageable.page++;
        this.loadingNotifications = true;
        this.notificationCenterService.getAll(this.pageable)
            .pipe(first())
            .subscribe((notifications: LogbookNotification[]) => {
                this.loadingNotifications = false;
                notifications.forEach((notification) =>
                    this.setIfShowDate(notification)
                );
                this.notifications = this.notifications.concat(notifications);

                if (!notifications || !notifications.length) {
                    this.noMoreContent = true;
                }

                this.changeDetector.markForCheck();
            });
    }

    displayNotification(notification: LogbookNotification) {
        this.markAsRead(notification);
        this.router.navigate(notification.getLink());

        this.mixpanelService.track(MixpanelEvent.NOTIFICATION.CENTER.NOTIFICATION_CLICKED, {
            notificationId: notification.id,
            notificationType: notification.category
        });
    }

    markAsRead(notification: LogbookNotification) {
        if (!notification.readAt) {
            this.notificationCenterService.markAsRead(notification);
            this.mixpanelService.track(MixpanelEvent.NOTIFICATION.CENTER.NOTIFICATION_READ, {
                notificationId: notification.id,
                notificationType: notification.category
            });
        }
    }

    markAllAsRead() {
        this.notificationCenterService.markAllAsRead();
        let count = 0;
        this.notifications
            .forEach((notification) => {
                count++;
                return notification.readAt = new Date()
            });

        this.mixpanelService.track(MixpanelEvent.NOTIFICATION.CENTER.MARK_ALL_AS_READ, {
            readNumber: count
        });
    }

    delete(notification: LogbookNotification) {
        this.notifications = this.notifications.filter((notif) => notif.id !== notification.id);
        this.notificationCenterService.remove(notification.id).pipe(first()).subscribe();
        this.datesShowed = new Set();
        this.notifications.forEach((notif) => this.setIfShowDate(notif));
        this.mixpanelService.track(MixpanelEvent.NOTIFICATION.CENTER.NOTIFICATION_DELETED, {
            notificationId: notification.id,
            notificationType: notification.category
        });
    }

    close() {
        this.closePanel.emit();
    }

    private setIfShowDate(notification: LogbookNotification) {
        const day = moment.utc(notification.createdAt).startOf('day').toISOString();
        if (!this.datesShowed.has(day)) {
            notification.isFirstNotificationForDay = true;
            this.datesShowed.add(day);
        } else {
            notification.isFirstNotificationForDay = false;
        }
    }

    ngOnDestroy(): void {
        if (this.newNotifSubscribtion)  {
            this.newNotifSubscribtion.unsubscribe();
        }
    }
}
