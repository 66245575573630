import {HttpClient} from "@angular/common/http";
import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {LOGBOOK_API} from "../../../../../app.constants";

@Injectable()
export class ShareApiService {

    constructor(private http: HttpClient) {
    }

    shareLog(recipients, threadId, messageId, type): Observable<any> {
        return this.http.post(
            `${LOGBOOK_API}/api/share/thread/${threadId}/message/${messageId}/${type}`,
            recipients);
    }
}
