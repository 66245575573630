"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var error_component_1 = require("./error.component");
var ɵ0 = {
    authorities: [],
    pageTitle: 'Error page!'
}, ɵ1 = {
    authorities: [],
    pageTitle: 'Error page!',
    error403: true
}, ɵ2 = {
    authorities: [],
    pageTitle: 'Page not found',
    errorMessage: 'This page cannot be found.'
};
exports.ɵ0 = ɵ0;
exports.ɵ1 = ɵ1;
exports.ɵ2 = ɵ2;
var APP_ERROR_ROUTES = [
    {
        path: 'error',
        component: error_component_1.ErrorComponent,
        data: ɵ0
    },
    {
        path: 'accessdenied',
        component: error_component_1.ErrorComponent,
        data: ɵ1
    },
    {
        path: '**',
        component: error_component_1.ErrorComponent,
        data: ɵ2
    }
];
var ErrorRoutingModule = /** @class */ (function () {
    function ErrorRoutingModule() {
    }
    return ErrorRoutingModule;
}());
exports.ErrorRoutingModule = ErrorRoutingModule;
