"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var operators_1 = require("rxjs/operators");
var core_1 = require("@angular/core");
var forms_1 = require("@angular/forms");
var pageable_1 = require("../../../model/pageable");
var ng_select_component_1 = require("../../../common/ng-select/ng-select.component");
var societe_model_1 = require("../../../../../../../app/core/shared/models/societe.model");
var societe_service_1 = require("../../../user/service/societe.service");
var index_1 = require("rxjs/index");
var DlnInputCompanyComponent = /** @class */ (function () {
    function DlnInputCompanyComponent(societeService, changeDetection) {
        this.societeService = societeService;
        this.changeDetection = changeDetection;
        this.onCompanySelected = new core_1.EventEmitter();
        this.companySearchControl = new core_1.EventEmitter();
        this.companiesSuggestion = [];
        this.companiesSuggestionByEmail = [];
        this.companiesSuggestionBySearch = [];
        this.disabled = false;
        this.destroy$ = new index_1.Subject();
        this.propagateChange = function (_) { };
    }
    DlnInputCompanyComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (this.loggedUser != null) {
            if (this.loggedUser.userId != null) {
                this.suggestSocieteByEmail(this.loggedUser.userId);
            }
        }
        else {
            if (this.contactEmail != null) {
                this.suggestSocieteByEmail(this.contactEmail);
            }
        }
        this.companySearchControl.pipe(operators_1.debounceTime(250), operators_1.distinctUntilChanged(), operators_1.takeUntil(this.destroy$)).subscribe(function (search) { return _this.searchCompanies(search); });
        if (this.companyPreSelected != null) {
            var companyItem = {
                id: this.companyPreSelected.id,
                name: this.companyPreSelected.nom,
                params: this.companyPreSelected
            };
            this.companiesSuggestion.push(companyItem);
            this.writeValue(this.companyPreSelected);
        }
    };
    DlnInputCompanyComponent.prototype.writeValue = function (obj) {
        var _this = this;
        if (obj) {
            setTimeout(function () {
                _this.ngSelect.toggle({
                    id: obj.id,
                    name: obj.nom,
                    params: obj,
                });
            }, 0);
        }
    };
    DlnInputCompanyComponent.prototype.registerOnChange = function (fn) {
        this.propagateChange = fn;
    };
    DlnInputCompanyComponent.prototype.registerOnTouched = function (fn) {
    };
    DlnInputCompanyComponent.prototype.setDisabledState = function (isDisabled) {
        this.disabled = isDisabled;
    };
    DlnInputCompanyComponent.prototype.updateCompany = function ($event) {
        var companySelected = $event ? $event.params : null;
        this.onCompanySelected.emit(companySelected);
        this.propagateChange(companySelected);
    };
    DlnInputCompanyComponent.prototype.searchCompanies = function (search) {
        var _this = this;
        this.societeService.findAll(new pageable_1.Pageable(0, 25, "nom"), search).pipe(operators_1.first())
            .subscribe(function (res) {
            _this.companiesSuggestionBySearch = res.content.map(function (i) {
                return {
                    id: i.id,
                    name: i.nom,
                    params: i,
                    parentId: 1,
                };
            }).filter(function (i) { return _this.companiesSuggestionByEmail
                .filter(function (societe) { return societe.id === i.id; })
                .length === 0; });
            _this.companiesSuggestionBySearch.unshift({ id: 1, name: 'Company', isParent: true });
            _this.companiesSuggestion = _this.companiesSuggestionByEmail.concat(_this.companiesSuggestionBySearch);
            _this.changeDetection.markForCheck();
        });
    };
    DlnInputCompanyComponent.prototype.suggestSocieteByEmail = function (email) {
        var _this = this;
        this.societeService.suggestSocieteByEmail(email).pipe(operators_1.first())
            .subscribe(function (res) {
            _this.companiesSuggestionByEmail = res.map(function (i) {
                return {
                    id: i.id,
                    name: i.nom,
                    params: i,
                    parentId: 0,
                };
            });
            _this.companiesSuggestionByEmail.unshift({ id: 0, name: 'Suggestion', isParent: true });
            _this.searchCompanies();
        });
    };
    DlnInputCompanyComponent.prototype.ngOnDestroy = function () {
        this.destroy$.next();
    };
    return DlnInputCompanyComponent;
}());
exports.DlnInputCompanyComponent = DlnInputCompanyComponent;
