import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import * as momentImported from 'moment'; const moment = momentImported;
import {FormControl} from "@angular/forms";
import {DateUtils} from "../../../util/date-utils";

@Component({
    selector: 'dln-input-time',
    templateUrl: './input-time.component.html',
    styleUrls: [
        './input-time.component.scss'
    ]
})
export class DlnInputTimeComponent {
    @Input() label: string;
    @Input() control = new FormControl();
    @Input() defaultDate: Date = new Date();

    setNewDate(newDate) {
        this.control.setValue(
            moment.utc(DateUtils.createDateAsUTC(newDate)).toISOString()
        );
    }

    getDate() {
        const dateStr = this.control.value;

        if (dateStr) {
            return DateUtils.convertDateToUTC(new Date(dateStr));
        }
        return DateUtils.convertDateToUTC(new Date(this.defaultDate));
    }

    displayDate() {
        return moment(this.getDate())
            .format('HH:mm');
    }
}
