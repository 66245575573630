"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Useful = /** @class */ (function () {
    function Useful(build) {
        this.participantIds = [];
        this.total = 0;
        if (build && build.participantIds) {
            this.participantIds = build.participantIds;
            this.total = build.total || 0;
        }
    }
    return Useful;
}());
exports.Useful = Useful;
