import {debounceTime, first, distinctUntilChanged, takeUntil} from 'rxjs/operators';
import {Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR} from "@angular/forms";
import {NgOption} from "../../../common/ng-select/index";
import {Pageable} from "../../../model/pageable";
import {User} from "../../../../../../../app/core/shared/models/user.model";
import {UserService} from "../../../user/service/user.service";
import {UserFilterPipe} from "../../../user/pipe/user-filter.pipe";
import {Subject} from "rxjs/index";

@Component({
    selector: 'dln-input-user-search',
    templateUrl: './input-user-search.component.html',
    styleUrls: ['./input-user-search.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DlnInputUserSearchComponent),
            multi: true,
        }
    ]
})
export class DlnInputUserSearchComponent implements OnInit, ControlValueAccessor, OnDestroy {

    @Input()
    usersFilters: User[];

    @Input()
    onlyTeamMembers = false;

    @Output("change") changeEvent = new EventEmitter();

    userSuggestion: any[];
    userSearchControl = new FormControl();
    private destroy$ = new Subject<void>();

    private updateForm = (_: any) => {
    };

    constructor(private userService: UserService, private userFilterPipe: UserFilterPipe) {
    }

    ngOnInit() {
        this.userSearchControl.valueChanges.pipe(
            debounceTime(250),
            distinctUntilChanged(),
            takeUntil(this.destroy$)
        ).subscribe((search) => this.searchUser(search));
    }

    writeValue(obj: any): void {
    }

    registerOnChange(fn: any): void {
        this.updateForm = fn
    }

    registerOnTouched(fn: any): void {
    }

    searchUser(query: string) {
        if (!this.onlyTeamMembers) {
            this.userService.findAll(new Pageable(0, 25, "id"), query).pipe(first())
                .subscribe((res) => {
                    this.userSuggestion = res.content.map(this.mapUser)
                })
        } else {
            this.userSuggestion = this.userFilterPipe.transform(this.usersFilters, query).map(this.mapUser)
        }
    }

    updateValue($event) {
        if ($event && $event.option && $event.option.value) {
            const userSelected = this.userSuggestion.find((i) => i.id === $event.option.value);
            if (userSelected) {
                this.updateForm(userSelected.user);
                this.changeEvent.emit(userSelected.user);
            }
        }
    }

    private mapUser(user: User) {
        return {
            id: user.userId,
            name: user.displayUserName(),
            user
        }
    }

    ngOnDestroy() {
        this.destroy$.next();
    }
}
