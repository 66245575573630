import {INSTRUCTIONS_LIST_ROUTE} from "@logbook/consigne";

export enum NotificationCategory {
    LOG = "LOG",
    INSTRUCTION = "INSTRUCTION"
}

export class LogbookNotification {
    id: string;
    title: string;
    description: string;
    category: NotificationCategory;
    emitterId: string;
    createdAt: Date;
    seeAt: Date;
    readAt: Date;
    entityId: Map<any, any>;

    isFirstNotificationForDay = false;

    getLink() {
        switch (this.category) {
            case NotificationCategory.INSTRUCTION:
                return this.getInstructionLink();
            case NotificationCategory.LOG:
                return this.getLogLink();
        }
    }

    private getInstructionLink() {
        return ['/instructions', this.entityId["instructionId"].toString()];
    }

    private getLogLink() {
        const period = this.entityId["date"];
        const idLog = this.entityId["groupedLog"];
        if (period && idLog) {
            return ['/logs', period, idLog];
        } else {
            return ['/logs'];
        }
    }
}
