"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * CoreSharedModule exports
 * - Directives related to user permission and features enables
 * - Route Guards
 */
var LogbookCoreSharedModule = /** @class */ (function () {
    function LogbookCoreSharedModule() {
    }
    return LogbookCoreSharedModule;
}());
exports.LogbookCoreSharedModule = LogbookCoreSharedModule;
