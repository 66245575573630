"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var login_service_1 = require("../../auth/services/login.service");
var TokenAuthRouteAccessService = /** @class */ (function () {
    function TokenAuthRouteAccessService(loginService) {
        this.loginService = loginService;
    }
    TokenAuthRouteAccessService.prototype.canActivate = function (route, state) {
        if (route.queryParams.token) {
            return this.loginService.loginWithToken(route.queryParams.token, true).then(function () {
                return true;
            }).catch(function () { return false; });
        }
        else {
            return true;
        }
    };
    return TokenAuthRouteAccessService;
}());
exports.TokenAuthRouteAccessService = TokenAuthRouteAccessService;
