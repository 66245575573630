"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var PieceJointe = /** @class */ (function () {
    function PieceJointe(json) {
        if (json) {
            this.id = json.id;
            this.uri = json.uri;
            this.md5 = json.md5;
            this.size = json.size;
            this.contentType = json.contentType;
            this.createdOn = json.createdOn;
            this.createdBy = json.createdBy;
            this.filename = this.getFilename(json.filename);
        }
    }
    PieceJointe.prototype.getFilename = function (filename) {
        if (filename) {
            return filename;
        }
        else if (this.contentType && this.contentType.indexOf("name=") !== -1) {
            return this.contentType.split(";")
                .filter(function (value) { return value.indexOf("name=") !== -1; })
                .map(function (value) { return value.split("=")[1].trim().replace(/"/g, ""); })
                .find(function (value, index) { return index === 0; });
        }
        else {
            return "Undefined";
        }
    };
    Object.defineProperty(PieceJointe.prototype, "fileSize", {
        get: function () {
            var mo = Number((this.size / 1000000).toFixed(1));
            var kb = Number((this.size / 1000).toFixed(0));
            if (mo >= 1) {
                return mo + " Mo";
            }
            else {
                return kb + " KB";
            }
        },
        enumerable: true,
        configurable: true
    });
    PieceJointe.prototype.isImage = function () {
        return this.contentType.indexOf("image") > -1;
    };
    PieceJointe.prototype.isPdf = function () {
        return this.contentType === 'application/pdf';
    };
    PieceJointe.prototype.isAudio = function () {
        return this.contentType.indexOf("audio") > -1;
    };
    PieceJointe.prototype.isSupportedFile = function () {
        return this.isImage() || this.isAudio() || this.isPdf();
    };
    PieceJointe.prototype.getExtension = function () {
        var splitName = this.filename.split(".");
        var extension = splitName[splitName.length - 1];
        return extension.toUpperCase();
    };
    return PieceJointe;
}());
exports.PieceJointe = PieceJointe;
