"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var momentImported = require("moment");
var moment = momentImported;
var ChangeLogAck = /** @class */ (function () {
    function ChangeLogAck(build) {
        if (build) {
            this.userId = build.userId;
            this.updateDate = build.updateDate;
            this.updateMoment = moment(this.updateDate);
        }
    }
    return ChangeLogAck;
}());
exports.ChangeLogAck = ChangeLogAck;
