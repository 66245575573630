import {HttpClient, HttpParams} from "@angular/common/http";
import {Injectable} from '@angular/core';
import {CustomHttpEncoder} from "@logbook/core/shared/utils/http-custom-converter";
import {LOGBOOK_API} from "@logbook/app.constants";
import {Filters, Log, LogGroup, MessageGroupID} from "@logbook/logs-view/shared/models";
import {Observable} from "rxjs";
import {LogbookLogsService} from "@logbook/logs-view/shared/services";
import {LogsViewModule} from "../../../logs-view.module";
import {catchError, first, map} from "rxjs/operators";
import {UserService} from "@logbook/shared";

@Injectable({
    providedIn: 'root'
})
export class LogbookLogsApiService {

    constructor(private http: HttpClient, private userService: UserService) {
    }

    postLog(formData) {
        const options: HttpParams = new HttpParams();
        return this.http.post(`${LOGBOOK_API}/api/messaging/thread-message-authorization`, formData, {
            params: options,
            observe: 'response'
        });
    }

    fetchLogs(filters: Filters, page: number) {
        const starDate = filters.date.format("DD-MM-YYYY");
        const endDate = filters.date.format("DD-MM-YYYY");
        const threads = filters.getSelectedThreads();
        const options: HttpParams = new HttpParams({encoder: new CustomHttpEncoder()})
            .set('page', page.toString())
            .set('startDate', starDate)
            .set('endDate', endDate)
            .set('threads', threads)
            .set('filters', JSON.stringify(filters.categoryTags));
        return this.http.get(`${LOGBOOK_API}/api/messaging/thread/messages`, {
            params: options,
        }).pipe(
            map((logs: Log[]) => {
                if (logs && logs.length) {
                    logs.map((log) => this.updateEmitterInformation(log));
                }
                return logs
            })
        );
    }

    findLog(messageGroupID: MessageGroupID): Observable<Log> {
        return this.http.get(
            `${LOGBOOK_API}/api/messaging/thread/${messageGroupID.threadId}/messages/${messageGroupID.messageIdBase64}`).pipe(
            map((log: Log) => {
                return this.updateEmitterInformation(log);
            })
        );
    }

    findLogByGroupedId(date, logGroupedId) {
        return this.http.get(`${LOGBOOK_API}/api/messaging/log/${date}/${logGroupedId}`).pipe(
            map((logs: Log[]) => {
                if (logs && logs.length) {
                    logs.map((log) => this.updateEmitterInformation(log));
                }
                return logs
            })
        );
    }

    editLog(threadId, formData) {
        return this.http.post(`${LOGBOOK_API}/api/messaging/${threadId}/edit-message`, formData)
            .pipe(
                map((log: Log) => {
                    return this.updateEmitterInformation(log);
                })
            );
    };

    removeLog(threadId, messageId) {
        return this.http.delete(`${LOGBOOK_API}/api/messaging/thread/${threadId}/messages/${messageId}`, {observe: 'response'});
    }

    updateEmitterInformation(log: Log) {
        log.messages.emitter$ = this.userService.find(log.messages.emetteurId.id);

        log.messages.emitter$.pipe(first()).subscribe((user) => {
            log.messages.emitter = user;
        });

        if (log.messages.comments) {
            log.messages.comments.map((comment) => {
                comment.emitter$ = this.userService.find(comment.emetteurId.id);
                comment.emitter$.pipe(first()).subscribe((user) => {
                    comment.emitter = user;
                });
            });
        }

        return log;
    }
}
