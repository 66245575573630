"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* tslint:disable:max-line-length */
var NgSpinnerComponent = /** @class */ (function () {
    function NgSpinnerComponent() {
    }
    return NgSpinnerComponent;
}());
exports.NgSpinnerComponent = NgSpinnerComponent;
/* tslint:enable:max-line-length */
