"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = ["[_nghost-%COMP%]   .check-updates[_ngcontent-%COMP%]{position:relative;width:100%;z-index:100;background-color:#e63a20;height:75px;opacity:.9;padding:0 30px;display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;-ms-flex-align:center;-moz-align-items:center;align-items:center;-ms-flex-pack:justify;-moz-justify-content:space-between;-ms-justify-content:space-between;justify-content:space-between;color:#fff;text-decoration:none;font-size:16px;font-weight:400}@media (max-width:768px){[_nghost-%COMP%]   .check-updates[_ngcontent-%COMP%]{padding:0;-ms-flex-direction:column;flex-direction:column;-ms-flex-pack:distribute;-moz-justify-content:space-around;-ms-justify-content:space-around;justify-content:space-around}}[_nghost-%COMP%]   .check-updates[_ngcontent-%COMP%]   span[_ngcontent-%COMP%]{display:-ms-flexbox;display:flex;-ms-flex-align:center;-moz-align-items:center;align-items:center;-ms-flex-pack:distribute;-moz-justify-content:space-around;-ms-justify-content:space-around;justify-content:space-around}@media (max-width:768px){[_nghost-%COMP%]   .check-updates[_ngcontent-%COMP%]   span[_ngcontent-%COMP%]{width:100%;-ms-flex-pack:center;-moz-justify-content:center;-ms-justify-content:center;justify-content:center}}[_nghost-%COMP%]   .check-updates[_ngcontent-%COMP%]   span[_ngcontent-%COMP%]   i[_ngcontent-%COMP%]{font-size:25px}[_nghost-%COMP%]   .check-updates[_ngcontent-%COMP%]   span.left[_ngcontent-%COMP%]{font-weight:600}[_nghost-%COMP%]   .check-updates[_ngcontent-%COMP%]   span.left[_ngcontent-%COMP%]   i[_ngcontent-%COMP%]{margin-right:15px}[_nghost-%COMP%]   .check-updates[_ngcontent-%COMP%]   span.right[_ngcontent-%COMP%]   i[_ngcontent-%COMP%]{font-weight:600;margin-left:15px}[_nghost-%COMP%]   .check-updates[_ngcontent-%COMP%]:hover{background-color:#e1224f;text-decoration:none}"];
exports.styles = styles;
