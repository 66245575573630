import {LogType} from "./log-type.enum";
import {Useful} from "./useful.model";
import {EmitterId, PieceJointe} from "@logbook/shared";
import {User} from "@logbook/core/shared/models/user.model";
import {Observable} from "rxjs";

export class Message {
    messageId: MessageID;
    emetteurId: EmitterId;
    emitter: User;
    emitter$;
    editor: User;
    edited: boolean;
    corps: string;
    dateCreation: Date;
    objet: string;
    typeMessage: LogType;
    metadata: Map<string, string> = new Map();
    tags: string[] = [];
    comments: Message[] = [];
    useful: Useful = new Useful();
    pieceJointes: PieceJointe[];

    constructor(message?: Message) {
        if (message) {
            this.messageId = message.messageId;
            this.emetteurId = message.emetteurId;
            this.emitter = message.emitter;
            this.emitter$ = message.emitter$;
            this.editor = message.editor;
            this.edited = message.edited;
            this.corps = message.corps;
            this.dateCreation = message.dateCreation;
            this.typeMessage = message.typeMessage;
            this.metadata = message.metadata;
            this.tags = message.tags;
            this.comments = message.comments ? message.comments.map((comment) => new Message(comment)) : [];
            this.pieceJointes = message.pieceJointes ? message.pieceJointes.map((attachment) => new PieceJointe(attachment)) : [];
            this.useful = message.useful ? Object.assign(new Useful(), message.useful) : new Useful();
        }
    }
}

export interface MessageID {
    id: string;
    idAsBase64: string;
}
