"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var forms_1 = require("@angular/forms");
var momentImported = require("moment");
var moment = momentImported;
var DlnInputComponent = /** @class */ (function () {
    function DlnInputComponent() {
        this.type = 'text';
        this.control = new forms_1.FormControl();
        this.asterisk = true;
        this.placeholder = '';
        this.hideErrors = false;
        this.inline = false;
        this.dateChangeEvent = new core_1.EventEmitter();
        this.hasError = false;
        this.timeAsString = null;
    }
    Object.defineProperty(DlnInputComponent.prototype, "errorMessages", {
        get: function () {
            return this._errorMessages;
        },
        set: function (val) {
            this._errorMessages = Object.assign({
                required: "The field is required",
                phoneInvalid: "Phone number is invalid"
            }, val);
        },
        enumerable: true,
        configurable: true
    });
    ;
    DlnInputComponent.prototype.ngOnInit = function () {
        this.id = this.inputName || "input-" + Math.floor(Math.random() * (1000));
        if (this.type === 'time' && this.control && this.control.value) {
            this.timeAsString = moment.utc(this.control.value).format("HH:mm");
        }
    };
    DlnInputComponent.prototype.ngAfterViewInit = function () {
    };
    DlnInputComponent.prototype.dateChanged = function (event) {
        this.control.setValue(event);
        this.dateChangeEvent.emit(event);
    };
    DlnInputComponent.prototype.ngOnDestroy = function () {
    };
    DlnInputComponent.prototype.checkValidation = function () {
        if (this.control) {
            this.control.updateValueAndValidity();
        }
    };
    DlnInputComponent.prototype.checkError = function () {
        this.hasError = this.control && this.control.touched && this.control.invalid;
    };
    DlnInputComponent.prototype.ngDoCheck = function () {
        this.checkError();
    };
    return DlnInputComponent;
}());
exports.DlnInputComponent = DlnInputComponent;
