import {Societe} from "./societe.model";

export class User {
    public userId?: string;
    public email?: string;
    public firstName?: string;
    public lastName?: string;
    public title?: string;
    public phones?: string[];
    public company?: Societe;
    public typeCompany?: string;
    public positionInCompany?: string;
    public pendingCompany?: string;
    public enabled?: boolean;
    public authorities?: string[];
    public localisation?: string;
    public password?: string;
    public team?: string;

    public isSelected = false;
    public anonymousUserId?: string;
    public acceptActivityTracking = true;

    constructor(
        userId?: string,
        email?: string,
        firstName?: string,
        lastName?: string,
        title?: string,
        phones?: string[],
        company?: any,
        typeCompany?: string,
        positionInCompany?: string,
        pendingCompany?: string,
        enabled?: boolean,
        authorities?: string[],
        localisation?: string,
        password?: string,
        anonymousUserId?: string) {
        this.userId = userId ? userId : null;
        this.firstName = firstName ? firstName : null;
        this.lastName = lastName ? lastName : null;
        this.title = title ? title : null;
        this.email = email ? email : null;
        this.phones = phones ? phones : null;
        this.company = company ? new Societe(company) : null;
        this.typeCompany = typeCompany ? typeCompany : null;
        this.positionInCompany = positionInCompany ? positionInCompany : null;
        this.pendingCompany = pendingCompany ? pendingCompany : null;
        this.localisation = localisation ? localisation : null;
        this.enabled = enabled ? enabled : false;
        this.authorities = authorities ? authorities : ["ROLE_USER"];
        this.password = password ? password : null;
        this.anonymousUserId = anonymousUserId ? anonymousUserId : null;
        this.acceptActivityTracking = true;
    }

    displayUserName(): string {
        if (this.firstName && this.lastName) {
            return `${this.firstName} ${this.lastName}`
        } else if (this.positionInCompany) {
            return this.positionInCompany
        } else if (this.email) {
            return this.email
        } else if (this.userId) {
            return this.userId;
        } else {
            return undefined;
        }
    }

    displayUserNameWithStyle(): string {
        if (this.firstName && this.lastName) {
            return `${this.firstName} <b> ${this.lastName} </b>`
        } else if (this.positionInCompany) {
            return this.positionInCompany
        } else if (this.email) {
            return this.email
        } else {
            return this.userId;
        }
    }

    getCompanyName() {
        return this.company != null ? this.company.nom : ""
    }

    displayAuthorities() {
        return this.authorities ?
            this.authorities.filter((authority) => ["ROLE_USER", "ROLE_ADMIN", "ROLE_TEAM_MANAGER"].indexOf(authority) >= 0)
                .map((authority) => {
                    switch (authority) {
                        case "ROLE_ADMIN" :
                            return "admin";
                        case "ROLE_TEAM_MANAGER" :
                            return "manager";
                        default :
                            return "user";
                    }
                })
                .join(", ")
            : null
    }

    isAdmin() {
        return this.authorities ? this.authorities.some((role) => role === 'ROLE_TEAM_MANAGER') : false
    }

    isSuperAdmin() {
        return this.authorities ? this.authorities.some((role) => role === 'ROLE_ADMIN') : false
    }
}
