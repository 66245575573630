"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var shared_1 = require("@logbook/shared");
var ng_intercom_1 = require("ng-intercom");
var principal_service_1 = require("../../auth/services/principal.service");
var login_service_1 = require("../../auth/services/login.service");
var mixpanel_service_1 = require("../../tracking/mixpanel.service");
var mixpanel_event_enum_1 = require("../../tracking/mixpanel-event.enum");
var sign_as_user_access_model_1 = require("../../shared/models/sign-as-user-access.model");
var operators_1 = require("rxjs/operators");
var NavbarComponent = /** @class */ (function () {
    function NavbarComponent(principal, loginService, router, mixpanelService, changeDetector, intercomService, changeLogService) {
        this.principal = principal;
        this.loginService = loginService;
        this.router = router;
        this.mixpanelService = mixpanelService;
        this.changeDetector = changeDetector;
        this.intercomService = intercomService;
        this.changeLogService = changeLogService;
        this.showBackButton = false;
        this.isNavbarCollapsed = true;
        if (navigator.language && navigator.language === 'fr') {
            this.userGuideLink = "https://s3-eu-west-1.amazonaws.com/teamlogs-prod-logbook/docs-public/Logbook_UserGuide_fr.pdf";
        }
        else {
            this.userGuideLink = "https://s3-eu-west-1.amazonaws.com/teamlogs-prod-logbook/docs-public/Logbook_UserGuide_en.pdf";
        }
    }
    NavbarComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.principal.getAuthenticationState().pipe(operators_1.startWith(this.principal.identityDirect()))
            .subscribe(function (identity) {
            _this.updateAccount(identity);
        });
        this.showBackButton = this.getRouteData(this.router.routerState.snapshot.root, 'backButton');
        this.navBarTitle = this.getRouteData(this.router.routerState.snapshot.root, 'navBarTitle');
        this.routerSub = this.router.events.subscribe(function (event) {
            if (event instanceof router_1.NavigationEnd) {
                _this.showBackButton = _this.getRouteData(_this.router.routerState.snapshot.root, 'backButton');
                _this.navBarTitle = _this.getRouteData(_this.router.routerState.snapshot.root, 'navBarTitle');
                _this.principal.identity().then(function (identity) {
                    _this.updateAccount(identity);
                });
            }
        });
    };
    NavbarComponent.prototype.getRouteData = function (routeSnapshot, data) {
        var resData = (routeSnapshot.data && routeSnapshot.data[data]) ? routeSnapshot.data[data] : false;
        if (routeSnapshot.firstChild) {
            resData = this.getRouteData(routeSnapshot.firstChild, data) || resData;
        }
        return resData;
    };
    NavbarComponent.prototype.updateAccount = function (identity) {
        var _this = this;
        this.account = identity;
        this.isSignAsUser = this.account instanceof sign_as_user_access_model_1.SignAsUserAccess;
        if (this.account) {
            if ((!this.prevUserId || this.prevUserId !== this.account.userId) && this.principal.hasAnyAuthorityDirect(['ROLE_USER'])) {
                this.changeLogService.countUnread().pipe(operators_1.first()).subscribe(function (count) {
                    _this.newChangeLogCount = count;
                    _this.changeDetector.markForCheck();
                });
            }
            this.prevUserId = this.account.userId;
        }
        this.changeDetector.markForCheck();
    };
    NavbarComponent.prototype.isAuthenticated = function () {
        return this.principal.isAuthenticated() && this.principal.hasAnyAuthorityDirect(['ROLE_USER']);
    };
    NavbarComponent.prototype.logoutSignAs = function () {
        this.loginService.logoutSignAs();
    };
    NavbarComponent.prototype.goToChangeLogs = function () {
        this.newChangeLogCount = 0;
        this.router.navigate(["/change-log"]);
    };
    NavbarComponent.prototype.goToSupportPage = function () {
        this.isNavbarCollapsed = true;
        this.router.navigate(["/support/learn-how-to-use-instructions"]);
    };
    NavbarComponent.prototype.logout = function () {
        var _this = this;
        this.loginService.logout()
            .then(function () {
            _this.mixpanelService.track(mixpanel_event_enum_1.MixpanelEvent.LOG_OUT, {});
            _this.router.navigate(['logout', { queryParams: { logout: true }, queryParamsHandling: "merge" }]);
        });
    };
    NavbarComponent.prototype.ngOnDestroy = function () {
        if (this.routerSub) {
            this.routerSub.unsubscribe();
        }
    };
    return NavbarComponent;
}());
exports.NavbarComponent = NavbarComponent;
