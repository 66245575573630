import {EmitterId} from "../../../../model/emitter-id";

export class PieceJointe {
    attachmentLink: string;
    audioPlayer: string;
    contentType: string;
    createdBy: EmitterId;
    createdOn: number;
    filename: string;
    id: PieceJointeId;
    imagePreview: string;
    md5: string;
    size: number;
    uri: string;
    isPosting: boolean;
    isRead: boolean;

    constructor(json?) {
        if (json) {
            this.id = json.id;
            this.uri = json.uri;
            this.md5 = json.md5;
            this.size = json.size;
            this.contentType = json.contentType;
            this.createdOn = json.createdOn;
            this.createdBy = json.createdBy;
            this.filename = this.getFilename(json.filename);

        }
    }

    getFilename(filename) {
        if (filename) {
            return filename
        } else if (this.contentType && this.contentType.indexOf("name=") !== -1) {
            return this.contentType.split(";")
                .filter((value) => value.indexOf("name=") !== -1)
                .map((value) => value.split("=")[1].trim().replace(/"/g, ""))
                .find((value, index) => index === 0);
        } else {
            return "Undefined"
        }
    }

    get fileSize() {
        const mo = Number((this.size / 1000000).toFixed(1));
        const kb = Number((this.size / 1000).toFixed(0));
        if (mo >= 1) {
            return mo + " Mo"
        } else {
            return kb + " KB"
        }
    }

    isImage() {
        return this.contentType.indexOf("image") > -1;
    }

    isPdf() {
        return this.contentType === 'application/pdf';
    }

    isAudio() {
        return this.contentType.indexOf("audio") > -1;
    }

    isSupportedFile() {
        return this.isImage() || this.isAudio() || this.isPdf();
    }

    getExtension() {
        const splitName = this.filename.split(".");
        const extension = splitName[splitName.length - 1];
        return extension.toUpperCase();
    }
}

export interface PieceJointeId {
    id: string;
    filename: string;
    threadIdAsBase64: string;
}
