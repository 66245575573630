import {Injectable, NgZone} from "@angular/core";
import {User} from "@logbook/core/shared/models/user.model";
import {LogbookConfig} from "@logbook/core/shared/config/logbook-config";

declare let ga: Function;

@Injectable({
    providedIn: 'root'
})
export class GoogleAnalyticsService {

    constructor(private config: LogbookConfig, private ngZone: NgZone) {
        ga('create', this.config.GOOGLE_ANALYTICS_TOKEN, 'auto');       //  paste the code till here, have to remove below line.
        ga('set', 'anonymizeIp', true);
        ga('send', 'pageview');
    }

    async setUser(user: User, team: string) {
        this.ngZone.runOutsideAngular(() => {
            if (!user.acceptActivityTracking) {
                return;
            }
            try {
                ga('set', '&uid', user.anonymousUserId);
                ga('set', 'userId', user.anonymousUserId);
                ga('set', 'dimension1', user.getCompanyName().toLowerCase());
                ga('set', 'dimension5', user.anonymousUserId);
                if (team) {
                    ga('set', 'dimension3', team);
                }
                ga('send', 'pageview');
            } catch (Exception) {
                console.warn(Exception)
            }
        })
    }
}
