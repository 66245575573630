import {Component, Input} from '@angular/core';

// http://tobiasahlin.com/spinkit/
@Component({
    selector: 'dln-spinner',
    template: `
        <div [ngClass]="{'spinner': true, 'is-button': isButton, 'black': isBlack}">
            <div class="rect rect1"></div>
            <div class="rect rect2"></div>
            <div class="rect rect3"></div>
            <div class="rect rect4"></div>
            <div class="rect rect5"></div>
            <div class="label-spinner" *ngIf="label">{{label}}</div>
        </div>
    `,
    styleUrls: [
        './spinner.component.scss'
    ]
})
export class SpinnerComponent {

    @Input() label = 'loading';
    @Input() isButton = false;
    @Input() isBlack = false;
}
