"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var LogbookConfig = /** @class */ (function () {
    function LogbookConfig(json) {
        this.production = json.production;
        this.hmr = json.hmr;
        this.LOGBOOK_API = json.LOGBOOK_API;
        this.LOGBOOK_WEB_URL = json.LOGBOOK_WEB_URL;
        this.MIXPANEL_TOKEN = json.MIXPANEL_TOKEN;
        this.GOOGLE_ANALYTICS_TOKEN = json.GOOGLE_ANALYTICS_TOKEN;
        this.INTERCOM_TOKEN = json.INTERCOM_TOKEN;
        this.CGU_VERSION = json.CGU_VERSION;
        this.TERMS_USER_AGREEMENT_URL = json.TERMS_USER_AGREEMENT_URL;
        this.TERMS_PRIVACY_POLICY_URL = json.TERMS_PRIVACY_POLICY_URL;
        this.VERSION = json.VERSION;
        this.VAPID_PUBLIC_KEY = json.VAPID_PUBLIC_KEY;
    }
    return LogbookConfig;
}());
exports.LogbookConfig = LogbookConfig;
