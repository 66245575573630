"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var dropdown_content_component_1 = require("./dropdown-content/dropdown-content.component");
var DropdownDirective = /** @class */ (function () {
    function DropdownDirective(viewContainerRef) {
        this.viewContainerRef = viewContainerRef;
        this.displayOnHover = false;
        this.displayDropdown = true;
        this.hoverDelay = 500;
    }
    DropdownDirective.prototype.hoverListener = function () {
        var _this = this;
        if (this.displayOnHover) {
            this.timer = setTimeout(function () {
                _this.show();
            }, this.hoverDelay);
        }
    };
    DropdownDirective.prototype.leaveHoverListener = function () {
        if (this.displayOnHover && this.timer) {
            clearTimeout(this.timer);
        }
    };
    DropdownDirective.prototype.clickListener = function () {
        if (!this.displayOnHover && this.displayDropdown) {
            if (!this.dlnDropdown.isActive) {
                this.show();
            }
            else {
                this.dlnDropdown.hide();
            }
        }
    };
    DropdownDirective.prototype.show = function () {
        this.dlnDropdown.hostElement = this.viewContainerRef.element.nativeElement;
        this.dlnDropdown.show();
    };
    return DropdownDirective;
}());
exports.DropdownDirective = DropdownDirective;
