"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var pageable_1 = require("./pageable");
var Page = /** @class */ (function () {
    function Page(res) {
        this.page = 0;
        this.totalPages = 0;
        this.totalElements = 0;
        this.content = [];
        this.size = 0;
        if (res) {
            this.page = res.number;
            this.content = res.content;
            this.totalPages = res.totalPages;
            this.totalElements = res.totalElements;
            this.size = res.size;
            this.sort = res.sort;
        }
    }
    Page.prototype.toPageRequest = function () {
        return new pageable_1.Pageable(this.page, this.size, this.sort);
    };
    return Page;
}());
exports.Page = Page;
