import {Pipe, PipeTransform, NgZone, ChangeDetectorRef, OnDestroy} from "@angular/core";
import * as moment from 'moment';

@Pipe({
    name: 'timeAgo',
    pure: false
})
export class TimeAgoPipe implements PipeTransform {

    constructor(private changeDetectorRef: ChangeDetectorRef, private ngZone: NgZone) {}

    transform(date: Date) {
        const now = moment.utc();
        const d = moment.utc(date);

        const seconds = now.diff(d, 'seconds');

        const minutes = now.diff(d, 'minutes');
        const hours = now.diff(d, 'hours');
        const days = now.diff(d, 'days');
        // const months = now.diff(d, 'months');
        // const years = now.diff(d, 'years');

        if (Number.isNaN(seconds)) {
            return '';
        } else if (seconds <= 45) {
            return 'a few secs ago';
        } else if (seconds <= 90) {
            return 'a min ago';
        } else if (minutes <= 45) {
            return minutes + ' mins ago';
        } else if (minutes <= 90) {
            return 'an hour ago';
        } else if (hours <= 24) {
            return hours + ' hours ago';
        } else {
            return d.format("MMM DD") + " at " + d.format("HH:mm");
        }
    }
}
