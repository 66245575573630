import {Consigne} from "@logbook/consigne";

export enum Category {
    USER = "User",
    LOG = "Log",
    INSTRUCTION = "Instruction",
    COMMENT = "Comment Log",
    EXPORT = "Export",
    FLIGHT_TAG = "Flight Tag",
    FILTER = "Filter",
    ADMINISTRATION = "Administration",
    CONTACT = "Contact",
    HELP = 'Help',
    TERMS_AND_CONDITIONS = 'Terms and conditions',
    NOTIFICATION = 'Notification',
    OTP = 'OTP',
}

export const MixpanelEvent = {

    PASSWORD_FORGOT: {category: Category.USER, action: 'Password forgot'},
    MEMBER_DETAIL_OPEN: {category: Category.USER, action: 'Member detail opening'},
    LOG_IN: {category: Category.USER, action: 'Log in'},
    LOG_IN_FAILED: {category: Category.USER, action: 'Log in failed'},
    LOG_OUT: {category: Category.USER, action: 'Log out'},
    SIGN_UP_STARTED: {category: Category.USER, action: 'Signup started'},
    SIGN_UP_COMPLETED: {category: Category.USER, action: 'Signup completed'},
    OPEN_LIVE_CHAT: {category: Category.USER, action: "Live chat call"},
    SEND_INVITATION: {category: Category.USER, action: "New member invitation"},
    NEED_UPDATE: {category: Category.USER, action: "Need Update"},
    ACCOUNT_DETAIL_CALL: {category: Category.USER, action: "Account detail call"},
    ACCOUNT_DETAIL_CHANGE: {category: Category.USER, action: "Account detail change"},

    LOG_DELETED: {category: Category.LOG, action: 'Log deleted'},
    LOG_UNDELETED: {category: Category.LOG, action: 'Log undeleted'},
    LOG_SHARED_EMAIL: {category: Category.LOG, action: 'Log shared by email'},
    LOG_SHARED_SMS: {category: Category.LOG, action: 'Log shared by sms'},
    LOG_TAG_ADDED: {category: Category.LOG, action: 'Tag added'},
    LOG_TAG_REMOVED: {category: Category.LOG, action: 'Tag removed'},
    START_WRITE_LOG: {category: Category.LOG, action: 'Write log start'},
    LOG_PUBLISH_REQUEST: {category: Category.LOG, action: 'Log publish request'},
    LOG_PUBLISH_SUCCESS: {category: Category.LOG, action: 'Log published'},
    LOG_PUBLISH_ERROR: {category: Category.LOG, action: 'Log published error'},
    LOG_UPDATED: {category: Category.LOG, action: "Log updated"},

    LOG_COMMENT: {category: Category.COMMENT, action: "Add more information"},
    LOG_COMMENT_EDITED: {category: Category.COMMENT, action: "Comment edited"},
    LOG_COMMENT_REMOVED: {category: Category.USER, action: 'Comment removed'},

    EXPORT_WORD: {category: Category.EXPORT, action: "Word export"},
    EXPORT_EXCEL: {category: Category.EXPORT, action: "Excel export"},
    EXPORT_MAIL: {category: Category.EXPORT, action: "Mail export"},

    FLIGHT_TAG_CREATION_STARTED: {category: Category.FLIGHT_TAG, action: "Flight tag creation started"},
    FLIGHT_TAG_SUGGEST_SELECTED: {category: Category.FLIGHT_TAG, action: 'Flight tag choice'},
    FLIGHT_TAG_SUGGEST_SEARCH: {category: Category.FLIGHT_TAG, action: 'Flight tag search suggestion'},
    FLIGHT_DELAY_VALIDATION: {category: Category.FLIGHT_TAG, action: "Flight delay validation"},
    FLIGHT_DELAY_CHOICE: {category: Category.FLIGHT_TAG, action: "Flight delay choice"},
    FLIGHT_TAG_CREATION_CALL: {category: Category.FLIGHT_TAG, action: "Flight tag creation call"},

    DATE_PICKER_OPEN: {category: Category.FILTER, action: "Date picker open"},
    DATE_CHANGE: {category: Category.FILTER, action: "Date change"},
    FILTER_FLIGHT: {category: Category.FILTER, action: "Filter on flight list"},
    FILTER_CATEGORY: {category: Category.FILTER, action: "Filter on category"},

    ACCEPT_TERMS_AND_CONDITIONS: {category: Category.TERMS_AND_CONDITIONS, action: "Terms and conditions accepted"},

    INSTRUCTION: {
        FORM: {
            START_WRITE: {category: Category.INSTRUCTION, action: 'Instruction write start'},
            PUBLISH_REQUEST: {category: Category.INSTRUCTION, action: 'Instruction publish request'},
            PUBLISH_SUCCESS: {category: Category.INSTRUCTION, action: 'Instruction published'},
            PUBLISH_ERROR: {category: Category.INSTRUCTION, action: 'Instruction published error'},

            ATTACH_FILE_SUCCESS: {category: Category.INSTRUCTION, action: 'Instruction form attach file'},

            SAVE_AS_DRAFT_REQUEST: {category: Category.INSTRUCTION, action: 'Instruction save as draft request'},
            SAVE_AS_DRAFT_SUCCESS: {category: Category.INSTRUCTION, action: 'Instruction save as draft success'},
            SAVE_AS_DRAFT_ERROR: {category: Category.INSTRUCTION, action: 'Instruction save as draft error'},

            CANCEL: {category: Category.INSTRUCTION, action: 'Instruction form cancel'},
            PREVIEW: {category: Category.INSTRUCTION, action: 'Instruction form preview'},

            ADD_RECIPIENT: {category: Category.INSTRUCTION, action: 'Instruction form add recipient'},
            getConsigneData(consigne: Consigne) {
                return {
                    id: consigne.id,
                    title: consigne.title,
                    text: consigne.description,
                    recipients: consigne.recipients,
                    startDate: consigne.startDate,
                    endDate: consigne.expirationDate,
                    attachedFiles: consigne.attachments.length
                }
            }
        },
        DASHBOARD: {
            CALENDAR_PREVIOUS: {category: Category.INSTRUCTION, action: 'Instruction dashboard calendar previous'},
            CALENDAR_NEXT: {category: Category.INSTRUCTION, action: 'Instruction dashboard calendar next'},

            VIEW: {category: Category.INSTRUCTION, action: 'Instruction dashboard view'},

            VIEW_DETAILS: {category: Category.INSTRUCTION, action: 'Instruction view details'},
            CLOSE_DETAILS: {category: Category.INSTRUCTION, action: 'Instruction close details'},

            ARCHIVE: {category: Category.INSTRUCTION, action: 'Instruction archive'},
            UNARCHIVE: {category: Category.INSTRUCTION, action: 'Instruction unarchive'},

            TODAY_WIDGET_PREVIOUS: {category: Category.INSTRUCTION, action: 'Instruction dashboard today previous'},
            TODAY_WIDGET_NEXT: {category: Category.INSTRUCTION, action: 'Instruction dashboard today next'},
        },

        SIGNED: {category: Category.INSTRUCTION, action: 'Instruction signed'},

        REPLY: {
            PUBLISH_REQUEST: {category: Category.INSTRUCTION, action: 'Instruction reply request'},

            PUBLISH_SUCCESS: {category: Category.INSTRUCTION, action: 'Instruction reply'},
            PUBLISH_ERROR: {category: Category.INSTRUCTION, action: 'Instruction reply error'},

            ATTACH_FILE_SUCCESS: {category: Category.INSTRUCTION, action: 'Instruction reply attach file'}, // TODO: pas utilisé actuellement
            ATTACH_FILE_ERROR: {category: Category.INSTRUCTION, action: 'Instruction reply attach file error'}, // TODO: pas utilisé actuellement
        }
    },

    CONTACT: {
        CREATED: {category: Category.CONTACT, action: 'Contact created'},
        UPDATED: {category: Category.CONTACT, action: 'Contact updated'},
        DELETED: {category: Category.CONTACT, action: 'Contact deleted'},
        IMPORTED: {category: Category.CONTACT, action: 'Contact imported'},
    },

    ADMINISTRATION: {
        USER: {
            CREATED: {category: Category.ADMINISTRATION, action: 'Administration user created'},
            UPDATED: {category: Category.ADMINISTRATION, action: 'Administration user updated'},
        },

        TEAM: {
            FLIGHT_ACCESS: {
                CREATED: {category: Category.ADMINISTRATION, action: 'Team flight access created'},
                UPDATED: {category: Category.ADMINISTRATION, action: 'Team flight access updated'},
                DELETED: {category: Category.ADMINISTRATION, action: 'Team flight access deleted'},
            },

            TAG: {
                CREATED: {category: Category.ADMINISTRATION, action: 'Team tag created'},
                DELETED: {category: Category.ADMINISTRATION, action: 'Team tag deleted'},
            },

            INTEGRATION_RULE: {
                CREATED: {category: Category.ADMINISTRATION, action: 'Team integration rule created'},
                UPDATED: {category: Category.ADMINISTRATION, action: 'Team integration rule updated'},
                DELETED: {category: Category.ADMINISTRATION, action: 'Team integration rule deleted'},
            },
        }
    },

    HELP: {
        VIEW_PAGE: {category: Category.HELP, action: 'Help view page'},
        REQUEST_DOCUMENT: {category: Category.HELP, action: 'Help request document'},
        DOWNLOAD_DOCUMENT: {category: Category.HELP, action: 'Help download document'},
        DOWNLOAD_DOCUMENT_ERROR: {category: Category.HELP, action: 'Help download document error'},
        REQUEST_TIPS: {category: Category.HELP, action: 'Help request tips'},
        DOWNLOAD_TIPS: {category: Category.HELP, action: 'Help download tips'},
        DOWNLOAD_TIPS_ERROR: {category: Category.HELP, action: 'Help download tips error'},

        GO_TO_TERMS_AND_CONDITIONS: {category: Category.HELP, action: 'Help go to terms and conditons'},
    },

    TERMS_AND_CONDITIONS: {
        VIEW_PAGE: {category: Category.TERMS_AND_CONDITIONS, action: 'Terms and conditions view page'},
    },

    NOTIFICATION: {
        PUSH: {
            ACCEPTED: {category: Category.NOTIFICATION, action: 'Notification push accepted'},
            REFUSED: {category: Category.NOTIFICATION, action: 'Notification push refused'},
            CLICKED: {category: Category.NOTIFICATION, action: 'Notification push clicked'},
        },
        CENTER: {
            OPENED: {category: Category.NOTIFICATION, action: 'Notification center opened'},
            CLOSED: {category: Category.NOTIFICATION, action: 'Notification center closed'},
            MARK_ALL_AS_READ: {category: Category.NOTIFICATION, action: 'Notification center mark all as read'},
            NOTIFICATION_DELETED: {category: Category.NOTIFICATION, action: 'Notification center notif deleted'},
            NOTIFICATION_READ: {category: Category.NOTIFICATION, action: 'Notification center notif read'},
            NOTIFICATION_CLICKED: {category: Category.NOTIFICATION, action: 'Notification center notif clicked'},
        }
    },

    OTP: {
        FILTERS: {
            SELECT_DATE: {category: Category.OTP, action: 'OTP Select date'},
            SELECT_AIRLINE: {category: Category.OTP, action: 'OTP Select airline'},
            SELECT_AIRPORT: {category: Category.OTP, action: 'OTP Select airport'},
            SET_AS_DEFAULT: {category: Category.OTP, action: 'OTP Set as default'},
        },
        TURNAROUNDS: {
            VIEW: {category: Category.OTP, action: 'OTP View turnarounds'}
        },
        MESSAGES: {
            VIEW: {category: Category.OTP, action: 'OTP View messages'}
        },
        EXPORT: {category: Category.OTP, action: 'OTP Export'},
        REFRESH: {category: Category.OTP, action: 'OTP Refresh'},
    }
};
