
import {first, map, startWith} from 'rxjs/operators';
import {HttpResponse} from "@angular/common/http";
import {Injectable, OnDestroy} from '@angular/core';
import {LogEventType, NotificationWsService} from "@logbook/shared";
import {LogbookLogsApiService} from "../logs.api.service";
import {LogbookLogsService} from "../logs.service";
import {CommentApiService} from "./comment.api.service";
import {Principal} from "@logbook/core/auth/services/principal.service";
import {User} from "@logbook/core/shared/models/user.model";
import {Log, MessageGroupID} from "@logbook/logs-view/shared/models";
import {Subscription} from "rxjs/internal/Subscription";

@Injectable()
export class CommentService implements OnDestroy {

    private currentUser: User;
    private wsPublish: Subscription;
    private wsEdit: Subscription;
    private wsDelete: Subscription;

    constructor(private commentApiService: CommentApiService, private logsService: LogbookLogsService,
                private logApiService: LogbookLogsApiService,
                private principal: Principal, private notificationWsService: NotificationWsService) {
        this.principal.getAuthenticationState().pipe(startWith(this.principal.identityDirect()))
            .subscribe((identity) => {
                if (identity != null) {
                    this.currentUser = identity;
                    this.wsPublish = this.notificationWsService.onEventPublished(LogEventType.CommentairePublie)
                        .subscribe((notif) => {
                            if (this.currentUser.userId !== notif.comment.emetteurId.id) {
                                this.logApiService
                                    .findLog(new MessageGroupID(notif.messageId, notif.filInformations.id))
                                    .pipe(first())
                                    .subscribe((res) => this.logsService.updateLog(res));
                            }
                        });

                    this.wsEdit = this.notificationWsService.onEventPublished(LogEventType.CommentaireEdite)
                        .subscribe((notif) => {
                            if (this.currentUser.userId !== notif.commentEdite.emetteurId.id) {
                                this.logApiService
                                    .findLog(new MessageGroupID(notif.messageId, notif.filInformationsId))
                                    .pipe(first())
                                    .subscribe((res) => this.logsService.updateLog(res));
                            }
                        });

                    this.wsDelete = this.notificationWsService.onEventPublished(LogEventType.CommentaireSupprime)
                        .subscribe((notif) => {
                            this.logApiService
                                .findLog(new MessageGroupID(notif.messageId, notif.filInformationsId))
                                .pipe(first())
                                .subscribe((res) => this.logsService.updateLog(res));
                        });
                }
        });

    }

    postComment(threadId, messageId, formData, notify = false) {
        return this.commentApiService.postComment(threadId, messageId, formData, notify).pipe(
            map((log: Log) => {
                return this.logApiService.updateEmitterInformation(log);
            }),
            map((log: Log) => {
                this.logsService.updateLog(log);
                return log;
            }));
    }

    editComment(threadId, messageId, commentId, formData) {
        return this.commentApiService.editComment(threadId, messageId, commentId, formData).pipe(
            map((log: Log) => {
                return this.logApiService.updateEmitterInformation(log);
            }),
            map((log: Log) => {
                this.logsService.updateLogComment(log, commentId);
                return log;
            }));
    }

    removeComment(threadId, messageId, commentId) {
        return this.commentApiService.removeComment(threadId, messageId, commentId).pipe(
            map((log: Log) => {
                this.logsService.updateLog(log);
                return log;
            }));
    }

    ngOnDestroy() {
        if (this.wsDelete) {
            this.wsDelete.unsubscribe();
        }
        if (this.wsEdit) {
            this.wsEdit.unsubscribe();
        }
        if (this.wsPublish) {
            this.wsPublish.unsubscribe();
        }
    }

}
