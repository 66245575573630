import {Team} from "./team.model";
import {SocieteLocalisationModel} from "./societe-localisation.model";

export class Societe {
    public id?: string;
    public nom?: string;
    public type?: string;
    public teamIds?: Array<string>;
    public teams?: Array<Team>;
    public salesforceId?: string;
    public logoUri?: string;
    public localisation?: SocieteLocalisationModel;

    public isSelected = false;

    constructor(build?) {
        if (build) {
            this.id = build.id;
            this.nom = build.nom;
            this.type = build.type;
            this.teamIds = build.teamIds;
            this.salesforceId = build.idSalesforce;
            this.localisation = build.localisation;
            this.logoUri = build.logoUri;
        }
    }

    displaySocieteName(): string {
        return `${this.nom} ${this.localisation.country}`;
    }

}
