<div [ngClass]="{'input-with-label': true, 'input-with-icon': icon,  'error': hasError}" *ngIf="control">
    <div [ngClass]="{'field': true, 'inline': inline}">
        <label [for]="id" *ngIf="label">{{label}}<span *ngIf="isRequired && asterisk"
                                                       class="required">*</span></label>
        <ng-container [ngSwitch]="type">
            <i [class]="icon" *ngIf="icon"></i>
            <ng-container *ngSwitchDefault>
                <ng-container *ngTemplateOutlet="inputText"></ng-container>
            </ng-container>

            <ng-container *ngSwitchCase="'time'">
                <dln-input-time *ngIf="!control?.disabled; else inputTimeDisabled" [control]="control"
                                [defaultDate]="defaultValue"></dln-input-time>
            </ng-container>
            <dln-input-date *ngSwitchCase="'date'"
                            [date]="control.value" [isNullable]="!required" (dateChanged)="dateChanged($event)"
                            [required]="isRequired"
                            [emptyDateLabel]="defaultValue" [arrowControl]="false">
            </dln-input-date>

            <dln-input-phone *ngSwitchCase="'phone'"
                             [formControl]="control" [inputName]="id">
            </dln-input-phone>


            <textarea *ngSwitchCase="'textarea'" [id]="id" [formControl]="control" rows="3"
                      [title]="id" [required]="isRequired"></textarea>

            <mat-radio-group class="radio-btn" [formControl]="control" *ngSwitchCase="'title'">
                <div class="radio-group">
                    <mat-radio-button [value]="'Mr'" name="title" [checked]="control.value === 'Mr'">
                        Mr
                    </mat-radio-button>
                </div>
                <div class="radio-group">
                    <mat-radio-button [value]="'Mrs'" name="title" [checked]="control.value === 'Mrs'">
                        Ms
                    </mat-radio-button>
                </div>
                <div class="radio-group">
                    <mat-radio-button [value]="'OTHER'" name="title" [checked]="control.value === 'OTHER'">
                        Other
                    </mat-radio-button>
                </div>
            </mat-radio-group>

            <ng-content *ngSwitchCase="'custom'"></ng-content>
        </ng-container>
    </div>
    <div class="errors" *ngIf="!hideErrors">
        <ng-container *ngIf="control && hasError && errorMessages">
            <span class="error-icon shake">!</span>
            <span class="error-message" *ngFor="let err of control.errors | keys">
            {{errorMessages[err]}}
        </span>
        </ng-container>
    </div>
</div>


<ng-template #inputText>
    <input name="{{inputName}}"
           type="{{type}}"
           id="{{id}}"
           [tabindex]="tabIndex"
           [formControl]="control"
           [required]="isRequired"
           [autocomplete]="autocomplete"
           [placeholder]="placeholder ? placeholder : ''"
           (blur)="checkValidation()"
    />
</ng-template>

<ng-template #inputTimeDisabled>
    <input name="{{inputName}}" type="time" [value]="timeAsString" disabled/>
</ng-template>
