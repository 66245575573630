import {Contact} from "./contact.model";

export const CATEGORY_TAG = "tags";
const CATEGORY_TAG_LABEL = "Tag";
const ICON_TAG = "dln-icon-tag";

export const CATEGORY_COMPANY = "companies";
const CATEGORY_COMPANY_LABEL = "Company";
const ICON_COMPANY = "dln-icon-company";

export const CATEGORY_LOCATION = "locations";
const CATEGORY_LOCATION_LABEL = "Location";
const ICON_LOCATION = "dln-icon-location";

const ICON_FILTER = "icon-funnel";

export class ContactGroup {
    public label?: string;
    public id?: string;
    public showLabel?: string;
    public members?: Set<Contact>;
    public count?: number;

    constructor(build?, contacts?: Contact[]) {
        if (build) {
            this.label = build.label;
            if (this.label) {
                this.showLabel = this.label.charAt(0).toUpperCase() + this.label.slice(1);
            }
            this.id = build.id;
            this.count = build.count;
            if (build.members) {
                if (contacts) {
                    this.members = build.members.map((member) => {
                        const find = contacts.find((value) => value.id === member.id);
                        if (find) {
                            return find;
                        } else {
                            return new Contact(member);
                        }
                    })
                } else {
                    this.members = build.members.map((member) => new Contact(member))
                }
            }
        }
    }

}

export class ContactFilter {
    public category?: string;
    public categoryLabel?: string;
    public iconClass?: string;
    public items: ContactGroup[] = [];
    public isExpanded = false;

    constructor(build?) {
        if (build) {
            this.category = build.category;

            switch (this.category) {
                case CATEGORY_TAG:
                    this.iconClass = ICON_TAG;
                    this.categoryLabel = CATEGORY_TAG_LABEL;
                    break;
                case CATEGORY_COMPANY:
                    this.iconClass = ICON_COMPANY;
                    this.categoryLabel = CATEGORY_COMPANY_LABEL;
                    break;
                case CATEGORY_LOCATION:
                    this.iconClass = ICON_LOCATION;
                    this.categoryLabel = CATEGORY_LOCATION_LABEL;
                    break;
                default:
                    this.categoryLabel = this.category.toUpperCase();
                    this.iconClass = ICON_FILTER;
            }

            this.items = build.items.map((item) => Object.assign(new ContactGroup(item)))
                .sort((a, b) => {
                    if (a.label.toLowerCase() < b.label.toLowerCase()) {
                        return -1;
                    }
                    if (a.label.toLowerCase() > b.label.toLowerCase()) {
                        return 1;
                    }
                    return 0;
                });
        }
    }
}

export class ContactActiveFilters {
    public category: string;
    public item: string;
}
