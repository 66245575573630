"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var flight_tag_model_1 = require("./flight-tag.model");
var FlightTagWithThreads = /** @class */ (function () {
    function FlightTagWithThreads() {
    }
    FlightTagWithThreads.sort = function (flightTagWithThreadsList) {
        var output = flightTagWithThreadsList.sort(function (ft1, ft2) {
            if (ft2.getKey() > ft1.getKey()) {
                return -1;
            }
            if (ft2.getKey() < ft1.getKey()) {
                return 1;
            }
            return 0;
        });
        return output;
    };
    FlightTagWithThreads.fromJson = function (flightTagWithThreadsAsString) {
        var flightTagWithThreads = new FlightTagWithThreads();
        if (flightTagWithThreadsAsString.flightTagAsString) {
            flightTagWithThreads.flightTag = flight_tag_model_1.FlightTag.createFlightTagFromString(flightTagWithThreadsAsString.flightTagAsString);
        }
        else if (flightTagWithThreadsAsString.flightTag) {
            flightTagWithThreads.flightTag = Object.assign(new flight_tag_model_1.FlightTag(), flightTagWithThreadsAsString.flightTag);
        }
        else {
            flightTagWithThreads.flightTag = new flight_tag_model_1.FlightTag();
        }
        if (flightTagWithThreadsAsString.threads) {
            flightTagWithThreads.threadIds = flightTagWithThreadsAsString.threads.map(function (thread) { return thread.threadIdAsBase64; });
        }
        else if (flightTagWithThreadsAsString.threadIds) {
            flightTagWithThreads.threadIds = flightTagWithThreadsAsString.threadIds;
        }
        else {
            flightTagWithThreads.threadIds = [];
        }
        return flightTagWithThreads;
    };
    FlightTagWithThreads.prototype.getKey = function () {
        return this.flightTag.airlineCode.concat(this.flightTag.flightNumber);
    };
    return FlightTagWithThreads;
}());
exports.FlightTagWithThreads = FlightTagWithThreads;
