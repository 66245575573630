import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Principal} from "../../auth/services/principal.service";
import {StateStorageService} from "../../auth/services/state-storage.service";

@Injectable()
export class FeatureEnabledAccessService implements CanActivate {

    constructor(private router: Router,
                private principal: Principal,
                private stateStorageService: StateStorageService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Promise<boolean> {

        const featureEnabled = route.data['featureEnabled'];
        if (!featureEnabled || featureEnabled.length === 0) {
            return true;
        }

        return this.checkLogin(featureEnabled, state.url);
    }

    checkLogin(featureEnabled: string[], url: string): Promise<boolean> {
        const principal = this.principal;
        return Promise.resolve(principal.identity().then((account) => {
            if (account && principal.hasFeatureEnableDirect(featureEnabled)) {
                return true;
            }
            this.stateStorageService.storeUrl(url);
            this.router.navigate(['']);
            return false;
        }));
    }
}
