import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import {LogbookAppModule} from './app.module';
import {environment} from '../environments/environment';
import {enableProdMode, ApplicationRef, NgModuleRef} from "@angular/core";
import {createNewHosts} from '@angularclass/hmr';

const hmrBootstrap = (module: any, boot: () => Promise<NgModuleRef<any>>) => {
    let ngModule: NgModuleRef<any>;
    module.hot.accept();
    boot().then((mod) => ngModule = mod);
    module.hot.dispose(() => {
        const appRef: ApplicationRef = ngModule.injector.get(ApplicationRef);
        const elements = appRef.components.map((c) => c.location.nativeElement);
        const makeVisible = createNewHosts(elements);
        ngModule.destroy();
        makeVisible();
    });
};

if (environment.production) {
    enableProdMode();
}

const bootstrap = () => platformBrowserDynamic().bootstrapModule(LogbookAppModule);

if (environment.hmr) {
    if (module['hot']) {
        console.log('HMR is enabled');
        hmrBootstrap(module, bootstrap);
    } else {
        console.error('HMR is not enabled for webpack-dev-server!');
        console.log('Are you using the --hmr flag for ng serve?');
    }
} else {
    console.log('HMR is disabled');
    bootstrap().then(() => {}).catch((err) => console.log(err));
}
