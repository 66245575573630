"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var logbook_config_1 = require("../shared/config/logbook-config");
var trackjs_1 = require("trackjs");
var i0 = require("@angular/core");
var i1 = require("../shared/config/logbook-config");
var TrackJsErrorHandlerService = /** @class */ (function () {
    function TrackJsErrorHandlerService(config, ngZone) {
        this.config = config;
        this.ngZone = ngZone;
        this.isEnabled = this.config.production;
        trackjs_1.TrackJS.install({
            application: 'Logbook',
            token: 'e60e51483c4a4de397f4d46e9ddc2fc4',
            network: {
                error: false
            },
            enabled: this.isEnabled,
            visitor: {
                enabled: false
            },
        });
    }
    TrackJsErrorHandlerService.prototype.configure = function (user) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.ngZone.runOutsideAngular(function () {
                    if (trackjs_1.TrackJS) {
                        trackjs_1.TrackJS.configure({
                            userId: user.userId,
                            version: _this.config.VERSION
                        });
                    }
                });
                return [2 /*return*/];
            });
        });
    };
    TrackJsErrorHandlerService.prototype.handleError = function (error) {
        var _this = this;
        this.ngZone.runOutsideAngular(function () {
            // Assumes we have already loaded and configured TrackJS*
            if (trackjs_1.TrackJS && error && _this.isEnabled) {
                trackjs_1.TrackJS.track(error); // Send the native error object to TrackJS
                console.warn(error);
            }
            else if (error) {
                console.error(error); // Trackjs disabled we still want to see stacktrace
            }
        });
    };
    TrackJsErrorHandlerService.ngInjectableDef = i0.defineInjectable({ factory: function TrackJsErrorHandlerService_Factory() { return new TrackJsErrorHandlerService(i0.inject(i1.LogbookConfig), i0.inject(i0.NgZone)); }, token: TrackJsErrorHandlerService, providedIn: "root" });
    return TrackJsErrorHandlerService;
}());
exports.TrackJsErrorHandlerService = TrackJsErrorHandlerService;
