import {Directive, HostListener, Input, ViewContainerRef} from "@angular/core";
import {DropdownContentComponent} from "./dropdown-content/dropdown-content.component";

@Directive({
    selector: '[dlnDropdown]'
})
export class DropdownDirective {

    @Input("dlnDropdown") dlnDropdown: DropdownContentComponent;
    @Input() displayOnHover = false;
    @Input() displayDropdown = true;
    @Input() hoverDelay = 500;
    private timer: any;

    constructor(private viewContainerRef: ViewContainerRef) {
    }

    @HostListener("focusin")
    @HostListener("mouseenter")
    hoverListener(): void {
        if (this.displayOnHover) {
            this.timer = setTimeout(() => {
                this.show();
            }, this.hoverDelay);
        }
    }

    @HostListener("focusout")
    @HostListener("mouseleave")
    leaveHoverListener(): void {
        if (this.displayOnHover && this.timer) {
            clearTimeout(this.timer);
        }
    }

    @HostListener("click")
    clickListener(): void {
        if (!this.displayOnHover && this.displayDropdown) {
            if (!this.dlnDropdown.isActive) {
                this.show();
            } else {
                this.dlnDropdown.hide();
            }
        }
    }

    show(): void {
        this.dlnDropdown.hostElement = this.viewContainerRef.element.nativeElement;
        this.dlnDropdown.show();
    }

}
