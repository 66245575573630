"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var operators_1 = require("rxjs/operators");
var http_1 = require("@angular/common/http");
var logbook_config_1 = require("../../../../../../app/core/shared/config/logbook-config");
var InvitationService = /** @class */ (function () {
    function InvitationService(http, config) {
        this.http = http;
        this.config = config;
        this.resourceUrl = this.config.LOGBOOK_API + '/api/invitation';
    }
    InvitationService.prototype.sendInvitation = function (emailGuest) {
        var options = new http_1.HttpParams();
        options = options.set('emailGuest', encodeURIComponent(emailGuest));
        return this.http.post(this.resourceUrl, {}, { params: options, observe: 'response' }).pipe(operators_1.map(function (res) { return res.body; }));
    };
    InvitationService.prototype.getInvitation = function (invitationToken) {
        var options = new http_1.HttpParams();
        options = options.set('token', encodeURIComponent(invitationToken));
        return this.http.get(this.resourceUrl, { params: options, observe: 'response' }).pipe(operators_1.map(function (res) { return res.body; }));
    };
    InvitationService.prototype.removeInvitation = function (invitationId) {
        return this.http.delete(this.resourceUrl + "/" + invitationId);
    };
    InvitationService.prototype.replayInvitation = function (invitationId) {
        return this.http.put(this.resourceUrl + "/" + invitationId + "/replay", {});
    };
    return InvitationService;
}());
exports.InvitationService = InvitationService;
