import {Injectable} from '@angular/core';
import {Router} from "@angular/router";
import {Moment} from "moment";
import * as moment from 'moment';
import {Observer, Observable} from "rxjs";
import {FlightTagWithThreads} from "@logbook/shared";
import {MixpanelService} from "@logbook/core/tracking/mixpanel.service";
import {MixpanelEvent} from "@logbook/core/tracking/mixpanel-event.enum";
import {Filters, Log, LogFilter} from "@logbook/logs-view/shared/models";
import {LogbookLogsService} from "@logbook/logs-view/shared/services";
import {LogsViewModule} from "../../../logs-view.module";

@Injectable({
    providedIn: 'root'
})
export class FiltersService {
    private listenerRefreshFilters: Observable<any>;
    private observerRefreshFilters: Array<Observer<any>>;
    public filters: Filters = new Filters();
    public allLogFilters: Array<LogFilter> = [];

    constructor(private router: Router, private mixpanelService: MixpanelService) {
        this.observerRefreshFilters = [];
        this.listenerRefreshFilters = this.createListener();
    }

    subscribeFilters(): Observable<any> {
        return this.listenerRefreshFilters;
    }

    initFilter(date: Moment = moment(), categoryTag: Array<LogFilter> = [], flightTag: Array<FlightTagWithThreads> = []) {
        this.filters.date = date;
        this.filters.categoryTags = categoryTag;
        this.filters.flightTags = flightTag;
        this.notifyObserver(false);
    }

    dateChanged(date: Moment) {
        this.filters.date = date;
        this.filters.flightTags = [];
        this.mixpanelService.track(MixpanelEvent.DATE_CHANGE, {selectedDate: date.toISOString()});
        this.notifyObserver(true);
    }

    previousDate() {
        this.dateChanged(this.filters.date.add(-1, 'day'));
    }

    nextDate() {
        this.dateChanged(this.filters.date.add(1, 'day'));
    }

    selectCategoryTag(categoryTag: LogFilter) {
        if (!this.filters.isCategoryTagIsSelected(categoryTag)) {
            this.mixpanelService.track(MixpanelEvent.FILTER_CATEGORY,
                {categoryFilterEntered: categoryTag.name}
            );
        }
        this.filters.selectCategoryTag(categoryTag);
        this.notifyObserver(true);
    }

    selectFlightTag(flightTag: FlightTagWithThreads) {
        if (!this.filters.isFlightTagIsSelected(flightTag)) {
            this.mixpanelService.track(MixpanelEvent.FILTER_FLIGHT,
                {filterEntered: flightTag.flightTag.toString()}
            );
        }
        this.filters.selectFlightTag(flightTag);
        this.notifyObserver(true);
    }

    clearAllFilters() {
        this.filters.categoryTags = [];
        this.filters.flightTags = [];
        this.notifyObserver(true);
    }

    logIsInSelectedFilters(log: Log) {
        return this.newLogIsInSelectedDate(log.tagVol.date)
            && this.newLogIsInSelectedFlightTag(log.id.idAsBase64)
            && this.newLogIsInSelectedCategory(log);
    }

    private newLogIsInSelectedDate(flightDate) {
        return moment(flightDate).isSame(this.filters.date, "day");
    }

    private newLogIsInSelectedFlightTag(threadId) {
        return this.filters.flightTags.length === 0
            || this.filters.flightTags.some((ftWithThread) =>
                ftWithThread.threadIds.some((filterThreadId) => filterThreadId === threadId)
            )
    }

    private newLogIsInSelectedCategory(log: Log) {
        return this.filters.categoryTags.length === 0
            || this.filters.categoryTags.some((filter) => filter.isPresentInLog(log))
    }

    private notifyObserver(navigate: boolean) {
        if (navigate) {
            this.router.navigate(['logs'], {
                queryParams: {
                    tagsFilters: JSON.stringify(this.filters.categoryTags),
                    date: this.filters.date.format("YYYY-MM-DD"),
                    flightFilters: JSON.stringify(this.filters.flightTags)
                }
            });
        }
        this.observerRefreshFilters.forEach((observer) => observer.next(this.filters));
    }

    private createListener(): Observable<any> {
        return new Observable((observer) => {
            this.observerRefreshFilters.push(observer);
        });
    }
}
