import {Phone} from "../../model/phone.model";
import {User} from "../../../../../../app/core/shared/models/user.model";

export class Contact {
    public id?: string;
    public _email?: string;
    public _prenom?: string;
    public _nom?: string;
    public _civilite?: string;
    public notes?: any;
    public owners?: Array<any>;
    public _posteDansLaSociete?: string;
    public _societe?: any;
    public _localisation?: any;
    public _telephones?: Phone[];
    public tags?: string[];
    public typeSociete?: string;
    public userInfo?: User;
    public surnom: string;
    public isEdited = false;
    public isShow = false;
    public isSelected = false;
    public showLetter = "";

    constructor(build?) {
        if (build) {
            this.id = build.id;
            this._email = build.email;
            this._prenom = build.firstName;
            this._nom = build.lastName;
            this.surnom = build.nickname;
            this._civilite = build.title;
            this.notes = build.notes;
            this.owners = build.owners;
            this._posteDansLaSociete = build.positionInCompany;
            this._societe = build.company;
            this._localisation = build.localisation;
            this._telephones = [];

            if (build.phones && build.phones.length) {
                this._telephones = build.phones.map((phone) => new Phone(phone));
            }

            this.typeSociete = build.typeCompany;
            this.tags = build.tags;
            this.userInfo = build.userInfo ? Object.assign(new User(), build.userInfo) : null;
        }
    }

    get email(): string {
        return this._email || (this.userInfo && this.userInfo.email)
    }

    get prenom(): string {
        return this._prenom || (this.userInfo && this.userInfo.firstName)
    }

    get nom(): string {
        return this._nom || (this.userInfo && this.userInfo.lastName)
    }

    get societe(): string {
        return this._societe || (this.userInfo && this.userInfo.company)
    }

    get civilite(): string {
        return this._civilite || (this.userInfo && this.userInfo.title)
    }

    get posteDansLaSociete(): string {
        return this._posteDansLaSociete || (this.userInfo && this.userInfo.positionInCompany)
    }

    get localisation(): string {
        return this._localisation || (this.userInfo && this.userInfo.localisation)
    }

    set email(email) {
        this._email = email;
    }

    get telephones(): Phone[] {
        if (this.userInfo && this.userInfo.phones) {
            const userPhones = this.userInfo.phones.map((tel) => new Phone({
                type: 'MOBILE',
                numero: tel
            }));
            if (this._telephones && this._telephones.length) {
                return this._telephones.concat(userPhones)
            } else {
                return userPhones
            }
        } else {
            return this._telephones;
        }
    }

    displayUserName(): string {
        if (this._prenom || this._nom) {
            let output = "";
            if (this._prenom) {
                output += this._prenom;
            }
            if (this._nom) {
                if (this._prenom) {
                    output += " ";
                }
                output += this._nom;
            }
            return output;
        } else if (this.userInfo) {
            return this.userInfo.displayUserName()
        } else if (this.email) {
            return this.email
        } else if (this.posteDansLaSociete) {
            return this.posteDansLaSociete
        }
        return "No name";
    }

    displayUserNameWithStyle(): string {
        if (this._prenom || this._nom) {
            let output = "";
            if (this._prenom) {
                output += this._prenom;
            }
            if (this._nom) {
                if (this._prenom) {
                    output += " ";
                }
                output += "<b>" + this._nom + "</b>";
            }
            return output;
        } else if (this.userInfo) {
            return this.userInfo.displayUserNameWithStyle()
        } else if (this.email) {
            return this.email
        } else if (this.posteDansLaSociete) {
            return this.posteDansLaSociete
        }
        return "No name";
    }

    displayCompany(): string {
        if (this.userInfo && this.userInfo.company && this.userInfo.company.nom) {
            return `${this.userInfo.getCompanyName()}`;
        } else if (this._societe && this._societe.nom) {
            return this._societe.nom
        }
        return null;
    }

    displayPositionCompany(): string {
        if (this.userInfo && this.userInfo.positionInCompany) {
            return `${this.userInfo.positionInCompany}`;
        } else if (this._posteDansLaSociete) {
            return this._posteDansLaSociete
        }
        return null;
    }

    displayLocation(): string {
        if (this.userInfo && this.userInfo.localisation) {
            return `${this.userInfo.localisation}`;
        } else if (this._localisation) {
            return this._localisation
        }
        return null;
    }

    getEmail(): string {
        return this.email;
    }

    getMobilePhone(): string {
        if (this.telephones && this.telephones.length) {
            const mobile = this.telephones.find((telephone) => telephone.type === 'MOBILE');
            if (mobile) {
                return mobile.numero;
            }
        }
        return null;
    }

    displayForContactList(): string {
        return `${this.displayUserName()} ${this._societe && this._societe._nom ? ` - ${this._societe._nom}` : ''}
        ${this._localisation && this._localisation.code ? ` - ${this._localisation.code}` : ''}`
    }

    getPhone(): string {
        if (this.telephones && this.telephones.length) {
            const phone = this.telephones.find((telephone) => telephone.type === 'OTHER');
            if (phone) {
                return phone.numero;
            }
        }
        return null;
    }

    hasTag(tag: string) {
        return this.tags && this.tags.indexOf(tag) > -1;
    }
}
