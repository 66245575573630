import {FlightTag} from "@logbook/shared";
import {Log} from "./log.model";
import {Message} from "./message.model";

export class MessageGroupID {
    messageId: string;
    messageIdBase64: string;
    threadId: string;

    constructor(messageId, threadId) {
        this.messageId = messageId.id;
        this.messageIdBase64 = messageId.idAsBase64;
        this.threadId = threadId.idAsBase64;
    }
}

export class LogGroup {
    key: string;
    message: Message;
    flightTags: FlightTag[];
    participants: any;
    messageIds: MessageGroupID[];
    isNew = false;
    update = 0;

    constructor(key?: string, log?: Log) {
        if (key) {
            this.key = key;
        }
        if (log) {
            this.setMessage(log.messages);
            this.flightTags = new Array(FlightTag.createFlightTag(log.tagVol));
            this.participants = log.participantsAutorises;
            this.messageIds = new Array(new MessageGroupID(log.messages.messageId, log.id));
            this.isNew = log.isNew;
        }
    }

    setMessage(message: Message) {
        if (message.comments == null) {
            message.comments = [];
        }
        this.message = new Message(message);
    }

    addLog(log: Log) {
        if (this.messageIdAlreadyPresent(log.messages.messageId.id)) {
            const flightTag = FlightTag.createFlightTag(log.tagVol);
            this.flightTags.push(flightTag);
            this.messageIds.push(new MessageGroupID(log.messages.messageId, log.id));
        }
    }

    private messageIdAlreadyPresent(messageId: string) {
        return !this.messageIds.some(
            (messagesIdInList) => messagesIdInList.messageId === messageId
        );
    }
}
