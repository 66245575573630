import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {CommunityService} from "../../auth/services/community.service";
import {Team} from "../models/team.model";
import {Principal} from "../../auth/services/principal.service";
import {catchError, first} from "rxjs/operators";

@Injectable()
export class UserAlreadyLoggedRouteAccessService implements CanActivate {

    constructor(private router: Router,
                private principal: Principal,
                private communityService: CommunityService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Promise<boolean> {
        return this.checkLogin();
    }

    checkLogin(): Promise<boolean> {
        return Promise.resolve(this.principal.identity().then((account) => {
            if (!account || !this.principal.hasAnyAuthorityDirect(["ROLE_USER"])) {
                return true;
            } else {
                return this.communityService.getCommunity().pipe(
                    first(),
                ).toPromise().then((community: Team) => {
                    if (community && community.id) {
                        this.router.navigate(['logs']).then();
                        return false
                    } else {
                        this.router.navigate(['instructions']).then();
                        return false;
                    }
                }).catch(() => true)
            }
        }).catch(() => {
            return false;
        }));
    }
}
