import {map} from 'rxjs/operators';
import {HttpClient, HttpParams, HttpResponse} from "@angular/common/http";
import {Injectable} from '@angular/core';
import {LogbookConfig} from "../../../../../../app/core/shared/config/logbook-config";
import {Observable} from "rxjs";

@Injectable()
export class InvitationService {
    private resourceUrl;

    constructor(private http: HttpClient, private config: LogbookConfig) {
        this.resourceUrl = this.config.LOGBOOK_API + '/api/invitation'
    }

    sendInvitation(emailGuest) {
        let options: HttpParams = new HttpParams();
        options = options.set('emailGuest', encodeURIComponent(emailGuest));
        return this.http.post(this.resourceUrl, {}, {params: options, observe: 'response'}).pipe(
            map((res: HttpResponse<any>) => res.body));
    }

    getInvitation(invitationToken): Observable<any> {
        let options: HttpParams = new HttpParams();
        options = options.set('token', encodeURIComponent(invitationToken));
        return this.http.get(this.resourceUrl, {params: options, observe: 'response'}).pipe(
            map((res: HttpResponse<any>) => res.body));
    }

    removeInvitation(invitationId) {
        return this.http.delete(`${this.resourceUrl}/${invitationId}`);
    }

    replayInvitation(invitationId) {
        return this.http.put(`${this.resourceUrl}/${invitationId}/replay`, {});
    }
}
