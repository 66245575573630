import {Injectable} from '@angular/core';
import {MatDialog} from "@angular/material";
import {ConfirmModalComponent} from "../components/confirm/confirm-modal.component";
import {ModalConfig} from "../model/modal.config.model";
import {Observable} from "rxjs";
import {first} from "rxjs/operators";

@Injectable()
export class ModalService {

    constructor(private dialog: MatDialog) {}

    /*
   * USAGE
   * show('Ton titre', 'Ton message').subscribe((confirm: boolean) => {
   *   if (confirm) { TA FONCTION }
   * })
   * */
    public showConfirmModal(title, message) {
        const modalConfig = new ModalConfig(ConfirmModalComponent, { title, message }, '555px', 'auto');
        return this.showModal(modalConfig);
    }

    /**
     * Un function qui prend en paramètre le titre et le message de la dialog et 2 functions de callbacks
     * @param title
     * @param message
     * @param onConfirm
     * @param onCancel
     */
    public confirm(title, message, onConfirm: Function, onCancel?: Function) {
        const modalConfig = new ModalConfig(ConfirmModalComponent, { title, message }, '555px', 'auto');
        return this.showModal(modalConfig).pipe(first()).subscribe((status) => {
            if (status) {
                onConfirm()
            } else {
                if (onCancel) {
                    onCancel()
                }
            }
        })
    }

    public alert(title, message?, onConfirm?: Function) {
        const modalConfig = new ModalConfig(ConfirmModalComponent, { title, message, hideCancel: true }, '555px', 'auto');
        return this.showModal(modalConfig).pipe(first()).subscribe((status) => {
            if (status && onConfirm) {
                onConfirm()
            }
        })
    }

    public showModal(modalConfig: ModalConfig): Observable<any> {
        const dialogRef = this.dialog.open(modalConfig.componentType, {
            height: modalConfig.height,
            width: modalConfig.width,
            data: modalConfig.data,
            panelClass: modalConfig.panelClass,
            hasBackdrop: modalConfig.hasBackdrop
        });
        return dialogRef.afterClosed().pipe(first());
    }

    closeAllModal() {
        if (this.dialog && this.dialog.openDialogs) {
            this.dialog.openDialogs.forEach((dialog) => dialog.close());
        }
    }

}
