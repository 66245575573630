"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var ng_intercom_1 = require("ng-intercom");
var ngx_webstorage_1 = require("ngx-webstorage");
var login_service_1 = require("../../auth/services/login.service");
var principal_service_1 = require("../../auth/services/principal.service");
var mixpanel_service_1 = require("../../tracking/mixpanel.service");
var mixpanel_event_enum_1 = require("../../tracking/mixpanel-event.enum");
var services_1 = require("@logbook/logs-view/shared/services");
var notification_center_service_1 = require("@logbook/notification-center/shared/service/notification.center.service");
var operators_1 = require("rxjs/operators");
var SwitchAppsMenuComponent = /** @class */ (function () {
    function SwitchAppsMenuComponent(loginService, router, principal, localStorageService, notificationCenter, mixpanelService, intercomService, changeDetection, viewManagementService) {
        this.loginService = loginService;
        this.router = router;
        this.principal = principal;
        this.localStorageService = localStorageService;
        this.notificationCenter = notificationCenter;
        this.mixpanelService = mixpanelService;
        this.intercomService = intercomService;
        this.changeDetection = changeDetection;
        this.viewManagementService = viewManagementService;
        this.notificationIsOpen = false;
        this.collapseChange = new core_1.EventEmitter();
        this.collapse = false;
        this.displayMobile = false;
    }
    SwitchAppsMenuComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.navBarSubscription = this.viewManagementService.subscribeActiveComponents()
            .subscribe(function (activeComponents) {
            return _this.displayMobile = activeComponents.rightPanelExpanded;
        });
        this.principal
            .getAuthenticationState()
            .pipe(operators_1.startWith(this.principal.identityDirect()), operators_1.filter(function (user) { return user != null; }))
            .subscribe(function (user) {
            _this.logo = SwitchAppsMenuComponent.LOGBOOK_LOGO;
            _this.companyName = SwitchAppsMenuComponent.LOGBOOK_NAME;
            if (user.company) {
                if (user.company.logoUri) {
                    _this.logo = user.company.logoUri;
                }
                if (user.company.nom) {
                    _this.companyName = user.company.nom;
                }
            }
            _this.changeDetection.markForCheck();
        });
    };
    SwitchAppsMenuComponent.prototype.collapseMenu = function () {
        this.collapse = !this.collapse;
        this.localStorageService.store(SwitchAppsMenuComponent.COLLAPSE_STORAGE_KEY, this.collapse);
        this.collapseChange.emit(this.collapse);
        this.changeDetection.markForCheck();
    };
    // Makes sure we don't have a memory leak by destroying the
    // Subscription when our flight-report is destroyed
    SwitchAppsMenuComponent.prototype.ngOnDestroy = function () {
        if (this.navBarSubscription) {
            this.navBarSubscription.unsubscribe();
        }
    };
    SwitchAppsMenuComponent.prototype.closeMenu = function () {
        this.viewManagementService.expandRightPanel();
        this.notificationIsOpen = false;
        this.changeDetection.markForCheck();
    };
    SwitchAppsMenuComponent.prototype.logout = function () {
        this.loginService.logout();
        this.mixpanelService.track(mixpanel_event_enum_1.MixpanelEvent.LOG_OUT, {});
        this.closeMenu();
        this.router.navigate(['logout', { queryParams: { logout: true }, queryParamsHandling: "merge" }]);
    };
    SwitchAppsMenuComponent.prototype.openNotification = function () {
        this.notificationIsOpen = !this.notificationIsOpen;
        this.mixpanelService.track(this.notificationIsOpen ?
            mixpanel_event_enum_1.MixpanelEvent.NOTIFICATION.CENTER.OPENED : mixpanel_event_enum_1.MixpanelEvent.NOTIFICATION.CENTER.CLOSED);
        this.changeDetection.markForCheck();
        // this.viewManagementService.expandRightPanel();
    };
    SwitchAppsMenuComponent.LOGBOOK_LOGO = "../../../content/images/logo.svg";
    SwitchAppsMenuComponent.LOGBOOK_NAME = "Logbook";
    SwitchAppsMenuComponent.COLLAPSE_STORAGE_KEY = "COLLAPSE_MENU";
    return SwitchAppsMenuComponent;
}());
exports.SwitchAppsMenuComponent = SwitchAppsMenuComponent;
