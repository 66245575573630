"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".simple-notification{width:100%;padding:10px 20px;border-radius:5px;box-sizing:border-box;position:relative;float:left;margin-bottom:10px;color:#fff;cursor:pointer;transition:all .5s}.simple-notification .sn-title{margin:0;padding:0 50px 0 0;line-height:30px;font-size:20px;font-weight:600}.simple-notification .sn-content{margin:0;font-size:16px;padding:0 50px 0 0;line-height:20px}.simple-notification .icon{position:absolute;box-sizing:border-box;top:0;right:0;width:60px;height:60px;padding:10px;font-size:40px;text-align:center}.simple-notification.rtl-mode{direction:rtl}.simple-notification.rtl-mode .sn-content{padding:0 0 0 50px}.simple-notification.error{background:#e63a20}.simple-notification.success{background:#54d2df}.simple-notification.alert{background:#27273b}.simple-notification.info{background:#1982ee}.simple-notification.warn{background:#ffae71}.simple-notification .sn-progress-loader{position:absolute;top:5px;left:5px;width:calc(100% - 10px);height:3px}.simple-notification .sn-progress-loader span{float:left;height:100%;background:#fff}.simple-notification.success .sn-progress-loader{background:#2db1bf}.simple-notification.error .sn-progress-loader{background:#e1224f}.simple-notification.alert .sn-progress-loader{background:#4f5d75}.simple-notification.info .sn-progress-loader{background:#0466cb}.simple-notification.warn .sn-progress-loader{background:#cc8b5a}.simple-notification.warn .sn-progress-loader span{background:#27273b}.simple-notification.warn .icon,.simple-notification.warn div .sn-content,.simple-notification.warn div .sn-title{color:#27273b}"];
exports.styles = styles;
