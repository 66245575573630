"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".spinner[_ngcontent-%COMP%]{margin:0 auto 20px;width:100px;height:30px;text-align:center;border-radius:30px;background:#848499;display:-ms-flexbox;display:flex;-ms-flex-pack:center;-moz-justify-content:center;-ms-justify-content:center;justify-content:center;-ms-flex-align:center;-moz-align-items:center;align-items:center}.spinner[_ngcontent-%COMP%]   .label-spinner[_ngcontent-%COMP%]{margin-left:10px;color:#fff;font-size:10px}.spinner.is-button[_ngcontent-%COMP%]{background:0 0;height:100%;width:100%;margin:auto}.spinner.is-button[_ngcontent-%COMP%]   .label-spinner[_ngcontent-%COMP%]{font-size:inherit;color:inherit}.spinner[_ngcontent-%COMP%] > div.rect[_ngcontent-%COMP%]{background-color:#fff;height:50%;width:2px;margin:1px;display:inline-block;font-size:10px;-webkit-animation:1.2s ease-in-out infinite sk-stretchdelay;animation:1.2s ease-in-out infinite sk-stretchdelay}.spinner.black[_ngcontent-%COMP%] > div.rect[_ngcontent-%COMP%]{background-color:#27273b}.spinner[_ngcontent-%COMP%]   .rect.rect2[_ngcontent-%COMP%]{-webkit-animation-delay:-1.1s;animation-delay:-1.1s}.spinner[_ngcontent-%COMP%]   .rect.rect3[_ngcontent-%COMP%]{-webkit-animation-delay:-1s;animation-delay:-1s}.spinner[_ngcontent-%COMP%]   .rect.rect4[_ngcontent-%COMP%]{-webkit-animation-delay:-.9s;animation-delay:-.9s}.spinner[_ngcontent-%COMP%]   .rect.rect5[_ngcontent-%COMP%]{-webkit-animation-delay:-.8s;animation-delay:-.8s}@-webkit-keyframes sk-stretchdelay{0%,100%,40%{-webkit-transform:scaleY(.4)}20%{-webkit-transform:scaleY(1)}}@keyframes sk-stretchdelay{0%,100%,40%{transform:scaleY(.4);-webkit-transform:scaleY(.4)}20%{transform:scaleY(1);-webkit-transform:scaleY(1)}}"];
exports.styles = styles;
