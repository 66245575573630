"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var momentImported = require("moment");
var moment = momentImported;
var FlightTag = /** @class */ (function () {
    function FlightTag() {
    }
    FlightTag.createDefaultFlightTag = function (flightDate) {
        var flightTag = new FlightTag();
        flightDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
        flightTag.date = flightDate.toISOString();
        flightTag.dateMoment = flightDate;
        flightTag.airlineCode = "XXX";
        flightTag.flightNumber = "0000";
        flightTag.from = "XXX";
        flightTag.to = "XXX";
        return flightTag;
    };
    FlightTag.createFlightTag = function (tagVol) {
        var flightTag = new FlightTag();
        flightTag.date = moment.utc(tagVol.date).toISOString();
        flightTag.dateMoment = moment.utc(tagVol.date);
        flightTag.airlineCode = tagVol.codeCompagnie.codeOACI;
        flightTag.flightNumber = tagVol.numeroVol.numero;
        flightTag.from = tagVol.provenance.code;
        flightTag.to = tagVol.destination.code;
        return flightTag;
    };
    FlightTag.createFlightTagFromString = function (flightTagAsString, dateFormat) {
        var flightTagSplit = flightTagAsString.split("-");
        var flightDateString = flightTagSplit[4] + "-" + flightTagSplit[5] + "-" + flightTagSplit[6];
        var flightDate = moment.utc(flightDateString, dateFormat);
        flightDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
        var flightTag = new FlightTag();
        flightTag.date = flightDate.toISOString();
        flightTag.dateMoment = flightDate;
        flightTag.airlineCode = flightTagSplit[0];
        flightTag.flightNumber = flightTagSplit[1];
        flightTag.from = flightTagSplit[2];
        flightTag.to = flightTagSplit[3];
        return flightTag;
    };
    FlightTag.areEquals = function (ft1, ft2) {
        return ft1.toString() === ft2.toString();
    };
    FlightTag.prototype.toStringWithoutDate = function () {
        return this.airlineCode + "-" + this.flightNumber + "-" + this.from + "-" + this.to;
    };
    FlightTag.prototype.toString = function () {
        return this.airlineCode + "-" + this.flightNumber + "-" + this.from + "-" + this.to + "-" +
            moment.utc(this.date).format("YYYY-MM-DD");
    };
    FlightTag.prototype.isGeneralFlight = function () {
        return this.toStringWithoutDate().lastIndexOf('XXX-0000-XXX-XXX', 0) === 0;
    };
    FlightTag.prototype.displayDate = function () {
        return moment(this.date).format("MMM DD");
    };
    FlightTag.prototype.toTagVol = function () {
        return {
            date: moment.utc(this.date).format(),
            codeCompagnie: {
                codeOACI: this.airlineCode
            },
            numeroVol: {
                numero: this.flightNumber
            },
            provenance: {
                code: this.from
            },
            destination: {
                code: this.to
            }
        };
    };
    return FlightTag;
}());
exports.FlightTag = FlightTag;
