"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ActiveComponents = /** @class */ (function () {
    function ActiveComponents() {
        this.writeLogFormExpanded = false;
        this.writeLogFormClear = true;
        this.leftPanelExpanded = false;
        this.rightPanelExpanded = false;
    }
    return ActiveComponents;
}());
exports.ActiveComponents = ActiveComponents;
