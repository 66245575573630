export const responsiveConfig = {
    breakPoints: {
        xs: {max: 575},
        sm: {min: 576, max: 768},
        md: {min: 769, max: 991},
        lg: {min: 992, max: 1999},
        xl: {min: 1200}
    },
    debounceTime: 100 // allow to debounce checking timer
};
