import {HttpClient, HttpParams, HttpResponse} from "@angular/common/http";
import {Injectable, NgZone, OnDestroy, OnInit} from '@angular/core';
import {interval as observableInterval, of as observableOf, Subject, Subscription, Observable} from 'rxjs';

import {catchError, first, map, mergeMap, publishReplay, refCount, tap, share} from 'rxjs/operators';
import {User} from '../../../../../../app/core/shared/models/user.model';
import {LogbookConfig} from "../../../../../../app/core/shared/config/logbook-config";
import {Pageable} from "../../model/pageable";
import {ResponseWrapper} from "../../model/response-wrapper.model";
import {Page} from "../../model";

@Injectable({
    providedIn: 'root'
})
export class UserService implements OnInit, OnDestroy {

    public usersLogged: string[];
    private resourceUrl;
    private usersCache: Map<string, Observable<User>> = new Map();
    private listenerRefreshUsersLogged: Subject<any>;
    private fetchUserOnlineInteval: Subscription;

    constructor(private http: HttpClient, private ngZone: NgZone, private config: LogbookConfig) {
        this.resourceUrl = this.config.LOGBOOK_API + '/api/participant/user';
        this.listenerRefreshUsersLogged = this.createListener();
    }

    ngOnInit(): void {
    }

    ngOnDestroy(): void {
        if (this.fetchUserOnlineInteval) {
            this.fetchUserOnlineInteval.unsubscribe();
        }
    }

    subscribeUsersLogged(): Observable<any> {
        return this.listenerRefreshUsersLogged;
    }

    fetchUsersLogged() {
        setTimeout(() => {
            this.getOnlineUsers().pipe(first()).subscribe((logged) => {
                this.usersLogged = logged.json;
                this.notifyObserver();
            });
        }, 1000);

        if (!this.fetchUserOnlineInteval) {
            this.ngZone.runOutsideAngular(() => {
                this.fetchUserOnlineInteval = observableInterval(60000)
                    .pipe(mergeMap(() => this.getOnlineUsers()))
                    .subscribe((logged) => {
                        this.usersLogged = logged.json;
                        this.ngZone.run(() => {
                            this.notifyObserver();
                        })
                    });
            })
        }

    }

    create(user: User): Observable<User> {
        return this.http.post(this.resourceUrl, user, {observe: 'response'}).pipe(
            map((res: HttpResponse<any>) => UserService.convertResponse(res)),
            map((res) => Object.assign(new User(), res.json))
        );
    }

    update(user: User): Observable<User> {
        return this.http.put(`${this.resourceUrl}/${user.userId}`, user, {observe: 'response'}).pipe(
            map((res: HttpResponse<any>) => UserService.convertResponse(res)),
            map((res) => Object.assign(new User(), res.json)),
            tap((res) => this.reset(res.userId))
        );
    }

    find(userId: string): Observable<User> {
        if (!this.usersCache.has(userId)) {
            this.usersCache.set(userId, this.http.get(`${this.resourceUrl}/${userId}`, {observe: 'response'}).pipe(
                map((res: HttpResponse<User>) => res.body),
                map((res) => Object.assign(new User(), res)),
                catchError(() => observableOf(null)),
                share(),
            ));
        }
        return this.usersCache.get(userId);
    }

    findAll(pageable: Pageable, search?: string): Observable<Page<User>> {
        let options: HttpParams = new HttpParams();
        options = options.set('page', pageable.page.toString());
        options = options.set('size', pageable.size.toString());
        options = options.set('sort', pageable.sort);
        if (search) {
            options = options.set('filter', search);
        }
        return this.http.get(`${this.resourceUrl}/`, {params: options, observe: 'response'}).pipe(
            map((res: HttpResponse<any>) => res.body),
            map((res) => {
                res = new Page(res);
                res.content = res.content.map((user) => Object.assign(new User(), user));
                return res;
            }),
            catchError(() => observableOf([]))
        );
    }

    delete(login: string): Observable<any> {
        return this.http.delete(`${this.resourceUrl}/${login}`, {observe: 'response'});
    }

    addRole(userId: string, role: string): Observable<any> {
        return this.http.put(`${this.resourceUrl}/${userId}/role/add`, {role})
            .pipe(map((res) => Object.assign(new User(), res)));
    }

    removeRole(userId: string, role: string): Observable<any> {
        return this.http.put(`${this.resourceUrl}/${userId}/role/remove`, {role})
            .pipe(map((res) => Object.assign(new User(), res)));
    }

    getOnlineUsers(): Observable<ResponseWrapper> {
        return this.http.get(`${this.resourceUrl}/online`, {observe: 'response'}).pipe(
            map((res: HttpResponse<any>) => UserService.convertResponse(res)));
    }

    private notifyObserver() {
        this.listenerRefreshUsersLogged.next(this.usersLogged);
    }

    private createListener(): Subject<any> {
        return new Subject();
    }

    private static convertResponse(res: HttpResponse<any>): ResponseWrapper {
        const jsonResponse = res.body;
        return new ResponseWrapper(res.headers, jsonResponse, res.status);
    }

    reset(userId?) {
        if (userId) {
            this.usersCache.delete(userId);
        } else {
            this.usersCache = new Map();
        }
    }
}
