import {Moment} from "moment";
import * as momentImported from 'moment'; const moment = momentImported;

export class ChangeLogAck {
    userId: string;
    updateDate: Date;
    updateMoment: Moment;

    constructor(build?) {
        if (build) {
            this.userId = build.userId;
            this.updateDate = build.updateDate;
            this.updateMoment = moment(this.updateDate);
        }
    }
}
