"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var operators_1 = require("rxjs/operators");
var http_1 = require("@angular/common/http");
var community_service_1 = require("./community.service");
var google_analytics_service_1 = require("@logbook/core/tracking/google-analytics.service");
var track_js_error_handler_service_1 = require("@logbook/core/tracking/track-js-error-handler.service");
var mixpanel_service_1 = require("@logbook/core/tracking/mixpanel.service");
var sign_as_user_access_model_1 = require("@logbook/core/shared/models/sign-as-user-access.model");
var visitor_access_model_1 = require("@logbook/core/shared/models/visitor-access.model");
var user_model_1 = require("@logbook/core/shared/models/user.model");
var logbook_config_1 = require("@logbook/core/shared/config/logbook-config");
var i0 = require("@angular/core");
var i1 = require("@angular/common/http");
var i2 = require("../../shared/config/logbook-config");
var i3 = require("../../tracking/mixpanel.service");
var i4 = require("../../tracking/google-analytics.service");
var i5 = require("../../tracking/track-js-error-handler.service");
var i6 = require("./community.service");
var AccountService = /** @class */ (function () {
    function AccountService(http, config, mixpanelService, googleAnayticsService, trackJsErrorHandlerService, communityService) {
        this.http = http;
        this.config = config;
        this.mixpanelService = mixpanelService;
        this.googleAnayticsService = googleAnayticsService;
        this.trackJsErrorHandlerService = trackJsErrorHandlerService;
        this.communityService = communityService;
    }
    AccountService.prototype.get = function () {
        var _this = this;
        return this.http.get(this.config.LOGBOOK_API + "/api/account", { observe: 'response' }).pipe(operators_1.map(function (res) { return res.body; }), operators_1.map(function (res) {
            if (res.signAsUserId) {
                var user = Object.assign(new sign_as_user_access_model_1.SignAsUserAccess(), res);
                _this.mixpanelService.setDisable(true);
                _this.communityService.getCommunity(true)
                    .pipe(operators_1.first())
                    .subscribe();
                return user;
            }
            else if (res.contactId || res.instructionId) {
                return new visitor_access_model_1.VisitorGuest(res);
            }
            else if (res.userId) {
                var user_1 = Object.assign(new user_model_1.User(), res);
                if (user_1.acceptActivityTracking) {
                    _this.mixpanelService.setDisable(false);
                    _this.communityService.getCommunity(true)
                        .pipe(operators_1.first())
                        .subscribe(function (community) {
                        _this.trackJsErrorHandlerService.configure(user_1);
                        _this.mixpanelService.setUser(user_1, { team: community ? community.name : null });
                        _this.googleAnayticsService.setUser(user_1, community ? community.name : null);
                    });
                }
                else {
                    _this.mixpanelService.setDisable(true);
                }
                return user_1;
            }
            else {
                return null;
            }
        }));
    };
    AccountService.prototype.save = function (account) {
        return this.http.post(this.config.LOGBOOK_API + "/api/account", account, { observe: 'response' });
    };
    AccountService.prototype.isReadCGU = function () {
        return this.http.get(this.config.LOGBOOK_API + "/api/participant/user/isReadCGU/" + this.config.CGU_VERSION, { observe: 'response' });
    };
    AccountService.prototype.readCGU = function () {
        return this.http.put(this.config.LOGBOOK_API + "/api/participant/user/readCGU/" + this.config.CGU_VERSION, {}, { observe: 'response' });
    };
    AccountService.prototype.changePassword = function (passwordEdit) {
        return this.http.post(this.config.LOGBOOK_API + "/api/password", passwordEdit, { observe: 'response' });
    };
    AccountService.ngInjectableDef = i0.defineInjectable({ factory: function AccountService_Factory() { return new AccountService(i0.inject(i1.HttpClient), i0.inject(i2.LogbookConfig), i0.inject(i3.MixpanelService), i0.inject(i4.GoogleAnalyticsService), i0.inject(i5.TrackJsErrorHandlerService), i0.inject(i6.CommunityService)); }, token: AccountService, providedIn: "root" });
    return AccountService;
}());
exports.AccountService = AccountService;
