<div [ngClass]="{'input-with-label': true}" *ngIf="!phoneForm.get('flatPhoneNumber')?.value">
    <div class="inputs">
        <div [ngClass]="{'calling-code': true, 'isError': inputError === 'code'}">
            <label class="country">
                <span class="flag-icon flag-icon-{{phoneForm.get('countryCode')?.value?.code?.toLowerCase()}}"></span>
                <span class="icon-chevron-down"></span>
                <span class="calling-code-label">+{{phoneForm.get('countryCode').value?.callingCode}}</span>
            </label>

            <mat-select [formControl]="phoneForm.get('countryCode')">
                <mat-option *ngFor="let country of countryCallingCodesList" [value]="country">
                    <span class="mat-select-country-option">
                        <span class="flag-icon flag-icon-{{country.code.toLowerCase()}}"></span>
                        {{country.name}}
                        <span class="mat-option-calling-code">+{{country.callingCode}}</span>
                    </span>
                </mat-option>
            </mat-select>

        </div>

        <input
            [textMask]="maskPhone"
            name="phoneNumber"
            id="{{inputName}}"
            [ngClass]="{'required': required, 'isError': inputError === 'number'}"
            [formControl]="phoneForm.get('phoneNumber')"
        />
    </div>
</div>

<!-- If we cannot parse phone number, we use simple input !-->

<input
    *ngIf="phoneForm.get('flatPhoneNumber')?.value"
    [name]="inputName"
    [formControl]="phoneForm.get('flatPhoneNumber')">
