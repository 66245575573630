"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ModalConfig = /** @class */ (function () {
    function ModalConfig(componentType, data, width, height, panelClass, hasBackdrop) {
        if (hasBackdrop === void 0) { hasBackdrop = true; }
        this.width = '760px';
        this.height = 'auto';
        this.hasBackdrop = true;
        this.componentType = componentType;
        if (data) {
            this.data = data;
        }
        if (width) {
            this.width = width;
        }
        if (height) {
            this.height = height;
        }
        if (panelClass) {
            this.panelClass = panelClass;
        }
        this.hasBackdrop = hasBackdrop;
    }
    return ModalConfig;
}());
exports.ModalConfig = ModalConfig;
