import {HttpClient} from "@angular/common/http";
import {LOGBOOK_API} from "@logbook/app.constants";
import {Injectable} from "@angular/core";
import {LogbookNotification} from "@logbook/notification-center/shared/model/LogbookNotification";
import {Pageable} from "@logbook/shared";
import {map} from "rxjs/operators";

@Injectable()
export class NotificationApiService {
    API_ROOT = `${LOGBOOK_API}/api/notifications`;

    constructor(private http: HttpClient) {}

    getAll(pageable: Pageable) {
        return this.http.get(`${this.API_ROOT}`, {params: pageable.toOptions()}).pipe(
            map((res: any) => res.content),
            map((notifications) => notifications.map((notification) =>
                Object.assign(new LogbookNotification(), notification))
            )
        );
    }

    markAsRead(notificationId: string, fromDevice: string) {
        return this.http.put<LogbookNotification>(`${this.API_ROOT}/read/${notificationId}${fromDevice ? `?from=${fromDevice}` : ''}`, {});
    }

    markAllAsRead() {
        return this.http.put<LogbookNotification>(`${this.API_ROOT}/readAll`, {});
    }

    getUnSeen() {
        return this.http.get<Array<LogbookNotification>>(`${this.API_ROOT}/unseen`);
    }

    get(id: string) {
        return this.http.get<LogbookNotification>(`${this.API_ROOT}/${id}`, {});
    }

    remove(id: string) {
        return this.http.delete<LogbookNotification>(`${this.API_ROOT}/delete/${id}`, {});
    }
}
