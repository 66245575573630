"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var FilterImagesPipe = /** @class */ (function () {
    function FilterImagesPipe() {
    }
    FilterImagesPipe.prototype.transform = function (files) {
        return files ? files.filter(function (file) { return file.isImage(); }) : [];
    };
    return FilterImagesPipe;
}());
exports.FilterImagesPipe = FilterImagesPipe;
