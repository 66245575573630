import {CommonModule} from '@angular/common';
import {ModuleWithProviders, NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';

import {NgSelectComponent} from './ng-select.component';
import {NgSelectConfig} from './ng-select.types';
import {NgAddTagTemplateDirective, NgLabelTemplateDirective, NgOptionTemplateDirective} from './ng-templates.directive';
import {NgSpinnerComponent} from './spinner.component';
import {VirtualScrollModule} from './virtual-scroll.component';

@NgModule({
    declarations: [
        NgSelectComponent,
        NgOptionTemplateDirective,
        NgLabelTemplateDirective,
        NgAddTagTemplateDirective,
        NgSpinnerComponent
    ],
    imports: [
        FormsModule,
        CommonModule,
        VirtualScrollModule
    ],
    exports: [
        NgSelectComponent,
        NgOptionTemplateDirective,
        NgAddTagTemplateDirective,
        NgLabelTemplateDirective
    ]
})
export class NgSelectModule {
    static forRoot(config: NgSelectConfig): ModuleWithProviders {
        return provideModule(config);
    }

    static forChild(config: NgSelectConfig): ModuleWithProviders {
        return provideModule(config);
    }
}

export function provideModule(config: NgSelectConfig) {
    return {
        ngModule: NgSelectModule,
        providers: [
            {provide: NgSelectConfig, useValue: config}
        ]
    };
}
