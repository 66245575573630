import {AbstractControl, ValidatorFn} from "@angular/forms";

export function dlnEmailValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
        // tslint:disable-next-line
        if (!control.value) {
            return null;
        }
        const emailRegexp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const isEmail = emailRegexp.test(control.value);
        return !isEmail ? {'isEmail': {value: control.value}} : null;
    };
}

export function isEmailValid(val) {
    const emailRegexp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailRegexp.test(val);
}
