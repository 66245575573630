import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import * as momentImported from 'moment'; const moment = momentImported;
import {MixpanelService} from "../../../../../../../app/core/tracking/mixpanel.service";
import {MixpanelEvent} from "../../../../../../../app/core/tracking/mixpanel-event.enum";

@Component({
    selector: 'dln-input-date',
    templateUrl: './input-date.component.html',
    styleUrls: [
        './input-date.component.scss'
    ]
})
export class DlnInputDateComponent implements OnChanges {
    @Input() date = null;
    @Input() isNullable = false;
    @Input() emptyDateLabel: string = null;
    @Input() arrowControl = true;
    @Output() dateChanged = new EventEmitter<any>();
    shouldDisplayDatePicker = false;
    datePicker: Date = null;

    constructor(private mixpanelService: MixpanelService) {
    }

    ngOnChanges(changes: SimpleChanges) {
        if (!changes.date.previousValue || changes.date.currentValue !== changes.date.previousValue) {
            if (changes.date.currentValue === null) {
                this.datePicker = moment.utc().toDate();
            } else {
                this.datePicker = changes.date.currentValue.toDate();
            }
        }
    }

    selectDateByShortcut(shortcut: string) {
        if (this.date === null) {
            this.date = moment.utc();
        } else {
            // Clone momentjs otherwise angular change detection not working
            this.date = this.date.clone();
        }
        switch (shortcut) {
            case "next":
                this.date.add(1, 'day');
                this.datePicker = this.date.toDate();
                this.emitDateChanged();
                break;
            case "previous":
                this.date.add(-1, 'day');
                this.datePicker = this.date.toDate();
                this.emitDateChanged();
                break;
        }
    }

    displayDatePicker() {
        this.shouldDisplayDatePicker = !this.shouldDisplayDatePicker;
        if (this.shouldDisplayDatePicker) {
            this.mixpanelService.track(MixpanelEvent.DATE_PICKER_OPEN);
        }
    }

    onSelectDate(event) {
        this.shouldDisplayDatePicker = false;
        this.date = moment(event.value).utc(true);
        this.emitDateChanged();
    }

    clearDate(event: Event) {
        event.stopPropagation();
        this.date = null;
        this.emitDateChanged();
    }

    emitDateChanged() {
        this.dateChanged.emit(this.date);
    }

}
