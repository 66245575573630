export class ShareRecipient {
    lastName: string;
    firstName: string;
    company: string;
    email: string;
    phone: string;

    constructor() {
    }

    initInfo() {
        this.firstName = null;
        this.lastName = null;
        this.company = null;
    }
}
