"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var CategoryTag = /** @class */ (function () {
    function CategoryTag(tag) {
        this.alias = tag.alias;
        this.color = tag.color;
        this.value = tag.value;
    }
    return CategoryTag;
}());
exports.CategoryTag = CategoryTag;
