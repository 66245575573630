import {Component, Input, OnInit} from '@angular/core';
import {UserService} from "../../../user/service/user.service";
import {map} from "rxjs/operators";
import {Observable} from "rxjs";

@Component({
    selector: 'dln-user-status',
    templateUrl: './user-status.component.html',
    styleUrls: [
        'user-status.scss'
    ]
})
export class UserStatusComponent implements OnInit {

    @Input() userId: string;
    userLogged$: Observable<boolean>;

    constructor(private userService: UserService) {
    }

    ngOnInit() {
        this.userLogged$ = this.userService.subscribeUsersLogged()
            .pipe(
                map((users) => users.some((u) => u === this.userId)),
            )
        ;
    }
}
