import {Moment} from "moment";
import * as moment from 'moment';
import {FlightTagWithThreads} from "@logbook/shared";
import {LogFilter} from "../category-tag/log-filter.model";
import {ChangeDetectorRef} from "@angular/core";

export class Filters {
    date: Moment;
    categoryTags: Array<LogFilter>;
    flightTags: Array<FlightTagWithThreads>;

    constructor() {
        this.date = moment.utc().startOf("day");
        this.categoryTags = [];
        this.flightTags = [];
    }

    isCategoryTagIsSelected(categoryTag: LogFilter): boolean {
        return this.categoryTags.filter(
            (tagInList) => tagInList.key === categoryTag.key && tagInList.value === categoryTag.value
        ).length > 0;
    }

    isFlightTagIsSelected(flightTag: FlightTagWithThreads): boolean {
        return this.flightTags.filter((flightTagInList) =>
            flightTagInList.flightTag.toString() === flightTag.flightTag.toString()
        ).length > 0;
    }

    selectCategoryTag(categoryTag: LogFilter) {
        // Check if filter already selected
        const categoryTagInList = this.categoryTags.filter((tagInList) =>
            tagInList.key === categoryTag.key && tagInList.value === categoryTag.value
        );

        if (categoryTagInList.length === 0) {

            // If a filter already selected on same property replace it  but not for messages tag filters
            if (
                this.categoryTags
                    .filter((tagInList) => tagInList.key === categoryTag.key)
                    .length > 0
                && categoryTag.key !== "messages.tags") {

                this.categoryTags = this.categoryTags.map((tagInList) => {
                    if (tagInList.key === categoryTag.key) {
                        return categoryTag
                    } else {
                        return tagInList
                    }
                });

            } else {
                this.categoryTags.push(categoryTag)
            }

        } else {
            this.categoryTags.splice(this.categoryTags.indexOf(categoryTagInList[0]), 1);
        }
    }

    selectFlightTag(flightTag: FlightTagWithThreads) {
        const flightTagsInList = this.flightTags.filter((flightTagInList) =>
            flightTagInList.flightTag.toString() === flightTag.flightTag.toString()
        );

        if (flightTagsInList.length === 0) {
            this.flightTags.push(flightTag)
        } else {
            this.flightTags.splice(this.flightTags.indexOf(flightTagsInList[0]), 1);
        }
    }

    getSelectedThreads() {
        const threads = this.flightTags.map((flightTag) => flightTag.threadIds);

        // CONVERT ARRAY OF ARRAY TO ARRAY (FLATMAP)
        return threads.concat.apply([], threads);
    }

    isActiveFilters(): boolean {
        return this.flightTags.length !== 0 || this.categoryTags.length !== 0;
    }

    countActiveFilters(): number {
        return this.flightTags.length + this.categoryTags.length;
    }
}
