import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {Intercom} from "ng-intercom";
import {MatDialogRef} from "@angular/material";
import {TERMS_PRIVACY_POLICY_URL, TERMS_USER_AGREEMENT_URL} from "../../../app.constants";
import {MixpanelService} from "../../tracking/mixpanel.service";
import {Principal} from "../../auth/services/principal.service";
import {AccountService} from "../../auth/services/account.service";
import {MixpanelEvent} from "../../tracking/mixpanel-event.enum";
import {User} from "../../shared/models/user.model";
import {SignAsUserAccess} from "../../shared/models/sign-as-user-access.model";
import {first} from "rxjs/operators";

@Component({
    selector: 'dln-on-boarding-modal',
    templateUrl: './on-boarding-modal.component.html',
    styleUrls: ['./on-boarding-modal.component.scss']
})
export class OnBoardingModalComponent implements OnInit {

    public currentUser: User;
    public signAsUser = false;
    public iRead = false;
    public userAgreementUrl = TERMS_USER_AGREEMENT_URL;
    public privacyPolicyUrl = TERMS_PRIVACY_POLICY_URL;

    constructor(public dialogRef: MatDialogRef<OnBoardingModalComponent>,
                private mixpanelService: MixpanelService,
                private router: Router, private principal: Principal,
                private intercomService: Intercom, private accountService: AccountService) {
    }

    ngOnInit() {
        this.dialogRef.disableClose = true;
        this.principal.identity().then((user: User) => {
            this.currentUser = user;
            this.signAsUser = user instanceof SignAsUserAccess;

            if (this.signAsUser) {
                this.dialogRef.disableClose = false;
            }
        })
    }

    cancel() {
        this.dialogRef.close();
    }

    showIntercom() {
        this.mixpanelService.track(MixpanelEvent.OPEN_LIVE_CHAT);
        this.intercomService.show();
    }

    showAccount() {
        this.router.navigate(["account"]);
        this.dialogRef.close();
    }

    readCGU() {
        if (this.iRead) {
            this.accountService.readCGU().pipe(first()).subscribe(() => {
                this.mixpanelService.track(MixpanelEvent.ACCEPT_TERMS_AND_CONDITIONS);
                this.dialogRef.close();
            });
        }
    }
}
