"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var principal_service_1 = require("../../auth/services/principal.service");
/**
 * @whatItDoes Conditionally includes an HTML element if current user has any
 * of the authorities passed as the `expression`.
 *
 * @howToUse
 * ```
 *     <some-element *dlnHasAnyAuthority="'ROLE_ADMIN'">...</some-element>
 *
 *     <some-element *dlnHasAnyAuthority="['ROLE_ADMIN', 'ROLE_USER']">...</some-element>
 * ```
 */
var HasAnyAuthorityDirective = /** @class */ (function () {
    function HasAnyAuthorityDirective(principal, templateRef, viewContainerRef, changeDetecor) {
        this.principal = principal;
        this.templateRef = templateRef;
        this.viewContainerRef = viewContainerRef;
        this.changeDetecor = changeDetecor;
    }
    Object.defineProperty(HasAnyAuthorityDirective.prototype, "dlnHasAnyAuthority", {
        set: function (value) {
            var _this = this;
            this.authorities = typeof value === 'string' ? [value] : value;
            this.updateView();
            // Get notified each time authentication state changes.
            this.subscription = this.principal.getAuthenticationState().subscribe(function (identity) { return _this.updateView(); });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(HasAnyAuthorityDirective.prototype, "dlnHasAnyAuthorityElse", {
        set: function (templateRef) {
            this.elseTemplateRef = templateRef;
            this.updateView();
        },
        enumerable: true,
        configurable: true
    });
    HasAnyAuthorityDirective.prototype.updateView = function () {
        var _this = this;
        this.principal.hasAnyAuthority(this.authorities).then(function (result) {
            _this.viewContainerRef.clear();
            if (result) {
                _this.viewContainerRef.createEmbeddedView(_this.templateRef);
            }
            else if (_this.elseTemplateRef) {
                _this.viewContainerRef.createEmbeddedView(_this.elseTemplateRef);
            }
            _this.changeDetecor.markForCheck();
        });
    };
    HasAnyAuthorityDirective.prototype.ngOnDestroy = function () {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    };
    return HasAnyAuthorityDirective;
}());
exports.HasAnyAuthorityDirective = HasAnyAuthorityDirective;
