import {Moment} from "moment";
import * as momentImported from 'moment'; const moment = momentImported;

export class FlightTag {
    airlineCode: string;
    flightNumber: string;
    from: string;
    to: string;
    date: string;
    dateMoment: Moment;

    static createDefaultFlightTag(flightDate: Moment): FlightTag {
        const flightTag = new FlightTag();

        flightDate.set({hour: 0, minute: 0, second: 0, millisecond: 0});

        flightTag.date = flightDate.toISOString();
        flightTag.dateMoment = flightDate;
        flightTag.airlineCode = "XXX";
        flightTag.flightNumber = "0000";
        flightTag.from = "XXX";
        flightTag.to = "XXX";

        return flightTag;
    }

    static createFlightTag(tagVol): FlightTag {
        const flightTag = new FlightTag();

        flightTag.date = moment.utc(tagVol.date).toISOString();
        flightTag.dateMoment = moment.utc(tagVol.date);
        flightTag.airlineCode = tagVol.codeCompagnie.codeOACI;
        flightTag.flightNumber = tagVol.numeroVol.numero;
        flightTag.from = tagVol.provenance.code;
        flightTag.to = tagVol.destination.code;

        return flightTag;
    }

    static createFlightTagFromString(flightTagAsString: string, dateFormat?: string): FlightTag {
        const flightTagSplit = flightTagAsString.split("-");

        const flightDateString = flightTagSplit[4] + "-" + flightTagSplit[5] + "-" + flightTagSplit[6];
        const flightDate = moment.utc(flightDateString, dateFormat);
        flightDate.set({hour: 0, minute: 0, second: 0, millisecond: 0});
        const flightTag = new FlightTag();

        flightTag.date = flightDate.toISOString();
        flightTag.dateMoment = flightDate;
        flightTag.airlineCode = flightTagSplit[0];
        flightTag.flightNumber = flightTagSplit[1];
        flightTag.from = flightTagSplit[2];
        flightTag.to = flightTagSplit[3];
        return flightTag;
    }

    static areEquals(ft1: FlightTag, ft2: FlightTag): boolean {
        return ft1.toString() === ft2.toString()
    }

    constructor() {
    }

    toStringWithoutDate() {
        return this.airlineCode + "-" + this.flightNumber + "-" + this.from + "-" + this.to;
    }

    toString() {
        return this.airlineCode + "-" + this.flightNumber + "-" + this.from + "-" + this.to + "-" +
            moment.utc(this.date).format("YYYY-MM-DD");
    }

    isGeneralFlight(): boolean {
        return this.toStringWithoutDate().lastIndexOf('XXX-0000-XXX-XXX', 0) === 0
    }

    displayDate() {
        return moment(this.date).format("MMM DD");
    }

    toTagVol() {
        return {
            date: moment.utc(this.date).format(),
            codeCompagnie: {
                codeOACI: this.airlineCode
            },
            numeroVol: {
                numero: this.flightNumber
            },
            provenance: {
                code: this.from
            },
            destination: {
                code: this.to
            }
        }
    }
}
