"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("@angular/common/http");
var logbook_config_1 = require("../../../../../../../app/core/shared/config/logbook-config");
var operators_1 = require("rxjs/operators");
var ReferenceDataApiService = /** @class */ (function () {
    function ReferenceDataApiService(http, config) {
        this.http = http;
        this.config = config;
    }
    ReferenceDataApiService.prototype.transcodeAirline = function (airlineCode) {
        var options = new http_1.HttpParams()
            .set('code', airlineCode);
        return this.http.get(this.config.LOGBOOK_API + "/api/reference-data-api/airline", { params: options });
    };
    ReferenceDataApiService.prototype.searchAirline = function (airlineCode) {
        var options = new http_1.HttpParams()
            .set('filter', airlineCode ? airlineCode : '');
        return this.http.get(this.config.LOGBOOK_API + "/api/reference-data-api/airlines", { params: options })
            .pipe(operators_1.map(function (i) { return [].concat(i); }));
    };
    ReferenceDataApiService.prototype.searchAirport = function (airportCode) {
        var options = new http_1.HttpParams()
            .set('filter', airportCode ? airportCode : '');
        return this.http.get(this.config.LOGBOOK_API + "/api/reference-data-api/airports", { params: options })
            .pipe(operators_1.map(function (i) { return [].concat(i); }));
    };
    return ReferenceDataApiService;
}());
exports.ReferenceDataApiService = ReferenceDataApiService;
