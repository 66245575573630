export class Phone {
    public type?: string;
    public numero?: string;

    constructor(build?) {
        if (build) {
            this.type = build.type;
            this.numero = build.numero;
        }
    }
}
