"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var operators_1 = require("rxjs/operators");
var http_1 = require("@angular/common/http");
var rxjs_1 = require("rxjs");
var piece_jointe_model_1 = require("../model/piece-jointe.model");
var logbook_config_1 = require("../../../../../../../../app/core/shared/config/logbook-config");
var notifications_service_1 = require("../../../../notification/services/notifications.service");
var AttachmentService = /** @class */ (function () {
    function AttachmentService(http, notificationsService, config) {
        this.http = http;
        this.notificationsService = notificationsService;
        this.config = config;
    }
    AttachmentService.prototype.postFile = function (messageForm, file) {
        var _this = this;
        var listenerObserver;
        var listener = new rxjs_1.Observable(function (observer) { return listenerObserver = observer; });
        var formData = new FormData();
        formData.append("file", file, file.name);
        var pieceJointe = new piece_jointe_model_1.PieceJointe();
        pieceJointe.filename = file.name;
        pieceJointe.isPosting = true;
        var currentIndex = messageForm.files.length;
        messageForm.files.push(pieceJointe);
        this.postAttachement(formData).pipe(operators_1.first()).subscribe(function (pj) {
            pieceJointe = pj;
            messageForm.files[currentIndex] = new piece_jointe_model_1.PieceJointe(pj);
            return pj;
        }, function (err) {
            messageForm.files = messageForm.files.filter(function (val, index) {
                return index !== currentIndex;
            });
            _this.notificationsService.error("An error occurred", "The size of your file excesses the authorized size (10Mb)");
            return err;
        }, function () {
            listenerObserver.next(null);
        });
        return listener;
    };
    AttachmentService.prototype.postAttachement = function (formData) {
        return this.http.post(this.config.LOGBOOK_API + "/api/attachment/upload", formData).pipe(operators_1.map(function (pj) { return pj; }));
    };
    return AttachmentService;
}());
exports.AttachmentService = AttachmentService;
