"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var principal_service_1 = require("../../auth/services/principal.service");
var HasFeatureEnableDirective = /** @class */ (function () {
    function HasFeatureEnableDirective(principal, templateRef, viewContainerRef, changeDetecor) {
        this.principal = principal;
        this.templateRef = templateRef;
        this.viewContainerRef = viewContainerRef;
        this.changeDetecor = changeDetecor;
    }
    Object.defineProperty(HasFeatureEnableDirective.prototype, "dlnHasFeatureEnable", {
        set: function (value) {
            var _this = this;
            this.features = typeof value === 'string' ? [value] : value;
            this.updateView();
            // Get notified each time authentication state changes.
            this._subscriptionState = this.principal.getAuthenticationState().subscribe(function (identity) {
                _this.updateView();
            });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(HasFeatureEnableDirective.prototype, "dlnHasFeatureEnableElse", {
        set: function (templateRef) {
            this.elseTemplateRef = templateRef;
        },
        enumerable: true,
        configurable: true
    });
    HasFeatureEnableDirective.prototype.updateView = function () {
        var _this = this;
        this.principal.hasFeatureEnable(this.features).then(function (result) {
            _this.viewContainerRef.clear();
            if (result) {
                _this.viewContainerRef.createEmbeddedView(_this.templateRef);
            }
            else {
                if (_this.elseTemplateRef) {
                    _this.viewContainerRef.createEmbeddedView(_this.elseTemplateRef);
                }
            }
            _this.changeDetecor.markForCheck();
        });
    };
    HasFeatureEnableDirective.prototype.ngOnDestroy = function () {
        if (this._subscriptionState) {
            this._subscriptionState.unsubscribe();
        }
    };
    return HasFeatureEnableDirective;
}());
exports.HasFeatureEnableDirective = HasFeatureEnableDirective;
