"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./simple-notifications.component.scss.ngstyle");
var i1 = require("@angular/core");
var i2 = require("../notification/simple-notification.component.ngfactory");
var i3 = require("../notification/simple-notification.component");
var i4 = require("../../services/notifications.service");
var i5 = require("@angular/platform-browser");
var i6 = require("@angular/common");
var i7 = require("./simple-notifications.component");
var styles_SimpleNotificationsComponent = [i0.styles];
var RenderType_SimpleNotificationsComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_SimpleNotificationsComponent, data: {} });
exports.RenderType_SimpleNotificationsComponent = RenderType_SimpleNotificationsComponent;
function View_SimpleNotificationsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "dln-simple-notification", [], null, null, null, i2.View_SimpleNotificationComponent_0, i2.RenderType_SimpleNotificationComponent)), i1.ɵdid(1, 245760, null, 0, i3.SimpleNotificationComponent, [i4.NotificationsService, i5.DomSanitizer, i1.NgZone], { timeOut: [0, "timeOut"], showProgressBar: [1, "showProgressBar"], pauseOnHover: [2, "pauseOnHover"], clickToClose: [3, "clickToClose"], maxLength: [4, "maxLength"], theClass: [5, "theClass"], rtl: [6, "rtl"], animate: [7, "animate"], position: [8, "position"], item: [9, "item"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.timeOut; var currVal_1 = _co.showProgressBar; var currVal_2 = _co.pauseOnHover; var currVal_3 = _co.clickToClose; var currVal_4 = _co.maxLength; var currVal_5 = _co.theClass; var currVal_6 = _co.rtl; var currVal_7 = _co.animate; var currVal_8 = _v.context.index; var currVal_9 = _v.context.$implicit; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9); }, null); }
function View_SimpleNotificationsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "simple-notification-wrapper"]], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i6.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SimpleNotificationsComponent_1)), i1.ɵdid(3, 278528, null, 0, i6.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "simple-notification-wrapper"; var currVal_1 = _co.position; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = _co.notifications; _ck(_v, 3, 0, currVal_2); }, null); }
exports.View_SimpleNotificationsComponent_0 = View_SimpleNotificationsComponent_0;
function View_SimpleNotificationsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "dln-simple-notifications", [], null, null, null, View_SimpleNotificationsComponent_0, RenderType_SimpleNotificationsComponent)), i1.ɵdid(1, 245760, null, 0, i7.SimpleNotificationsComponent, [i4.NotificationsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_SimpleNotificationsComponent_Host_0 = View_SimpleNotificationsComponent_Host_0;
var SimpleNotificationsComponentNgFactory = i1.ɵccf("dln-simple-notifications", i7.SimpleNotificationsComponent, View_SimpleNotificationsComponent_Host_0, { options: "options" }, { onCreate: "onCreate", onDestroy: "onDestroy" }, []);
exports.SimpleNotificationsComponentNgFactory = SimpleNotificationsComponentNgFactory;
