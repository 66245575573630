"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var ModalFooterComponent = /** @class */ (function () {
    function ModalFooterComponent() {
        this.canSave = new core_1.EventEmitter();
        this.cancel = new core_1.EventEmitter();
        this.save = new core_1.EventEmitter();
    }
    ModalFooterComponent.prototype.ngOnInit = function () { };
    return ModalFooterComponent;
}());
exports.ModalFooterComponent = ModalFooterComponent;
