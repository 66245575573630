import {HttpClient} from "@angular/common/http";
import {Injectable} from '@angular/core';
import {ImportParams} from "../../../../../../app/core/shared/models/team.model";
import {LogbookConfig} from "../../../../../../app/core/shared/config/logbook-config";
import {Observable} from "rxjs";

@Injectable()
export class ImportParamsService {
    private resourceUrl;

    constructor(private http: HttpClient, private config: LogbookConfig) {
        this.resourceUrl = this.config.LOGBOOK_API + '/api/import';
    }

    saveParams(importParams: ImportParams): Observable<any> {
        return this.http.post(this.resourceUrl + "/params", importParams)
    }

    deleteParams(id: string) {
        return this.http.delete(this.resourceUrl + "/params" + "/" + id)
    }
}
