"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("@angular/common/http");
var core_1 = require("@angular/core");
var rxjs_1 = require("rxjs");
var operators_1 = require("rxjs/operators");
var user_model_1 = require("../../../../../../app/core/shared/models/user.model");
var logbook_config_1 = require("../../../../../../app/core/shared/config/logbook-config");
var response_wrapper_model_1 = require("../../model/response-wrapper.model");
var model_1 = require("../../model");
var i0 = require("@angular/core");
var i1 = require("@angular/common/http");
var i2 = require("../../../../../../app/core/shared/config/logbook-config");
var UserService = /** @class */ (function () {
    function UserService(http, ngZone, config) {
        this.http = http;
        this.ngZone = ngZone;
        this.config = config;
        this.usersCache = new Map();
        this.resourceUrl = this.config.LOGBOOK_API + '/api/participant/user';
        this.listenerRefreshUsersLogged = this.createListener();
    }
    UserService.prototype.ngOnInit = function () {
    };
    UserService.prototype.ngOnDestroy = function () {
        if (this.fetchUserOnlineInteval) {
            this.fetchUserOnlineInteval.unsubscribe();
        }
    };
    UserService.prototype.subscribeUsersLogged = function () {
        return this.listenerRefreshUsersLogged;
    };
    UserService.prototype.fetchUsersLogged = function () {
        var _this = this;
        setTimeout(function () {
            _this.getOnlineUsers().pipe(operators_1.first()).subscribe(function (logged) {
                _this.usersLogged = logged.json;
                _this.notifyObserver();
            });
        }, 1000);
        if (!this.fetchUserOnlineInteval) {
            this.ngZone.runOutsideAngular(function () {
                _this.fetchUserOnlineInteval = rxjs_1.interval(60000)
                    .pipe(operators_1.mergeMap(function () { return _this.getOnlineUsers(); }))
                    .subscribe(function (logged) {
                    _this.usersLogged = logged.json;
                    _this.ngZone.run(function () {
                        _this.notifyObserver();
                    });
                });
            });
        }
    };
    UserService.prototype.create = function (user) {
        return this.http.post(this.resourceUrl, user, { observe: 'response' }).pipe(operators_1.map(function (res) { return UserService.convertResponse(res); }), operators_1.map(function (res) { return Object.assign(new user_model_1.User(), res.json); }));
    };
    UserService.prototype.update = function (user) {
        var _this = this;
        return this.http.put(this.resourceUrl + "/" + user.userId, user, { observe: 'response' }).pipe(operators_1.map(function (res) { return UserService.convertResponse(res); }), operators_1.map(function (res) { return Object.assign(new user_model_1.User(), res.json); }), operators_1.tap(function (res) { return _this.reset(res.userId); }));
    };
    UserService.prototype.find = function (userId) {
        if (!this.usersCache.has(userId)) {
            this.usersCache.set(userId, this.http.get(this.resourceUrl + "/" + userId, { observe: 'response' }).pipe(operators_1.map(function (res) { return res.body; }), operators_1.map(function (res) { return Object.assign(new user_model_1.User(), res); }), operators_1.catchError(function () { return rxjs_1.of(null); }), operators_1.share()));
        }
        return this.usersCache.get(userId);
    };
    UserService.prototype.findAll = function (pageable, search) {
        var options = new http_1.HttpParams();
        options = options.set('page', pageable.page.toString());
        options = options.set('size', pageable.size.toString());
        options = options.set('sort', pageable.sort);
        if (search) {
            options = options.set('filter', search);
        }
        return this.http.get(this.resourceUrl + "/", { params: options, observe: 'response' }).pipe(operators_1.map(function (res) { return res.body; }), operators_1.map(function (res) {
            res = new model_1.Page(res);
            res.content = res.content.map(function (user) { return Object.assign(new user_model_1.User(), user); });
            return res;
        }), operators_1.catchError(function () { return rxjs_1.of([]); }));
    };
    UserService.prototype.delete = function (login) {
        return this.http.delete(this.resourceUrl + "/" + login, { observe: 'response' });
    };
    UserService.prototype.addRole = function (userId, role) {
        return this.http.put(this.resourceUrl + "/" + userId + "/role/add", { role: role })
            .pipe(operators_1.map(function (res) { return Object.assign(new user_model_1.User(), res); }));
    };
    UserService.prototype.removeRole = function (userId, role) {
        return this.http.put(this.resourceUrl + "/" + userId + "/role/remove", { role: role })
            .pipe(operators_1.map(function (res) { return Object.assign(new user_model_1.User(), res); }));
    };
    UserService.prototype.getOnlineUsers = function () {
        return this.http.get(this.resourceUrl + "/online", { observe: 'response' }).pipe(operators_1.map(function (res) { return UserService.convertResponse(res); }));
    };
    UserService.prototype.notifyObserver = function () {
        this.listenerRefreshUsersLogged.next(this.usersLogged);
    };
    UserService.prototype.createListener = function () {
        return new rxjs_1.Subject();
    };
    UserService.convertResponse = function (res) {
        var jsonResponse = res.body;
        return new response_wrapper_model_1.ResponseWrapper(res.headers, jsonResponse, res.status);
    };
    UserService.prototype.reset = function (userId) {
        if (userId) {
            this.usersCache.delete(userId);
        }
        else {
            this.usersCache = new Map();
        }
    };
    UserService.ngInjectableDef = i0.defineInjectable({ factory: function UserService_Factory() { return new UserService(i0.inject(i1.HttpClient), i0.inject(i0.NgZone), i0.inject(i2.LogbookConfig)); }, token: UserService, providedIn: "root" });
    return UserService;
}());
exports.UserService = UserService;
