"use strict";
var _this = this;
Object.defineProperty(exports, "__esModule", { value: true });
var forms_1 = require("@angular/forms");
exports.validateAllFormFields = function (formGroup) {
    return Object.keys(formGroup.controls).forEach(function (field) {
        var control = formGroup.get(field);
        if (control instanceof forms_1.FormControl) {
            control.markAsTouched({ onlySelf: true });
        }
        else if (control instanceof forms_1.FormGroup) {
            _this.validateAllFormFields(control);
        }
    });
};
