import {RxStompConfig} from "@stomp/rx-stomp";

export const stompConfig: RxStompConfig = {
    // Which server?
    brokerURL: ``,

    // Headers
    // Typical keys: login, passcode, host
    connectHeaders: {},

    // How often to heartbeat?
    // Interval in milliseconds, set to 0 to disable
    heartbeatIncoming: 20000,
    heartbeatOutgoing: 10000,

    // Wait in milliseconds before attempting auto reconnect
    // Set to 0 to disable
    // Typical value 5000 (5 seconds)
    reconnectDelay: 5000,

    // Will log diagnostics on console
    // debug:
};
