import {Injectable, OnDestroy} from "@angular/core";
import {NotificationApiService} from "@logbook/notification-center/shared/service/notification.api.service";
import {Principal} from "@logbook/core/auth/services/principal.service";
import {first, map, startWith} from "rxjs/operators";
import { NotificationEventType, NotificationWsService, Pageable} from "@logbook/shared";
import {LogbookNotification} from "@logbook/notification-center/shared/model/LogbookNotification";
import {Subject, Subscription} from "rxjs";

@Injectable()
export class NotificationCenterService implements OnDestroy {

    unreadNotifications: LogbookNotification[] = [];
    private notificationSubscribe: Subject<LogbookNotification> = new Subject();
    private wsSub: Subscription;

    constructor(private notificationApiService: NotificationApiService, private principal: Principal,
                private notificationWsService: NotificationWsService) {

        this.principal.getAuthenticationState().pipe(startWith(this.principal.identityDirect()))
            .subscribe((user) => {
                if (user) {
                    this.notificationApiService.getUnSeen().pipe(first())
                        .subscribe((notifications) => this.unreadNotifications = notifications);
                }
            });

        this.wsSub = this.notificationWsService.onEventPublished(NotificationEventType.NotificationCreated)
            .pipe(map((notif) => Object.assign(new LogbookNotification(), notif.notification)))
            .subscribe((notif) => {
                if (!this.notificationIsInUnSeeList(notif.id)) {
                    this.unreadNotifications.push(notif);
                    this.notificationSubscribe.next(notif);
                }
            });
    }

    public onNewNotification() {
        return this.notificationSubscribe;
    }

    public getAll(pageable: Pageable) {
        this.setNotificationsAsSee();
        return this.notificationApiService.getAll(pageable);
    }

    public markAsRead(notification: LogbookNotification) {
        this.notificationApiService.markAsRead(notification.id, 'LOGBOOK').pipe(first())
            .subscribe(() => notification.readAt = new Date());
    }

    public remove(id: string) {
        return this.notificationApiService.remove(id);
    }

    public markAllAsRead() {
        this.notificationApiService.markAllAsRead().pipe(first()).subscribe();
    }

    public setNotificationsAsSee() {
        this.unreadNotifications = []
    }

    private notificationIsInUnSeeList(notificationId: string): boolean {
        return this.unreadNotifications.some((notification: LogbookNotification) =>
            notification.id === notificationId
        );
    }

    ngOnDestroy() {
        if (this.wsSub) {
            this.wsSub.unsubscribe();
        }
    }
}
