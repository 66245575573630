import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'dln-file-icon',
    templateUrl: './file-icon.component.html',
    styleUrls: ['./file-icon.scss']
})
export class FileIconComponent implements OnInit {
    @Input() contentType: string;

    constructor() {

    }

    ngOnInit(): void {
    }

    getFileType(): string {
        if (this.contentType && this.contentType.indexOf("image") > -1) {
            return "IMAGE"
        } else if (this.contentType && this.contentType.indexOf("audio") > -1) {
            return "AUDIO"
        } else if (this.contentType && this.contentType === 'application/pdf') {
            return "PDF"
        } else {
            return "OTHER"
        }
    }
}
