"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".row[_ngcontent-%COMP%]{width:100%;height:60vh;display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;-ms-flex-align:center;-moz-align-items:center;align-items:center;-ms-flex-pack:center;-moz-justify-content:center;-ms-justify-content:center;justify-content:center}.row[_ngcontent-%COMP%]   h1[_ngcontent-%COMP%]{font-size:32px;font-weight:800;margin-bottom:25px}"];
exports.styles = styles;
