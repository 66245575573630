"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@angular/material");
var confirm_modal_component_1 = require("../components/confirm/confirm-modal.component");
var modal_config_model_1 = require("../model/modal.config.model");
var operators_1 = require("rxjs/operators");
var ModalService = /** @class */ (function () {
    function ModalService(dialog) {
        this.dialog = dialog;
    }
    /*
   * USAGE
   * show('Ton titre', 'Ton message').subscribe((confirm: boolean) => {
   *   if (confirm) { TA FONCTION }
   * })
   * */
    ModalService.prototype.showConfirmModal = function (title, message) {
        var modalConfig = new modal_config_model_1.ModalConfig(confirm_modal_component_1.ConfirmModalComponent, { title: title, message: message }, '555px', 'auto');
        return this.showModal(modalConfig);
    };
    /**
     * Un function qui prend en paramètre le titre et le message de la dialog et 2 functions de callbacks
     * @param title
     * @param message
     * @param onConfirm
     * @param onCancel
     */
    ModalService.prototype.confirm = function (title, message, onConfirm, onCancel) {
        var modalConfig = new modal_config_model_1.ModalConfig(confirm_modal_component_1.ConfirmModalComponent, { title: title, message: message }, '555px', 'auto');
        return this.showModal(modalConfig).pipe(operators_1.first()).subscribe(function (status) {
            if (status) {
                onConfirm();
            }
            else {
                if (onCancel) {
                    onCancel();
                }
            }
        });
    };
    ModalService.prototype.alert = function (title, message, onConfirm) {
        var modalConfig = new modal_config_model_1.ModalConfig(confirm_modal_component_1.ConfirmModalComponent, { title: title, message: message, hideCancel: true }, '555px', 'auto');
        return this.showModal(modalConfig).pipe(operators_1.first()).subscribe(function (status) {
            if (status && onConfirm) {
                onConfirm();
            }
        });
    };
    ModalService.prototype.showModal = function (modalConfig) {
        var dialogRef = this.dialog.open(modalConfig.componentType, {
            height: modalConfig.height,
            width: modalConfig.width,
            data: modalConfig.data,
            panelClass: modalConfig.panelClass,
            hasBackdrop: modalConfig.hasBackdrop
        });
        return dialogRef.afterClosed().pipe(operators_1.first());
    };
    ModalService.prototype.closeAllModal = function () {
        if (this.dialog && this.dialog.openDialogs) {
            this.dialog.openDialogs.forEach(function (dialog) { return dialog.close(); });
        }
    };
    return ModalService;
}());
exports.ModalService = ModalService;
