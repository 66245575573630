"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@angular/material");
var ZoomImageComponent = /** @class */ (function () {
    function ZoomImageComponent(dialogRef, data) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.imageUrl = data.imageUrl;
    }
    ZoomImageComponent.prototype.cancel = function () {
        this.dialogRef.close();
    };
    return ZoomImageComponent;
}());
exports.ZoomImageComponent = ZoomImageComponent;
