import {HttpClient} from "@angular/common/http";
import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {LOGBOOK_API} from "../../../../app.constants";

@Injectable()
export class ExportLogsService {

    constructor(private http: HttpClient) {
    }

    public export(type, params): Observable<any> {
        return this.http.get(`${LOGBOOK_API}/api/export/${type}`, {params});
    }

}
