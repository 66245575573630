"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./zoom-image.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("@angular/material/dialog");
var i3 = require("./zoom-image.component");
var styles_ZoomImageComponent = [i0.styles];
var RenderType_ZoomImageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ZoomImageComponent, data: {} });
exports.RenderType_ZoomImageComponent = RenderType_ZoomImageComponent;
function View_ZoomImageComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "mat-dialog-content", [["class", "mat-dialog-content"]], null, null, null, null, null)), i1.ɵdid(1, 16384, null, 0, i2.MatDialogContent, [], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 4, "mat-dialog-actions", [["class", "mat-dialog-actions"]], null, null, null, null, null)), i1.ɵdid(4, 16384, null, 0, i2.MatDialogActions, [], null, null), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "buttons"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "button", [["class", "cancel"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Close"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.imageUrl; _ck(_v, 2, 0, currVal_0); }); }
exports.View_ZoomImageComponent_0 = View_ZoomImageComponent_0;
function View_ZoomImageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "dln-zoom-image", [], null, null, null, View_ZoomImageComponent_0, RenderType_ZoomImageComponent)), i1.ɵdid(1, 49152, null, 0, i3.ZoomImageComponent, [i2.MatDialogRef, [2, i2.MAT_DIALOG_DATA]], null, null)], null, null); }
exports.View_ZoomImageComponent_Host_0 = View_ZoomImageComponent_Host_0;
var ZoomImageComponentNgFactory = i1.ɵccf("dln-zoom-image", i3.ZoomImageComponent, View_ZoomImageComponent_Host_0, {}, {}, []);
exports.ZoomImageComponentNgFactory = ZoomImageComponentNgFactory;
