import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FlightTagSearchService} from "./service/flight.tag.search.service";
import {ReferenceDataApiService} from "./service/reference-data-api.service";
import {FlightTagComponent} from "./component/flight-tag/flight-tag.component";

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [FlightTagComponent],
    providers: [
        FlightTagSearchService, ReferenceDataApiService
    ],
    exports: [FlightTagComponent]
})
export class FlightTagModule { }
