"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = ["[_nghost-%COMP%]   .message[_ngcontent-%COMP%], [_nghost-%COMP%]   .title[_ngcontent-%COMP%]{color:#27273b;text-align:center}[_nghost-%COMP%]   .title[_ngcontent-%COMP%]{font-size:18px;font-weight:600;line-height:25px;margin:0 15px 15px}[_nghost-%COMP%]   .message[_ngcontent-%COMP%]{width:60%;margin:auto;font-size:14px;line-height:20px}[_nghost-%COMP%]   .buttons[_ngcontent-%COMP%]{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;-ms-flex-align:center;-moz-align-items:center;align-items:center;-ms-flex-pack:center;-moz-justify-content:center;-ms-justify-content:center;justify-content:center;-ms-flex-wrap:nowrap;flex-wrap:nowrap}[_nghost-%COMP%]   .buttons[_ngcontent-%COMP%]   button[_ngcontent-%COMP%]{width:170px;height:40px;margin-top:30px;cursor:pointer;font-size:16px;border:1px solid #5491e8;border-radius:5px}[_nghost-%COMP%]   .buttons[_ngcontent-%COMP%]   button.cancel[_ngcontent-%COMP%]{color:#5491e8;background:#fff;margin-right:20px}[_nghost-%COMP%]   .buttons[_ngcontent-%COMP%]   button.confirm[_ngcontent-%COMP%]{color:#fff;background:#5491e8}"];
exports.styles = styles;
