"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var rxjs_1 = require("rxjs");
var operators_1 = require("rxjs/operators");
var http_1 = require("@angular/common/http");
var contact_filter_model_1 = require("../model/contact-filter.model");
var contact_model_1 = require("../model/contact.model");
var logbook_config_1 = require("../../../../../../app/core/shared/config/logbook-config");
var ContactService = /** @class */ (function () {
    function ContactService(http, config) {
        this.http = http;
        this.config = config;
        this.contactCache = new Map();
        this.resourceUrl = config.LOGBOOK_API + '/api/contact';
    }
    ContactService.prototype.find = function (id) {
        if (!this.contactCache.has(id)) {
            this.contactCache.set(id, this.http.get(this.resourceUrl + "/" + id).pipe(operators_1.map(function (res) { return new contact_model_1.Contact(res); }), operators_1.catchError(function () { return rxjs_1.of(null); }), operators_1.publishReplay(1), operators_1.refCount()));
        }
        return this.contactCache.get(id);
    };
    ContactService.prototype.findAllContactForUser = function (pageable, query, filter) {
        var params = pageable.toOptions();
        var filters = [];
        if (query && query.search) {
            filters.push({ key: 'search', value: query.search });
        }
        if (filter) {
            filters.push({ key: filter.category, value: filter.item });
        }
        params = params.set('filters', JSON.stringify(filters));
        return this.http.get("" + this.resourceUrl, { params: params }).pipe(operators_1.map(function (res) { return res.content; }), operators_1.map(function (contacts) { return contacts.map(function (contact) { return new contact_model_1.Contact(contact); }); }));
    };
    ContactService.prototype.getContactFilters = function (forced) {
        if (forced || !this.contactFiltersCache) {
            this.contactFiltersCache = this.http.get(this.resourceUrl + "/filters").pipe(operators_1.map(function (contactFilters) {
                return contactFilters.map(function (contactFilter) { return new contact_filter_model_1.ContactFilter(contactFilter); });
            }), operators_1.catchError(function (err) {
                console.warn(err);
                return rxjs_1.of(null);
            }), operators_1.publishReplay(1), operators_1.refCount());
        }
        return this.contactFiltersCache;
    };
    ContactService.prototype.save = function (contact) {
        return this.http.post(this.resourceUrl + "/", contact).pipe(operators_1.map(function (contactFromApi) { return new contact_model_1.Contact(contactFromApi); }));
    };
    ContactService.prototype.saveMultiple = function (contactList) {
        return this.http.post(this.resourceUrl + "/multiple", contactList).pipe(operators_1.map(function (contactsFromApi) { return contactsFromApi.map(function (contactFromApi) { return new contact_model_1.Contact(contactFromApi); }); }));
    };
    ContactService.prototype.load = function (pieceJointe) {
        return this.http.post(this.resourceUrl + "/load", pieceJointe).pipe(operators_1.map(function (res) { return res.map(function (contact) { return new contact_model_1.Contact(contact); }); }));
    };
    ContactService.prototype.remove = function (id) {
        return this.http.delete(this.resourceUrl + "/" + id);
    };
    ContactService.prototype.removeMultiple = function (ids) {
        return this.http.delete(this.resourceUrl + "/" + ids + "/multiple");
    };
    return ContactService;
}());
exports.ContactService = ContactService;
