"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var momentImported = require("moment");
var moment = momentImported;
var feature_model_1 = require("./feature.model");
var ChangeLog = /** @class */ (function () {
    function ChangeLog(build) {
        this.enable = false;
        this.features = [];
        this.isSelected = false;
        this.highlight = false;
        this.releaseDate = new Date();
        if (build) {
            this.id = build.id;
            this.enable = build.enable;
            this.version = build.version;
            this.releaseDate = build.version;
            this.releaseDate = build.releaseDate;
            this.updateDate = build.updateDate;
            this.features = build.features.map(function (feature) { return Object.assign(new feature_model_1.Feature(feature)); });
        }
        this.releaseMoment = moment(this.releaseDate);
        if (this.updateDate) {
            this.updateMoment = moment(this.updateDate);
        }
    }
    ChangeLog.prototype.isRead = function (ack) {
        return ack && ack.updateMoment.isAfter(this.updateMoment);
    };
    return ChangeLog;
}());
exports.ChangeLog = ChangeLog;
