"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var material_1 = require("@angular/material");
var attachment_service_1 = require("../../common/text-editor/attach-file/service/attachment.service");
var resizing_cropping_images_1 = require("@alyle/ui/resizing-cropping-images");
var operators_1 = require("rxjs/operators");
var UploadImageDialogComponent = /** @class */ (function () {
    function UploadImageDialogComponent(dialogRef, attachmentService) {
        this.dialogRef = dialogRef;
        this.attachmentService = attachmentService;
        this.isLoading = false;
        this.cropConfig = {
            width: 200,
            height: 200
        };
    }
    UploadImageDialogComponent.prototype.ngOnInit = function () { };
    UploadImageDialogComponent.prototype.close = function () {
        this.dialogRef.close(null);
    };
    UploadImageDialogComponent.prototype.save = function () {
        var _this = this;
        this.isLoading = true;
        var croppedImage = this.imgCropping.crop();
        var imageBase64 = croppedImage.base64;
        var contentType = croppedImage.type;
        var blob = UploadImageDialogComponent.dataURItoBlob(imageBase64, contentType);
        var formData = new FormData();
        formData.append("file", blob, croppedImage.name);
        this.attachmentService.postAttachement(formData).pipe(operators_1.first()).subscribe(function (pj) {
            _this.isLoading = false;
            _this.dialogRef.close(pj);
        });
    };
    UploadImageDialogComponent.dataURItoBlob = function (dataURI, contentType) {
        var byteString = atob(dataURI.toString().split(',')[1]);
        var ab = new ArrayBuffer(byteString.length);
        var ia = new Uint8Array(ab);
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ab], { type: contentType });
    };
    return UploadImageDialogComponent;
}());
exports.UploadImageDialogComponent = UploadImageDialogComponent;
