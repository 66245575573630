import {Injectable, NgZone} from "@angular/core";
import mixpanel from "mixpanel-browser/src/loader-module";
import {User} from "../shared/models/user.model";
import {LogbookConfig} from "../shared/config/logbook-config";

declare let ga: Function;

@Injectable({
    providedIn: 'root'
})
export class MixpanelService {

    private mixpanel: any;
    private disable = false;

    constructor(private config: LogbookConfig, private ngZone: NgZone) {
        this.mixpanel = mixpanel;
        this.mixpanel.init(this.config.MIXPANEL_TOKEN);
    }

    async track(eventName: any, metadata?: any) {
        if (!this.disable) {
            try {
                this.ngZone.runOutsideAngular(() => {
                    this.mixpanel.track(eventName.action, metadata);
                    ga("send", "event", eventName.category, eventName.action)
                });
            } catch (Exception) {
                console.warn(Exception)
            }
        }
    }

    async identify(userId: string) {
        try {
            this.ngZone.runOutsideAngular(() => {
                this.mixpanel.identify(userId);
            });
        } catch (Exception) {
            console.warn(Exception)
        }
    }

    async alias(userId: string) {
        try {
            this.ngZone.runOutsideAngular(() => {
                this.mixpanel.alias(userId);
            });
        } catch (Exception) {
            console.warn(Exception)
        }
    }

    async reset() {
        try {
            this.ngZone.runOutsideAngular(() => {
                this.mixpanel.reset();
            });
        } catch (Exception) {
            console.warn(Exception)
        }
    }

    setDisable(disable) {
        this.disable = disable;
    }

    async setUser(user: User, other: any = {}) {
        try {
            this.ngZone.runOutsideAngular(() => {
                const mixpanelUser = {
                    name: user.displayUserName(),
                    title: user.title,
                    "$email": user.userId,
                    firstname: user.firstName,
                    lastname: user.lastName,
                    company: user.getCompanyName(),
                    "company type": user.typeCompany,
                    "company position": user.positionInCompany,
                    location: user.localisation,
                    phone: user.phones ? user.phones[0] : null
                };

                this.mixpanel.people.set(Object.assign(mixpanelUser, other));
            });
        } catch (Exception) {
            console.warn(Exception)
        }
    }
}
