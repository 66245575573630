import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CommunityService} from "@logbook/core/auth/services/community.service";
import {AccountService} from "@logbook/core/auth/services/account.service";
import {Principal} from "@logbook/core/auth/services/principal.service";
import {LoginService} from "@logbook/core/auth/services/login.service";
import {AuthServerProvider} from "@logbook/core/auth/services/auth-jwt.service";
import {StateStorageService} from "@logbook/core/auth/services/state-storage.service";

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [],
    providers: [
    ]
})
export class LogbookCoreAuthenticationModule {
}
