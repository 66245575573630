"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var ngx_device_detector_1 = require("ngx-device-detector");
var ButtonComponent = /** @class */ (function () {
    function ButtonComponent(device) {
        this.device = device;
        this.color = 'blue';
        this.isDisabled = false;
        this.type = 'button';
        this.idBtn = '';
        this.iconPosition = "right";
        this.isLoading = false;
        this.isLoadingLabel = "Loading";
        this.onClick = new core_1.EventEmitter();
    }
    ButtonComponent.prototype.ngOnInit = function () {
        if (!this.height) {
            if (this.device.isMobile()) {
                this.heightStyle = "50px";
            }
            else {
                this.heightStyle = "40px";
            }
        }
        else {
            this.heightStyle = this.height;
        }
    };
    ButtonComponent.prototype.clickEmit = function () {
        this.onClick.emit(null);
    };
    return ButtonComponent;
}());
exports.ButtonComponent = ButtonComponent;
