import {Component, Inject, Optional} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";

@Component({
    selector: 'dln-zoom-image',
    templateUrl: './zoom-image.component.html',
    styleUrls: [
        'zoom-image.scss'
    ]

})
export class ZoomImageComponent {

    imageUrl: any;

    constructor(public dialogRef: MatDialogRef<ZoomImageComponent>,
                @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
        this.imageUrl = data.imageUrl;
    }

    cancel() {
        this.dialogRef.close();
    }
}
