import { Pipe, PipeTransform } from '@angular/core';
import {User} from "../../../../../../app/core/shared/models/user.model";
import {Invitation} from "../../../../../../app/core/shared/models/team.model";

@Pipe({
  name: 'userFilter'
})
export class UserFilterPipe implements PipeTransform {

  transform(value: User[] | Invitation[], filter?: string): any[] {
      if (value && value.length && filter && filter.length) {
          if ((<User>value[0]).userId) {
              const users = <User[]>value;
              return users.filter((user) => {
                  const filterUpperCase = filter.toUpperCase();
                  return user.displayUserName().toUpperCase().indexOf(filterUpperCase) >= 0 || user.userId.toUpperCase().indexOf(filterUpperCase) >= 0 || (user.company && user.company.nom.toUpperCase().indexOf(filterUpperCase) >= 0)
              });
          } else {
              const invitations = <Invitation[]>value;
              return invitations.filter((invitation) => {
                  const filterUpperCase = filter.toUpperCase();
                  return invitation.guest.toUpperCase().indexOf(filterUpperCase) >= 0
              });
          }
      } else {
        return value;
      }
  }

}
