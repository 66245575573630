"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var service_worker_1 = require("@angular/service-worker");
var http_1 = require("@angular/common/http");
var logbook_config_1 = require("@logbook/core/shared/config/logbook-config");
var mixpanel_service_1 = require("@logbook/core/tracking/mixpanel.service");
var mixpanel_event_enum_1 = require("@logbook/core/tracking/mixpanel-event.enum");
var operators_1 = require("rxjs/operators");
var i0 = require("@angular/core");
var i1 = require("@angular/service-worker");
var i2 = require("@angular/common/http");
var i3 = require("../../../../../../app/core/shared/config/logbook-config");
var i4 = require("../../../../../../app/core/tracking/mixpanel.service");
var PushNotificationsService = /** @class */ (function () {
    function PushNotificationsService(swPush, http, config, mixpanelService) {
        this.swPush = swPush;
        this.http = http;
        this.config = config;
        this.mixpanelService = mixpanelService;
    }
    PushNotificationsService.prototype.subscribeToNotifications = function () {
        var _this = this;
        if (this.config.VAPID_PUBLIC_KEY) {
            this.swPush.requestSubscription({
                serverPublicKey: this.config.VAPID_PUBLIC_KEY
            })
                .then(function (pushSubscription) {
                _this.mixpanelService.track(mixpanel_event_enum_1.MixpanelEvent.NOTIFICATION.PUSH.ACCEPTED);
                _this.http.post((_this.config.production ? _this.config.LOGBOOK_API : '') + "/api/notifications/subscribe", pushSubscription)
                    .subscribe(function (subResponse) {
                    console.log('[App] Subscription successful');
                });
            })
                .catch(function (err) {
                // Need typescript >= v3 to use 'Notification.permission'
                if (err && err.message && err.message.indexOf('denied') > 0) {
                    _this.mixpanelService.track(mixpanel_event_enum_1.MixpanelEvent.NOTIFICATION.PUSH.REFUSED);
                }
                console.error("Could not subscribe to notifications", err);
            });
        }
        else {
            console.log("Could not subscribe to notifications, VALID_PUBLIC_KEY invalid");
        }
    };
    PushNotificationsService.prototype.unsubscribeToNotifications = function (identity) {
        var _this = this;
        // Get active subscription
        return new Promise(function (resolve) {
            if (_this.swPush.isEnabled) {
                _this.swPush.subscription
                    .pipe(operators_1.take(1))
                    .subscribe(function (pushSubscription) {
                    // Delete the subscription from the backend
                    _this.http.post((_this.config.production ? _this.config.LOGBOOK_API : '') + "/api/notifications/unsubscribe/" + identity.userId, pushSubscription)
                        .subscribe(function (subResponse) {
                        pushSubscription.unsubscribe()
                            .then(function (success) {
                            console.log('[App] Unsubscription successful', success);
                            return resolve();
                        })
                            .catch(function (err) {
                            console.log('[App] Unsubscription failed', err);
                            return resolve();
                        });
                    }, function (err) {
                        console.log('[App] Delete subscription request failed', err);
                        return resolve();
                    });
                });
            }
            else {
                resolve();
            }
        });
    };
    PushNotificationsService.ngInjectableDef = i0.defineInjectable({ factory: function PushNotificationsService_Factory() { return new PushNotificationsService(i0.inject(i1.SwPush), i0.inject(i2.HttpClient), i0.inject(i3.LogbookConfig), i0.inject(i4.MixpanelService)); }, token: PushNotificationsService, providedIn: "root" });
    return PushNotificationsService;
}());
exports.PushNotificationsService = PushNotificationsService;
