"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("@angular/common/http");
var app_constants_1 = require("../../../../../app.constants");
var http_custom_converter_1 = require("@logbook/core/shared/utils/http-custom-converter");
var CommentApiService = /** @class */ (function () {
    function CommentApiService(http) {
        this.http = http;
    }
    CommentApiService.prototype.postComment = function (threadId, messageId, formData, notify) {
        var options = new http_1.HttpParams({ encoder: new http_custom_converter_1.CustomHttpEncoder() })
            .set('notify', notify);
        return this.http.post(app_constants_1.LOGBOOK_API + "/api/comment/" + threadId + "/message/" + messageId, formData, { params: options });
    };
    CommentApiService.prototype.editComment = function (threadId, messageId, commentId, formData) {
        return this.http.post(app_constants_1.LOGBOOK_API + '/api/comment/' + threadId + '/message/' + messageId
            + '/edit/' + commentId, formData);
    };
    CommentApiService.prototype.removeComment = function (threadId, messageId, commentId) {
        return this.http.delete(app_constants_1.LOGBOOK_API + '/api/comment/' + threadId + '/message/' + messageId + '/remove/' + commentId);
    };
    return CommentApiService;
}());
exports.CommentApiService = CommentApiService;
