"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = ["[_nghost-%COMP%]{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;-ms-flex-align:start;-moz-align-items:flex-start;align-items:flex-start}[_nghost-%COMP%]   #body[_ngcontent-%COMP%]{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;-ms-flex-align:start;-moz-align-items:flex-start;align-items:flex-start;width:100vw;min-height:100vh}[_nghost-%COMP%]   .main[_ngcontent-%COMP%]{max-width:calc(100% - 160px);width:calc(100% - 160px);min-width:calc(100% - 160px);transition:all .3s ease-out;-moz-transition:.3s ease-out;-o-transition:.3s ease-out;-webkit-transition:.3s ease-out;-moz-flex-grow:1;-ms-flex-positive:1;flex-grow:1;overflow-x:hidden}.withCollapseMenu[_nghost-%COMP%]   .main[_ngcontent-%COMP%]{max-width:calc(100% - 50px);width:calc(100% - 50px);min-width:calc(100% - 50px)}@media (min-width:1600px){[_nghost-%COMP%]   .main[_ngcontent-%COMP%]{max-width:calc(100% - 230px);width:calc(100% - 230px);min-width:calc(100% - 230px)}.withCollapseMenu[_nghost-%COMP%]   .main[_ngcontent-%COMP%]{max-width:calc(100% - 60px);width:calc(100% - 60px);min-width:calc(100% - 60px)}}@media (max-width:768px){[_nghost-%COMP%]   .main[_ngcontent-%COMP%]{max-width:100%;width:100%;min-width:100%}.withCollapseMenu[_nghost-%COMP%]   .main[_ngcontent-%COMP%]{max-width:100%;width:100%;min-width:100%}}@media print{[_nghost-%COMP%]   .main[_ngcontent-%COMP%]{max-width:100%;width:100%;min-width:100%}.withCollapseMenu[_nghost-%COMP%]   .main[_ngcontent-%COMP%]{max-width:100%;width:100%;min-width:100%}}"];
exports.styles = styles;
