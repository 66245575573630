
import {of as observableOf, Observable} from 'rxjs';

import {map, refCount, publishReplay, catchError} from 'rxjs/operators';
import {HttpClient} from "@angular/common/http";
import {Injectable} from '@angular/core';
import {ContactActiveFilters, ContactFilter} from "../model/contact-filter.model";
import {Contact} from "../model/contact.model";
import {Pageable} from "../../model/pageable";
import {LogbookConfig} from "../../../../../../app/core/shared/config/logbook-config";

@Injectable()
export class ContactService {
    private resourceUrl;
    private contactCache: Map<string, Observable<Contact>> = new Map();
    private contactFiltersCache: Observable<ContactFilter[]>;

    constructor(private http: HttpClient, private config: LogbookConfig) {
        this.resourceUrl = config.LOGBOOK_API + '/api/contact'
    }

    find(id: string): Observable<any> {
        if (!this.contactCache.has(id)) {
            this.contactCache.set(id, this.http.get(`${this.resourceUrl}/${id}`).pipe(
                map((res) => new Contact(res)),
                catchError(() => observableOf(null)),
                publishReplay(1),
                refCount()
            ));
        }
        return this.contactCache.get(id);
    }

    findAllContactForUser(pageable: Pageable, query?: any, filter?: ContactActiveFilters): Observable<any> {

        let params = pageable.toOptions();
        const filters = [];

        if (query && query.search) {
            filters.push({key: 'search', value: query.search});
        }

        if (filter) {
            filters.push({key: filter.category, value: filter.item})
        }

        params = params.set('filters', JSON.stringify(filters));

        return this.http.get(`${this.resourceUrl}`, {params}).pipe(
            map((res: any) => res.content),
            map((contacts) => contacts.map((contact) => new Contact(contact)))
        );
    }

    getContactFilters(forced?: boolean): Observable<any> {
        if (forced || !this.contactFiltersCache) {
            this.contactFiltersCache = this.http.get(`${this.resourceUrl}/filters`).pipe(
                map((contactFilters: any) =>
                    contactFilters.map((contactFilter) => new ContactFilter(contactFilter))
                ),
                catchError((err) => {
                    console.warn(err);
                    return observableOf(null)
                }),
                publishReplay(1),
                refCount()
            );
        }

        return this.contactFiltersCache;
    }

    save(contact) {
        return this.http.post(`${this.resourceUrl}/`, contact).pipe(
            map((contactFromApi) => new Contact(contactFromApi)));
    }

    saveMultiple(contactList: Contact[]) {
        return this.http.post(`${this.resourceUrl}/multiple`, contactList).pipe(
            map((contactsFromApi: any) => contactsFromApi.map(
                (contactFromApi) => new Contact(contactFromApi))
            ));
    }

    load(pieceJointe) {
        return this.http.post(`${this.resourceUrl}/load`, pieceJointe).pipe(
            map((res: any) => res.map((contact) => new Contact(contact))));
    }

    remove(id: string): Observable<any> {
        return this.http.delete(`${this.resourceUrl}/${id}`)
    }

    removeMultiple(ids: string[]): Observable<any> {
        return this.http.delete(`${this.resourceUrl}/${ids}/multiple`)
    }

}
