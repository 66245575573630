import {FlightTag} from "./flight-tag.model";

export class FlightTagWithThreads {
    flightTag: FlightTag;
    threadIds: Array<String>;

    static sort(flightTagWithThreadsList: Array<FlightTagWithThreads>) {
        const output = flightTagWithThreadsList.sort((ft1, ft2) => {

            if (ft2.getKey() > ft1.getKey()) {
                return -1;
            }
            if (ft2.getKey() < ft1.getKey()) {
                return 1;
            }

            return 0;
        })

        return output;
    }

    static fromJson(flightTagWithThreadsAsString): FlightTagWithThreads {
        const flightTagWithThreads = new FlightTagWithThreads();
        if (flightTagWithThreadsAsString.flightTagAsString) {
            flightTagWithThreads.flightTag = FlightTag.createFlightTagFromString(flightTagWithThreadsAsString.flightTagAsString);
        } else if (flightTagWithThreadsAsString.flightTag) {
            flightTagWithThreads.flightTag = Object.assign(new FlightTag(), flightTagWithThreadsAsString.flightTag);
        } else {
            flightTagWithThreads.flightTag = new FlightTag();
        }

        if (flightTagWithThreadsAsString.threads) {
            flightTagWithThreads.threadIds = flightTagWithThreadsAsString.threads.map((thread) => thread.threadIdAsBase64);
        } else if (flightTagWithThreadsAsString.threadIds) {
            flightTagWithThreads.threadIds = flightTagWithThreadsAsString.threadIds;
        } else {
            flightTagWithThreads.threadIds = [];
        }
        return flightTagWithThreads;
    }

    constructor() {
    }

    private getKey(): string {
        return this.flightTag.airlineCode.concat(this.flightTag.flightNumber);
    }

}
