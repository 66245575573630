"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("@angular/common/http");
var app_constants_1 = require("@logbook/app.constants");
var LogbookNotification_1 = require("@logbook/notification-center/shared/model/LogbookNotification");
var operators_1 = require("rxjs/operators");
var NotificationApiService = /** @class */ (function () {
    function NotificationApiService(http) {
        this.http = http;
        this.API_ROOT = app_constants_1.LOGBOOK_API + "/api/notifications";
    }
    NotificationApiService.prototype.getAll = function (pageable) {
        return this.http.get("" + this.API_ROOT, { params: pageable.toOptions() }).pipe(operators_1.map(function (res) { return res.content; }), operators_1.map(function (notifications) { return notifications.map(function (notification) {
            return Object.assign(new LogbookNotification_1.LogbookNotification(), notification);
        }); }));
    };
    NotificationApiService.prototype.markAsRead = function (notificationId, fromDevice) {
        return this.http.put(this.API_ROOT + "/read/" + notificationId + (fromDevice ? "?from=" + fromDevice : ''), {});
    };
    NotificationApiService.prototype.markAllAsRead = function () {
        return this.http.put(this.API_ROOT + "/readAll", {});
    };
    NotificationApiService.prototype.getUnSeen = function () {
        return this.http.get(this.API_ROOT + "/unseen");
    };
    NotificationApiService.prototype.get = function (id) {
        return this.http.get(this.API_ROOT + "/" + id, {});
    };
    NotificationApiService.prototype.remove = function (id) {
        return this.http.delete(this.API_ROOT + "/delete/" + id, {});
    };
    return NotificationApiService;
}());
exports.NotificationApiService = NotificationApiService;
