"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var ng_select_types_1 = require("./ng-select.types");
var NgSelectModule = /** @class */ (function () {
    function NgSelectModule() {
    }
    NgSelectModule.forRoot = function (config) {
        return provideModule(config);
    };
    NgSelectModule.forChild = function (config) {
        return provideModule(config);
    };
    return NgSelectModule;
}());
exports.NgSelectModule = NgSelectModule;
function provideModule(config) {
    return {
        ngModule: NgSelectModule,
        providers: [
            { provide: ng_select_types_1.NgSelectConfig, useValue: config }
        ]
    };
}
exports.provideModule = provideModule;
