"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var DlnAlertComponent = /** @class */ (function () {
    function DlnAlertComponent() {
        this.type = 'error';
    }
    DlnAlertComponent.prototype.ngOnInit = function () {
    };
    DlnAlertComponent.prototype.ngOnDestroy = function () {
    };
    return DlnAlertComponent;
}());
exports.DlnAlertComponent = DlnAlertComponent;
