"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var router_1 = require("@angular/router");
var rxjs_1 = require("rxjs");
var operators_1 = require("rxjs/operators");
var ngx_webstorage_1 = require("ngx-webstorage");
var i0 = require("@angular/core");
var i1 = require("ngx-webstorage");
var AccessFromCuteStationGuard = /** @class */ (function () {
    function AccessFromCuteStationGuard($sessionStorage) {
        this.$sessionStorage = $sessionStorage;
    }
    AccessFromCuteStationGuard.prototype.resolve = function (route, state) {
        var _this = this;
        return rxjs_1.of(route.queryParams).pipe(operators_1.map(function (params) { return params ? params.cuteStation : null; }), operators_1.tap(function (res) {
            if (!!res) {
                _this.$sessionStorage.store("disableAccessToFileSystem", true);
            }
        }));
    };
    AccessFromCuteStationGuard.ngInjectableDef = i0.defineInjectable({ factory: function AccessFromCuteStationGuard_Factory() { return new AccessFromCuteStationGuard(i0.inject(i1.SessionStorageService)); }, token: AccessFromCuteStationGuard, providedIn: "root" });
    return AccessFromCuteStationGuard;
}());
exports.AccessFromCuteStationGuard = AccessFromCuteStationGuard;
