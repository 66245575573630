import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
    selector: 'dln-send-buttons',
    templateUrl: './send-buttons.component.html',
    styleUrls: ['./send-buttons.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SendButtonsComponent {
    @Input() sendLabel: string;
    @Input() sendClass: string;
    @Input() isValid: boolean;
    @Input() isLoading = false;
    @Input() isLoadingLabel = "Sending";
    @Output() sendAction = new EventEmitter();
    @Output() cancelAction = new EventEmitter();

    public sendClick() {
        this.sendAction.emit(null)
    }

    public cancelClick() {
        this.cancelAction.emit(null)
    }

}
