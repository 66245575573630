"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var shared_1 = require("@logbook/shared");
var LogFilter = /** @class */ (function () {
    function LogFilter(name, key, operation, value) {
        this.name = name;
        this.key = key;
        this.operation = operation;
        this.value = value;
    }
    LogFilter.prototype.isPresentInLog = function (log) {
        switch (this.operation) {
            case "equals":
                return this.isValueIsEqual(log);
            case "not equals":
                return !this.isValueIsEqual(log);
            case "more than":
                // DATE => return always true
                return true;
            case "less than":
                // DATE => return always true
                return true;
        }
        return false;
    };
    LogFilter.prototype.isValueIsEqual = function (log) {
        var _this = this;
        var keySplit = this.key.split(".");
        if (this.key.indexOf("metadata") !== -1) {
            var mapKey = keySplit[keySplit.length - 1];
            return log.messages.metadata.get(mapKey) === this.value.toString();
        }
        else {
            if (this.key.indexOf('tags') !== -1) {
                return log.messages.tags.some(function (tag) { return _this.value; });
            }
            else {
                if (this.key === 'tagVol') {
                    var flightTag = shared_1.FlightTag.createFlightTag(log.tagVol);
                    return flightTag.toStringWithoutDate() === this.value;
                }
                else {
                    var logValue_1 = log;
                    keySplit.forEach(function (key) { return logValue_1 = logValue_1[key]; });
                    return logValue_1 === this.value;
                }
            }
        }
    };
    return LogFilter;
}());
exports.LogFilter = LogFilter;
