"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var forms_1 = require("@angular/forms");
var country_calling_code_1 = require("../../../model/country-calling-code");
var libphonenumber_js_1 = require("libphonenumber-js");
var index_1 = require("rxjs/index");
var operators_1 = require("rxjs/operators");
var DlnInputPhoneComponent = /** @class */ (function () {
    function DlnInputPhoneComponent() {
        this.maskPhonePattern = [/[1-9]/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
        this.maskPhone = { mask: this.maskPhonePattern, guide: false };
        this.countryCallingCodesList = country_calling_code_1.CountryCallingCode.getCountryCodes();
        this.destroy$ = new index_1.Subject();
        this.propagateChange = function (_) { };
        this.propagateTouched = function () { };
    }
    DlnInputPhoneComponent.prototype.ngOnInit = function () {
        var _this = this;
        var required = forms_1.Validators.required, minLength = forms_1.Validators.minLength, maxLength = forms_1.Validators.maxLength;
        this.phoneForm = new forms_1.FormGroup({
            phoneNumber: new forms_1.FormControl(null, [
                required,
                minLength(4),
                maxLength(20)
            ]),
            countryCode: new forms_1.FormControl(country_calling_code_1.CountryCallingCode.getDefault(), [
                required,
                minLength(1),
                maxLength(3)
            ]),
            flatPhoneNumber: new forms_1.FormControl(null)
        });
        this.phoneForm.valueChanges.pipe(operators_1.takeUntil(this.destroy$))
            .subscribe(function (form) {
            if (form.phoneNumber && form.countryCode && form.countryCode.callingCode) {
                var phoneNumber = "+" + form.countryCode.callingCode + form.phoneNumber;
                _this.propagateChange(phoneNumber);
                _this.propagateTouched();
            }
            else if (form.flatPhoneNumber) {
                _this.propagateChange(form.flatPhoneNumber);
                _this.propagateTouched();
            }
        });
    };
    DlnInputPhoneComponent.prototype.writeValue = function (val) {
        if (val) {
            var parsedNumber = libphonenumber_js_1.parseNumber(val);
            if (parsedNumber.country) {
                this.phoneForm.get('countryCode').setValue(country_calling_code_1.CountryCallingCode.findByCode(parsedNumber.country));
                this.phoneForm.get('phoneNumber').setValue(parsedNumber.phone);
            }
            else {
                this.phoneForm.get('flatPhoneNumber').setValue(val);
            }
        }
    };
    DlnInputPhoneComponent.prototype.registerOnChange = function (fn) {
        this.propagateChange = fn;
    };
    DlnInputPhoneComponent.prototype.registerOnTouched = function (fn) {
        this.propagateTouched = fn;
    };
    DlnInputPhoneComponent.prototype.validate = function (c) {
        if (this.phoneForm.get('phoneNumber').value) {
            var phoneNumber = "+" + this.phoneForm.get('countryCode').value.callingCode + this.phoneForm.get('phoneNumber').value;
            var parsedNumber = libphonenumber_js_1.parseNumber(phoneNumber, { extended: true });
            var parseError = false;
            if (!parsedNumber.valid) {
                parseError = true;
            }
            return (!parseError) ? null : {
                phoneInvalid: true
            };
        }
        else {
            return null;
        }
    };
    DlnInputPhoneComponent.prototype.ngOnDestroy = function () {
        this.destroy$.next();
    };
    return DlnInputPhoneComponent;
}());
exports.DlnInputPhoneComponent = DlnInputPhoneComponent;
