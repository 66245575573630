"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = ["[_nghost-%COMP%]   mat-dialog-content[_ngcontent-%COMP%]{max-height:80vh}[_nghost-%COMP%]   mat-dialog-content[_ngcontent-%COMP%]   img[_ngcontent-%COMP%]{margin:auto;display:block;max-width:80vw;max-height:80vh}[_nghost-%COMP%]   .buttons[_ngcontent-%COMP%]{width:100%;display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;-ms-flex-align:center;-moz-align-items:center;align-items:center;-ms-flex-pack:center;-moz-justify-content:center;-ms-justify-content:center;justify-content:center;-ms-flex-wrap:nowrap;flex-wrap:nowrap}[_nghost-%COMP%]   .buttons[_ngcontent-%COMP%]   button.cancel[_ngcontent-%COMP%]{width:170px;height:40px;cursor:pointer;margin-top:30px;color:#5491e8;background:#fff;margin-right:20px;font-size:16px;border:1px solid #5491e8;border-radius:5px}"];
exports.styles = styles;
