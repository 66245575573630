"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var loader_module_1 = require("mixpanel-browser/src/loader-module");
var logbook_config_1 = require("../shared/config/logbook-config");
var i0 = require("@angular/core");
var i1 = require("../shared/config/logbook-config");
var MixpanelService = /** @class */ (function () {
    function MixpanelService(config, ngZone) {
        this.config = config;
        this.ngZone = ngZone;
        this.disable = false;
        this.mixpanel = loader_module_1.default;
        this.mixpanel.init(this.config.MIXPANEL_TOKEN);
    }
    MixpanelService.prototype.track = function (eventName, metadata) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                if (!this.disable) {
                    try {
                        this.ngZone.runOutsideAngular(function () {
                            _this.mixpanel.track(eventName.action, metadata);
                            ga("send", "event", eventName.category, eventName.action);
                        });
                    }
                    catch (Exception) {
                        console.warn(Exception);
                    }
                }
                return [2 /*return*/];
            });
        });
    };
    MixpanelService.prototype.identify = function (userId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                try {
                    this.ngZone.runOutsideAngular(function () {
                        _this.mixpanel.identify(userId);
                    });
                }
                catch (Exception) {
                    console.warn(Exception);
                }
                return [2 /*return*/];
            });
        });
    };
    MixpanelService.prototype.alias = function (userId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                try {
                    this.ngZone.runOutsideAngular(function () {
                        _this.mixpanel.alias(userId);
                    });
                }
                catch (Exception) {
                    console.warn(Exception);
                }
                return [2 /*return*/];
            });
        });
    };
    MixpanelService.prototype.reset = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                try {
                    this.ngZone.runOutsideAngular(function () {
                        _this.mixpanel.reset();
                    });
                }
                catch (Exception) {
                    console.warn(Exception);
                }
                return [2 /*return*/];
            });
        });
    };
    MixpanelService.prototype.setDisable = function (disable) {
        this.disable = disable;
    };
    MixpanelService.prototype.setUser = function (user, other) {
        if (other === void 0) { other = {}; }
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                try {
                    this.ngZone.runOutsideAngular(function () {
                        var mixpanelUser = {
                            name: user.displayUserName(),
                            title: user.title,
                            "$email": user.userId,
                            firstname: user.firstName,
                            lastname: user.lastName,
                            company: user.getCompanyName(),
                            "company type": user.typeCompany,
                            "company position": user.positionInCompany,
                            location: user.localisation,
                            phone: user.phones ? user.phones[0] : null
                        };
                        _this.mixpanel.people.set(Object.assign(mixpanelUser, other));
                    });
                }
                catch (Exception) {
                    console.warn(Exception);
                }
                return [2 /*return*/];
            });
        });
    };
    MixpanelService.ngInjectableDef = i0.defineInjectable({ factory: function MixpanelService_Factory() { return new MixpanelService(i0.inject(i1.LogbookConfig), i0.inject(i0.NgZone)); }, token: MixpanelService, providedIn: "root" });
    return MixpanelService;
}());
exports.MixpanelService = MixpanelService;
