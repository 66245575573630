"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var piece_jointe_model_1 = require("../../../attach-file/model/piece-jointe.model");
var AttachmentViewerComponent = /** @class */ (function () {
    function AttachmentViewerComponent(changeDetector) {
        this.changeDetector = changeDetector;
        this.displayFile = false;
        this.onlyDownload = false;
        this.hideDownload = false;
        this.pdfIsVisible = false;
        this.nbPage = 1;
        this.currentPage = 1;
    }
    AttachmentViewerComponent.prototype.ngOnInit = function () {
        this.pieceJointe.isRead = !this.pieceJointe.isPdf();
    };
    AttachmentViewerComponent.prototype.openLink = function () {
        window.open(this.pieceJointe.uri, '_blank');
    };
    AttachmentViewerComponent.prototype.callBackPdf = function (pdf) {
        this.nbPage = pdf.numPages;
        if (this.nbPage === 1) {
            this.pieceJointe.isRead = true;
        }
        this.changeDetector.markForCheck();
    };
    AttachmentViewerComponent.prototype.changePage = function (direction) {
        if (direction === 'prev') {
            this.currentPage = this.currentPage - 1;
        }
        else {
            this.currentPage = this.currentPage + 1;
        }
        if (this.currentPage === this.nbPage) {
            this.pieceJointe.isRead = true;
        }
        this.changeDetector.markForCheck();
    };
    return AttachmentViewerComponent;
}());
exports.AttachmentViewerComponent = AttachmentViewerComponent;
