
import {throwError as observableThrowError, Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {HttpClient} from "@angular/common/http";
import {Injectable} from '@angular/core';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';
import {LogbookConfig} from "../../shared/config/logbook-config";

@Injectable({
    providedIn: 'root',
})
export class AuthServerProvider {
    constructor(private http: HttpClient, private config: LogbookConfig,
                private $localStorage: LocalStorageService,
                private $sessionStorage: SessionStorageService) {
    }

    getToken() {
        return this.$sessionStorage.retrieve('signAsAuthenticationToken')
            || this.$localStorage.retrieve('authenticationToken') || this.$sessionStorage.retrieve('authenticationToken');
    }

    login(credentials): Observable<any> {

        const data = {
            username: credentials.username,
            password: credentials.password,
            rememberMe: credentials.rememberMe
        };
        return this.http.post(`${this.config.LOGBOOK_API}/api/authenticate`, data, {observe: "response"}).pipe(
            map(authenticateSuccess.bind(this)),
            catchError((err) => {
                return observableThrowError(err)
            })
        );

        function authenticateSuccess(resp) {
            const bearerToken = resp.headers.get('Authorization');
            if (bearerToken && bearerToken.slice(0, 7) === 'Bearer ') {
                const jwt = bearerToken.slice(7, bearerToken.length);
                this.storeAuthenticationToken(jwt, credentials.rememberMe);
                return jwt;
            }
        }
    }

    signAsUser(email, prevUrl): Observable<any> {
        return this.http.post(`${this.config.LOGBOOK_API}/api/sign-as-user`, email, {observe: "response"}).pipe(
            map(authenticateSuccess.bind(this)),
            catchError((err) => {
                return observableThrowError(err)
            })
        );

        function authenticateSuccess(resp) {
            const bearerToken = resp.headers.get('Authorization');
            if (bearerToken && bearerToken.slice(0, 7) === 'Bearer ') {
                const jwt = bearerToken.slice(7, bearerToken.length);
                this.storeSignAsAuthenticationToken(jwt, prevUrl);
                return jwt;
            }
        }
    }

    loginWithToken(jwt, rememberMe) {
        if (jwt) {
            this.storeAuthenticationToken(jwt, rememberMe);
            return Promise.resolve(jwt);
        } else {
            return Promise.reject('auth-jwt-services Promise reject'); // Put appropriate error message here
        }
    }

    storeAuthenticationToken(jwt, rememberMe) {
        if (rememberMe) {
            this.$localStorage.store('authenticationToken', jwt);
        } else {
            this.$sessionStorage.store('authenticationToken', jwt);
        }
    }

    storeSignAsAuthenticationToken(jwt, prevUrl) {
        this.$sessionStorage.store('signAsAuthenticationToken', jwt);
        this.$sessionStorage.store('signAsPrevUrl', prevUrl);
    }

    logout(): Observable<any> {
        return new Observable((observer) => {
            this.$localStorage.clear('authenticationToken');
            this.$sessionStorage.clear('authenticationToken');
            this.$sessionStorage.clear('signAsAuthenticationToken');
            observer.complete();
        });
    }

    logoutSignAs(): Observable<any> {
        return new Observable((observer) => {
            const prevUrl = this.$sessionStorage.retrieve('signAsPrevUrl');
            this.$sessionStorage.clear('signAsAuthenticationToken');
            this.$sessionStorage.clear('signAsPrevUrl');
            observer.next(prevUrl);
        });
    }
}
