import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {NotificationApiService} from "@logbook/notification-center/shared/service/notification.api.service";
import {NotificationCenterService} from "@logbook/notification-center/shared/service/notification.center.service";
import {LogbookSharedModule} from "@logbook/shared";
import { NotificationCenterComponent } from './page/notification-center/notification-center.component';
import { NotificationComponent } from './components/notification/notification.component';
import { NotificationCategoryComponent } from './components/notification-category/notification-category.component';
import {RouterModule} from "@angular/router";
import {MatMenuModule} from "@angular/material";
import {NotificationRouteAccessService} from "@logbook/notification-center/shared/guards/notification-route-access-service";
import {notificationRoute} from "@logbook/notification-center/notification.route";

@NgModule({
    imports: [
        CommonModule,
        LogbookSharedModule,
        RouterModule.forChild(notificationRoute),
        MatMenuModule
    ],
    declarations: [NotificationCenterComponent, NotificationComponent, NotificationCategoryComponent],
    providers: [
        NotificationApiService,
        NotificationCenterService,
        NotificationRouteAccessService
    ],
    exports: [
        NotificationCenterComponent
    ]
})
export class NotificationCenterModule { }
