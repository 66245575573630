"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var color_model_1 = require("../../model/color.model");
var message_form_model_1 = require("../../model/message-form.model");
var window_service_1 = require("../../service/window.service");
var ng_select_types_1 = require("../ng-select/ng-select.types");
var attachment_service_1 = require("./attach-file/service/attachment.service");
var piece_jointe_model_1 = require("./attach-file/model/piece-jointe.model");
var operators_1 = require("rxjs/operators");
var TextEditorComponent = /** @class */ (function () {
    function TextEditorComponent($window, attachmentService) {
        this.$window = $window;
        this.attachmentService = attachmentService;
        this.isFocused = false;
        this.withAttachFile = true;
        this.multipleAttachFile = false;
        this.placeholder = 'Write here...';
        this.messageFormChange = new core_1.EventEmitter();
        this.colors = color_model_1.Color.getDefaultColors();
        this.activeCommands = [];
        this.colorSelected = this.colors[0];
        if (this.messageForm == null) {
            this.messageForm = new message_form_model_1.MessageForm();
        }
    }
    TextEditorComponent.prototype.ngOnChanges = function (changes) {
        this.textAreaInit();
    };
    TextEditorComponent.prototype.ngAfterViewInit = function () {
        this.textAreaInit();
    };
    TextEditorComponent.prototype.onFileChange = function (event) {
        var target = event.target || event.srcElement;
        this.uploadFiles(target.files);
        this.inputFile.nativeElement.value = "";
    };
    TextEditorComponent.prototype.uploadFiles = function (files) {
        var _this = this;
        if (this.multipleAttachFile) {
            Array.from(files).forEach(function (file) {
                _this.attachmentService.postFile(_this.messageForm, file).pipe(operators_1.first()).subscribe(function () {
                    _this.messageFormChange.emit(_this.messageForm);
                });
            });
        }
        else {
            if (files && files[0]) {
                this.messageForm.files.push(files[0]);
                this.messageFormChange.emit(this.messageForm);
            }
        }
    };
    TextEditorComponent.prototype.removeFile = function (indexToRemove) {
        this.messageForm.files = this.messageForm.files.filter(function (val, index) {
            return index !== indexToRemove;
        });
        this.messageFormChange.emit(this.messageForm);
    };
    TextEditorComponent.prototype.fileIsPJ = function (file) {
        return file instanceof piece_jointe_model_1.PieceJointe;
    };
    TextEditorComponent.prototype.selectColor = function (color) {
        this.colorSelected = color;
        this.execCommand('forecolor', 'FONT', color.value);
        this.onBodyChange();
    };
    TextEditorComponent.prototype.execCommand = function (command, commandTagName, commandValue) {
        this.textArea.nativeElement.focus();
        document.execCommand(command, false, commandValue);
        if (this.isCommandIsSelected(commandTagName)) {
            this.activeCommands = this.activeCommands.filter(function (cmd) { return cmd !== commandTagName; });
        }
        else {
            this.activeCommands.push(commandTagName);
        }
        this.onBodyChange();
    };
    TextEditorComponent.prototype.isCommandIsSelected = function (commandTagName) {
        return this.activeCommands.some(function (tagName) { return tagName === commandTagName; });
    };
    TextEditorComponent.prototype.onBodyChange = function () {
        this.messageForm.body = this.textArea.nativeElement.innerHTML;
        this.messageFormChange.emit(this.messageForm);
    };
    TextEditorComponent.prototype.updateSelectedCommand = function ($event) {
        this.onBodyChange();
        var eventType = $event.which;
        if (eventType !== ng_select_types_1.KeyCode.Backspace && eventType !== ng_select_types_1.KeyCode.Enter) {
            var node_1 = this.getSelectedNode();
            this.activeCommands = [];
            while (node_1.parentNode && node_1.parentNode["id"] !== this.textArea.nativeElement.id) {
                var tagName = node_1.parentNode["tagName"];
                if (tagName === "FONT") {
                    this.colorSelected = this.colors
                        .find(function (color) {
                        return color["value"].toUpperCase() === node_1.parentNode["color"].toUpperCase();
                    });
                }
                this.activeCommands.push(tagName);
                node_1 = node_1.parentNode;
            }
            if (!this.isCommandIsSelected("FONT")) {
                this.colorSelected = this.colors[0];
            }
        }
    };
    TextEditorComponent.prototype.onPaste = function (ev) {
        var _this = this;
        if (ev.clipboardData && ev.clipboardData.items) {
            for (var i = 0; i < ev.clipboardData.items.length; i++) {
                var item = ev.clipboardData.items[i];
                if (item.kind === 'file') {
                    ev.preventDefault();
                    var asFile = item.getAsFile() ? [item.getAsFile()] : null;
                    if (asFile) {
                        this.uploadFiles(asFile);
                    }
                }
            }
        }
        else {
            setTimeout(function () { return _this.onBodyChange(); });
        }
    };
    TextEditorComponent.prototype.getSelectedNode = function () {
        if (this.$window.nativeWindow.getSelection) {
            var selection = this.$window.nativeWindow.getSelection();
            if (selection.rangeCount > 0) {
                return selection.getRangeAt(0).startContainer;
            }
        }
    };
    TextEditorComponent.prototype.textAreaInit = function () {
        this.textArea.nativeElement.innerHTML = this.messageForm.body;
    };
    return TextEditorComponent;
}());
exports.TextEditorComponent = TextEditorComponent;
