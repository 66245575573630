
import {map} from 'rxjs/operators';
import {HttpClient} from "@angular/common/http";
import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {ChangeLogAck} from "../model/change-log-ack.model";
import {ChangeLog} from "../model/change-log.model";
import {Pageable} from "@logbook/shared";
import {LogbookConfig} from "@logbook/core/shared/config/logbook-config";

@Injectable()
export class ChangeLogService {
    private resourceUrl;

    constructor(private http: HttpClient, private config: LogbookConfig) {
        this.resourceUrl = config.LOGBOOK_API + '/api/change-log'
    }

    findAllForAdmin(pageable: Pageable): Observable<any> {
        return this.http.get(`${this.resourceUrl}/admin`, {params: pageable.toOptions()}).pipe(
            map((res: any) => res.content),
            map((changeLogs) => changeLogs.map((changeLog) => new ChangeLog(changeLog)))
        );
    }

    findAll(pageable: Pageable): Observable<any> {
        return this.http.get(`${this.resourceUrl}`, {params: pageable.toOptions()}).pipe(
            map((res: any) => res.content),
            map((changeLogs) => changeLogs.map((changeLog) => new ChangeLog(changeLog)))
        );
    }

    getAck(): Observable<any> {
        return this.http.get(`${this.resourceUrl}/ack`).pipe(
            map((ack) => {
                if (ack === null) {
                    return null;
                } else {
                    return new ChangeLogAck(ack)
                }
            }));
    }

    countUnread(): Observable<any> {
        return this.http.get(`${this.resourceUrl}/countUnread`).pipe(
            map((count) => count));
    }

    save(changeLog: ChangeLog) {
        return this.http.post(`${this.resourceUrl}/`, changeLog).pipe(
            map((changeLogSaved) => new ChangeLog(changeLogSaved)));
    }
}
