"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var ng2_stompjs_1 = require("@stomp/ng2-stompjs");
var rx_stomp_1 = require("@stomp/rx-stomp");
var rxjs_1 = require("rxjs");
var operators_1 = require("rxjs/operators");
var SockJS = require("sockjs-client");
var auth_jwt_service_1 = require("../../../../../../app/core/auth/services/auth-jwt.service");
var logbook_config_1 = require("../../../../../../app/core/shared/config/logbook-config");
var stomp_config_1 = require("../util/stomp-config");
var i0 = require("@angular/core");
var i1 = require("../../../../../../app/core/auth/services/auth-jwt.service");
var i2 = require("@stomp/ng2-stompjs");
var i3 = require("../../../../../../app/core/shared/config/logbook-config");
var LogEventType;
(function (LogEventType) {
    LogEventType["MessagePublie"] = "MessagePublie";
    LogEventType["FilInformationsCree"] = "FilInformationsCree";
    LogEventType["FilInformationsVisiblePourParticipant"] = "FilInformationsVisiblePourParticipant";
    LogEventType["CommentairePublie"] = "CommentairePublie";
    LogEventType["CommentaireSupprime"] = "CommentaireSupprime";
    LogEventType["CommentaireEdite"] = "CommentaireEdite";
    LogEventType["ConsigneLue"] = "ConsigneLue";
    LogEventType["MessageSupprimeOuFerme"] = "MessageSupprimeOuFerme";
    LogEventType["MessageEdite"] = "MessageEdite";
    LogEventType["LogUsefulChanged"] = "LogUsefulChanged";
})(LogEventType = exports.LogEventType || (exports.LogEventType = {}));
var InstructionEventType;
(function (InstructionEventType) {
    InstructionEventType["InstructionSentToUser"] = "InstructionSentToUser";
    InstructionEventType["InstructionSigned"] = "InstructionSigned";
    InstructionEventType["InstructionRead"] = "InstructionRead";
    InstructionEventType["InstructionArchived"] = "InstructionArchived";
    InstructionEventType["InstructionCommented"] = "InstructionCommented";
})(InstructionEventType = exports.InstructionEventType || (exports.InstructionEventType = {}));
var NotificationEventType;
(function (NotificationEventType) {
    NotificationEventType["NotificationCreated"] = "NotificationCreated";
})(NotificationEventType = exports.NotificationEventType || (exports.NotificationEventType = {}));
var NotificationWsService = /** @class */ (function () {
    function NotificationWsService(authServerProvider, _stompService, config, ngZone) {
        this.authServerProvider = authServerProvider;
        this._stompService = _stompService;
        this.config = config;
        this.ngZone = ngZone;
        this.connection = this.createConnection();
        this.createListener();
        this.subscribed = false;
    }
    NotificationWsService.prototype.initStomp = function () {
        var _this = this;
        if (this.subscribed) {
            return;
        }
        this.ngZone.runOutsideAngular(function () {
            _this.stompConfigInit = stomp_config_1.stompConfig;
            var authToken = _this.authServerProvider.getToken();
            _this.stompConfigInit.webSocketFactory = function () { return new SockJS(_this.config.LOGBOOK_API + "/websocket/logbook?access_token=" + authToken); };
            _this._stompService.configure(_this.stompConfigInit);
            _this._stompService.activate();
            _this.state_subscription = _this._stompService.connectionState$.pipe(operators_1.map(function (state) { return ng2_stompjs_1.StompState[state]; }))
                .subscribe(function (status) {
                console.log("Stomp connection status: " + status);
            });
            _this.message_subscription = _this._stompService.watch('/user/exchange/logbook.direct/notif');
            _this.stomp_subscription = _this.message_subscription.pipe(operators_1.map(function (message) {
                return message;
            })).subscribe(function (msg_body) {
                var message = JSON.parse(msg_body.body);
                var eventType = msg_body.headers.eventType.split(".");
                var subscriber = _this.listener.get(eventType[eventType.length - 1]);
                if (subscriber) {
                    _this.ngZone.run(function () {
                        subscriber.next(message);
                    });
                }
            });
            _this.subscribed = true;
            _this.autoReconnect = rxjs_1.interval(60000)
                .subscribe(function () {
                if (_this._stompService.connectionState$.getValue() === rx_stomp_1.RxStompState.CLOSED && !_this._stompService.connected()) {
                    _this.disconnect();
                    _this.initStomp();
                }
            });
        });
    };
    NotificationWsService.prototype.disconnect = function () {
        var _this = this;
        this.ngZone.run(function () {
            if (!_this.subscribed) {
                return;
            }
            if (_this.stomp_subscription) {
                _this.stomp_subscription.unsubscribe();
            }
            if (_this.autoReconnect) {
                _this.autoReconnect.unsubscribe();
            }
            if (_this.state_subscription) {
                _this.state_subscription.unsubscribe();
            }
            _this.stomp_subscription = null;
            _this.message_subscription = null;
            _this.subscribed = false;
        });
    };
    NotificationWsService.prototype.onEventPublished = function (event) {
        return this.listener.get(event);
    };
    NotificationWsService.prototype.createListener = function () {
        var _this = this;
        this.listener = new Map();
        Object.keys(LogEventType).forEach(function (event) {
            _this.setListenerForEvent(event);
        });
        Object.keys(InstructionEventType).forEach(function (event) {
            _this.setListenerForEvent(event);
        });
        Object.keys(NotificationEventType).forEach(function (event) {
            _this.setListenerForEvent(event);
        });
    };
    NotificationWsService.prototype.setListenerForEvent = function (event) {
        this.listener.set(event, new rxjs_1.Subject());
    };
    NotificationWsService.prototype.createConnection = function () {
        var _this = this;
        return new Promise(function (resolve, reject) { return _this.connectedPromise = resolve; });
    };
    NotificationWsService.ngInjectableDef = i0.defineInjectable({ factory: function NotificationWsService_Factory() { return new NotificationWsService(i0.inject(i1.AuthServerProvider), i0.inject(i2.RxStompService), i0.inject(i3.LogbookConfig), i0.inject(i0.NgZone)); }, token: NotificationWsService, providedIn: "root" });
    return NotificationWsService;
}());
exports.NotificationWsService = NotificationWsService;
