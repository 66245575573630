import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormControl} from "@angular/forms";
import {MatDialogRef} from "@angular/material";
import { Pageable } from "../../model/pageable";
import { Page} from "../../model/page";
import {debounceTime, distinctUntilChanged, first, takeUntil} from 'rxjs/operators';
import {CommunityService} from "../../../../../../app/core/auth/services/community.service";
import {Team} from "../../../../../../app/core/shared/models/team.model";
import {Subject} from "rxjs/index";

@Component({
    selector: 'dln-team-select-dialog',
    templateUrl: './team-select-dialog.component.html',
    styleUrls: ['./team-select-dialog.component.scss']
})
export class TeamSelectDialogComponent implements OnInit, OnDestroy {

    public teamPage = new Page({
        page: 0,
        size: 20,
        totalElements: 0
    });
    public teamSearchControl = new FormControl();
    public teamNameControl = new FormControl();
    public openNewTeamForm = false;
    public pageable = new Pageable(0, 20, "name");
    private destroy$ = new Subject<void>();

    constructor(public dialogRef: MatDialogRef<TeamSelectDialogComponent>, private teamService: CommunityService) {}

    ngOnInit() {
        this.fetchTeams(this.pageable);
        this.teamSearchControl.valueChanges.pipe(
            debounceTime(250),
            distinctUntilChanged(),
            takeUntil(this.destroy$)
        ).subscribe((search) => {
            this.pageable.page = 0;
            this.fetchTeams(this.pageable, search)
        })
    }

    fetchTeams(pageRequest: Pageable, search?: string) {
        this.teamPage.isLoading = true;
        this.teamService.searchCommunity(pageRequest, search).pipe(first())
            .subscribe((teams: any) => {
                this.teamPage = teams;
            })
    }

    setPage(pageInfo) {
        this.pageable.page = pageInfo.offset;
        this.fetchTeams(this.pageable);
    }

    selectTeam(team) {
        if (team.selected[0]) {
            this.dialogRef.close(team.selected[0].id);
        }
    }

    createNewTeam() {
        this.openNewTeamForm = true
    }

    saveTeam() {
        const teamToCreate = new Team();
        teamToCreate.name = this.teamNameControl.value;
        this.teamService.save(teamToCreate).pipe(first()).subscribe(
            (res) => {
                this.dialogRef.close(res.id);
            }
        );
    }

    ngOnDestroy() {
        this.destroy$.next();
    }
}
