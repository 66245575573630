"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ng_intercom_1 = require("ng-intercom");
var rxjs_1 = require("rxjs");
var sign_as_user_access_model_1 = require("../../shared/models/sign-as-user-access.model");
var visitor_access_model_1 = require("../../shared/models/visitor-access.model");
var account_service_1 = require("./account.service");
var i0 = require("@angular/core");
var i1 = require("./account.service");
var i2 = require("ng-intercom");
var Principal = /** @class */ (function () {
    function Principal(account, intercomService) {
        this.account = account;
        this.intercomService = intercomService;
        this.authenticated = false;
        this.authenticationState = new rxjs_1.Subject();
    }
    Principal.prototype.authenticate = function (identity) {
        this.userIdentity = identity;
        this.authenticated = identity !== null;
        this.authenticationState.next(this.userIdentity);
    };
    Principal.prototype.hasAnyAuthority = function (authorities) {
        return Promise.resolve(this.hasAnyAuthorityDirect(authorities));
    };
    Principal.prototype.hasAnyAuthorityDirect = function (authorities) {
        if (!this.authenticated || !this.userIdentity || !this.userIdentity.authorities) {
            return false;
        }
        var _loop_1 = function (i) {
            if (this_1.userIdentity.authorities
                .filter(function (userAuthorities) { return userAuthorities === authorities[i]; })
                .length
                !== 0) {
                return { value: true };
            }
        };
        var this_1 = this;
        for (var i = 0; i < authorities.length; i++) {
            var state_1 = _loop_1(i);
            if (typeof state_1 === "object")
                return state_1.value;
        }
        return false;
    };
    Principal.prototype.hasFeatureEnable = function (features) {
        return Promise.resolve(this.hasFeatureEnableDirect(features));
    };
    Principal.prototype.hasFeatureEnableDirect = function (features) {
        if (!this.authenticated || !this.userIdentity || !this.userIdentity.featuresEnabled) {
            return false;
        }
        var _loop_2 = function (i) {
            if (this_2.userIdentity.featuresEnabled
                .filter(function (feature) { return feature === features[i]; })
                .length
                !== 0) {
                return { value: true };
            }
        };
        var this_2 = this;
        for (var i = 0; i < features.length; i++) {
            var state_2 = _loop_2(i);
            if (typeof state_2 === "object")
                return state_2.value;
        }
        return false;
    };
    Principal.prototype.identity = function (force) {
        var _this = this;
        if (force === true) {
            this.userIdentity = undefined;
        }
        // check and see if we have retrieved the userIdentity data from the server.
        // if we have, reuse it by immediately resolving
        if (this.userIdentity) {
            return Promise.resolve(this.userIdentity);
        }
        // retrieve the userIdentity data from the server, update the identity object, and then resolve.
        return this.account.get().toPromise().then(function (account) {
            if (account) {
                _this.userIdentity = account;
                _this.authenticated = true;
                if (!(account instanceof visitor_access_model_1.VisitorGuest || account instanceof sign_as_user_access_model_1.SignAsUserAccess)) {
                    _this.intercomService.boot({
                        email: account.email,
                        name: account.displayUserName(),
                        user_id: account.userId,
                        phone: account.phones,
                        companies: [{
                                name: account.getCompanyName(),
                                company_id: account.getCompanyName()
                            }]
                    });
                }
            }
            else {
                _this.userIdentity = null;
                _this.authenticated = false;
            }
            _this.authenticationState.next(_this.userIdentity);
            return _this.userIdentity;
        }).catch(function (err) {
            _this.userIdentity = null;
            _this.authenticated = false;
            _this.authenticationState.next(_this.userIdentity);
            return null;
        });
    };
    Principal.prototype.identityDirect = function () {
        return this.userIdentity;
    };
    Principal.prototype.isAuthenticated = function () {
        return this.authenticated;
    };
    Principal.prototype.isIdentityResolved = function () {
        return this.userIdentity !== undefined;
    };
    Principal.prototype.getAuthenticationState = function () {
        return this.authenticationState.asObservable();
    };
    Principal.ngInjectableDef = i0.defineInjectable({ factory: function Principal_Factory() { return new Principal(i0.inject(i1.AccountService), i0.inject(i2.Intercom)); }, token: Principal, providedIn: "root" });
    return Principal;
}());
exports.Principal = Principal;
