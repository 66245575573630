import {ModuleWithProviders, NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import {MaxPipe} from "./pipes/max.pipe";
import {SimpleNotificationComponent} from "./components/notification/simple-notification.component";
import {SimpleNotificationsComponent} from "./components/simple-notifications/simple-notifications.component";
import {NotificationsService} from "./services/notifications.service";
import {PushNotificationsService} from "./services/push-notifications.service";

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        SimpleNotificationsComponent,
        SimpleNotificationComponent,
        MaxPipe
    ],
    exports: [SimpleNotificationsComponent]
})
export class NotificationModule {

}
