import { environment } from '../environments/environment';
export const LOGBOOK_API = environment.LOGBOOK_API;
export const LOGBOOK_WEB_URL = environment.LOGBOOK_WEB_URL;
export const MIXPANEL_TOKEN = environment.MIXPANEL_TOKEN;
export const GOOGLE_ANALYTICS_TOKEN = environment.GOOGLE_ANALYTICS_TOKEN;
export const INTERCOM_TOKEN = environment.INTERCOM_TOKEN;
export const CGU_VERSION = environment.CGU_VERSION;
export const TERMS_USER_AGREEMENT_URL = environment.TERMS_USER_AGREEMENT_URL;
export const TERMS_PRIVACY_POLICY_URL = environment.TERMS_PRIVACY_POLICY_URL;
export const VERSION = `${environment.VERSION}`;
export const VAPID_PUBLIC_KEY = environment.VAPID_PUBLIC_KEY;
