"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var app_constants_1 = require("../../app.constants");
var AuthInterceptor = /** @class */ (function () {
    function AuthInterceptor(localStorage, sessionStorage) {
        this.localStorage = localStorage;
        this.sessionStorage = sessionStorage;
    }
    AuthInterceptor.prototype.intercept = function (request, next) {
        if (!request || !request.url || (/^http/.test(request.url) && !(app_constants_1.LOGBOOK_API && request.url.startsWith(app_constants_1.LOGBOOK_API)))) {
            return next.handle(request);
        }
        var token = this.sessionStorage.retrieve('signAsAuthenticationToken')
            || this.localStorage.retrieve('authenticationToken')
            || this.sessionStorage.retrieve('authenticationToken');
        if (!!token) {
            request = request.clone({
                setHeaders: {
                    Authorization: 'Bearer ' + token
                }
            });
        }
        return next.handle(request);
    };
    return AuthInterceptor;
}());
exports.AuthInterceptor = AuthInterceptor;
