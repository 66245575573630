"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var NotificationCategory;
(function (NotificationCategory) {
    NotificationCategory["LOG"] = "LOG";
    NotificationCategory["INSTRUCTION"] = "INSTRUCTION";
})(NotificationCategory = exports.NotificationCategory || (exports.NotificationCategory = {}));
var LogbookNotification = /** @class */ (function () {
    function LogbookNotification() {
        this.isFirstNotificationForDay = false;
    }
    LogbookNotification.prototype.getLink = function () {
        switch (this.category) {
            case NotificationCategory.INSTRUCTION:
                return this.getInstructionLink();
            case NotificationCategory.LOG:
                return this.getLogLink();
        }
    };
    LogbookNotification.prototype.getInstructionLink = function () {
        return ['/instructions', this.entityId["instructionId"].toString()];
    };
    LogbookNotification.prototype.getLogLink = function () {
        var period = this.entityId["date"];
        var idLog = this.entityId["groupedLog"];
        if (period && idLog) {
            return ['/logs', period, idLog];
        }
        else {
            return ['/logs'];
        }
    };
    return LogbookNotification;
}());
exports.LogbookNotification = LogbookNotification;
