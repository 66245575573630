<h2 mat-dialog-title>Position and size your photo</h2>
<mat-dialog-content>
    <ly-cropping #imgCropping [config]="cropConfig">
        <span class="select-image-label">Select image</span>
    </ly-cropping>

    <!--<ly-cropping #imgCropping format="png" [config]="cropConfig"></ly-cropping>-->
    <!--<input [(ngModel)]="imgCropping.img" placeholder="Img">-->
</mat-dialog-content>
<mat-dialog-actions>
    <div class="tools-bar">
        <div class="zoom minus" (click)="imgCropping.zoomOut()">-</div>
        <div class="zoom" (click)="imgCropping.zoomIn()">+</div>
        <!--<button (click)="imgCropping.center()" ly-button>center</button>-->
        <!--<button (click)="imgCropping.fit()" ly-button>fit</button>-->
        <!--<button (click)="imgCropping.fitToScreen()" ly-button>fit to screen</button>-->
    </div>

    <div class="actions">
        <div class="cancel" (click)="close()">Cancel</div>
        <div class="save" [class.disable]="isLoading" (click)="save()">Apply</div>
    </div>


</mat-dialog-actions>
