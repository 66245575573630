"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./mobile-navbar-button.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("@angular/common");
var i3 = require("./mobile-navbar-button.component");
var i4 = require("../../../../logs-view/shared/services/view-management.service");
var styles_MobileNavbarButtonComponent = [i0.styles];
var RenderType_MobileNavbarButtonComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MobileNavbarButtonComponent, data: {} });
exports.RenderType_MobileNavbarButtonComponent = RenderType_MobileNavbarButtonComponent;
function View_MobileNavbarButtonComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleNav() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(2, { "mobile-navbar-button": 0, "toggled": 1 }), (_l()(), i1.ɵeld(3, 0, null, null, 0, "i", [["class", "icon-menu"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 2, 0, true, _co.isToggled); _ck(_v, 1, 0, currVal_0); }, null); }
exports.View_MobileNavbarButtonComponent_0 = View_MobileNavbarButtonComponent_0;
function View_MobileNavbarButtonComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mobile-navbar-button", [], null, null, null, View_MobileNavbarButtonComponent_0, RenderType_MobileNavbarButtonComponent)), i1.ɵdid(1, 245760, null, 0, i3.MobileNavbarButtonComponent, [i4.LogsViewManagementService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_MobileNavbarButtonComponent_Host_0 = View_MobileNavbarButtonComponent_Host_0;
var MobileNavbarButtonComponentNgFactory = i1.ɵccf("mobile-navbar-button", i3.MobileNavbarButtonComponent, View_MobileNavbarButtonComponent_Host_0, {}, {}, []);
exports.MobileNavbarButtonComponentNgFactory = MobileNavbarButtonComponentNgFactory;
