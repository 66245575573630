"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var ng_intercom_1 = require("ng-intercom");
var material_1 = require("@angular/material");
var app_constants_1 = require("../../../app.constants");
var mixpanel_service_1 = require("../../tracking/mixpanel.service");
var principal_service_1 = require("../../auth/services/principal.service");
var account_service_1 = require("../../auth/services/account.service");
var mixpanel_event_enum_1 = require("../../tracking/mixpanel-event.enum");
var sign_as_user_access_model_1 = require("../../shared/models/sign-as-user-access.model");
var operators_1 = require("rxjs/operators");
var OnBoardingModalComponent = /** @class */ (function () {
    function OnBoardingModalComponent(dialogRef, mixpanelService, router, principal, intercomService, accountService) {
        this.dialogRef = dialogRef;
        this.mixpanelService = mixpanelService;
        this.router = router;
        this.principal = principal;
        this.intercomService = intercomService;
        this.accountService = accountService;
        this.signAsUser = false;
        this.iRead = false;
        this.userAgreementUrl = app_constants_1.TERMS_USER_AGREEMENT_URL;
        this.privacyPolicyUrl = app_constants_1.TERMS_PRIVACY_POLICY_URL;
    }
    OnBoardingModalComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.dialogRef.disableClose = true;
        this.principal.identity().then(function (user) {
            _this.currentUser = user;
            _this.signAsUser = user instanceof sign_as_user_access_model_1.SignAsUserAccess;
            if (_this.signAsUser) {
                _this.dialogRef.disableClose = false;
            }
        });
    };
    OnBoardingModalComponent.prototype.cancel = function () {
        this.dialogRef.close();
    };
    OnBoardingModalComponent.prototype.showIntercom = function () {
        this.mixpanelService.track(mixpanel_event_enum_1.MixpanelEvent.OPEN_LIVE_CHAT);
        this.intercomService.show();
    };
    OnBoardingModalComponent.prototype.showAccount = function () {
        this.router.navigate(["account"]);
        this.dialogRef.close();
    };
    OnBoardingModalComponent.prototype.readCGU = function () {
        var _this = this;
        if (this.iRead) {
            this.accountService.readCGU().pipe(operators_1.first()).subscribe(function () {
                _this.mixpanelService.track(mixpanel_event_enum_1.MixpanelEvent.ACCEPT_TERMS_AND_CONDITIONS);
                _this.dialogRef.close();
            });
        }
    };
    return OnBoardingModalComponent;
}());
exports.OnBoardingModalComponent = OnBoardingModalComponent;
