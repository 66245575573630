<div class="header-mobile">
    <span class="label">
        Menu
    </span>
    <i class="dln-icon-close" (click)="closeMenu()"></i>
</div>

<span class="logo">
    <img [src]="logo" width="35px" height="35px" alt="logo-team"> <span>{{companyName}}</span>
</span>
<span class="collapse-link" (click)="collapseMenu()">
    <span>Collapse menu</span>
    <i *ngIf="!collapse" class="dln-icon-left"></i>
    <i *ngIf="collapse" class="dln-icon-right"></i>
</span>

<a class="logs" (click)="closeMenu()" [routerLink]="['logs']" routerLinkActive="active" *dlnHasAnyAuthority="['ROLE_USER']" title="Logs"
   dlnTooltip tooltipText="Logs" placement="right" [tooltipActive]="collapse" tooltipClass="tooltip-menu">
    <i class="dln-icon-logs"></i>
    <span>Logs</span>
</a>

<a (click)="closeMenu()" [routerLink]="['instructions']" routerLinkActive="active" title="Instructions"
   dlnTooltip tooltipText="Instructions" placement="right" [tooltipActive]="collapse" tooltipClass="tooltip-menu">
    <i class="dln-icon-instructions"></i>
    <span>Instructions</span>
</a>

<a (click)="closeMenu()" [routerLink]="['flight-report']" routerLinkActive="active" *dlnHasFeatureEnable="['FLIGHT_REPORT']" title="Turnarounds"
   dlnTooltip tooltipText="Turnarounds" placement="right" [tooltipActive]="collapse" tooltipClass="tooltip-menu">
    <i class="dln-icon-flight"></i>
    <span>Turnarounds</span>
</a>

<a (click)="closeMenu()" [routerLink]="['dashboard']" routerLinkActive="active" *dlnHasFeatureEnable="['DASHBOARD_KPIS']" title="Dashboard"
   dlnTooltip tooltipText="Dashboard" placement="right" [tooltipActive]="collapse" tooltipClass="tooltip-menu">
    <i class="icon-diamond"></i>
    <span>Dashboard</span>
</a>

<a (click)="closeMenu()" [routerLink]="['contact']" routerLinkActive="active" *dlnHasFeatureEnable="['CONTACT_MANAGEMENT']" title="Contacts"
   dlnTooltip tooltipText="Contacts" placement="right" [tooltipActive]="collapse" tooltipClass="tooltip-menu">
    <i class="dln-icon-contact"></i>
    <span>Contacts</span>
</a>

<a (click)="closeMenu()" [routerLink]="['management']" routerLinkActive="active" *dlnHasFeatureEnable="['ADMIN_INTERFACE']" title="Settings"
   dlnTooltip tooltipText="Settings" placement="right" [tooltipActive]="collapse" tooltipClass="tooltip-menu">
    <i class="dln-icon-mon_logbook"></i>
    <span>Settings</span>
</a>

<a (click)="closeMenu()" [routerLink]="['help']" routerLinkActive="active" title="Help"
   dlnTooltip tooltipText="Help" placement="right" [tooltipActive]="collapse" tooltipClass="tooltip-menu">
    <i class="dln-icon-help"></i>
    <span>Help</span>
</a>

<a (click)="openNotification()" [class.active]="notificationIsOpen" dlnTooltip tooltipText="Notifications"  title="Notifications"
   placement="right" [tooltipActive]="collapse" tooltipClass="tooltip-menu" *dlnHasFeatureEnable="['NOTIFICATION_CENTER']">
    <span class="notif" *ngIf="notificationCenter.unreadNotifications && notificationCenter.unreadNotifications.length">
        {{notificationCenter.unreadNotifications.length}}
    </span>
    <i class="dln-icon-notifications"></i>
    <span>Notifications</span>
</a>

<a (click)="logout()" *showItBootstrap="['xs','sm']">
    <i class="dln-icon-log-out"></i>
    <span>Log-out</span>
</a>

<a (click)="closeMenu()" [routerLink]="['/']" *dlnHasAnyAuthority="['ANONYMOUS']"
   dlnTooltip tooltipText="Log-in" placement="right" [tooltipActive]="collapse" tooltipClass="tooltip-menu">
    <i class="icon-enter"></i>
    <span>Log-in</span>
</a>

<div class="notification-center-container" [class.open]="notificationIsOpen" [class.menuCollapse]="collapse">
    <notification-center *ngIf="notificationIsOpen" (closePanel)="openNotification()"></notification-center>
</div>

<a class="terms" (click)="closeMenu()" [routerLink]="['/terms-and-conditions']">
    Terms <br *ngIf="collapse"/> & <br *ngIf="collapse"/> Conditions
</a>
