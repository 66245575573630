import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {PieceJointe} from "../../../attach-file/model/piece-jointe.model";

@Component({
    selector: 'dln-attachment-viewer',
    templateUrl: './attachment-viewer.component.html',
    styleUrls: ['./attachment-viewer.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AttachmentViewerComponent implements OnInit {
    @Input() pieceJointe: PieceJointe;
    @Input() displayFile = false;
    @Input() onlyDownload = false;
    @Input() hideDownload = false;

    pdfIsVisible = false;
    nbPage = 1;
    currentPage = 1;

    constructor(private changeDetector: ChangeDetectorRef) {
    }

    ngOnInit(): void {
        this.pieceJointe.isRead = !this.pieceJointe.isPdf();
    }

    openLink() {
        window.open(this.pieceJointe.uri, '_blank')
    }

    callBackPdf(pdf) {
        this.nbPage = pdf.numPages;

        if (this.nbPage === 1) {
            this.pieceJointe.isRead = true;
        }
        this.changeDetector.markForCheck();
    }

    changePage(direction) {
        if (direction === 'prev') {
            this.currentPage = this.currentPage - 1;
        } else {
            this.currentPage = this.currentPage + 1;
        }
        if (this.currentPage === this.nbPage) {
            this.pieceJointe.isRead = true;
        }
        this.changeDetector.markForCheck();
    }
}
