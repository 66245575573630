import {ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, OnInit, Output} from '@angular/core';
import {DeviceDetectorService} from "ngx-device-detector";

@Component({
    selector: 'dln-button',
    templateUrl: './button.component.html',
    styleUrls: ['./button.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ButtonComponent implements OnInit {
    @Input() label: string;
    @Input() color: "blue" | "red" | "green" | "purple" | "black" | "white" | "gray" | 'transparent' = 'blue';
    @Input() border: "blue" | "red" | "green" | "purple" | "black" | "white" | "gray";
    @Input() isDisabled = false;
    @Input() type = 'button';
    @Input() idBtn = '';
    @Input() icon: string;
    @Input() iconPosition: "left" | "right" | "center" = "right";
    @Input() isLoading = false;
    @Input() isLoadingLabel = "Loading";
    @Input() height;

    @Output() onClick = new EventEmitter();

    @HostBinding('style.height') heightStyle: string;

    constructor(private device: DeviceDetectorService) {

    }

    ngOnInit() {
        if (!this.height) {
            if (this.device.isMobile()) {
                this.heightStyle = "50px";
            } else {
                this.heightStyle = "40px";
            }
        } else {
            this.heightStyle = this.height;
        }
    }

    public clickEmit() {
        this.onClick.emit(null)
    }
}
