import {ChangeDetectorRef, Directive, Input, OnDestroy, TemplateRef, ViewContainerRef} from '@angular/core';
import {Principal} from '../../auth/services/principal.service';

@Directive({
    selector: '[dlnHasFeatureEnable]'
})
export class HasFeatureEnableDirective implements OnDestroy {

    private features: string[];
    private elseTemplateRef: TemplateRef<any>;
    private _subscriptionState;

    constructor(private principal: Principal, private templateRef: TemplateRef<any>, private viewContainerRef: ViewContainerRef, private changeDetecor: ChangeDetectorRef) {
    }

    @Input()
    set dlnHasFeatureEnable(value: string | string[]) {
        this.features = typeof value === 'string' ? [<string> value] : <string[]> value;
        this.updateView();
        // Get notified each time authentication state changes.
        this._subscriptionState = this.principal.getAuthenticationState().subscribe((identity) => {
            this.updateView()
        });
    }

    @Input()
    set dlnHasFeatureEnableElse(templateRef: TemplateRef<any>) {
        this.elseTemplateRef = templateRef;
    }

    private updateView(): void {
        this.principal.hasFeatureEnable(this.features).then((result) => {
            this.viewContainerRef.clear();
            if (result) {
                this.viewContainerRef.createEmbeddedView(this.templateRef);
            } else {
                if (this.elseTemplateRef) {
                    this.viewContainerRef.createEmbeddedView(this.elseTemplateRef);
                }
            }
            this.changeDetecor.markForCheck();
        });
    }

    ngOnDestroy(): void {
        if (this._subscriptionState) {
            this._subscriptionState.unsubscribe();
        }
    }
}
