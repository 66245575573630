export interface Icons {
    alert: string;
    error: string;
    info: string;
    warn: string;
    success: string;
}

export const defaultIcons: Icons = {
    alert: `<i class="icon-alarm"></i>`,
    error: `<i class="icon-cross-circle"></i>`,
    info: `<i class="icon-question-circle"></i>`,
    success: `<i class="icon-checkmark-circle"></i>`,
    warn: `<i class="icon-warning"></i>`
};
