import {Component, Input, OnInit} from '@angular/core';
import {NotificationCategory} from "@logbook/notification-center/shared/model/LogbookNotification";

@Component({
    selector: 'notification-category',
    templateUrl: './notification-category.component.html',
    styleUrls: ['./notification-category.component.scss']
})
export class NotificationCategoryComponent implements OnInit {

    @Input() category: NotificationCategory;

    constructor() { }

    ngOnInit() {}

    getIcon() {
        switch (this.category) {
            case NotificationCategory.INSTRUCTION:
                return "dln-icon-instructions";

            case NotificationCategory.LOG:
                return "dln-icon-logs";

            default:
                return "dln-icon-notifications"
        }
    }

    getLabel() {
        switch (this.category) {
            case NotificationCategory.LOG:
                return "Logs";

            case NotificationCategory.INSTRUCTION:
                return "Instructions";

            default:
                return "Others"
        }
    }

}
