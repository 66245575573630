"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var RecipientType;
(function (RecipientType) {
    RecipientType["EMAIL"] = "EMAIL";
    RecipientType["USER"] = "USER";
    RecipientType["TEAM"] = "TEAM";
    RecipientType["CONTACT"] = "CONTACT";
    RecipientType["GROUP"] = "GROUP";
})(RecipientType = exports.RecipientType || (exports.RecipientType = {}));
var Recipient = /** @class */ (function () {
    function Recipient() {
    }
    Recipient.prototype.isUser = function () {
        return this.type === RecipientType.USER;
    };
    Recipient.prototype.isTeam = function () {
        return this.type === RecipientType.TEAM;
    };
    Recipient.prototype.isContact = function () {
        return this.type === RecipientType.CONTACT || this.type === RecipientType.EMAIL;
    };
    Recipient.prototype.isGroup = function () {
        return this.type === RecipientType.GROUP;
    };
    return Recipient;
}());
exports.Recipient = Recipient;
