"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var LogbookNotification_1 = require("@logbook/notification-center/shared/model/LogbookNotification");
var NotificationComponent = /** @class */ (function () {
    function NotificationComponent() {
        this.read = new core_1.EventEmitter();
        this.delete = new core_1.EventEmitter();
        this.view = new core_1.EventEmitter();
        this.dropdownIsVisible = false;
    }
    NotificationComponent.prototype.viewNotification = function () {
        this.view.emit();
    };
    NotificationComponent.prototype.deleteNotification = function () {
        this.delete.emit();
        this.dropdownIsVisible = false;
    };
    NotificationComponent.prototype.readNotification = function () {
        this.read.emit();
        this.dropdownIsVisible = false;
    };
    return NotificationComponent;
}());
exports.NotificationComponent = NotificationComponent;
