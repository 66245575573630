import {ApplicationRef, Component, EventEmitter, NgZone, OnDestroy, OnInit} from '@angular/core';
import {SwUpdate} from "@angular/service-worker";
import {interval as observableInterval, Subscription} from 'rxjs';
import {filter} from "rxjs/operators";
import {Error} from "tslint/lib/error";
import {LOGBOOK_WEB_URL} from "../../../app.constants";
import {first} from 'rxjs/internal/operators/first';
import {MixpanelService} from "../../tracking/mixpanel.service";
import {MixpanelEvent} from "../../tracking/mixpanel-event.enum";
import {MatSnackBar} from "@angular/material";

@Component({
    selector: 'dln-check-updates',
    templateUrl: './check-updates.component.html',
    styleUrls: [
        'check-updates.scss'
    ]
})
export class CheckUpdatesComponent implements OnInit, OnDestroy {

    private INTERVAL_IN_MINUTES = 2;

    private previousHtml: string;
    private subscriptionOnUpdate: Subscription;
    private updateAvailableSub: Subscription;
    private updateActivatedSub: Subscription;

    constructor(private mixpanelService: MixpanelService, private updates: SwUpdate, private ngZone: NgZone, private appRef: ApplicationRef, public snackBar: MatSnackBar) {
    }

    ngOnInit() {

        const intervalInMilliseconds = this.INTERVAL_IN_MINUTES * 60000;
        this.checkUpdatesInCode();

        this.appRef.isStable.pipe(filter((res) => res === true), first())
            .subscribe((res) => {
                console.log("App is stable :", res);
                if (res && !this.subscriptionOnUpdate) {
                    this.subscriptionOnUpdate = observableInterval(intervalInMilliseconds)
                        .subscribe(() => {
                                this.checkUpdatesInCode()
                            },
                            (error) => {
                                console.error(error);
                                return;
                            }
                        );
                }
            });

        if (this.updates.isEnabled) {
            console.log("sw is enabled");
            this.updateAvailableSub = this.updates.available.subscribe((event) => {
                console.log('current version is', event.current);
                console.log('available version is', event.available);
                this.showUpdateSnackBar()
            });
            this.updateActivatedSub = this.updates.activated.subscribe((event) => {
                console.log('old version was', event.previous);
                console.log('new version is', event.current);
            });
        }
    }

    ngOnDestroy() {
        if (this.subscriptionOnUpdate) {
            this.subscriptionOnUpdate.unsubscribe();
        }
        if (this.updateAvailableSub) {
            this.updateAvailableSub.unsubscribe();
        }
        if (this.updateActivatedSub) {
            this.updateActivatedSub.unsubscribe();
        }
    }

    checkUpdatesInCode() {
        if (this.updates.isEnabled) {
            this.updates.checkForUpdate();
        } else {
            const headers = new Headers();
            headers.append('pragma', 'no-cache');
            headers.append('cache-control', 'no-cache');
            return fetch(`${LOGBOOK_WEB_URL}/index.html`, {
                method: 'GET',
                headers
            })
                .then((response) => {
                    if (response.status !== 200) {
                        throw new Error('offline');
                    }
                    return response.text();
                })
                .then((html) => {
                    if (!this.previousHtml) {
                        this.previousHtml = html;
                        return;
                    }
                    if (this.previousHtml !== html) {
                        this.previousHtml = html;
                        this.showUpdateSnackBar()
                        this.mixpanelService.track(MixpanelEvent.NEED_UPDATE);
                    }
                })
                .catch((err) => { /* do nothing */
                });
        }
    }

    reload() {
        if (this.updates.isEnabled) {
            this.updates.activateUpdate()
                .then(() => document.location.reload())
                .catch(() => document.location.reload());
        } else {
            document.location.reload()
        }
    }

    private showUpdateSnackBar() {
        this.snackBar.open("Logbook update available", "Reload now to get the latest version", {
            duration: null,
            horizontalPosition: "center",
            panelClass: "red-snackbar"
        }).onAction().pipe(first()).subscribe(() => {
            this.reload();
        });
    }
}
