"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Team = /** @class */ (function () {
    function Team() {
        this.membres = [];
        this.profile = [];
        this.tags = [];
        this.invitations = [];
        this.importParams = [];
    }
    Team.prototype.isUserMember = function (userId) {
        console.log(this.membres);
        return this.membres && this.membres.some(function (user) { return user.userId === userId; });
    };
    Team.prototype.convertToJson = function () {
        return {
            id: this.id,
            name: this.name,
            profile: this.profile,
            tags: this.tags,
            membres: this.membres.map(function (membre) {
                return {
                    id: membre.userId
                };
            })
        };
    };
    return Team;
}());
exports.Team = Team;
