
import {map} from 'rxjs/operators';
import {HttpClient, HttpParams} from "@angular/common/http";
import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {ContactGroup} from "../model/contact-filter.model";
import {Contact} from "../model/contact.model";
import {LogbookConfig} from "../../../../../../app/core/shared/config/logbook-config";
import {Team} from "../../../../../../app/core/shared/models/team.model";
import {Recipient, RecipientType} from "../model/recipient.model";

@Injectable()
export class RecipientsService {
    private resourceUrl;

    constructor(private http: HttpClient, private config: LogbookConfig) {
        this.resourceUrl = config.LOGBOOK_API + '/api/recipients';
    }

    findRecipients(value?: string): Observable<any> {
        let options: HttpParams = new HttpParams();

        if (value && value.length) {
            options = options.set('search', value);
        }

        return this.http.get(`${this.resourceUrl}`, {params: options}).pipe(
            map((res: any) => {
                const contacts = res.CONTACT.map((contact) => new Contact(contact));
                const teams = res.TEAM.map((team) => Object.assign(new Team(), team));
                const groups = res.GROUP.map((group) => new ContactGroup(group, contacts));
                return {
                    "TEAM": teams,
                    "CONTACT": contacts,
                    "GROUP": groups
                }
            }))
    }

    convertToConsigneRecipient(item) {
        const userRecipient = new Recipient();
        userRecipient.type = item.type;
        switch (item.type) {
            case RecipientType.GROUP:
                userRecipient.recipientId = item.params.label;
                break;
            case RecipientType.TEAM:
                userRecipient.recipientId = item.params.id;
                break;
            case RecipientType.USER:
                userRecipient.recipientId = item.params.userInfo.userId;
                break;
            case RecipientType.CONTACT:
                userRecipient.recipientId = item.params.email;
                userRecipient.contactId = item.params.id;
                break;
            case RecipientType.EMAIL:
                userRecipient.recipientId = item.id;
                break;
        }
        return userRecipient
    }
}
