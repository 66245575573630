import {ChangeDetectorRef, Directive, Input, OnDestroy, TemplateRef, ViewContainerRef} from '@angular/core';
import {Principal} from '../../auth/services/principal.service';
import {Subscription} from "rxjs";

/**
 * @whatItDoes Conditionally includes an HTML element if current user has any
 * of the authorities passed as the `expression`.
 *
 * @howToUse
 * ```
 *     <some-element *dlnHasAnyAuthority="'ROLE_ADMIN'">...</some-element>
 *
 *     <some-element *dlnHasAnyAuthority="['ROLE_ADMIN', 'ROLE_USER']">...</some-element>
 * ```
 */
@Directive({
    selector: '[dlnHasAnyAuthority]'
})
export class HasAnyAuthorityDirective implements OnDestroy {

    private authorities: string[];
    private elseTemplateRef: any;
    private subscription: Subscription;

    constructor(private principal: Principal, private templateRef: TemplateRef<any>, private viewContainerRef: ViewContainerRef, private changeDetecor: ChangeDetectorRef) {
    }

    @Input()
    set dlnHasAnyAuthority(value: string | string[]) {
        this.authorities = typeof value === 'string' ? [<string> value] : <string[]> value;
        this.updateView();
        // Get notified each time authentication state changes.
        this.subscription = this.principal.getAuthenticationState().subscribe((identity) => this.updateView());
    }

    @Input()
    set dlnHasAnyAuthorityElse(templateRef: TemplateRef<any> | null) {
        this.elseTemplateRef = templateRef;
        this.updateView();
    }

    private updateView(): void {
        this.principal.hasAnyAuthority(this.authorities).then((result) => {
            this.viewContainerRef.clear();
            if (result) {
                this.viewContainerRef.createEmbeddedView(this.templateRef);
            } else if (this.elseTemplateRef) {
                this.viewContainerRef.createEmbeddedView(this.elseTemplateRef);
            }
            this.changeDetecor.markForCheck();
        });
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }
}
