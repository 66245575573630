import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'sort',
    pure: false
})
export class SortPipe implements PipeTransform {
    transform(ary: any, fn: Function = (a, b) => a > b ? 1 : -1): any {
        return ary.sort(fn)
    }

    // EXAMPLE |sort:byname
    //
    // sortByUserName(a: Contact, b: Contact) {
    //     if (a.displayUserName().toLowerCase() > b.displayUserName().toLowerCase()) {
    //         return 1;
    //     } else if (a.displayUserName() === b.displayUserName()) {
    //         return 0;
    //     } else {
    //         return -1;
    //     }
    // }
}
