"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var UserOrderPipe = /** @class */ (function () {
    function UserOrderPipe() {
    }
    UserOrderPipe.prototype.transform = function (users) {
        if (users) {
            return users.sort(function (a, b) {
                var fieldA = a.lastName || a.positionInCompany || a.userId;
                var fieldB = b.lastName || b.positionInCompany || b.userId;
                if (fieldA.toUpperCase() < fieldB.toUpperCase()) {
                    return -1;
                }
                else if (fieldA.toUpperCase() > fieldB.toUpperCase()) {
                    return 1;
                }
                else {
                    return 0;
                }
            });
        }
        else {
            return users;
        }
    };
    return UserOrderPipe;
}());
exports.UserOrderPipe = UserOrderPipe;
