"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var router_1 = require("@angular/router");
var principal_service_1 = require("../../auth/services/principal.service");
var state_storage_service_1 = require("../../auth/services/state-storage.service");
var FeatureEnabledAccessService = /** @class */ (function () {
    function FeatureEnabledAccessService(router, principal, stateStorageService) {
        this.router = router;
        this.principal = principal;
        this.stateStorageService = stateStorageService;
    }
    FeatureEnabledAccessService.prototype.canActivate = function (route, state) {
        var featureEnabled = route.data['featureEnabled'];
        if (!featureEnabled || featureEnabled.length === 0) {
            return true;
        }
        return this.checkLogin(featureEnabled, state.url);
    };
    FeatureEnabledAccessService.prototype.checkLogin = function (featureEnabled, url) {
        var _this = this;
        var principal = this.principal;
        return Promise.resolve(principal.identity().then(function (account) {
            if (account && principal.hasFeatureEnableDirect(featureEnabled)) {
                return true;
            }
            _this.stateStorageService.storeUrl(url);
            _this.router.navigate(['']);
            return false;
        }));
    };
    return FeatureEnabledAccessService;
}());
exports.FeatureEnabledAccessService = FeatureEnabledAccessService;
