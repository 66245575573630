"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var user_service_1 = require("../../../user/service/user.service");
var operators_1 = require("rxjs/operators");
var UserStatusComponent = /** @class */ (function () {
    function UserStatusComponent(userService) {
        this.userService = userService;
    }
    UserStatusComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.userLogged$ = this.userService.subscribeUsersLogged()
            .pipe(operators_1.map(function (users) { return users.some(function (u) { return u === _this.userId; }); }));
    };
    return UserStatusComponent;
}());
exports.UserStatusComponent = UserStatusComponent;
