"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var operators_1 = require("rxjs/operators");
var core_1 = require("@angular/core");
var shared_1 = require("@logbook/shared");
var rxjs_1 = require("rxjs");
var filters_service_1 = require("./filters.service");
var logs_api_service_1 = require("./logs.api.service");
var principal_service_1 = require("../../../../core/auth/services/principal.service");
var models_1 = require("@logbook/logs-view/shared/models");
var i0 = require("@angular/core");
var i1 = require("../../../../../logbook/shared/src/lib/user/service/user.service");
var i2 = require("./filters.service");
var i3 = require("./logs.api.service");
var i4 = require("../../../../../logbook/shared/src/lib/notification/services/notifications.service");
var i5 = require("../../../../core/auth/services/principal.service");
var i6 = require("../../../../../logbook/shared/src/lib/user/service/notification-ws.service");
var LogbookLogsService = /** @class */ (function () {
    function LogbookLogsService(userService, filtersService, logsApiService, notificationsService, principal, notificationWsService) {
        var _this = this;
        this.userService = userService;
        this.filtersService = filtersService;
        this.logsApiService = logsApiService;
        this.notificationsService = notificationsService;
        this.principal = principal;
        this.notificationWsService = notificationWsService;
        this.listernersLogUpdate = new Map();
        this.hasMoreContentToFetch = true;
        this.hasFinishLoadNewLogs = true;
        this.listenerRefreshLogs = this.createListener();
        this.filtersSub = this.filtersService.subscribeFilters().subscribe(function (filters) {
            _this.selectedFilters = filters;
            _this.hasMoreContentToFetch = true;
            _this.page = 0;
            _this.groupedLogs = new Map();
            _this.notifyObserver();
            _this.fetchLogs();
        });
        this.principal.getAuthenticationState()
            .pipe(operators_1.startWith(this.principal.identityDirect()))
            .subscribe(function (identity) {
            if (identity != null) {
                var currentUser_1 = identity;
                _this.wsMessageEdite = _this.notificationWsService.onEventPublished(shared_1.LogEventType.MessageEdite)
                    .subscribe(function (notif) {
                    if (currentUser_1.userId !== notif.invoqueurId.id) {
                        _this.logsApiService
                            .findLog(new models_1.MessageGroupID(notif.message.messageId, notif.filInformations.id))
                            .pipe(operators_1.first())
                            .subscribe(function (res) { return _this.updateLog(res); });
                    }
                });
                _this.wsMessageSupprime = _this.notificationWsService.onEventPublished(shared_1.LogEventType.MessageSupprimeOuFerme)
                    .subscribe(function (notif) {
                    if (currentUser_1.userId !== notif.invoqueurId.id) {
                        _this.logsApiService
                            .findLog(new models_1.MessageGroupID(notif.messageId, notif.filInformations.id))
                            .pipe(operators_1.first())
                            .subscribe(function (res) {
                            _this.groupedLogs.delete(LogbookLogsService.getLogKey(res));
                            _this.notifyObserver();
                        });
                    }
                });
                _this.wsLogUseful = _this.notificationWsService.onEventPublished(shared_1.LogEventType.LogUsefulChanged)
                    .subscribe(function (notif) {
                    _this.logsApiService
                        .findLog(new models_1.MessageGroupID(notif.messageId, notif.filInformationsId))
                        .pipe(operators_1.first())
                        .subscribe(function (res) { return _this.updateLog(res); });
                });
            }
        });
    }
    LogbookLogsService.getLogKey = function (log) {
        return (log.messages.metadata && log.messages.metadata['groupedLog']) ?
            log.messages.metadata['groupedLog'] : log.messages.messageId.id;
    };
    LogbookLogsService.prototype.subscribeLogs = function () {
        return this.listenerRefreshLogs;
    };
    LogbookLogsService.prototype.subscribeLog = function (groupId) {
        if (!this.listernersLogUpdate.has(groupId)) {
            var subject = new rxjs_1.Subject();
            // TODO Fuite de mémoire potentiel il faudrait reussir à supprimer l'entrée de la map quand toutes les subscriptions ont été stoppées
            this.listernersLogUpdate.set(groupId, subject);
        }
        return this.listernersLogUpdate.get(groupId);
    };
    LogbookLogsService.prototype.postLog = function (formData) {
        var _this = this;
        return this.logsApiService.postLog(formData).pipe(operators_1.map(function (res) { return res.body; }), operators_1.map(function (logs) {
            logs.forEach(function (log) {
                log.isNew = true;
                if (_this.filtersService.logIsInSelectedFilters(log)) {
                    _this.addNewLogsToList([log]);
                }
                else {
                    var flightTag = shared_1.FlightTag.createFlightTag(log.tagVol);
                    var info = "Your log for the flight " + flightTag.toStringWithoutDate() + " has been successfully sent";
                    if (flightTag.isGeneralFlight()) {
                        info = "Your general log has been successfully sent";
                    }
                    _this.notificationsService.success("Log created !", info);
                }
            });
            return logs;
        }));
    };
    LogbookLogsService.prototype.editLog = function (threadId, formData) {
        var _this = this;
        return this.logsApiService.editLog(threadId, formData).pipe(operators_1.map(function (log) {
            _this.updateLog(log);
            return log;
        }));
    };
    LogbookLogsService.prototype.removeLog = function (threadId, messageId) {
        var _this = this;
        return this.logsApiService.removeLog(threadId, messageId).pipe(operators_1.map(function (res) {
            _this.removeLogFromList(threadId, messageId);
            return res;
        }));
    };
    LogbookLogsService.prototype.addNewLogsToListById = function (messageGroupIDS) {
        var _this = this;
        var callRemaining = messageGroupIDS.length;
        this.hasFinishLoadNewLogs = false;
        var decountCall = function () {
            callRemaining--;
            if (callRemaining === 0) {
                _this.hasFinishLoadNewLogs = true;
            }
        };
        messageGroupIDS.forEach(function (messageGroupID) {
            _this.logsApiService.findLog(messageGroupID).pipe(operators_1.first())
                .subscribe(function (res) {
                res.isNew = true;
                _this.addNewLogsToList([res]);
                decountCall();
            }, function (err) { return decountCall(); });
        });
    };
    LogbookLogsService.prototype.addNewLogsToList = function (logs) {
        this.groupedLogs = this.groupLogs(logs);
        this.notifyObserver();
    };
    LogbookLogsService.prototype.updateLog = function (log) {
        var logGroup = this.findLogGroup(log);
        if (logGroup != null) {
            logGroup.setMessage(log.messages);
            logGroup.update++;
            this.groupedLogs.set(LogbookLogsService.getLogKey(log), Object.assign(new models_1.LogGroup(), logGroup));
        }
        this.notifyObserver();
        this.notifyLogObserver(log);
    };
    LogbookLogsService.prototype.updateLogComment = function (log, commentId) {
        var logGroup = this.findLogGroup(log);
        if (logGroup != null) {
            var oldComment = this.findComment(logGroup.message, commentId);
            var newComment = this.findComment(log.messages, commentId);
            oldComment.corps = newComment.corps;
            oldComment.metadata = newComment.metadata;
            newComment.pieceJointes = newComment.pieceJointes.map(function (pj) { return new shared_1.PieceJointe(pj); });
            oldComment.pieceJointes = newComment.pieceJointes;
            logGroup.setMessage(log.messages);
            this.groupedLogs.set(LogbookLogsService.getLogKey(log), Object.assign(new models_1.LogGroup(), logGroup));
        }
        this.notifyObserver();
        this.notifyLogObserver(log);
    };
    LogbookLogsService.prototype.fetchLogs = function () {
        var _this = this;
        this.logsApiService.fetchLogs(this.selectedFilters, this.page).pipe(operators_1.map(function (res) {
            if (!res || !res.length) {
                _this.hasMoreContentToFetch = false;
            }
            return _this.groupLogs(res);
        })).pipe(operators_1.first()).subscribe(function (data) {
            _this.groupedLogs = data;
            _this.notifyObserver();
        });
    };
    LogbookLogsService.prototype.loadMore = function () {
        this.page++;
        this.fetchLogs();
    };
    LogbookLogsService.prototype.getLogByGroupId = function (date, logGroupedId) {
        return this.logsApiService
            .findLogByGroupedId(date, logGroupedId)
            .pipe(operators_1.map(function (res) {
            var logs = null;
            res.forEach(function (value, index) {
                if (logs == null) {
                    logs = new models_1.LogGroup(LogbookLogsService.getLogKey(value), value);
                }
                else {
                    logs.addLog(value);
                }
                return logs;
            });
            return logs;
        }));
    };
    LogbookLogsService.prototype.createListener = function () {
        return new rxjs_1.Subject();
    };
    LogbookLogsService.prototype.removeLogFromList = function (threadId, messageIdBase64) {
        var logKey;
        this.groupedLogs.forEach(function (value, key) {
            var filters = value.messageIds.filter(function (id) {
                return id.threadId === threadId && id.messageIdBase64 === messageIdBase64;
            });
            if (filters.length > 0) {
                logKey = key;
            }
        });
        if (logKey) {
            this.groupedLogs.delete(logKey);
            this.notifyObserver();
        }
    };
    LogbookLogsService.prototype.groupLogs = function (res) {
        return res.reduce(function (previousValue, currentValue) {
            var key = LogbookLogsService.getLogKey(currentValue);
            if (!currentValue.messages.tags) {
                currentValue.messages.tags = [];
            }
            if (previousValue.has(key)) {
                var logGroup = previousValue.get(key);
                logGroup.addLog(currentValue);
            }
            else {
                var logGroup = new models_1.LogGroup(key, currentValue);
                previousValue.set(key, logGroup);
            }
            return previousValue;
        }, this.groupedLogs);
    };
    LogbookLogsService.prototype.notifyObserver = function () {
        if (this.listenerRefreshLogs) {
            var groups = Array.from(this.groupedLogs.values()).sort(function (l1, l2) {
                if (l1.message.dateCreation < l2.message.dateCreation) {
                    return 1;
                }
                if (l1.message.dateCreation > l2.message.dateCreation) {
                    return -1;
                }
                return 0;
            });
            this.listenerRefreshLogs.next(groups);
        }
    };
    LogbookLogsService.prototype.notifyLogObserver = function (logUpdated) {
        var logKey = LogbookLogsService.getLogKey(logUpdated);
        if (logKey && this.listernersLogUpdate.has(logKey)) {
            this.listernersLogUpdate.get(logKey).next(logUpdated);
        }
    };
    LogbookLogsService.prototype.findLogGroup = function (log) {
        return this.groupedLogs.get(LogbookLogsService.getLogKey(log));
    };
    LogbookLogsService.prototype.findComment = function (message, commentId) {
        return message.comments.find(function (comment) {
            return comment.messageId.id === commentId;
        });
    };
    LogbookLogsService.prototype.ngOnDestroy = function () {
        if (this.filtersSub) {
            this.filtersSub.unsubscribe();
        }
        if (this.wsMessageEdite) {
            this.wsMessageEdite.unsubscribe();
        }
        if (this.wsMessageSupprime) {
            this.wsMessageSupprime.unsubscribe();
        }
        if (this.wsLogUseful) {
            this.wsLogUseful.unsubscribe();
        }
    };
    LogbookLogsService.ngInjectableDef = i0.defineInjectable({ factory: function LogbookLogsService_Factory() { return new LogbookLogsService(i0.inject(i1.UserService), i0.inject(i2.FiltersService), i0.inject(i3.LogbookLogsApiService), i0.inject(i4.NotificationsService), i0.inject(i5.Principal), i0.inject(i6.NotificationWsService)); }, token: LogbookLogsService, providedIn: "root" });
    return LogbookLogsService;
}());
exports.LogbookLogsService = LogbookLogsService;
