"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var rxjs_1 = require("rxjs");
var operators_1 = require("rxjs/operators");
var http_1 = require("@angular/common/http");
var user_service_1 = require("../../../../logbook/shared/src/lib/user/service/user.service");
var logbook_config_1 = require("@logbook/core/shared/config/logbook-config");
var team_model_1 = require("../../shared/models/team.model");
var model_1 = require("../../../../logbook/shared/src/lib/model");
var i0 = require("@angular/core");
var i1 = require("@angular/common/http");
var i2 = require("../../../../logbook/shared/src/lib/user/service/user.service");
var i3 = require("../../shared/config/logbook-config");
var CommunityService = /** @class */ (function () {
    function CommunityService(http, userService, config) {
        var _this = this;
        this.http = http;
        this.userService = userService;
        this.config = config;
        this.communitiesCache = new Map();
        this.communityState = new rxjs_1.BehaviorSubject(null);
        this.enrichCommunity = function (community) {
            // Set members
            var observableCommunity = rxjs_1.of(community);
            var observableMembers = community.membres && community.membres.length ? rxjs_1.forkJoin(community.membres.map(function (membre) { return _this.userService.find(membre.id).pipe(operators_1.catchError(function (err) {
                return rxjs_1.of(null);
            })); })) : rxjs_1.of(null);
            return rxjs_1.forkJoin([observableCommunity, observableMembers]);
        };
        this.cleanCommunity = function (res) {
            var community = Object.assign(new team_model_1.Team(), res[0]);
            community.membres = res[1] ? res[1].filter(function (user) { return user != null; }) : [];
            // Set tags
            if (!community.tags.some(function (tag) { return tag.value === 'important'; })) {
                community.tags.unshift({
                    alias: "Important",
                    value: "important",
                    color: "red"
                });
            }
            if (!community.tags.some(function (tag) { return tag.value === 'flightDelayed'; })) {
                community.tags.unshift({
                    alias: "Flight delay",
                    value: "flightDelayed",
                    color: "purple"
                });
            }
            if (!community.tags.some(function (tag) { return tag.value === 'mvt'; })) {
                community.tags.unshift({
                    alias: "MVT",
                    value: "mvt",
                    color: "gray"
                });
            }
            if (!community.tags.some(function (tag) { return tag.value === 'mail'; })) {
                community.tags.unshift({
                    alias: "Mail",
                    value: "mail",
                    color: "green"
                });
            }
            return community;
        };
        this.resourceUrl = this.config.LOGBOOK_API + '/api/communaute/';
    }
    CommunityService.prototype.getCommunity = function (force) {
        if (force === true) {
            this.fetchUserCommunity(force).pipe(operators_1.first()).subscribe();
        }
        return this.communityState.pipe(operators_1.filter(function (i) { return i !== null; }));
    };
    CommunityService.prototype.cleanCommunityCache = function () {
        this.communityCache = undefined;
    };
    CommunityService.prototype.getCommunityById = function (id, withDetail) {
        if (withDetail === void 0) { withDetail = false; }
        if (!id) {
            return this.getCommunity();
        }
        if (!this.communitiesCache.has(id) || withDetail) {
            this.communitiesCache.set(id, this.http.get("" + this.resourceUrl + id + (withDetail ? '/detail' : ''), { observe: 'response' }).pipe(operators_1.map(function (res) { return res.body; }), operators_1.mergeMap(this.enrichCommunity), operators_1.map(this.cleanCommunity), operators_1.catchError(function (err) {
                console.error("Cannot fetch team", err);
                return rxjs_1.of(null);
            }), operators_1.publishReplay(1), operators_1.refCount()));
        }
        return this.communitiesCache.get(id);
    };
    CommunityService.prototype.save = function (teamToSave) {
        var _this = this;
        if (teamToSave.id) {
            return this.http.put("" + this.resourceUrl, teamToSave.convertToJson()).pipe(operators_1.mergeMap(function (res) {
                _this.communitiesCache.delete(res.id);
                if (_this.communityState.getValue() && _this.communityState.getValue().id === res.id) {
                    return _this.fetchUserCommunity(true);
                }
                else {
                    return _this.getCommunityById(res.id);
                }
            }), operators_1.tap(function (res) {
                if (_this.communityState.getValue() && _this.communityState.getValue().id === res.id) {
                    _this.communityState.next(res);
                }
            }));
        }
        else {
            return this.http.post("" + this.resourceUrl, teamToSave.convertToJson()).pipe(operators_1.mergeMap(function (res) {
                return _this.getCommunityById(res.id);
            }));
        }
    };
    CommunityService.prototype.addMember = function (teamId, memberToAdd) {
        return this.http.put("" + this.resourceUrl + teamId + "/member", memberToAdd);
    };
    CommunityService.prototype.removeMember = function (teamId, memberToRemove) {
        return this.http.delete("" + this.resourceUrl + teamId + "/member/" + memberToRemove);
    };
    CommunityService.prototype.searchCommunity = function (page, searchQuery) {
        var options = page.toOptions();
        if (searchQuery) {
            options = options.set("filter", searchQuery);
        }
        return this.http.get(this.resourceUrl, { params: options })
            .pipe(operators_1.map(function (res) { return new model_1.Page(res); }));
    };
    CommunityService.prototype.fetchUserCommunity = function (force) {
        var _this = this;
        if (force === true) {
            this.communityCache = undefined;
        }
        if (!this.communityCache) {
            this.communityCache = this.http.get(this.resourceUrl + "account", { observe: 'response' }).pipe(operators_1.map(function (res) { return res.body; }), operators_1.mergeMap(this.enrichCommunity), operators_1.map(this.cleanCommunity), operators_1.catchError(function () { return rxjs_1.of(_this.getDefaultTeam()); }), operators_1.tap(function (community) {
                _this.communityState.next(community);
            }), operators_1.publishReplay(1), operators_1.refCount());
        }
        return this.communityCache;
    };
    CommunityService.prototype.getDefaultTeam = function () {
        var team = new team_model_1.Team();
        team.name = "No team";
        team.tags.unshift({
            alias: "Important",
            value: "important",
            color: "red"
        });
        team.tags.unshift({
            alias: "MVT",
            value: "mvt",
            color: "gray"
        });
        team.tags.unshift({
            alias: "Flight delay",
            value: "flightDelayed",
            color: "purple"
        });
        team.tags.unshift({
            alias: "Mail",
            value: "mail",
            color: "green"
        });
        team.profile = [{ airlines: [], airport: [] }];
        return team;
    };
    CommunityService.ngInjectableDef = i0.defineInjectable({ factory: function CommunityService_Factory() { return new CommunityService(i0.inject(i1.HttpClient), i0.inject(i2.UserService), i0.inject(i3.LogbookConfig)); }, token: CommunityService, providedIn: "root" });
    return CommunityService;
}());
exports.CommunityService = CommunityService;
