"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var moment = require("moment");
var TimeAgoPipe = /** @class */ (function () {
    function TimeAgoPipe(changeDetectorRef, ngZone) {
        this.changeDetectorRef = changeDetectorRef;
        this.ngZone = ngZone;
    }
    TimeAgoPipe.prototype.transform = function (date) {
        var now = moment.utc();
        var d = moment.utc(date);
        var seconds = now.diff(d, 'seconds');
        var minutes = now.diff(d, 'minutes');
        var hours = now.diff(d, 'hours');
        var days = now.diff(d, 'days');
        // const months = now.diff(d, 'months');
        // const years = now.diff(d, 'years');
        if (Number.isNaN(seconds)) {
            return '';
        }
        else if (seconds <= 45) {
            return 'a few secs ago';
        }
        else if (seconds <= 90) {
            return 'a min ago';
        }
        else if (minutes <= 45) {
            return minutes + ' mins ago';
        }
        else if (minutes <= 90) {
            return 'an hour ago';
        }
        else if (hours <= 24) {
            return hours + ' hours ago';
        }
        else {
            return d.format("MMM DD") + " at " + d.format("HH:mm");
        }
    };
    return TimeAgoPipe;
}());
exports.TimeAgoPipe = TimeAgoPipe;
