import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'modal-footer',
    templateUrl: './modal-footer.component.html',
    styleUrls: ['./modal-footer.component.scss']
})
export class ModalFooterComponent implements OnInit {

    @Input() canSave = new EventEmitter<void>();
    @Output() cancel = new EventEmitter<void>();
    @Output() save = new EventEmitter<void>();

    constructor() { }

    ngOnInit() {}

}
