import {Injectable} from '@angular/core';

import {SwPush} from "@angular/service-worker";

import {HttpClient} from "@angular/common/http";
import {LogbookConfig} from "@logbook/core/shared/config/logbook-config";
import {MixpanelService} from "@logbook/core/tracking/mixpanel.service";
import {MixpanelEvent} from "@logbook/core/tracking/mixpanel-event.enum";
import {take} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class PushNotificationsService {

    constructor(
        private swPush: SwPush,
        private http: HttpClient,
        private config: LogbookConfig,
        private mixpanelService: MixpanelService) {
    }

    subscribeToNotifications() {
        if (this.config.VAPID_PUBLIC_KEY) {
            this.swPush.requestSubscription({
                serverPublicKey: this.config.VAPID_PUBLIC_KEY
            })
                .then((pushSubscription: PushSubscription) => {
                    this.mixpanelService.track(MixpanelEvent.NOTIFICATION.PUSH.ACCEPTED);
                    this.http.post(`${this.config.production ? this.config.LOGBOOK_API : ''}/api/notifications/subscribe`, pushSubscription)
                        .subscribe((subResponse: any) => {
                            console.log('[App] Subscription successful');
                        });
                })
                .catch((err) => {
                    // Need typescript >= v3 to use 'Notification.permission'
                    if (err && err.message && err.message.indexOf('denied') > 0) {
                        this.mixpanelService.track(MixpanelEvent.NOTIFICATION.PUSH.REFUSED);
                    }
                    console.error("Could not subscribe to notifications", err);
                });
        } else {
            console.log("Could not subscribe to notifications, VALID_PUBLIC_KEY invalid");
        }
    }

    unsubscribeToNotifications(identity): Promise<any> {
        // Get active subscription
        return new Promise((resolve) => {
            if (this.swPush.isEnabled) {
                this.swPush.subscription
                    .pipe(take(1))
                    .subscribe((pushSubscription) => {
                        // Delete the subscription from the backend
                        this.http.post(`${this.config.production ? this.config.LOGBOOK_API : ''}/api/notifications/unsubscribe/${identity.userId}`, pushSubscription)
                            .subscribe((subResponse: any) => {
                                    pushSubscription.unsubscribe()
                                        .then((success) => {
                                            console.log('[App] Unsubscription successful', success);
                                            return resolve();
                                        })
                                        .catch((err) => {
                                            console.log('[App] Unsubscription failed', err);
                                            return resolve();
                                        });

                                },
                                (err) => {
                                    console.log('[App] Delete subscription request failed', err);
                                    return resolve();
                                }
                            );
                    });
            } else {
                resolve()
            }
        });
    }

}
