import {CategoryTag} from "../category-tag/category-tag.model";
import {FlightTag, MessageForm} from "@logbook/shared";
import {CategoryLogFilter} from "../category-tag/category-log-filter.model";

export class LogForm extends MessageForm {
    tags: Array<CategoryTag>;
    flightTags: Array<FlightTag>;
    metadata: any;
    isEditing = false;

    constructor(messageId?: string) {
        super(messageId);
        this.tags = [];
        this.flightTags = [];
        this.metadata = new Map();

        this.metadata = {
            authorType: "human",
            messageType: "TEXT"
        };
    }

    get tagsFilter() {
        return this.tags.map((tag) =>
            new CategoryLogFilter(tag.alias, 'messages.tags', 'equals', tag.value)
        );
    }

    isValid() {
        return super.isValid() && this.flightDelayIsValid();
    }

    flightDelayIsValid() {
        if (this.isEditing) {
            return true;
        }
        const isFlightDelay = this.tags.some((categoryTag) => categoryTag.value === 'flightDelayed');

        if (isFlightDelay) {
            return this.flightTags.length > 0
        } else {
            return true
        }
    }

    pushFlightTag(flightTag: FlightTag) {
        if (!this.flightTagIsAlreadyPresent(flightTag)) {
            this.flightTags.push(flightTag);
        }
    }

    removeFlightTag(flightTag: FlightTag) {
        this.flightTags = this.flightTags.filter((flightTagInList) => {
            return flightTagInList.toString() !== flightTag.toString()
        });
    }

    private flightTagIsAlreadyPresent(flightTag: FlightTag) {
        return this.flightTags.filter((flightTagInList) => {
            return flightTagInList.toString() === flightTag.toString()
        }).length > 0;
    }
}
