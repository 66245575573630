"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var FeatureType;
(function (FeatureType) {
    FeatureType["NEW"] = "NEW";
    FeatureType["CHANGE"] = "CHANGE";
    FeatureType["FIX"] = "FIX";
    FeatureType["REMOVE"] = "REMOVE";
    FeatureType["SECURITY"] = "SECURITY";
})(FeatureType = exports.FeatureType || (exports.FeatureType = {}));
var Feature = /** @class */ (function () {
    function Feature(build) {
        this.description = "";
        if (build) {
            this.type = build.type;
            this.description = build.description;
            switch (this.type) {
                case FeatureType.NEW:
                    this.colorType = 'green';
                    break;
                case FeatureType.CHANGE:
                    this.colorType = 'blue';
                    break;
                case FeatureType.FIX:
                    this.colorType = 'purple';
                    break;
                case FeatureType.REMOVE:
                    this.colorType = 'red';
                    break;
                case FeatureType.SECURITY:
                    this.colorType = 'orange';
                    break;
            }
        }
    }
    return Feature;
}());
exports.Feature = Feature;
