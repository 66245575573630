import {of as observableOf, forkJoin as observableForkJoin, Observable} from 'rxjs';

import {map, mergeMap, catchError, publishReplay, refCount} from 'rxjs/operators';
import {HttpClient, HttpParams} from "@angular/common/http";
import {Injectable} from '@angular/core';
import {Societe} from "../../../../../../app/core/shared/models/societe.model";
import {CommunityService} from "../../../../../../app/core/auth/services/community.service";
import {LogbookConfig} from "../../../../../../app/core/shared/config/logbook-config";
import {Pageable} from "../../model/pageable";
import {Page} from "../../model";

@Injectable()
export class SocieteService {
    private resourceUrl;
    private societeByTeamCache: Map<string, Observable<Societe>> = new Map();

    constructor(private http: HttpClient, private communityService: CommunityService, private config: LogbookConfig) {
        this.resourceUrl = config.LOGBOOK_API + '/api/societe';
    }

    findById(societeId: string): Observable<Societe> {
        return this.http.get(`${this.resourceUrl}/${societeId}`)
            .pipe(map( (res) => Object.assign(new Societe(), res)));
    }

    findByTeamId(teamId: any): Observable<any> {
        if (!this.societeByTeamCache.has(teamId)) {
            // fetch other communities from societe for team passed in parameter
            this.societeByTeamCache.set(teamId, this.http.get(`${this.resourceUrl}/team/${teamId}`).pipe(
                mergeMap((societeFromApi) => {
                    const societe = new Societe(societeFromApi);
                    // Set teams
                    return observableForkJoin(
                        [observableOf(societe), observableForkJoin(
                            societe.teamIds
                            // .filter((otherTeamId) => otherTeamId !== teamId) //FIXME: TC: j'ai désactivé car on ne
                            // voyait pas les contacts quand on a qu'une seul team dans notre societe, à revoir
                                .map((otherTeamId) =>
                                    this.communityService.getCommunityById(otherTeamId)
                                        .pipe(catchError((err) => observableOf(null)))
                                )
                        )]);
                }),
                map((res) => {
                    const societe = Object.assign(new Societe(), res[0]);
                    societe.teams = Object.assign([], res[1]).filter((team) => team != null);
                    return societe;
                }),
                catchError(() => observableOf(null)),
                publishReplay(1),
                refCount()
            ));
        }

        return this.societeByTeamCache.get(teamId);
    }

    findAll(pageable: Pageable, query?: string): Observable<Page<Societe>> {
        let options: HttpParams = new HttpParams()
            .set('page', pageable.page.toString())
            .set('size', pageable.size.toString())
            .set('sort', pageable.sort);
        if (query) {
            options = options.set("search", query)
        }

        return this.http.get(`${this.resourceUrl}`, {params: options}).pipe(
            map((res: Page<Societe>) => {
                res = new Page<Societe>(res);
                res.content = res.content.map((societe) => new Societe(societe));
                return res;
            })
        );
    }

    save(societe: any) {
        return this.http.post(`${this.resourceUrl}/`, societe).pipe(
            map((societeFromApi) => new Societe(societeFromApi)));
    }

    suggestSocieteByEmail(email: string) {
        if (email) {
            return this.http.get(`${this.resourceUrl}/suggest/${email}`)
                .pipe(map((res: Societe[]) => res.map((societe) => new Societe(societe))));
        }
    }

}
