
import {tap} from 'rxjs/operators';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Injectable, Injector} from '@angular/core';
import {Observable} from 'rxjs';
import {LoginService} from "@logbook/core/auth/services/login.service";

export class AuthExpiredInterceptor implements HttpInterceptor {

    constructor(private injector: Injector) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(tap((event: HttpEvent<any>) => {
        }, (err: any) => {
            if (err instanceof HttpErrorResponse) {
                if (err.status === 401) {
                    const loginService: LoginService = this.injector.get(LoginService);
                    loginService.logout();
                }
            }
        }));
    }
}
