import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR} from "@angular/forms";
import {Authorities, Authority} from "../../../../../../../app/core/shared/models/authorities.model";
import {MatAutocompleteSelectedEvent} from "@angular/material";

@Component({
    selector: 'dln-input-authorities',
    templateUrl: './input-authorities.component.html',
    styleUrls: ['./input-authorities.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DlnInputAuthoritiesComponent),
            multi: true,
        }
    ]
})
export class DlnInputAuthoritiesComponent implements ControlValueAccessor, OnInit {

    @Input()
    public isCurrentUserSuperAdmin = false;

    @Input()
    public multiple = false;

    authorityControl = new FormControl();
    roleList: Authority[] = Authorities;
    userRoles = [];
    noLogbookRoles = [];
    inputFocused = false;

    private propagateChange = (_: any) => {
    };

    private propagateTouched = () => {
    };

    constructor() {
    }

    ngOnInit() {
        if (!this.isCurrentUserSuperAdmin) {
            this.roleList = this.roleList.filter((role) => role.role !== "ROLE_ADMIN")
        }
        if (this.multiple) {
            this.roleList = this.roleList.filter((role) => role.role !== "ROLE_USER")
        }
    }

    writeValue(roles: string[]): void {
        if (roles) {
            this.userRoles = roles.filter((role) => {
                return this.roleList.some((roleItem) => roleItem.role === role)
            });
            this.noLogbookRoles = roles.filter((role) => {
                return !this.roleList.some((roleItem) => roleItem.role === role)
            });

            if (!this.multiple) {
                this.authorityControl.setValue(roles.indexOf("ROLE_TEAM_MANAGER") !== -1 ? this.getLabel("ROLE_TEAM_MANAGER") : this.getLabel('ROLE_USER'))
            }
        }
    }

    registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.propagateTouched = fn;
    }

    selectRole($event: MatAutocompleteSelectedEvent) {
        if (this.multiple) {
            if (!this.userRoles.some((value) => value === $event.option.value)) {
                this.userRoles.push($event.option.value);
            }
            this.authorityControl.setValue('');
        } else {
            this.userRoles = [$event.option.value];
            this.authorityControl.setValue(this.getLabel($event.option.value));

        }
        this.propagateChange(this.noLogbookRoles.concat(this.userRoles));
        this.propagateTouched();
    }

    removeRole(role: string) {
        this.userRoles = this.userRoles.filter((value) => value !== role);
        this.propagateChange(this.noLogbookRoles.concat(this.userRoles));
        this.propagateTouched();
    }

    getLabel(role: string) {
        return this.roleList.find((value) => value.role === role).label
    }
}
