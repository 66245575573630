"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var operators_1 = require("rxjs/operators");
var core_1 = require("@angular/core");
var forms_1 = require("@angular/forms");
var notifications_service_1 = require("../../notification/services/notifications.service");
var momentImported = require("moment");
var moment = momentImported;
var reference_data_api_service_1 = require("../../common/flight-tag/service/reference-data-api.service");
var flight_tag_model_1 = require("../../common/flight-tag/model/flight-tag.model");
var material_1 = require("@angular/material");
var mixpanel_service_1 = require("../../../../../../app/core/tracking/mixpanel.service");
var mixpanel_event_enum_1 = require("../../../../../../app/core/tracking/mixpanel-event.enum");
var AddManualFlightTagModalComponent = /** @class */ (function () {
    function AddManualFlightTagModalComponent(data, dialogRef, notificationsService, mixpanelService, referenceDataApi) {
        this.data = data;
        this.dialogRef = dialogRef;
        this.notificationsService = notificationsService;
        this.mixpanelService = mixpanelService;
        this.referenceDataApi = referenceDataApi;
        this.dateSelected = moment.utc();
        var required = forms_1.Validators.required, minLength = forms_1.Validators.minLength, maxLength = forms_1.Validators.maxLength;
        this.flightTagForm = new forms_1.FormGroup({
            airline: new forms_1.FormControl(null, [
                required,
                minLength(2),
                maxLength(3)
            ]),
            flightNumber: new forms_1.FormControl(null, [
                required,
                minLength(1)
            ]),
            airportFrom: new forms_1.FormControl(null, [
                required,
                minLength(3),
                maxLength(3)
            ]),
            airportTo: new forms_1.FormControl(null, [
                required,
                minLength(3),
                maxLength(3)
            ])
        });
    }
    AddManualFlightTagModalComponent.prototype.ngOnInit = function () {
        this.mixpanelService.track(mixpanel_event_enum_1.MixpanelEvent.FLIGHT_TAG_CREATION_STARTED);
        this.dateSelected = this.data.flightDate;
        this.dateSelected.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
        if (this.data.searchText) {
            var searchText = this.data.searchText.toUpperCase();
            var splitSearchText = searchText.split(" ");
            this.flightTagForm.get('airline').setValue(splitSearchText[0]);
            this.airlineChange();
            this.flightTagForm.get('flightNumber').setValue(splitSearchText[1]);
            this.flightTagForm.get('airportFrom').setValue(splitSearchText[2]);
            this.flightTagForm.get('airportTo').setValue(splitSearchText[3]);
        }
    };
    AddManualFlightTagModalComponent.prototype.cancel = function () {
        this.dialogRef.close(null);
    };
    AddManualFlightTagModalComponent.prototype.createFlightTag = function () {
        if (this.flightTagForm.valid) {
            var flightTag = new flight_tag_model_1.FlightTag();
            flightTag.date = this.dateSelected.toISOString();
            flightTag.dateMoment = this.dateSelected;
            flightTag.airlineCode = this.airline.value.toUpperCase();
            flightTag.flightNumber = this.flightNumber.value.toUpperCase();
            flightTag.from = this.airportFrom.value.toUpperCase();
            flightTag.to = this.airportTo.value.toUpperCase();
            this.mixpanelService.track(mixpanel_event_enum_1.MixpanelEvent.FLIGHT_TAG_CREATION_CALL, {
                airline: flightTag.airlineCode,
                line: flightTag.flightNumber,
                "airport from": flightTag.from,
                "airport to": flightTag.to,
                "flight date": flightTag.date
            });
            this.notificationsService.success("Flight created !", "The flight " + flightTag.toStringWithoutDate() + " has been created");
            this.dialogRef.close(flightTag);
        }
        else {
            this.notificationsService.error("Flight tag cannot be create", "Please verify inserted flight tag");
        }
    };
    Object.defineProperty(AddManualFlightTagModalComponent.prototype, "airline", {
        get: function () {
            return this.flightTagForm.get('airline');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AddManualFlightTagModalComponent.prototype, "flightNumber", {
        get: function () {
            return this.flightTagForm.get('flightNumber');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AddManualFlightTagModalComponent.prototype, "airportFrom", {
        get: function () {
            return this.flightTagForm.get('airportFrom');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AddManualFlightTagModalComponent.prototype, "airportTo", {
        get: function () {
            return this.flightTagForm.get('airportTo');
        },
        enumerable: true,
        configurable: true
    });
    AddManualFlightTagModalComponent.prototype.airlineChange = function () {
        var _this = this;
        this.normalizeAirline(this.airline.value).pipe(operators_1.first())
            .subscribe(function (res) {
            _this.airline.setValue(res);
        });
    };
    AddManualFlightTagModalComponent.prototype.normalizeAirline = function (airlineCode) {
        return this.referenceDataApi.transcodeAirline(airlineCode).pipe(operators_1.map(function (res) { return res.icaoCode; }));
    };
    return AddManualFlightTagModalComponent;
}());
exports.AddManualFlightTagModalComponent = AddManualFlightTagModalComponent;
