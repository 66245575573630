"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var MessageForm = /** @class */ (function () {
    function MessageForm(id) {
        this.body = "";
        this.files = [];
        this.id = "";
        if (id != null) {
            this.id = id;
        }
    }
    MessageForm.prototype.isValid = function () {
        return (this.body.length > 0 || (this.files != null && this.files.length > 0))
            && this.files.filter(function (file) { return file && file.isPosting; }).length === 0;
    };
    return MessageForm;
}());
exports.MessageForm = MessageForm;
