import {Pipe, PipeTransform} from "@angular/core";
import * as momentImported from 'moment';

const moment = momentImported;

@Pipe({name: 'prettyDate'})
export class PrettyDatePipe implements PipeTransform {

    transform(date: Date): string {
        try {
            const utcDate = moment.utc(date);
            const today = moment.utc();
            const yesterday = moment.utc().subtract(1, 'days').startOf('day');
            if (utcDate.isValid()) {
                if (utcDate.isSame(today, 'day')) {
                    // TODAY
                    return 'at ' + utcDate.format("HH:mm") + " GMT"
                } else {
                    if (utcDate.isSame(yesterday, 'day')) {
                        // YESTERDAY
                        return "Yesterday at " + utcDate.format("HH:mm") + " GMT"
                    } else {
                        return 'on ' + utcDate.format("ll") + " at " + utcDate.format("HH:mm") + " GMT"
                    }
                }
            }
        } catch (e) {
            return null
        }
    }
}
