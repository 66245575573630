import {Injectable} from '@angular/core';
import {NotificationWsService} from "../../../../logbook/shared/src/lib/user/service/notification-ws.service";
import {CommunityService} from "./community.service";
import {UserService} from "../../../../logbook/shared/src/lib/user/service/user.service";
import {AuthServerProvider} from "./auth-jwt.service";
import {Principal} from "./principal.service";
import {PushNotificationsService} from "../../../../logbook/shared/src/lib/notification/services/push-notifications.service";
import {Router} from "@angular/router";
import {Location} from "@angular/common";
import {first} from "rxjs/operators";

@Injectable({
    providedIn: 'root',
})
export class LoginService {

    constructor(private principal: Principal,
                private authServerProvider: AuthServerProvider,
                private communityService: CommunityService,
                private userService: UserService,
                private wsService: NotificationWsService,
                private location: Location,
                private router: Router,
                private pushNotificationService: PushNotificationsService) {
    }

    login(credentials, callback?) {
        const cb = callback || function() {
        };

        return new Promise((resolve, reject) => {
            this.authServerProvider.login(credentials).pipe(first()).subscribe((data) => {
                this.principal.identity(true).then((account) => {
                    resolve(data);
                    this.wsService.initStomp();
                    this.userService.fetchUsersLogged();
                });
                return cb();
            }, (err) => {
                this.logout();
                reject(err);
                return cb(err);
            });
        });
    }

    signAsUser(email, callback?) {
        const cb = callback || function() {
        };
        this.wsService.disconnect();
        const previousUrl = this.location.path();

        return new Promise((resolve, reject) => {
            this.authServerProvider.signAsUser(email, previousUrl).pipe(first()).subscribe((data) => {
                this.principal.identity(true).then((account) => {
                    resolve(data);
                    this.wsService.initStomp();
                    this.userService.fetchUsersLogged();
                });
                return cb();
            }, (err) => {
                reject(err);
                return cb(err);
            });
        });
    }

    loginWithToken(jwt, rememberMe) {
        return this.authServerProvider.loginWithToken(jwt, rememberMe);
    }

    logoutSignAs(callback?) {
        const cb = callback || function() {
        };
        this.wsService.disconnect();
        return new Promise((resolve, reject) => {
            this.authServerProvider.logoutSignAs().pipe(first()).subscribe((prevUrl) => {
                this.principal.identity(true).then((account) => {
                    resolve(account);
                    this.wsService.initStomp();
                    this.userService.fetchUsersLogged();
                    this.router.navigateByUrl(prevUrl);
                });
            }, (err) => {
                reject(err);
                return cb(err);
            });
        });
    }

    logout(): Promise<any> {
        return new Promise((resolve, reject) => {
            if (this.principal.hasFeatureEnableDirect(["NOTIFICATION_CENTER"])) {
                this.pushNotificationService.unsubscribeToNotifications(this.principal.identityDirect())
                    .then(() => {
                        this.performLogout(resolve);
                    }).catch(() => {
                        this.performLogout(resolve);
                    });
            } else {
                this.performLogout(resolve);
            }
        });
    }

    private performLogout(resolve) {
        this.authServerProvider.logout().pipe(first()).subscribe();
        this.wsService.disconnect();
        this.communityService.cleanCommunityCache();
        this.principal.authenticate(null);
        resolve();
    }
}
