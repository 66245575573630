"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var ModalHeaderComponent = /** @class */ (function () {
    function ModalHeaderComponent() {
        this.close = new core_1.EventEmitter();
    }
    ModalHeaderComponent.prototype.ngOnInit = function () { };
    return ModalHeaderComponent;
}());
exports.ModalHeaderComponent = ModalHeaderComponent;
