"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var KeysPipe = /** @class */ (function () {
    function KeysPipe() {
    }
    KeysPipe.prototype.transform = function (value, args) {
        var keys = [];
        if (value) {
            for (var key in value) {
                if (key) {
                    keys.push(key);
                }
            }
        }
        return keys;
    };
    return KeysPipe;
}());
exports.KeysPipe = KeysPipe;
