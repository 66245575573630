"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var shared_1 = require("@logbook/shared");
var message_model_1 = require("./message.model");
var MessageGroupID = /** @class */ (function () {
    function MessageGroupID(messageId, threadId) {
        this.messageId = messageId.id;
        this.messageIdBase64 = messageId.idAsBase64;
        this.threadId = threadId.idAsBase64;
    }
    return MessageGroupID;
}());
exports.MessageGroupID = MessageGroupID;
var LogGroup = /** @class */ (function () {
    function LogGroup(key, log) {
        this.isNew = false;
        this.update = 0;
        if (key) {
            this.key = key;
        }
        if (log) {
            this.setMessage(log.messages);
            this.flightTags = new Array(shared_1.FlightTag.createFlightTag(log.tagVol));
            this.participants = log.participantsAutorises;
            this.messageIds = new Array(new MessageGroupID(log.messages.messageId, log.id));
            this.isNew = log.isNew;
        }
    }
    LogGroup.prototype.setMessage = function (message) {
        if (message.comments == null) {
            message.comments = [];
        }
        this.message = new message_model_1.Message(message);
    };
    LogGroup.prototype.addLog = function (log) {
        if (this.messageIdAlreadyPresent(log.messages.messageId.id)) {
            var flightTag = shared_1.FlightTag.createFlightTag(log.tagVol);
            this.flightTags.push(flightTag);
            this.messageIds.push(new MessageGroupID(log.messages.messageId, log.id));
        }
    };
    LogGroup.prototype.messageIdAlreadyPresent = function (messageId) {
        return !this.messageIds.some(function (messagesIdInList) { return messagesIdInList.messageId === messageId; });
    };
    return LogGroup;
}());
exports.LogGroup = LogGroup;
