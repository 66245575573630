"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var dropdown_content_component_1 = require("./dropdown-content/dropdown-content.component");
var DropdownComponent = /** @class */ (function () {
    function DropdownComponent() {
        this.displayOnHover = false;
        this.hoverDelay = 500;
        this.displayDropdown = true;
        this.placement = "bottom";
    }
    DropdownComponent.prototype.ngOnInit = function () {
        if (!this.placementArrow) {
            this.placementArrow = this.placement;
        }
    };
    DropdownComponent.prototype.closeDropdown = function () {
        this.dropdownContent.hide();
    };
    return DropdownComponent;
}());
exports.DropdownComponent = DropdownComponent;
