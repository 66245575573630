import {Injectable} from '@angular/core';
import {Observer, Observable} from "rxjs";
import {FlightTag} from "@logbook/shared";
import {LogForm} from "@logbook/logs-view/shared/models";

@Injectable()
export class LogFormService {
    private listenerRefreshLogForm: Observable<any>;
    private observerRefreshLogForm: Observer<any>;
    private logForm: LogForm;

    constructor() {
        this.listenerRefreshLogForm = this.createListener();
        this.logForm = new LogForm();
    }

    subscribeLogForm(): Observable<any> {
        return this.listenerRefreshLogForm;
    }

    setLogForm(logForm: LogForm) {
        this.logForm = logForm;
    }

    logFormChange(logForm: LogForm) {
        this.logForm = logForm;
        this.notifyObserver();
    }

    flightTagsChange(flightTags: FlightTag[]) {
        this.logForm.flightTags = flightTags;
        this.notifyObserver();
    }

    addFlightTag(flightTag: FlightTag) {
        this.logForm.pushFlightTag(flightTag);
        this.notifyObserver();
    }

    removeFlightTag(flightTag: FlightTag) {
        this.logForm.removeFlightTag(flightTag);
        this.notifyObserver();
    }

    notifyObserver() {
        this.observerRefreshLogForm.next(this.logForm);
    }

    private createListener(): Observable<any> {
        return new Observable((observer) => {
            this.observerRefreshLogForm = observer;
        });
    }
}
