"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var operators_1 = require("rxjs/operators");
var http_1 = require("@angular/common/http");
var trackjs_1 = require("trackjs");
var ErrorHandlerInterceptor = /** @class */ (function () {
    function ErrorHandlerInterceptor() {
    }
    ErrorHandlerInterceptor.prototype.intercept = function (request, next) {
        return next.handle(request).pipe(operators_1.tap(function (event) {
        }, function (err) {
            if (err instanceof http_1.HttpErrorResponse) {
                if (!(err.status === 401 && (err.message === '' || (err.url && err.url.indexOf('/api/account') === 0)))) {
                }
            }
            console.log("HTTP Error : " + err.message + " : " + JSON.stringify(err.error));
            console.log("HTTP body : " + JSON.stringify(request.body, ErrorHandlerInterceptor.hidePassword));
            console.log("HTTP params : " + request.params);
            trackjs_1.TrackJS.track(request.method + " " + err.status + ": " + request.url);
        }));
    };
    ErrorHandlerInterceptor.hidePassword = function (key, value) {
        if (key && key.toLowerCase().indexOf("password") >= 0) {
            return '*******';
        }
        else {
            return value;
        }
    };
    return ErrorHandlerInterceptor;
}());
exports.ErrorHandlerInterceptor = ErrorHandlerInterceptor;
