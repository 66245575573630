"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var user_model_1 = require("./user.model");
var VisitorGuest = /** @class */ (function (_super) {
    tslib_1.__extends(VisitorGuest, _super);
    function VisitorGuest(res) {
        var _this = _super.call(this, res.email, res.email) || this;
        _this.instructionId = res.instructionId;
        _this.contactId = res.contactId;
        _this.authorities = ["ANONYMOUS"];
        return _this;
    }
    return VisitorGuest;
}(user_model_1.User));
exports.VisitorGuest = VisitorGuest;
