<header>
    <nav *ngIf="isAuthenticated()" class="nav-bar-logged">
        <div class="left-content">
            <dln-back-button className="no-background" desktopLabel="Back" mobileLabel=""
                             *ngIf="showBackButton"></dln-back-button>
            <h1 *ngIf="navBarTitle" class="nav-bar-title">{{navBarTitle}}</h1>
        </div>
        <div class="center-content">
            <a class="logo-wide" routerLink="/" *showItBootstrap="['xs','sm']">
                <img src="../../../content/images/logo-logbook-wide.svg" alt="logbook">
            </a>
        </div>
        <div class="right-content" *showItBootstrap="['xs','sm']">
            <mobile-navbar-button *showItBootstrap="['xs','sm']"></mobile-navbar-button>
        </div>
        <div class="right-content" *hideItBootstrap="['xs','sm']">
            <a class="account" routerLink="/management/account/profile">
                <i class="dln-icon-people"></i> <span>{{account?.displayUserName()}}</span>
            </a>

            <a class="log-out" id="log-out-btn" (click)="logout()">
                <i class="dln-icon-log-out"></i> Log-out
            </a>

            <div class="change-log" (click)="goToChangeLogs()" dlnTooltip placement="left" tooltipText="What's New ?"
                 *dlnHasFeatureEnable="['RELEASES_NOTES']">
                <i class="icon-gift"></i> <span *ngIf="newChangeLogCount">{{newChangeLogCount}}</span>
            </div>
        </div>
    </nav>
    <nav *ngIf="!isAuthenticated()" class="nav-bar-account">
        <div class="left-content">
            <dln-back-button [url]="'/'" desktopLabel="Back to log-in" mobileLabel="Back"></dln-back-button>
        </div>
        <div class="center-content">
            <a class="logo-wide" routerLink="/">
                <img src="../../../content/images/logo-logbook-wide.svg" alt="logbook">
            </a>
        </div>
        <div class="right-content">
        </div>
    </nav>
    <nav class="nav-bar-logged nav-bar-print">
        <img src="../../../content/images/logo-logbook-wide.svg" alt="logbook">
    </nav>
</header>

<div class="sign-as-message" *ngIf="isSignAsUser">
    <span class="left">
        <i class="icon-warning"></i> You are sign as {{account['signAsUserId']}}
    </span>
    <span class="right" (click)="logoutSignAs()">
        Back to {{account['invoqueurId']}}
    </span>
</div>
