import {HttpParams} from "@angular/common/http";

export class Pageable {
    page: number;
    size: number;
    sort: string;

    constructor(page, size, sort) {
        this.page = page;
        this.size = size;
        this.sort = sort;
    }

    toOptions() {
        const options: HttpParams = new HttpParams()
            .set('page', this.page.toString())
            .set('size', this.size.toString())
            .set('sort', this.sort);
        return options;
    }
}
