"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./spinner.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("@angular/common");
var i3 = require("./spinner.component");
var styles_SpinnerComponent = [i0.styles];
var RenderType_SpinnerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SpinnerComponent, data: {} });
exports.RenderType_SpinnerComponent = RenderType_SpinnerComponent;
function View_SpinnerComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "label-spinner"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.label; _ck(_v, 1, 0, currVal_0); }); }
function View_SpinnerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(2, { "spinner": 0, "is-button": 1, "black": 2 }), (_l()(), i1.ɵeld(3, 0, null, null, 0, "div", [["class", "rect rect1"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "div", [["class", "rect rect2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "div", [["class", "rect rect3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "div", [["class", "rect rect4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "div", [["class", "rect rect5"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SpinnerComponent_1)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 2, 0, true, _co.isButton, _co.isBlack); _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.label; _ck(_v, 9, 0, currVal_1); }, null); }
exports.View_SpinnerComponent_0 = View_SpinnerComponent_0;
function View_SpinnerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "dln-spinner", [], null, null, null, View_SpinnerComponent_0, RenderType_SpinnerComponent)), i1.ɵdid(1, 49152, null, 0, i3.SpinnerComponent, [], null, null)], null, null); }
exports.View_SpinnerComponent_Host_0 = View_SpinnerComponent_Host_0;
var SpinnerComponentNgFactory = i1.ɵccf("dln-spinner", i3.SpinnerComponent, View_SpinnerComponent_Host_0, { label: "label", isButton: "isButton", isBlack: "isBlack" }, {}, []);
exports.SpinnerComponentNgFactory = SpinnerComponentNgFactory;
