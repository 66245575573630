import {Pipe, PipeTransform} from "@angular/core";
import * as momentImported from 'moment';
import {Moment} from "moment"; const moment = momentImported;

@Pipe({name: 'dayDate'})
export class DayDatePipe implements PipeTransform {

    transform(date: Date): string {
        const dateMoment = moment.utc(date);
        const today = moment.utc();
        const yesterday = moment.utc().subtract(1, 'days').startOf('day');

        if (dateMoment.isSame(today, 'day')) {
            // TODAY
            return "Today"
        } else {
            if (dateMoment.isSame(yesterday, 'day')) {
                // YESTERDAY
                return "Yesterday"
            } else {
                return today.diff(date, 'days') + " days ago"
            }
        }
    }
}
