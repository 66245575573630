"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Phone = /** @class */ (function () {
    function Phone(build) {
        if (build) {
            this.type = build.type;
            this.numero = build.numero;
        }
    }
    return Phone;
}());
exports.Phone = Phone;
