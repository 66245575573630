"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var piece_jointe_model_1 = require("../model/piece-jointe.model");
var message_form_model_1 = require("../../../../model/message-form.model");
var attachment_service_1 = require("../service/attachment.service");
var operators_1 = require("rxjs/operators");
var AttachFileComponent = /** @class */ (function () {
    function AttachFileComponent(attachmentService) {
        this.attachmentService = attachmentService;
        this.accept = "*";
        this.showDragAndDrop = true;
        this.multipleAttachFile = false;
        this.messageFormChange = new core_1.EventEmitter();
        if (this.messageForm == null) {
            this.messageForm = new message_form_model_1.MessageForm();
        }
    }
    AttachFileComponent.prototype.onFileChange = function (event) {
        var _this = this;
        var target = event.target || event.srcElement;
        if (this.multipleAttachFile) {
            Array.from(target.files).forEach(function (file) {
                _this.attachmentService.postFile(_this.messageForm, file).pipe(operators_1.first()).subscribe(function () {
                    _this.messageFormChange.emit(_this.messageForm);
                });
            });
        }
        else {
            if (target.files && target.files[0]) {
                this.messageForm.files = [];
                this.attachmentService.postFile(this.messageForm, target.files[0]).pipe(operators_1.first()).subscribe(function () {
                    _this.messageFormChange.emit(_this.messageForm);
                });
            }
        }
        this.inputFile.nativeElement.value = "";
    };
    AttachFileComponent.prototype.removeFile = function (indexToRemove) {
        this.messageForm.files = this.messageForm.files.filter(function (val, index) {
            return index !== indexToRemove;
        });
        this.messageFormChange.emit(this.messageForm);
    };
    AttachFileComponent.prototype.fileIsPJ = function (file) {
        return file instanceof piece_jointe_model_1.PieceJointe;
    };
    return AttachFileComponent;
}());
exports.AttachFileComponent = AttachFileComponent;
