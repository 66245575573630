"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ShareRecipient = /** @class */ (function () {
    function ShareRecipient() {
    }
    ShareRecipient.prototype.initInfo = function () {
        this.firstName = null;
        this.lastName = null;
        this.company = null;
    };
    return ShareRecipient;
}());
exports.ShareRecipient = ShareRecipient;
