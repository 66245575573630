"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./check-updates.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("./check-updates.component");
var i3 = require("../../tracking/mixpanel.service");
var i4 = require("@angular/service-worker");
var i5 = require("@angular/material/snack-bar");
var styles_CheckUpdatesComponent = [i0.styles];
var RenderType_CheckUpdatesComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CheckUpdatesComponent, data: {} });
exports.RenderType_CheckUpdatesComponent = RenderType_CheckUpdatesComponent;
function View_CheckUpdatesComponent_0(_l) { return i1.ɵvid(0, [], null, null); }
exports.View_CheckUpdatesComponent_0 = View_CheckUpdatesComponent_0;
function View_CheckUpdatesComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "dln-check-updates", [], null, null, null, View_CheckUpdatesComponent_0, RenderType_CheckUpdatesComponent)), i1.ɵdid(1, 245760, null, 0, i2.CheckUpdatesComponent, [i3.MixpanelService, i4.SwUpdate, i1.NgZone, i1.ApplicationRef, i5.MatSnackBar], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_CheckUpdatesComponent_Host_0 = View_CheckUpdatesComponent_Host_0;
var CheckUpdatesComponentNgFactory = i1.ɵccf("dln-check-updates", i2.CheckUpdatesComponent, View_CheckUpdatesComponent_Host_0, {}, {}, []);
exports.CheckUpdatesComponentNgFactory = CheckUpdatesComponentNgFactory;
