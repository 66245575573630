export class CategoryTag {
    alias: string;
    color: string;
    value: string;

    constructor(tag) {
        this.alias = tag.alias;
        this.color = tag.color;
        this.value = tag.value;
    }

}
