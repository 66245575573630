<div class="header-mobile">
    <span class="label">
        Notifications
    </span>
    <i class="dln-icon-close" (click)="close()"></i>
</div>

<div class="inner" infiniteScroll [infiniteScrollDistance]="5"
     [infiniteScrollThrottle]="300" [scrollWindow]="false"
     [infiniteScrollDisabled]="noMoreContent" (scrolled)="fetchNextPage()"
     *ngIf="notifications && notifications.length"
>

    <div class="notification" *ngFor="let notification of notifications">
        <span class="date" *ngIf="notification.isFirstNotificationForDay">
            {{notification.createdAt | dayDate}}
        </span>

        <notification [notification]="notification"
                      (view)="displayNotification(notification)"
                      (read)="markAsRead(notification)"
                      (delete)="delete(notification)">
        </notification>
    </div>

    <span class="markAllAsRead" (click)="markAllAsRead()">Mark All as Read</span>
</div>

<div class="no-content"  *ngIf="!(notifications && notifications.length) && loadingNotifications">
    <dln-spinner></dln-spinner>
</div>

<div class="no-content"  *ngIf="!(notifications && notifications.length) && !loadingNotifications">
    <h3>No new notifications</h3>
</div>
