"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var UserFilterPipe = /** @class */ (function () {
    function UserFilterPipe() {
    }
    UserFilterPipe.prototype.transform = function (value, filter) {
        if (value && value.length && filter && filter.length) {
            if (value[0].userId) {
                var users = value;
                return users.filter(function (user) {
                    var filterUpperCase = filter.toUpperCase();
                    return user.displayUserName().toUpperCase().indexOf(filterUpperCase) >= 0 || user.userId.toUpperCase().indexOf(filterUpperCase) >= 0 || (user.company && user.company.nom.toUpperCase().indexOf(filterUpperCase) >= 0);
                });
            }
            else {
                var invitations = value;
                return invitations.filter(function (invitation) {
                    var filterUpperCase = filter.toUpperCase();
                    return invitation.guest.toUpperCase().indexOf(filterUpperCase) >= 0;
                });
            }
        }
        else {
            return value;
        }
    };
    return UserFilterPipe;
}());
exports.UserFilterPipe = UserFilterPipe;
