"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var operators_1 = require("rxjs/operators");
var http_1 = require("@angular/common/http");
var core_1 = require("@angular/core");
var rxjs_1 = require("rxjs");
var app_constants_1 = require("@logbook/app.constants");
var shared_1 = require("@logbook/shared");
var filters_service_1 = require("../log/filters.service");
var FlightTagService = /** @class */ (function () {
    function FlightTagService(http, filtersService) {
        var _this = this;
        this.http = http;
        this.filtersService = filtersService;
        this.listenerRefreshFlights = this.createListener();
        this.filtersSub = this.filtersService.subscribeFilters().subscribe(function (filters) {
            // RELOAD FLIGHT ONLY IF DATE CHANGED
            if (_this.previousSelectedDate !== filters.date.toISOString()) {
                _this.selectedDate = filters.date;
                _this.previousSelectedDate = filters.date.toISOString();
                _this.findFlights();
            }
        });
    }
    FlightTagService.prototype.subscribeRefreshFlights = function () {
        return this.listenerRefreshFlights;
    };
    FlightTagService.prototype.findFlights = function () {
        var _this = this;
        var options = new http_1.HttpParams()
            .set('startDate', this.selectedDate.format("DD-MM-YYYY"))
            .set('endDate', this.selectedDate.format("DD-MM-YYYY"));
        return this.http.get(app_constants_1.LOGBOOK_API + "/api/flightTag", { params: options })
            .pipe(operators_1.map(function (res) { return res.flightTags.map(function (entry) { return shared_1.FlightTagWithThreads.fromJson(entry); }); }))
            .subscribe(function (flightList) {
            // HIDE GENERAL TAG
            _this.flightList = flightList.filter(function (flightTagWithThreads) {
                return !flightTagWithThreads.flightTag.isGeneralFlight();
            });
            _this.notifyObserver();
        });
    };
    FlightTagService.prototype.notifyObserver = function () {
        this.listenerRefreshFlights.next(shared_1.FlightTagWithThreads.sort(this.flightList));
    };
    FlightTagService.prototype.createListener = function () {
        return new rxjs_1.BehaviorSubject(null);
    };
    FlightTagService.prototype.ngOnDestroy = function () {
        if (this.filtersSub) {
            this.filtersSub.unsubscribe();
        }
    };
    return FlightTagService;
}());
exports.FlightTagService = FlightTagService;
