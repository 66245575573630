import {Component, EventEmitter, Input, Output} from '@angular/core';
import {LogbookNotification} from "@logbook/notification-center/shared/model/LogbookNotification";

@Component({
    selector: 'notification',
    templateUrl: './notification.component.html',
    styleUrls: ['./notification.component.scss']
})
export class NotificationComponent {

    @Input() notification: LogbookNotification;
    @Output() read = new EventEmitter<void>();
    @Output() delete = new EventEmitter<void>();
    @Output() view = new EventEmitter<void>();

    dropdownIsVisible = false;

    constructor() { }

    viewNotification() {
        this.view.emit();
    }

    deleteNotification() {
        this.delete.emit();
        this.dropdownIsVisible = false;
    }

    readNotification() {
        this.read.emit();
        this.dropdownIsVisible = false;
    }

}
