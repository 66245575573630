import {debounceTime, distinctUntilChanged, filter, first, flatMap, map, mergeMap, switchMap} from 'rxjs/operators';
import {
    ChangeDetectionStrategy, ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import {Moment} from "moment";
import {FlightTag} from "../../common/flight-tag/model/flight-tag.model";
import {FlightTagSearchService} from "../../common/flight-tag/service/flight.tag.search.service";
import {ModalService} from "../../modal/service/modal.service";
import {NgSelectComponent} from "../../common/ng-select/ng-select.component";
import {ModalConfig} from "../../modal/model/modal.config.model";
import {AddManualFlightTagModalComponent} from "../add-manual-flight-tag/add-manual-flight-tag-modal.component";
import {MixpanelService} from "../../../../../../app/core/tracking/mixpanel.service";
import {MixpanelEvent} from "../../../../../../app/core/tracking/mixpanel-event.enum";
import {CommunityService} from "../../../../../../app/core/auth/services/community.service";
import {Observable, of, Subject} from "rxjs";
import {NgOption} from "@logbook/shared";

@Component({
    selector: 'dln-input-flight',
    templateUrl: './input-flight.component.html',
    styleUrls: [
        './input-flight.component.scss'
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DlnInputFlightComponent implements OnChanges, OnInit {

    @Input() suggestionDate: Moment;
    @Input() selectedFlightTags = [];
    @Input() showFlightDate = false;
    @Output() onSelectFlight = new EventEmitter<FlightTag>();
    @Output() onRemoveFlight = new EventEmitter<FlightTag>();
    @ViewChild(NgSelectComponent) ngSelect: NgSelectComponent;

    private userProfile: any;
    public flightTagsSearchControl = new EventEmitter<string>();
    public flightTagsSuggestion$: Observable<NgOption[]>;

    constructor(private flightTagSearchService: FlightTagSearchService,
                private mixpanelService: MixpanelService,
                private modalService: ModalService,
                private communityService: CommunityService) {
    }

    ngOnInit(): void {
        this.flightTagsSuggestion$ = this.flightTagsSearchControl.pipe(
            distinctUntilChanged(),
            debounceTime(150),
            flatMap((val) => (val && val.length) ? this.searchFlight(val) : of([]))
        );

        this.communityService.getCommunity().pipe(first()).subscribe((community) => {
            this.userProfile = JSON.stringify(community.profile.map((item) => {
                return {
                    airlines: item.airlines,
                    airports: item.airports
                }
            }))
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.suggestionDate) {
            this.flightTagsSearchControl.emit("")
        }
    }

    public selectFlight(flightTag) {
        this.mixpanelService.track(MixpanelEvent.FLIGHT_TAG_SUGGEST_SELECTED, {
            flightTag: flightTag.params.toString(),
        });
        this.onSelectFlight.emit(flightTag.params);
    }

    public removeFlight(flightTag) {
        this.onRemoveFlight.emit(flightTag.params);
    }

    public searchFlight(flightTagSearch): Observable<NgOption[]> {
        this.mixpanelService.track(MixpanelEvent.FLIGHT_TAG_SUGGEST_SEARCH, {
            query: flightTagSearch,
        });
        return this.flightTagSearchService.suggestFlights(
            flightTagSearch,
            'search',
            this.suggestionDate.format("DD/MM/YYYY"),
            this.suggestionDate.format("DD/MM/YYYY"),
            this.userProfile
        ).pipe(
            map((flights) => flights
                .filter((flight) => flight.airlineCode !== 'TVX')
                .map((flight) => DlnInputFlightComponent.convertToNgSelectItem(flight))
            )
        )
    }

    public openNewFlightTagModal() {
        const modalConfig = new ModalConfig(AddManualFlightTagModalComponent, {
            flightDate: this.suggestionDate,
            searchText: this.ngSelect.filterValue
        });

        this.modalService.showModal(modalConfig).subscribe((flightTag) => {
            if (flightTag) {
                this.ngSelect.filterValue = '';
                this.ngSelect.select(DlnInputFlightComponent.convertToNgSelectItem(flightTag));
                this.onSelectFlight.emit(flightTag);
            }
        });

    }

    public static convertToNgSelectItem(flightTag: FlightTag) {
        return {
            name: flightTag.toString(),
            id: flightTag.toString(),
            isParent: false,
            params: flightTag
        };
    }

}
