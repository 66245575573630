"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var user_model_1 = require("./user.model");
var SignAsUserAccess = /** @class */ (function (_super) {
    tslib_1.__extends(SignAsUserAccess, _super);
    function SignAsUserAccess() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return SignAsUserAccess;
}(user_model_1.User));
exports.SignAsUserAccess = SignAsUserAccess;
