
import {first, map} from 'rxjs/operators';
import {HttpClient, HttpResponse} from "@angular/common/http";
import {Injectable} from '@angular/core';
import {CommunityService} from "./community.service";
import {GoogleAnalyticsService} from "@logbook/core/tracking/google-analytics.service";
import {TrackJsErrorHandlerService} from "@logbook/core/tracking/track-js-error-handler.service";
import {MixpanelService} from "@logbook/core/tracking/mixpanel.service";
import {SignAsUserAccess} from "@logbook/core/shared/models/sign-as-user-access.model";
import {VisitorGuest} from "@logbook/core/shared/models/visitor-access.model";
import {User} from "@logbook/core/shared/models/user.model";
import {LogbookConfig} from "@logbook/core/shared/config/logbook-config";
import {Observable} from "rxjs";

@Injectable({
    providedIn: 'root',
})
export class AccountService {
    constructor(private http: HttpClient,
                private config: LogbookConfig,
                private mixpanelService: MixpanelService,
                private googleAnayticsService: GoogleAnalyticsService,
                private trackJsErrorHandlerService: TrackJsErrorHandlerService,
                private communityService: CommunityService) {
    }

    get(): Observable<any> {
        return this.http.get(`${this.config.LOGBOOK_API}/api/account`, {observe: 'response'}).pipe(
            map((res: HttpResponse<any>) => res.body),
            map((res) => {
                if (res.signAsUserId) {
                    const user = Object.assign(new SignAsUserAccess(), res);
                    this.mixpanelService.setDisable(true);
                    this.communityService.getCommunity(true)
                        .pipe(first())
                        .subscribe();
                    return user;
                } else if (res.contactId || res.instructionId) {
                    return new VisitorGuest(res);
                } else if (res.userId) {
                    const user = Object.assign(new User(), res);
                    if (user.acceptActivityTracking) {
                        this.mixpanelService.setDisable(false);
                        this.communityService.getCommunity(true)
                            .pipe(first())
                            .subscribe((community) => {
                                this.trackJsErrorHandlerService.configure(user);
                                this.mixpanelService.setUser(user, {team: community ? community.name : null});
                                this.googleAnayticsService.setUser(user, community ? community.name : null);
                            });
                    } else {
                        this.mixpanelService.setDisable(true);
                    }
                    return user;
                } else {
                    return null;
                }
            })
        );
    }

    save(account: any): Observable<HttpResponse<any>> {
        return this.http.post(`${this.config.LOGBOOK_API}/api/account`, account, {observe: 'response'});
    }

    isReadCGU(): Observable<HttpResponse<any>> {
        return this.http.get(`${this.config.LOGBOOK_API}/api/participant/user/isReadCGU/${this.config.CGU_VERSION}`, {observe: 'response'});
    }

    readCGU() {
        return this.http.put(`${this.config.LOGBOOK_API}/api/participant/user/readCGU/${this.config.CGU_VERSION}`, {}, {observe: 'response'});
    }

    changePassword(passwordEdit: any): Observable<HttpResponse<any>> {
        return this.http.post(`${this.config.LOGBOOK_API}/api/password`, passwordEdit, {observe: 'response'});
    }

}
