import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from "rxjs";
import {LogsViewManagementService} from "@logbook/logs-view/shared/services";
import {ActiveComponents} from "@logbook/logs-view/shared/models";

@Component({
    selector: 'mobile-navbar-button',
    providers: [],
    templateUrl: './mobile-navbar-button.component.html',
    styleUrls: [
        'mobile-navbar-button.scss'
    ]
})
export class MobileNavbarButtonComponent implements OnInit, OnDestroy {
    public isToggled: boolean;
    private navBarSubscription: Subscription;

    constructor(private viewManagementService: LogsViewManagementService) {
    }

    ngOnInit() {
        this.navBarSubscription = this.viewManagementService.subscribeActiveComponents()
            .subscribe((activeComponents: ActiveComponents) =>
                this.isToggled = activeComponents.rightPanelExpanded
            );
    }

    toggleNav() {
        this.viewManagementService.expandRightPanel();
    }

    ngOnDestroy() {
        if (this.navBarSubscription) {
            this.navBarSubscription.unsubscribe();
        }
    }
}
