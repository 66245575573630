import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    HostBinding,
    Input,
    OnDestroy,
    OnInit,
    Output
} from '@angular/core';
import {Router} from "@angular/router";
import {Intercom} from "ng-intercom";
import {LocalStorageService} from "ngx-webstorage";
import {Subscription} from "rxjs/index";
import {LoginService} from "../../auth/services/login.service";
import {Principal} from "../../auth/services/principal.service";
import {MixpanelService} from "../../tracking/mixpanel.service";
import {MixpanelEvent} from "../../tracking/mixpanel-event.enum";
import {User} from "../../shared/models/user.model";
import {ActiveComponents} from "@logbook/logs-view/shared/models";
import {LogsViewManagementService} from "@logbook/logs-view/shared/services";
import {NotificationCenterService} from "@logbook/notification-center/shared/service/notification.center.service";
import {filter, startWith} from "rxjs/operators";

@Component({
    selector: 'dln-switch-apps-menu',
    templateUrl: './switch-apps-menu.component.html',
    styleUrls: [
        'switch-apps-menu.component.scss'
    ]
})
export class SwitchAppsMenuComponent implements OnInit, OnDestroy {

    static readonly LOGBOOK_LOGO = "../../../content/images/logo.svg";
    static readonly LOGBOOK_NAME = "Logbook";
    static readonly COLLAPSE_STORAGE_KEY = "COLLAPSE_MENU";

    public logo: string;
    public companyName: string;
    public notificationIsOpen = false;
    @Output() collapseChange = new EventEmitter<boolean>();
    @Input() @HostBinding('class.collapse') public collapse = false;

    @HostBinding('class.displayMobile') public displayMobile = false;

    private navBarSubscription: Subscription;

    constructor(private loginService: LoginService,
                private router: Router,
                private principal: Principal,
                private localStorageService: LocalStorageService,
                public notificationCenter: NotificationCenterService,
                private mixpanelService: MixpanelService,
                private intercomService: Intercom,
                private changeDetection: ChangeDetectorRef,
                private viewManagementService: LogsViewManagementService) {
    }

    ngOnInit() {
        this.navBarSubscription = this.viewManagementService.subscribeActiveComponents()
            .subscribe((activeComponents: ActiveComponents) =>
                this.displayMobile = activeComponents.rightPanelExpanded
            );

        this.principal
            .getAuthenticationState()
            .pipe(
                startWith(this.principal.identityDirect()),
                filter((user) => user != null)
            )
            .subscribe((user: User) => {
                this.logo = SwitchAppsMenuComponent.LOGBOOK_LOGO;
                this.companyName = SwitchAppsMenuComponent.LOGBOOK_NAME;
                if (user.company) {
                    if (user.company.logoUri) {
                        this.logo = user.company.logoUri;
                    }
                    if (user.company.nom) {
                        this.companyName = user.company.nom;
                    }
                }
                this.changeDetection.markForCheck();
            });
    }

    collapseMenu() {
        this.collapse = !this.collapse;
        this.localStorageService.store(SwitchAppsMenuComponent.COLLAPSE_STORAGE_KEY, this.collapse);
        this.collapseChange.emit(this.collapse);
        this.changeDetection.markForCheck();
    }

    // Makes sure we don't have a memory leak by destroying the
    // Subscription when our flight-report is destroyed
    ngOnDestroy() {
        if (this.navBarSubscription) {
            this.navBarSubscription.unsubscribe();
        }
    }

    closeMenu() {
        this.viewManagementService.expandRightPanel();
        this.notificationIsOpen = false;
        this.changeDetection.markForCheck();
    }

    logout() {
        this.loginService.logout();
        this.mixpanelService.track(MixpanelEvent.LOG_OUT, {});
        this.closeMenu();
        this.router.navigate(['logout', {queryParams: {logout: true}, queryParamsHandling: "merge"}]);
    }

    openNotification() {
        this.notificationIsOpen = !this.notificationIsOpen;
        this.mixpanelService.track(this.notificationIsOpen ?
            MixpanelEvent.NOTIFICATION.CENTER.OPENED : MixpanelEvent.NOTIFICATION.CENTER.CLOSED);
        this.changeDetection.markForCheck();
        // this.viewManagementService.expandRightPanel();
    }

}
