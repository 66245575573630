
import {first, map} from 'rxjs/operators';
import {Component, Inject, OnInit, Optional} from '@angular/core';
import {AbstractControl, FormControl, FormGroup, Validators} from "@angular/forms";
import {NotificationsService} from "../../notification/services/notifications.service";
import {Moment} from "moment";
import * as momentImported from 'moment'; const moment = momentImported;
import {ReferenceDataApiService} from "../../common/flight-tag/service/reference-data-api.service";
import {FlightTag} from "../../common/flight-tag/model/flight-tag.model";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";
import {MixpanelService} from "../../../../../../app/core/tracking/mixpanel.service";
import {MixpanelEvent} from "../../../../../../app/core/tracking/mixpanel-event.enum";

@Component({
    selector: 'dln-add-manual-flight-tag-modal',
    templateUrl: './add-manual-flight-tag-modal.html',
    styleUrls: [
        'add-manual-flight-tag-modal.scss'
    ]
})
export class AddManualFlightTagModalComponent implements OnInit {

    flightTagForm: FormGroup;
    dateSelected: Moment = moment.utc();

    constructor(@Optional() @Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<AddManualFlightTagModalComponent>,
                private notificationsService: NotificationsService, private mixpanelService: MixpanelService,
                private referenceDataApi: ReferenceDataApiService) {

        const {required, minLength, maxLength} = Validators;
        this.flightTagForm = new FormGroup({
            airline: new FormControl(null, [
                required,
                minLength(2),
                maxLength(3)
            ]),
            flightNumber: new FormControl(null, [
                required,
                minLength(1)
            ]),
            airportFrom: new FormControl(null, [
                required,
                minLength(3),
                maxLength(3)
            ]),
            airportTo: new FormControl(null, [
                required,
                minLength(3),
                maxLength(3)
            ])
        });
    }

    ngOnInit() {

        this.mixpanelService.track(MixpanelEvent.FLIGHT_TAG_CREATION_STARTED);

        this.dateSelected = this.data.flightDate;

        this.dateSelected.set({hour: 0, minute: 0, second: 0, millisecond: 0});

        if (this.data.searchText) {
            const searchText = this.data.searchText.toUpperCase();

            const splitSearchText = searchText.split(" ");
            this.flightTagForm.get('airline').setValue(splitSearchText[0]);
            this.airlineChange();
            this.flightTagForm.get('flightNumber').setValue(splitSearchText[1]);
            this.flightTagForm.get('airportFrom').setValue(splitSearchText[2]);
            this.flightTagForm.get('airportTo').setValue(splitSearchText[3]);
        }
    }

    cancel() {
        this.dialogRef.close(null);
    }

    createFlightTag() {
        if (this.flightTagForm.valid) {

            const flightTag = new FlightTag();
            flightTag.date = this.dateSelected.toISOString();
            flightTag.dateMoment = this.dateSelected;
            flightTag.airlineCode = this.airline.value.toUpperCase();
            flightTag.flightNumber = this.flightNumber.value.toUpperCase();
            flightTag.from = this.airportFrom.value.toUpperCase();
            flightTag.to = this.airportTo.value.toUpperCase();

            this.mixpanelService.track(MixpanelEvent.FLIGHT_TAG_CREATION_CALL, {
                airline: flightTag.airlineCode,
                line: flightTag.flightNumber,
                "airport from": flightTag.from,
                "airport to": flightTag.to,
                "flight date": flightTag.date
            });

            this.notificationsService.success(
                "Flight created !",
                "The flight " + flightTag.toStringWithoutDate() + " has been created"
            );

            this.dialogRef.close(flightTag);
        } else {
            this.notificationsService.error("Flight tag cannot be create", "Please verify inserted flight tag")
        }
    }

    get airline(): AbstractControl {
        return this.flightTagForm.get('airline');
    }

    get flightNumber() {
        return this.flightTagForm.get('flightNumber');
    }

    get airportFrom() {
        return this.flightTagForm.get('airportFrom');
    }

    get airportTo() {
        return this.flightTagForm.get('airportTo');
    }

    airlineChange() {
        this.normalizeAirline(this.airline.value).pipe(first())
            .subscribe((res) => {
                this.airline.setValue(res)
            });
    }

    private normalizeAirline(airlineCode) {
        return this.referenceDataApi.transcodeAirline(airlineCode).pipe(
            map((res) => res.icaoCode))
    }

}
