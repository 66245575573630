"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Guid = /** @class */ (function () {
    function Guid() {
    }
    Guid.newGuid = function () {
        var pattern = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx';
        return pattern.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0;
            var v = c === 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    };
    return Guid;
}());
exports.Guid = Guid;
