import {Component} from '@angular/core';

@Component({
    selector: 'dln-success-animation',
    template: `
        <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
            <circle class="checkmark__circle" cx="26" cy="26" r="50" fill="none"/>
            <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
        </svg>
    `,
    styleUrls: [
        './success-animation.component.scss'
    ]
})
export class SuccessAnimationComponent {
}
