"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("@angular/common/http");
var http_custom_converter_1 = require("@logbook/core/shared/utils/http-custom-converter");
var app_constants_1 = require("@logbook/app.constants");
var models_1 = require("@logbook/logs-view/shared/models");
var operators_1 = require("rxjs/operators");
var shared_1 = require("@logbook/shared");
var i0 = require("@angular/core");
var i1 = require("@angular/common/http");
var i2 = require("../../../../../logbook/shared/src/lib/user/service/user.service");
var LogbookLogsApiService = /** @class */ (function () {
    function LogbookLogsApiService(http, userService) {
        this.http = http;
        this.userService = userService;
    }
    LogbookLogsApiService.prototype.postLog = function (formData) {
        var options = new http_1.HttpParams();
        return this.http.post(app_constants_1.LOGBOOK_API + "/api/messaging/thread-message-authorization", formData, {
            params: options,
            observe: 'response'
        });
    };
    LogbookLogsApiService.prototype.fetchLogs = function (filters, page) {
        var _this = this;
        var starDate = filters.date.format("DD-MM-YYYY");
        var endDate = filters.date.format("DD-MM-YYYY");
        var threads = filters.getSelectedThreads();
        var options = new http_1.HttpParams({ encoder: new http_custom_converter_1.CustomHttpEncoder() })
            .set('page', page.toString())
            .set('startDate', starDate)
            .set('endDate', endDate)
            .set('threads', threads)
            .set('filters', JSON.stringify(filters.categoryTags));
        return this.http.get(app_constants_1.LOGBOOK_API + "/api/messaging/thread/messages", {
            params: options,
        }).pipe(operators_1.map(function (logs) {
            if (logs && logs.length) {
                logs.map(function (log) { return _this.updateEmitterInformation(log); });
            }
            return logs;
        }));
    };
    LogbookLogsApiService.prototype.findLog = function (messageGroupID) {
        var _this = this;
        return this.http.get(app_constants_1.LOGBOOK_API + "/api/messaging/thread/" + messageGroupID.threadId + "/messages/" + messageGroupID.messageIdBase64).pipe(operators_1.map(function (log) {
            return _this.updateEmitterInformation(log);
        }));
    };
    LogbookLogsApiService.prototype.findLogByGroupedId = function (date, logGroupedId) {
        var _this = this;
        return this.http.get(app_constants_1.LOGBOOK_API + "/api/messaging/log/" + date + "/" + logGroupedId).pipe(operators_1.map(function (logs) {
            if (logs && logs.length) {
                logs.map(function (log) { return _this.updateEmitterInformation(log); });
            }
            return logs;
        }));
    };
    LogbookLogsApiService.prototype.editLog = function (threadId, formData) {
        var _this = this;
        return this.http.post(app_constants_1.LOGBOOK_API + "/api/messaging/" + threadId + "/edit-message", formData)
            .pipe(operators_1.map(function (log) {
            return _this.updateEmitterInformation(log);
        }));
    };
    ;
    LogbookLogsApiService.prototype.removeLog = function (threadId, messageId) {
        return this.http.delete(app_constants_1.LOGBOOK_API + "/api/messaging/thread/" + threadId + "/messages/" + messageId, { observe: 'response' });
    };
    LogbookLogsApiService.prototype.updateEmitterInformation = function (log) {
        var _this = this;
        log.messages.emitter$ = this.userService.find(log.messages.emetteurId.id);
        log.messages.emitter$.pipe(operators_1.first()).subscribe(function (user) {
            log.messages.emitter = user;
        });
        if (log.messages.comments) {
            log.messages.comments.map(function (comment) {
                comment.emitter$ = _this.userService.find(comment.emetteurId.id);
                comment.emitter$.pipe(operators_1.first()).subscribe(function (user) {
                    comment.emitter = user;
                });
            });
        }
        return log;
    };
    LogbookLogsApiService.ngInjectableDef = i0.defineInjectable({ factory: function LogbookLogsApiService_Factory() { return new LogbookLogsApiService(i0.inject(i1.HttpClient), i0.inject(i2.UserService)); }, token: LogbookLogsApiService, providedIn: "root" });
    return LogbookLogsApiService;
}());
exports.LogbookLogsApiService = LogbookLogsApiService;
