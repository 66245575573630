import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DayDatePipe, FilterImagesPipe, OrderByPipe, PrettyDatePipe, SafeHtmlPipe, SortPipe} from "./";
import {TimeAgoPipe} from "./date/time-ago.pipe";
import {KeysPipe} from "./keys.pipe";

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        DayDatePipe,
        PrettyDatePipe,
        TimeAgoPipe,
        FilterImagesPipe,
        OrderByPipe,
        SafeHtmlPipe,
        SortPipe,
        KeysPipe
    ],
    exports: [
        DayDatePipe,
        PrettyDatePipe,
        TimeAgoPipe,
        FilterImagesPipe,
        OrderByPipe,
        SafeHtmlPipe,
        SortPipe,
        KeysPipe
    ]
})
export class SharedPipeModule {
}
