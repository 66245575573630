"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var forms_1 = require("@angular/forms");
var material_1 = require("@angular/material");
var pageable_1 = require("../../model/pageable");
var page_1 = require("../../model/page");
var operators_1 = require("rxjs/operators");
var community_service_1 = require("../../../../../../app/core/auth/services/community.service");
var team_model_1 = require("../../../../../../app/core/shared/models/team.model");
var index_1 = require("rxjs/index");
var TeamSelectDialogComponent = /** @class */ (function () {
    function TeamSelectDialogComponent(dialogRef, teamService) {
        this.dialogRef = dialogRef;
        this.teamService = teamService;
        this.teamPage = new page_1.Page({
            page: 0,
            size: 20,
            totalElements: 0
        });
        this.teamSearchControl = new forms_1.FormControl();
        this.teamNameControl = new forms_1.FormControl();
        this.openNewTeamForm = false;
        this.pageable = new pageable_1.Pageable(0, 20, "name");
        this.destroy$ = new index_1.Subject();
    }
    TeamSelectDialogComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.fetchTeams(this.pageable);
        this.teamSearchControl.valueChanges.pipe(operators_1.debounceTime(250), operators_1.distinctUntilChanged(), operators_1.takeUntil(this.destroy$)).subscribe(function (search) {
            _this.pageable.page = 0;
            _this.fetchTeams(_this.pageable, search);
        });
    };
    TeamSelectDialogComponent.prototype.fetchTeams = function (pageRequest, search) {
        var _this = this;
        this.teamPage.isLoading = true;
        this.teamService.searchCommunity(pageRequest, search).pipe(operators_1.first())
            .subscribe(function (teams) {
            _this.teamPage = teams;
        });
    };
    TeamSelectDialogComponent.prototype.setPage = function (pageInfo) {
        this.pageable.page = pageInfo.offset;
        this.fetchTeams(this.pageable);
    };
    TeamSelectDialogComponent.prototype.selectTeam = function (team) {
        if (team.selected[0]) {
            this.dialogRef.close(team.selected[0].id);
        }
    };
    TeamSelectDialogComponent.prototype.createNewTeam = function () {
        this.openNewTeamForm = true;
    };
    TeamSelectDialogComponent.prototype.saveTeam = function () {
        var _this = this;
        var teamToCreate = new team_model_1.Team();
        teamToCreate.name = this.teamNameControl.value;
        this.teamService.save(teamToCreate).pipe(operators_1.first()).subscribe(function (res) {
            _this.dialogRef.close(res.id);
        });
    };
    TeamSelectDialogComponent.prototype.ngOnDestroy = function () {
        this.destroy$.next();
    };
    return TeamSelectDialogComponent;
}());
exports.TeamSelectDialogComponent = TeamSelectDialogComponent;
