import {Injectable, NgZone} from '@angular/core';
import {RxStompService, StompState} from "@stomp/ng2-stompjs";
import {RxStompState, RxStompConfig} from '@stomp/rx-stomp';
import {Message} from '@stomp/stompjs';
import {Subject, Observable, Subscription, interval} from "rxjs";
import {map} from 'rxjs/operators';
import * as SockJS from 'sockjs-client';
import {AuthServerProvider} from "../../../../../../app/core/auth/services/auth-jwt.service";
import {LogbookConfig} from "../../../../../../app/core/shared/config/logbook-config";
import {stompConfig} from "../util/stomp-config";

export enum LogEventType {
    MessagePublie = "MessagePublie",
    FilInformationsCree = "FilInformationsCree",
    FilInformationsVisiblePourParticipant = "FilInformationsVisiblePourParticipant",
    CommentairePublie = "CommentairePublie",
    CommentaireSupprime = "CommentaireSupprime",
    CommentaireEdite = "CommentaireEdite",
    ConsigneLue = "ConsigneLue",
    MessageSupprimeOuFerme = "MessageSupprimeOuFerme",
    MessageEdite = "MessageEdite",
    LogUsefulChanged = "LogUsefulChanged"
}

export enum InstructionEventType {
    InstructionSentToUser = "InstructionSentToUser",
    InstructionSigned = "InstructionSigned",
    InstructionRead = "InstructionRead",
    InstructionArchived = "InstructionArchived",
    InstructionCommented = "InstructionCommented"
}

export enum NotificationEventType {
    NotificationCreated = "NotificationCreated",
}

@Injectable({
    providedIn: 'root'
})
export class NotificationWsService {
    state_subscription: Subscription;
    connection: Promise<any>;
    connectedPromise: any;
    listener: Map<string, Subject<any>>;
    stomp_subscription: Subscription;
    autoReconnect: Subscription;
    stompConfigInit: RxStompConfig;
    message_subscription: Observable<Message>;
    subscribed: boolean;

    constructor(private authServerProvider: AuthServerProvider, private _stompService: RxStompService, private config: LogbookConfig, private ngZone: NgZone) {
        this.connection = this.createConnection();
        this.createListener();
        this.subscribed = false;
    }

    public initStomp() {
        if (this.subscribed) {
            return;
        }
        this.ngZone.runOutsideAngular(() => {
            this.stompConfigInit = stompConfig;
            const authToken = this.authServerProvider.getToken();
            this.stompConfigInit.webSocketFactory =  () => new SockJS(`${this.config.LOGBOOK_API}/websocket/logbook?access_token=${authToken}`);
            this._stompService.configure(this.stompConfigInit);
            this._stompService.activate();

            this.state_subscription = this._stompService.connectionState$.pipe(
                map((state: number) => StompState[state]))
                .subscribe((status: string) => {
                    console.log(`Stomp connection status: ${status}`);
                });
            this.message_subscription = this._stompService.watch('/user/exchange/logbook.direct/notif');
            this.stomp_subscription = this.message_subscription.pipe(map((message: Message) => {
                return message;
            })).subscribe((msg_body: Message) => {
                const message = JSON.parse(msg_body.body);
                const eventType = msg_body.headers.eventType.split(".");
                const subscriber = this.listener.get(eventType[eventType.length - 1]);
                if (subscriber) {
                    this.ngZone.run(() => {
                        subscriber.next(message);
                    })
                }
            });
            this.subscribed = true;

            this.autoReconnect = interval(60000)
                .subscribe(() => {
                    if (this._stompService.connectionState$.getValue() === RxStompState.CLOSED && !this._stompService.connected()) {
                        this.disconnect();
                        this.initStomp();
                    }
                });
        })
    }

    public disconnect() {
        this.ngZone.run(() => {
            if (!this.subscribed) {
                return;
            }
            if (this.stomp_subscription) {
                this.stomp_subscription.unsubscribe();
            }
            if (this.autoReconnect) {
                this.autoReconnect.unsubscribe()
            }
            if (this.state_subscription) {
                this.state_subscription.unsubscribe()
            }
            this.stomp_subscription = null;
            this.message_subscription = null;
            this.subscribed = false;
        })
    }

    onEventPublished(event): Observable<any> {
        return this.listener.get(event)
    }

    private createListener() {
        this.listener = new Map();
        Object.keys(LogEventType).forEach((event) => {
            this.setListenerForEvent(event);
        });
        Object.keys(InstructionEventType).forEach((event) => {
            this.setListenerForEvent(event);
        });
        Object.keys(NotificationEventType).forEach((event) => {
            this.setListenerForEvent(event);
        });
    }

    private setListenerForEvent(event) {
        this.listener.set(event, new Subject());
    }

    private createConnection(): Promise<any> {
        return new Promise((resolve, reject) => this.connectedPromise = resolve);
    }
}
