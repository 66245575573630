import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {NavbarComponent} from '@logbook/core/layouts/navbar/navbar.component';
import {DlnMainComponent} from "@logbook/core/layouts/main/main.component";
import {SwitchAppsMenuComponent} from "@logbook/core/layouts/switch-apps-menu/switch-apps-menu.component";
import {MobileNavbarButtonComponent} from "@logbook/core/layouts/navbar/mobile/mobile-navbar-button.component";
import {CheckUpdatesComponent} from "@logbook/core/layouts/check-updates/check-updates.component";
import {OnBoardingModalComponent} from "@logbook/core/layouts/on-boarding-modal/on-boarding-modal.component";
import {LogbookSharedModule} from "@logbook/shared";
import {LogbookCoreSharedModule} from "@logbook/core/shared/core-shared.module";
import {SUPPORT_MAIN_ROUTE, SUPPORT_TERMS_AND_CONTITIONS} from "@logbook/support/routes.definitions";
import {UserAlreadyLoggedRouteAccessService} from "@logbook/core/shared/guards/user-already-logged-route-acces-service";
import {NotificationCenterModule} from "@logbook/notification-center/notification-center.module";
import {MatSnackBarModule} from "@angular/material";
import {AccessFromCuteStationGuard} from "@logbook/core/shared/guards/access-from-cute-station-guard.service";

const APP_ROUTES: Routes = [
    {
        path: '',
        component: NavbarComponent,
        outlet: 'navbar',
        resolve: {
            onCuteStation: AccessFromCuteStationGuard
        }
    },
    {
        path: 'account',
        redirectTo: '/management/account/profile'
    },
    {
        path: 'change-log',
        loadChildren: './change-log/change-log.module#ChangeLogModule'
    },
    {
        path: 'logs',
        loadChildren: '../../logs-view/logs-view.module#LogsViewModule'
    },
    {
        path: 'management',
        loadChildren: '../../../logbook/settings/src/lib/logbook-settings.module#LogbookSettingsModule',
    },
    {
        path: 'contact',
        loadChildren: '../../contact/contact.module#LogbookContactModule'
    },
    {
        path: 'instructions',
        loadChildren: '../../consigne/instructions/instructions.module#LogbookInstructionsModule'
    },
    {
        path: 'flight-report',
        loadChildren: '../../../logbook/flight-report/src/lib/flight-report.module#FlightReportModule'
    },
    {
        path: 'dashboard',
        loadChildren: '../../dashboard/dashboard.module#DashboardModule'
    },
    {
        path: SUPPORT_MAIN_ROUTE,
        loadChildren: '../../support/support.module#LogbookSupportModule'
    },
    {
        path: SUPPORT_TERMS_AND_CONTITIONS,
        loadChildren: '../../support/help-and-condition.module#LogbookHelpAndConditionModule'
    },
    {
        path: '',
        loadChildren: '../../landing/account.module#LogbookAccountModule',
        canActivate: [UserAlreadyLoggedRouteAccessService],
        runGuardsAndResolvers: "always"
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(APP_ROUTES, {useHash: false}),
        LogbookSharedModule,
        LogbookCoreSharedModule,
        NotificationCenterModule,
        MatSnackBarModule
    ],
    exports: [
        RouterModule,
        DlnMainComponent
    ],
    declarations: [
        DlnMainComponent,
        SwitchAppsMenuComponent,
        NavbarComponent,
        MobileNavbarButtonComponent,
        CheckUpdatesComponent,
        OnBoardingModalComponent
    ],
    providers: [],
    entryComponents: [OnBoardingModalComponent]
})
export class LayoutRoutingModule {
}
