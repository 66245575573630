import {Pipe, PipeTransform} from "@angular/core";
import {PieceJointe} from "../common/text-editor/attach-file/model/piece-jointe.model";

@Pipe({name: 'filterImages'})
export class FilterImagesPipe implements PipeTransform {

    transform(files: PieceJointe[]): PieceJointe[] {
        return files ? files.filter((file) => file.isImage()) : [];
    }
}
