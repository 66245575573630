"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var SendButtonsComponent = /** @class */ (function () {
    function SendButtonsComponent() {
        this.isLoading = false;
        this.isLoadingLabel = "Sending";
        this.sendAction = new core_1.EventEmitter();
        this.cancelAction = new core_1.EventEmitter();
    }
    SendButtonsComponent.prototype.sendClick = function () {
        this.sendAction.emit(null);
    };
    SendButtonsComponent.prototype.cancelClick = function () {
        this.cancelAction.emit(null);
    };
    return SendButtonsComponent;
}());
exports.SendButtonsComponent = SendButtonsComponent;
