"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var operators_1 = require("rxjs/operators");
var core_1 = require("@angular/core");
var forms_1 = require("@angular/forms");
var pageable_1 = require("../../../model/pageable");
var user_service_1 = require("../../../user/service/user.service");
var user_filter_pipe_1 = require("../../../user/pipe/user-filter.pipe");
var index_1 = require("rxjs/index");
var DlnInputUserSearchComponent = /** @class */ (function () {
    function DlnInputUserSearchComponent(userService, userFilterPipe) {
        this.userService = userService;
        this.userFilterPipe = userFilterPipe;
        this.onlyTeamMembers = false;
        this.changeEvent = new core_1.EventEmitter();
        this.userSearchControl = new forms_1.FormControl();
        this.destroy$ = new index_1.Subject();
        this.updateForm = function (_) {
        };
    }
    DlnInputUserSearchComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.userSearchControl.valueChanges.pipe(operators_1.debounceTime(250), operators_1.distinctUntilChanged(), operators_1.takeUntil(this.destroy$)).subscribe(function (search) { return _this.searchUser(search); });
    };
    DlnInputUserSearchComponent.prototype.writeValue = function (obj) {
    };
    DlnInputUserSearchComponent.prototype.registerOnChange = function (fn) {
        this.updateForm = fn;
    };
    DlnInputUserSearchComponent.prototype.registerOnTouched = function (fn) {
    };
    DlnInputUserSearchComponent.prototype.searchUser = function (query) {
        var _this = this;
        if (!this.onlyTeamMembers) {
            this.userService.findAll(new pageable_1.Pageable(0, 25, "id"), query).pipe(operators_1.first())
                .subscribe(function (res) {
                _this.userSuggestion = res.content.map(_this.mapUser);
            });
        }
        else {
            this.userSuggestion = this.userFilterPipe.transform(this.usersFilters, query).map(this.mapUser);
        }
    };
    DlnInputUserSearchComponent.prototype.updateValue = function ($event) {
        if ($event && $event.option && $event.option.value) {
            var userSelected = this.userSuggestion.find(function (i) { return i.id === $event.option.value; });
            if (userSelected) {
                this.updateForm(userSelected.user);
                this.changeEvent.emit(userSelected.user);
            }
        }
    };
    DlnInputUserSearchComponent.prototype.mapUser = function (user) {
        return {
            id: user.userId,
            name: user.displayUserName(),
            user: user
        };
    };
    DlnInputUserSearchComponent.prototype.ngOnDestroy = function () {
        this.destroy$.next();
    };
    return DlnInputUserSearchComponent;
}());
exports.DlnInputUserSearchComponent = DlnInputUserSearchComponent;
