import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {CommunityService} from "../../../../../../app/core/auth/services/community.service";
import {first} from "rxjs/operators";

@Component({
    selector: 'dln-category-tag',
    templateUrl: './category-tag.component.html',
    encapsulation: ViewEncapsulation.None,
    styleUrls: [
        'category-tag.component.scss'
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CategoryTagComponent implements OnInit {

    @Input() tag: string;
    @Input() color: string;
    @Input() closable = false;
    @Input() withBG = true;
    tagAlias: "red" | "important" | "purple" | "flightDelayed" | "mvt" | "blue" | "green" | "dark" | "gray-dark" | "gray";

    constructor(private communityService: CommunityService, private changeDetector: ChangeDetectorRef) {
    }

    ngOnInit() {
        if (!this.color) {
            this.communityService.getCommunity().pipe(first())
                .subscribe((community) => {
                    const completeTag = community.tags.find((tag) => {
                        return tag.value && this.tag && tag.value.toUpperCase() === this.tag.toUpperCase();
                    });
                    this.color = "";
                    if (completeTag) {
                        this.color = completeTag.color;
                        this.tagAlias = completeTag.alias;
                    }
                    this.changeDetector.markForCheck();
                })
        }
    }
}
