
export class ActiveComponents {

    writeLogFormExpanded: boolean;
    writeLogFormClear: boolean;
    leftPanelExpanded: boolean;
    rightPanelExpanded: boolean;

    constructor() {
        this.writeLogFormExpanded = false;
        this.writeLogFormClear = true;
        this.leftPanelExpanded = false;
        this.rightPanelExpanded = false;
    }

}
