"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var rxjs_1 = require("rxjs");
var core_1 = require("@angular/core");
var forms_1 = require("@angular/forms");
var items_list_1 = require("./items-list");
var ng_select_types_1 = require("./ng-select.types");
var virtual_scroll_component_1 = require("./virtual-scroll.component");
var NgSelectComponent = /** @class */ (function () {
    function NgSelectComponent(config, changeDetectorRef, elementRef, renderer) {
        var _this = this;
        this.changeDetectorRef = changeDetectorRef;
        this.elementRef = elementRef;
        this.renderer = renderer;
        this.clearable = true;
        this.displayItem = 'block';
        this.notFoundText = 'No items found';
        this.typeToSearchText = 'Type to search';
        this.addTagText = 'Add item';
        this.removeFromListIfSelected = false;
        this.filterItemSelected = true;
        this.suggestionPosition = 'absolute';
        this.multiple = false;
        this.addTag = false;
        this.addTagByButton = false;
        // output events
        this.blurEvent = new core_1.EventEmitter();
        this.focusEvent = new core_1.EventEmitter();
        this.changeEvent = new core_1.EventEmitter();
        this.openEvent = new core_1.EventEmitter();
        this.closeEvent = new core_1.EventEmitter();
        this.searchEvent = new core_1.EventEmitter();
        this.selectedEvent = new core_1.EventEmitter();
        this.removeEvent = new core_1.EventEmitter();
        this.isOpen = false;
        this.isFocused = false;
        this.isDisabled = false;
        this.itemsList = new items_list_1.ItemsList();
        this.viewPortItems = [];
        this.isLoading = false;
        this.filterValue = null;
        this._items$ = new rxjs_1.Subject();
        this._writeValue$ = new rxjs_1.Subject();
        this._checkWriteValue = false;
        this._writeValueHandler$ = null;
        this.onChange = function (_) {
        };
        this.onTouched = function () {
        };
        this.disposeDocumentClickListener = function () {
        };
        this.clearItem = function (item) { return _this.unselect(item); };
        this.mergeConfig(config);
        this.handleWriteValue();
    }
    Object.defineProperty(NgSelectComponent.prototype, "single", {
        get: function () {
            return !this.multiple;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NgSelectComponent.prototype, "filtered", {
        get: function () {
            return !!this.filterValue;
        },
        enumerable: true,
        configurable: true
    });
    ;
    Object.defineProperty(NgSelectComponent.prototype, "items", {
        get: function () {
            return this.itemsList.items;
        },
        set: function (items) {
            var _this = this;
            if (items) {
                items = items.map(function (item) {
                    var isSelected = _this.selectedItems.some(function (selectedItem) {
                        return selectedItem.id === item.id;
                    });
                    if (isSelected) {
                        item.selected = true;
                    }
                    return item;
                });
            }
            this.setItems(items || []);
            this._items$.next(true);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NgSelectComponent.prototype, "selectedItems", {
        get: function () {
            return this.itemsList.value;
        },
        enumerable: true,
        configurable: true
    });
    NgSelectComponent.prototype.ngOnInit = function () {
        this.handleDocumentClick();
        this.bindLabel = this.bindLabel || 'label';
    };
    NgSelectComponent.prototype.ngOnChanges = function (changes) {
        if (changes.multiple) {
            this.itemsList.setMultiple(changes.multiple.currentValue);
        }
    };
    NgSelectComponent.prototype.ngOnDestroy = function () {
        this.changeDetectorRef.detach();
        this.disposeDocumentClickListener();
        this._writeValueHandler$.unsubscribe();
    };
    NgSelectComponent.prototype.handleKeyDown = function ($event) {
        if (ng_select_types_1.KeyCode[$event.which]) {
            switch ($event.which) {
                case ng_select_types_1.KeyCode.ArrowDown:
                    this.handleArrowDown($event);
                    break;
                case ng_select_types_1.KeyCode.ArrowUp:
                    this.handleArrowUp($event);
                    break;
                case ng_select_types_1.KeyCode.Space:
                    this.handleSpace($event);
                    break;
                case ng_select_types_1.KeyCode.Enter:
                    this.handleEnter($event);
                    break;
                case ng_select_types_1.KeyCode.Tab:
                    this.handleTab($event);
                    break;
                case ng_select_types_1.KeyCode.Esc:
                    this.close();
                    break;
                case ng_select_types_1.KeyCode.Backspace:
                    this.handleBackspace();
                    break;
            }
        }
    };
    NgSelectComponent.prototype.handleSelectClick = function ($event) {
        $event.stopPropagation();
        this.open();
    };
    NgSelectComponent.prototype.handleClearClick = function ($event) {
        $event.stopPropagation();
        this.clear();
    };
    NgSelectComponent.prototype.clear = function () {
        var _this = this;
        if (!this.clearable) {
            return;
        }
        this.itemsList._selected.forEach(function (item) {
            _this.removeEvent.emit(item);
        });
        this.itemsList.clearSelected();
        this.clearSearch();
        this.notifyModelChanged();
        if (this.isTypeahead()) {
            this.typeahead.next(this.filterValue);
        }
    };
    NgSelectComponent.prototype.writeValue = function (value) {
        this._checkWriteValue = true;
        this._writeValue$.next(value);
    };
    NgSelectComponent.prototype.registerOnChange = function (fn) {
        this.onChange = fn;
    };
    NgSelectComponent.prototype.registerOnTouched = function (fn) {
        this.onTouched = fn;
    };
    NgSelectComponent.prototype.setDisabledState = function (isDisabled) {
        this.isDisabled = isDisabled;
    };
    NgSelectComponent.prototype.open = function () {
        if (this.isDisabled || this.isOpen) {
            return;
        }
        this.isOpen = true;
        this.itemsList.markItem();
        this.scrollToMarked();
        this.focusSearchInput();
        this.openEvent.emit();
    };
    NgSelectComponent.prototype.close = function () {
        if (!this.isOpen) {
            return;
        }
        this.isOpen = false;
        if (this.filterValue && this.addTag && !this.addTagByButton) {
            this.selectTag();
        }
        this.clearSearch();
        this.closeEvent.emit();
    };
    NgSelectComponent.prototype.toggle = function (item) {
        if (!item || item.disabled || item.isParent) {
            return;
        }
        if (this.multiple && item.selected) {
            this.unselect(item);
            this.close();
        }
        else {
            this.select(item);
        }
    };
    NgSelectComponent.prototype.select = function (item) {
        this.selectedEvent.emit(item);
        this._checkWriteValue = false;
        if (!item.selected) {
            this.itemsList.select(item);
            this.clearSearch();
            this.updateModel();
        }
    };
    NgSelectComponent.prototype.unselect = function (item) {
        this._checkWriteValue = false;
        this.removeEvent.emit(item);
        this.itemsList.unselect(item);
        this.updateModel();
    };
    NgSelectComponent.prototype.selectTag = function () {
        var tag = {};
        if (this.filterValue && this.filterValue.trim().length) {
            if (this.addTag instanceof Function) {
                tag = this.addTag(this.filterValue);
            }
            else {
                tag[this.bindLabel] = this.filterValue;
            }
            this.itemsList.addTag(tag);
            this.select(tag);
        }
    };
    NgSelectComponent.prototype.showPlaceholder = function () {
        return this.placeholder && !this.isValueSet(this.selectedItems) && !this.filterValue;
    };
    NgSelectComponent.prototype.showClear = function () {
        return this.clearable && (this.isValueSet(this.selectedItems) || this.filterValue != null) && !this.isDisabled;
    };
    NgSelectComponent.prototype.showFilter = function () {
        return !this.isDisabled;
    };
    NgSelectComponent.prototype.showNoItemsFound = function () {
        var empty = this.itemsList.filteredItems.length === 0;
        return (empty && !this.isTypeahead()) ||
            (empty && this.isTypeahead() && this.filterValue && !this.isLoading);
    };
    NgSelectComponent.prototype.showTypeToSearch = function () {
        var empty = this.itemsList.filteredItems.length === 0;
        return empty && this.isTypeahead() && !this.filterValue && !this.isLoading;
    };
    NgSelectComponent.prototype.onFilter = function ($event) {
        if (!this.isOpen) {
            this.open();
        }
        this.filterValue = $event.target.value;
        if (this.isTypeahead()) {
            this.isLoading = true;
            this.typeahead.next(this.filterValue);
        }
        else {
            this.itemsList.filter(this.filterValue, this.bindLabel);
        }
    };
    NgSelectComponent.prototype.onInputFocus = function ($event) {
        this.isFocused = true;
        this.focusEvent.emit(null);
    };
    NgSelectComponent.prototype.onInputBlur = function () {
        this.isFocused = false;
        this.blurEvent.emit(null);
        if (!this.isOpen && !this.isDisabled) {
            this.onTouched();
        }
    };
    NgSelectComponent.prototype.onItemHover = function (item) {
        if (item.disabled) {
            return;
        }
        this.itemsList.markItem(item);
    };
    NgSelectComponent.prototype.isParentHasVisibleItem = function (parentId) {
        return this.viewPortItems.some(function (item) { return (item.parentId === parentId && !item.disabled); });
    };
    NgSelectComponent.prototype.handleWriteValue = function () {
        var _this = this;
        // combineLatest ensures that write value is always set after latest items are loaded
        this._writeValueHandler$ = rxjs_1.combineLatest(this._items$, this._writeValue$).subscribe(function (result) {
            if (!_this._checkWriteValue) {
                return;
            }
            var value = result[1];
            _this.validateWriteValue(value);
            _this.itemsList.clearSelected();
            if (value) {
                if (_this.multiple) {
                    value.forEach(function (item) {
                        _this.selectWriteValue(item);
                    });
                }
                else {
                    _this.selectWriteValue(value);
                }
            }
            _this.detectChanges();
        });
    };
    NgSelectComponent.prototype.setItems = function (items) {
        this.itemsList.setItems(items);
        if (this.isTypeahead()) {
            this.isLoading = false;
            this.itemsList.markItem();
        }
    };
    NgSelectComponent.prototype.handleDocumentClick = function () {
        var _this = this;
        var handler = function ($event) {
            // prevent close if clicked on select
            if (_this.elementRef.nativeElement.contains($event.target)) {
                return;
            }
            // prevent close if clicked on dropdown menu
            var dropdown = _this.getDropdownMenu();
            if (dropdown && dropdown.contains($event.target)) {
                return;
            }
            if (typeof $event.target.closest === "function" && $event.target.closest('.ng-option')) {
                return;
            }
            if (_this.isFocused) {
                _this.onInputBlur();
            }
            if (_this.isOpen) {
                _this.close();
            }
        };
        this.disposeDocumentClickListener = this.renderer.listen('document', 'mousedown', handler);
    };
    NgSelectComponent.prototype.validateWriteValue = function (value) {
        var _this = this;
        if (!value) {
            return;
        }
        var validateBinding = function (item) {
            if (item instanceof Object && _this.bindValue) {
                throw new Error('Binding object with bindValue is not allowed.');
            }
        };
        if (this.multiple) {
            if (!Array.isArray(value)) {
                throw new Error('Multiple select model should be array.');
            }
            value.forEach(function (item) { return validateBinding(item); });
        }
        else {
            validateBinding(value);
        }
    };
    NgSelectComponent.prototype.selectWriteValue = function (value) {
        var item = this.itemsList.findItem(value, this.bindLabel, this.bindValue);
        if (item) {
            this.itemsList.select(item);
        }
        else {
            this.itemsList.select(value);
        }
    };
    NgSelectComponent.prototype.updateModel = function () {
        this.notifyModelChanged();
        this.changeDetectorRef.markForCheck();
    };
    NgSelectComponent.prototype.clearSearch = function () {
        this.filterValue = null;
        this.itemsList.clearFilter();
    };
    NgSelectComponent.prototype.focusSearchInput = function () {
        var _this = this;
        setTimeout(function () {
            _this.filterInput.nativeElement.focus(); // TODO: this won't work on mobile
        });
    };
    NgSelectComponent.prototype.scrollToMarked = function () {
        this.dropdownList.scrollInto(this.itemsList.markedItem);
    };
    NgSelectComponent.prototype.handleTab = function ($event) {
        if (this.isOpen) {
            this.close();
        }
    };
    NgSelectComponent.prototype.handleEnter = function ($event) {
        if (this.isOpen) {
            if (this.itemsList.markedItem && !this.itemsList.markedItem.isParent) {
                this.toggle(this.itemsList.markedItem);
            }
            else if (this.addTag && !this.addTagByButton) {
                this.selectTag();
            }
        }
        $event.preventDefault();
    };
    NgSelectComponent.prototype.handleSpace = function ($event) {
        if (this.isOpen) {
            var isItem = this.itemsList.filteredItems.some(function (item) { return !item.isParent; });
            if (!isItem && this.addTag && !this.addTagByButton) {
                this.selectTag();
            }
            return;
        }
        this.open();
        $event.preventDefault();
    };
    NgSelectComponent.prototype.handleArrowDown = function ($event) {
        if (!this.isOpen) {
            this.open();
        }
        else {
            this.itemsList.markNextItem();
            this.scrollToMarked();
        }
        $event.preventDefault();
    };
    NgSelectComponent.prototype.handleArrowUp = function ($event) {
        this.itemsList.markPreviousItem();
        this.scrollToMarked();
        $event.preventDefault();
    };
    NgSelectComponent.prototype.handleBackspace = function () {
        if (this.filterValue) {
            return;
        }
        if (this.multiple) {
            this.itemsList.unselectLastItem();
            this.updateModel();
        }
        else {
            this.clear();
        }
    };
    NgSelectComponent.prototype.notifyModelChanged = function () {
        var _this = this;
        var value = this.value;
        if (!value) {
            this.onChange(null);
        }
        else if (this.bindValue) {
            var bindValue = Array.isArray(value) ?
                value.map(function (x) { return x[_this.bindValue]; }) :
                value[this.bindValue];
            this.onChange(bindValue);
        }
        else {
            this.onChange(value);
        }
        this.changeEvent.emit(value);
    };
    NgSelectComponent.prototype.getDropdownMenu = function () {
        if (!this.isOpen || !this.dropdownList) {
            return null;
        }
        return this.elementRef.nativeElement.querySelector('.ng-menu-outer');
    };
    NgSelectComponent.prototype.isTypeahead = function () {
        return this.typeahead && this.typeahead.observers.length > 0;
    };
    NgSelectComponent.prototype.detectChanges = function () {
        if (!this.changeDetectorRef.destroyed) {
            this.changeDetectorRef.detectChanges();
        }
    };
    Object.defineProperty(NgSelectComponent.prototype, "value", {
        get: function () {
            if (this.multiple) {
                return this.selectedItems;
            }
            return this.selectedItems[0] || null;
        },
        enumerable: true,
        configurable: true
    });
    NgSelectComponent.prototype.isValueSet = function (value) {
        return !!value && value.length > 0;
    };
    NgSelectComponent.prototype.mergeConfig = function (config) {
        if (!config) {
            return;
        }
        this.notFoundText = config.notFoundText || this.notFoundText;
        this.typeToSearchText = config.typeToSearchText || this.typeToSearchText;
        this.addTagText = config.addTagText || this.addTagText;
    };
    return NgSelectComponent;
}());
exports.NgSelectComponent = NgSelectComponent;
