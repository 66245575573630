import {Injector, ModuleWithProviders, NgModule, Optional, SkipSelf} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LocalStorageService, SessionStorageService} from "ngx-webstorage";
import {HTTP_INTERCEPTORS} from "@angular/common/http";
import {AuthInterceptor} from "@logbook/core/interceptor/auth.interceptor";
import {LogbookCoreAuthenticationModule} from "@logbook/core/auth/core-authentication.module";
import {AuthExpiredInterceptor} from "@logbook/core/interceptor/auth-expired.interceptor";
import {ErrorHandlerInterceptor} from "@logbook/core/interceptor/errorhandler.interceptor";
import {NotificationInterceptor} from "@logbook/core/interceptor/notification.interceptor";
import {LogbookCoreTrackingModule} from "@logbook/core/tracking/tracking.module";
import {LogbookCoreSharedModule} from "@logbook/core/shared/core-shared.module";
import {LayoutRoutingModule} from "@logbook/core/layouts/layout-routing.module";
import {LogbookConfig} from "./shared/config/logbook-config";
import {IntercomModule} from "ng-intercom";
import {INTERCOM_TOKEN} from "@logbook/app.constants";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {ResponsiveModule} from "ngx-responsive";
import {responsiveConfig} from "@logbook/shared";

@NgModule({
    imports: [
        CommonModule,
        BrowserAnimationsModule,
        ResponsiveModule.forRoot(responsiveConfig),
        IntercomModule.forRoot({
            appId: INTERCOM_TOKEN,
            updateOnRouterChange: true
        }),
        LogbookCoreAuthenticationModule,
        LogbookCoreTrackingModule.forRoot(),
        LogbookCoreSharedModule,
        LayoutRoutingModule
    ],
    declarations: [],
    providers : [
    ],
    exports: [
        LogbookCoreSharedModule
    ]
})
export class LogbookCoreModule {
    constructor(@Optional() @SkipSelf() parentModule: LogbookCoreModule) {
        if (parentModule) {
            throw new Error(`LogbookCoreModule has already been loaded. Import Core modules in the AppModule only.`);
        }
    }

    static forRoot(config: LogbookConfig): ModuleWithProviders {
        return {
            ngModule: LogbookCoreModule,
            providers: [
                {
                    provide: LogbookConfig,
                    useValue: config
                },
                {
                    provide: HTTP_INTERCEPTORS,
                    useClass: AuthInterceptor,
                    multi: true,
                    deps: [
                        LocalStorageService,
                        SessionStorageService
                    ]
                }, {
                    provide: HTTP_INTERCEPTORS,
                    useClass: AuthExpiredInterceptor,
                    multi: true,
                    deps: [
                        Injector
                    ]
                }, {
                    provide: HTTP_INTERCEPTORS,
                    useClass: ErrorHandlerInterceptor,
                    multi:
                        true,
                    deps: []
                }, {
                    provide: HTTP_INTERCEPTORS,
                    useClass: NotificationInterceptor,
                    multi:
                        true,
                    deps: []
                }
            ]
        };
    }
}
