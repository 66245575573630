import {Injectable} from '@angular/core';
import {Observer, Observable} from "rxjs";
import {ActiveComponents} from "@logbook/logs-view/shared/models";

@Injectable({
    providedIn: 'root'
})
export class LogsViewManagementService {
    private listenerRefreshActiveComponents: Observable<any>;
    private observerRefreshActiveComponents: Array<Observer<any>>;
    public activeComponents = new ActiveComponents();

    constructor() {
        this.observerRefreshActiveComponents = [];
        this.listenerRefreshActiveComponents = this.createListener();
    }

    subscribeActiveComponents(): Observable<any> {
        return this.listenerRefreshActiveComponents;
    }

    expandWriteLogForm(clear = false, forced = false, forcedValue = true) {
        if (clear) {
            this.activeComponents.writeLogFormExpanded = false;
            this.activeComponents.writeLogFormClear = true;
        } else {
            this.activeComponents.writeLogFormClear = false;
            if (forced) {
                this.activeComponents.writeLogFormExpanded = forcedValue;
            } else {
                this.activeComponents.writeLogFormExpanded = !this.activeComponents.writeLogFormExpanded;
            }
        }

        this.notifyObserver();
    }

    expandLeftPanel() {
        this.activeComponents.leftPanelExpanded = !this.activeComponents.leftPanelExpanded;

        if (this.activeComponents.leftPanelExpanded) {
            this.activeComponents.rightPanelExpanded = false;
        }

        this.notifyObserver();
    }

    expandRightPanel() {
        this.activeComponents.rightPanelExpanded = !this.activeComponents.rightPanelExpanded;

        if (this.activeComponents.rightPanelExpanded) {
            this.activeComponents.leftPanelExpanded = false;
        }

        this.notifyObserver();
    }

    private notifyObserver() {
        this.observerRefreshActiveComponents.forEach((observer) =>
            observer.next(this.activeComponents)
        );
    }

    private createListener(): Observable<any> {
        return new Observable((observer) => {
            this.observerRefreshActiveComponents.push(observer);
        });
    }
}
