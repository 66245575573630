"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var NgOptionTemplateDirective = /** @class */ (function () {
    function NgOptionTemplateDirective(template) {
        this.template = template;
    }
    return NgOptionTemplateDirective;
}());
exports.NgOptionTemplateDirective = NgOptionTemplateDirective;
var NgLabelTemplateDirective = /** @class */ (function () {
    function NgLabelTemplateDirective(template) {
        this.template = template;
    }
    return NgLabelTemplateDirective;
}());
exports.NgLabelTemplateDirective = NgLabelTemplateDirective;
var NgAddTagTemplateDirective = /** @class */ (function () {
    function NgAddTagTemplateDirective(template) {
        this.template = template;
    }
    return NgAddTagTemplateDirective;
}());
exports.NgAddTagTemplateDirective = NgAddTagTemplateDirective;
