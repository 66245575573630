import {Directive, ElementRef, HostListener, Input, OnDestroy} from '@angular/core';

@Directive({
    selector: '[dlnTooltip]'
})

export class TooltipDirective implements  OnDestroy {

    tooltip: any;
    elemPosition: any;
    tooltipOffset = 8;
    hideTimeoutId;
    showTimeoutId;

    @Input() private tooltipText = "";
    @Input() private tooltipActive = true;
    @Input() private tooltipClass = "";
    @Input() private placement = "top";
    @Input() private delay = 0;
    @Input() private showDelay = 0;
    @Input() private hideDelay = 300;
    @Input() private zIndex = false;

    constructor(private elementRef: ElementRef) {
    }

    @HostListener("focusin")
    @HostListener("mouseenter")
    onMouseEnter() {
        this.getElemPosition();

        if (this.tooltipActive && !this.tooltip && this.tooltipText && this.tooltipText.length) {
            this.create();
            this.setPosition();
            this.show();
        }
    }

    @HostListener("focusout")
    @HostListener("click")
    @HostListener("window:click")
    @HostListener("mouseleave")
    onMouseLeave() {
        this.hide();
    }

    getElemPosition() {
        this.elemPosition = this.elementRef.nativeElement.getBoundingClientRect();
    }

    create() {
        this.showDelay = this.delay || this.showDelay;
        this.tooltip = document.createElement('span');
        this.tooltip.className += "ng-tooltip ng-tooltip-" + this.placement + " " + this.tooltipClass;
        this.tooltip.innerHTML = this.tooltipText;
        if (this.zIndex) {
            this.tooltip.style.zIndex = this.zIndex;
        }

        document.body.appendChild(this.tooltip);
    }

    show() {
        if (this.showTimeoutId) {
            clearTimeout(this.showTimeoutId);
        }

        this.showDelay = this.delay || this.showDelay;
        this.showTimeoutId = setTimeout(() => {
            if (this.tooltip) {
                this.tooltip.className += " ng-tooltip-show";
            }
        }, this.showDelay);
    }

    hide() {
        if (this.hideTimeoutId) {
            clearTimeout(this.hideTimeoutId);
        }

        if (this.tooltip) {
            this.tooltip.classList.remove("ng-tooltip-show");
            this.hideTimeoutId = setTimeout(() => {
                this.tooltip.parentNode.removeChild(this.tooltip);
                this.tooltip = null;
            }, this.hideDelay);
        }
    }

    setPosition() {
        const elemHeight = this.elementRef.nativeElement.offsetHeight;
        const elemWidth = this.elementRef.nativeElement.offsetWidth;
        const tooltipHeight = this.tooltip.clientHeight;
        const tooltipWidth = this.tooltip.offsetWidth;
        const scrollY = window.pageYOffset;

        if (this.placement === 'top') {
            this.tooltip.style.top = (this.elemPosition.top + scrollY) - (tooltipHeight + this.tooltipOffset) + 'px';
        }

        if (this.placement === 'bottom') {
            this.tooltip.style.top = (this.elemPosition.top + scrollY) + elemHeight + this.tooltipOffset + 'px';
        }

        if (this.placement === 'top' || this.placement === 'bottom') {
            this.tooltip.style.left = (this.elemPosition.left + elemWidth / 2) - tooltipWidth / 2 + 'px';
        }

        if (this.placement === 'left') {
            this.tooltip.style.left = this.elemPosition.left - tooltipWidth - this.tooltipOffset + 'px';
        }

        if (this.placement === 'right') {
            this.tooltip.style.left = this.elemPosition.left + elemWidth + this.tooltipOffset + 'px';
        }

        if (this.placement === 'left' || this.placement === 'right') {
            this.tooltip.style.top = (this.elemPosition.top + scrollY) + elemHeight / 2 - this.tooltip.clientHeight / 2 + 'px';
        }
    }

    ngOnDestroy(): void {
        this.hide()
    }
}
