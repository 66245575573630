"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var operators_1 = require("rxjs/operators");
var core_1 = require("@angular/core");
var shared_1 = require("@logbook/shared");
var logs_api_service_1 = require("../logs.api.service");
var logs_service_1 = require("../logs.service");
var comment_api_service_1 = require("./comment.api.service");
var principal_service_1 = require("@logbook/core/auth/services/principal.service");
var models_1 = require("@logbook/logs-view/shared/models");
var CommentService = /** @class */ (function () {
    function CommentService(commentApiService, logsService, logApiService, principal, notificationWsService) {
        var _this = this;
        this.commentApiService = commentApiService;
        this.logsService = logsService;
        this.logApiService = logApiService;
        this.principal = principal;
        this.notificationWsService = notificationWsService;
        this.principal.getAuthenticationState().pipe(operators_1.startWith(this.principal.identityDirect()))
            .subscribe(function (identity) {
            if (identity != null) {
                _this.currentUser = identity;
                _this.wsPublish = _this.notificationWsService.onEventPublished(shared_1.LogEventType.CommentairePublie)
                    .subscribe(function (notif) {
                    if (_this.currentUser.userId !== notif.comment.emetteurId.id) {
                        _this.logApiService
                            .findLog(new models_1.MessageGroupID(notif.messageId, notif.filInformations.id))
                            .pipe(operators_1.first())
                            .subscribe(function (res) { return _this.logsService.updateLog(res); });
                    }
                });
                _this.wsEdit = _this.notificationWsService.onEventPublished(shared_1.LogEventType.CommentaireEdite)
                    .subscribe(function (notif) {
                    if (_this.currentUser.userId !== notif.commentEdite.emetteurId.id) {
                        _this.logApiService
                            .findLog(new models_1.MessageGroupID(notif.messageId, notif.filInformationsId))
                            .pipe(operators_1.first())
                            .subscribe(function (res) { return _this.logsService.updateLog(res); });
                    }
                });
                _this.wsDelete = _this.notificationWsService.onEventPublished(shared_1.LogEventType.CommentaireSupprime)
                    .subscribe(function (notif) {
                    _this.logApiService
                        .findLog(new models_1.MessageGroupID(notif.messageId, notif.filInformationsId))
                        .pipe(operators_1.first())
                        .subscribe(function (res) { return _this.logsService.updateLog(res); });
                });
            }
        });
    }
    CommentService.prototype.postComment = function (threadId, messageId, formData, notify) {
        var _this = this;
        if (notify === void 0) { notify = false; }
        return this.commentApiService.postComment(threadId, messageId, formData, notify).pipe(operators_1.map(function (log) {
            return _this.logApiService.updateEmitterInformation(log);
        }), operators_1.map(function (log) {
            _this.logsService.updateLog(log);
            return log;
        }));
    };
    CommentService.prototype.editComment = function (threadId, messageId, commentId, formData) {
        var _this = this;
        return this.commentApiService.editComment(threadId, messageId, commentId, formData).pipe(operators_1.map(function (log) {
            return _this.logApiService.updateEmitterInformation(log);
        }), operators_1.map(function (log) {
            _this.logsService.updateLogComment(log, commentId);
            return log;
        }));
    };
    CommentService.prototype.removeComment = function (threadId, messageId, commentId) {
        var _this = this;
        return this.commentApiService.removeComment(threadId, messageId, commentId).pipe(operators_1.map(function (log) {
            _this.logsService.updateLog(log);
            return log;
        }));
    };
    CommentService.prototype.ngOnDestroy = function () {
        if (this.wsDelete) {
            this.wsDelete.unsubscribe();
        }
        if (this.wsEdit) {
            this.wsEdit.unsubscribe();
        }
        if (this.wsPublish) {
            this.wsPublish.unsubscribe();
        }
    };
    return CommentService;
}());
exports.CommentService = CommentService;
