"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var momentImported = require("moment");
var moment = momentImported;
var forms_1 = require("@angular/forms");
var date_utils_1 = require("../../../util/date-utils");
var DlnInputTimeComponent = /** @class */ (function () {
    function DlnInputTimeComponent() {
        this.control = new forms_1.FormControl();
        this.defaultDate = new Date();
    }
    DlnInputTimeComponent.prototype.setNewDate = function (newDate) {
        this.control.setValue(moment.utc(date_utils_1.DateUtils.createDateAsUTC(newDate)).toISOString());
    };
    DlnInputTimeComponent.prototype.getDate = function () {
        var dateStr = this.control.value;
        if (dateStr) {
            return date_utils_1.DateUtils.convertDateToUTC(new Date(dateStr));
        }
        return date_utils_1.DateUtils.convertDateToUTC(new Date(this.defaultDate));
    };
    DlnInputTimeComponent.prototype.displayDate = function () {
        return moment(this.getDate())
            .format('HH:mm');
    };
    return DlnInputTimeComponent;
}());
exports.DlnInputTimeComponent = DlnInputTimeComponent;
