"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./notification.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("../../../../../node_modules/@angular/material/menu/typings/index.ngfactory");
var i3 = require("@angular/material/menu");
var i4 = require("@angular/common");
var i5 = require("@angular/cdk/a11y");
var i6 = require("../../../../logbook/shared/src/lib/pipe/safe-html.pipe");
var i7 = require("@angular/platform-browser");
var i8 = require("../notification-category/notification-category.component.ngfactory");
var i9 = require("../notification-category/notification-category.component");
var i10 = require("../../../../logbook/shared/src/lib/pipe/date/time-ago.pipe");
var i11 = require("@angular/cdk/overlay");
var i12 = require("@angular/cdk/bidi");
var i13 = require("./notification.component");
var styles_NotificationComponent = [i0.styles];
var RenderType_NotificationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NotificationComponent, data: {} });
exports.RenderType_NotificationComponent = RenderType_NotificationComponent;
function View_NotificationComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["class", "mat-menu-item"], ["mat-menu-item", ""], ["role", "menuitem"]], [[2, "mat-menu-item-highlighted", null], [2, "mat-menu-item-submenu-trigger", null], [1, "tabindex", 0], [1, "aria-disabled", 0], [1, "disabled", 0]], [[null, "click"], [null, "mouseenter"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1)._checkDisabled($event) !== false);
        ad = (pd_0 && ad);
    } if (("mouseenter" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1)._handleMouseEnter() !== false);
        ad = (pd_1 && ad);
    } if (("click" === en)) {
        var pd_2 = (_co.readNotification() !== false);
        ad = (pd_2 && ad);
    } return ad; }, i2.View_MatMenuItem_0, i2.RenderType_MatMenuItem)), i1.ɵdid(1, 180224, [[1, 4]], 0, i3.MatMenuItem, [i1.ElementRef, i4.DOCUMENT, i5.FocusMonitor, [2, i3.ɵf23]], null, null), (_l()(), i1.ɵted(-1, 0, [" Mark as read "]))], null, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1)._highlighted; var currVal_1 = i1.ɵnov(_v, 1)._triggersSubmenu; var currVal_2 = i1.ɵnov(_v, 1)._getTabIndex(); var currVal_3 = i1.ɵnov(_v, 1).disabled.toString(); var currVal_4 = (i1.ɵnov(_v, 1).disabled || null); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }); }
function View_NotificationComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i6.SafeHtmlPipe, [i7.DomSanitizer]), (_l()(), i1.ɵeld(1, 0, null, null, 11, "div", [["class", "notification-card"]], [[2, "isRead", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.viewNotification() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 5, "div", [["class", "header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "notification-category", [], null, null, null, i8.View_NotificationCategoryComponent_0, i8.RenderType_NotificationCategoryComponent)), i1.ɵdid(4, 114688, null, 0, i9.NotificationCategoryComponent, [], { category: [0, "category"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "date"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, [" - ", " "])), i1.ɵpid(0, i10.TimeAgoPipe, [i1.ChangeDetectorRef, i1.NgZone]), (_l()(), i1.ɵeld(8, 0, null, null, 4, "div", [["class", "body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "div", [["class", "title"]], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, [" ", " "])), (_l()(), i1.ɵeld(11, 0, null, null, 1, "div", [["class", "description"]], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵppd(12, 1), (_l()(), i1.ɵeld(13, 16777216, null, null, 2, "span", [["aria-haspopup", "true"], ["class", "dropdown-toggle"]], [[2, "isRead", null], [1, "aria-expanded", 0]], [[null, "mousedown"], [null, "keydown"], [null, "click"]], function (_v, en, $event) { var ad = true; if (("mousedown" === en)) {
        var pd_0 = (i1.ɵnov(_v, 14)._handleMousedown($event) !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 14)._handleKeydown($event) !== false);
        ad = (pd_1 && ad);
    } if (("click" === en)) {
        var pd_2 = (i1.ɵnov(_v, 14)._handleClick($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(14, 1196032, null, 0, i3.MatMenuTrigger, [i11.Overlay, i1.ElementRef, i1.ViewContainerRef, i3.MAT_MENU_SCROLL_STRATEGY, [2, i3.MatMenu], [8, null], [2, i12.Directionality], i5.FocusMonitor], { menu: [0, "menu"] }, null), (_l()(), i1.ɵeld(15, 0, null, null, 0, "i", [["class", "dln-icon-see_more"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 9, "mat-menu", [], null, null, null, i2.View_MatMenu_0, i2.RenderType_MatMenu)), i1.ɵprd(6144, null, i3.ɵf23, null, [i3.MatMenu]), i1.ɵdid(18, 1294336, [["actionMenu", 4]], 2, i3.MatMenu, [i1.ElementRef, i1.NgZone, i3.MAT_MENU_DEFAULT_OPTIONS], { xPosition: [0, "xPosition"], yPosition: [1, "yPosition"], overlapTrigger: [2, "overlapTrigger"], panelClass: [3, "panelClass"] }, null), i1.ɵqud(603979776, 1, { items: 1 }), i1.ɵqud(335544320, 2, { lazyContent: 0 }), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_NotificationComponent_1)), i1.ɵdid(22, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(23, 0, null, 0, 2, "button", [["class", "mat-menu-item"], ["mat-menu-item", ""], ["role", "menuitem"]], [[2, "mat-menu-item-highlighted", null], [2, "mat-menu-item-submenu-trigger", null], [1, "tabindex", 0], [1, "aria-disabled", 0], [1, "disabled", 0]], [[null, "click"], [null, "mouseenter"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 24)._checkDisabled($event) !== false);
        ad = (pd_0 && ad);
    } if (("mouseenter" === en)) {
        var pd_1 = (i1.ɵnov(_v, 24)._handleMouseEnter() !== false);
        ad = (pd_1 && ad);
    } if (("click" === en)) {
        var pd_2 = (_co.deleteNotification() !== false);
        ad = (pd_2 && ad);
    } return ad; }, i2.View_MatMenuItem_0, i2.RenderType_MatMenuItem)), i1.ɵdid(24, 180224, [[1, 4]], 0, i3.MatMenuItem, [i1.ElementRef, i4.DOCUMENT, i5.FocusMonitor, [2, i3.ɵf23]], null, null), (_l()(), i1.ɵted(-1, 0, [" Delete "]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = ((_co.notification == null) ? null : _co.notification.category); _ck(_v, 4, 0, currVal_1); var currVal_7 = i1.ɵnov(_v, 18); _ck(_v, 14, 0, currVal_7); var currVal_8 = "before"; var currVal_9 = "below"; var currVal_10 = false; var currVal_11 = "blue-menu-background"; _ck(_v, 18, 0, currVal_8, currVal_9, currVal_10, currVal_11); var currVal_12 = !((_co.notification == null) ? null : _co.notification.readAt); _ck(_v, 22, 0, currVal_12); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.notification == null) ? null : _co.notification.readAt); _ck(_v, 1, 0, currVal_0); var currVal_2 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform(((_co.notification == null) ? null : _co.notification.createdAt))); _ck(_v, 6, 0, currVal_2); var currVal_3 = ((_co.notification == null) ? null : _co.notification.title); _ck(_v, 10, 0, currVal_3); var currVal_4 = i1.ɵunv(_v, 11, 0, _ck(_v, 12, 0, i1.ɵnov(_v, 0), ((_co.notification == null) ? null : _co.notification.description))); _ck(_v, 11, 0, currVal_4); var currVal_5 = ((_co.notification == null) ? null : _co.notification.readAt); var currVal_6 = (i1.ɵnov(_v, 14).menuOpen || null); _ck(_v, 13, 0, currVal_5, currVal_6); var currVal_13 = i1.ɵnov(_v, 24)._highlighted; var currVal_14 = i1.ɵnov(_v, 24)._triggersSubmenu; var currVal_15 = i1.ɵnov(_v, 24)._getTabIndex(); var currVal_16 = i1.ɵnov(_v, 24).disabled.toString(); var currVal_17 = (i1.ɵnov(_v, 24).disabled || null); _ck(_v, 23, 0, currVal_13, currVal_14, currVal_15, currVal_16, currVal_17); }); }
exports.View_NotificationComponent_0 = View_NotificationComponent_0;
function View_NotificationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "notification", [], null, null, null, View_NotificationComponent_0, RenderType_NotificationComponent)), i1.ɵdid(1, 49152, null, 0, i13.NotificationComponent, [], null, null)], null, null); }
exports.View_NotificationComponent_Host_0 = View_NotificationComponent_Host_0;
var NotificationComponentNgFactory = i1.ɵccf("notification", i13.NotificationComponent, View_NotificationComponent_Host_0, { notification: "notification" }, { read: "read", delete: "delete", view: "view" }, []);
exports.NotificationComponentNgFactory = NotificationComponentNgFactory;
