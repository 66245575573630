"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var router_1 = require("@angular/router");
var moment = require("moment");
var rxjs_1 = require("rxjs");
var mixpanel_service_1 = require("@logbook/core/tracking/mixpanel.service");
var mixpanel_event_enum_1 = require("@logbook/core/tracking/mixpanel-event.enum");
var models_1 = require("@logbook/logs-view/shared/models");
var i0 = require("@angular/core");
var i1 = require("@angular/router");
var i2 = require("../../../../core/tracking/mixpanel.service");
var FiltersService = /** @class */ (function () {
    function FiltersService(router, mixpanelService) {
        this.router = router;
        this.mixpanelService = mixpanelService;
        this.filters = new models_1.Filters();
        this.allLogFilters = [];
        this.observerRefreshFilters = [];
        this.listenerRefreshFilters = this.createListener();
    }
    FiltersService.prototype.subscribeFilters = function () {
        return this.listenerRefreshFilters;
    };
    FiltersService.prototype.initFilter = function (date, categoryTag, flightTag) {
        if (date === void 0) { date = moment(); }
        if (categoryTag === void 0) { categoryTag = []; }
        if (flightTag === void 0) { flightTag = []; }
        this.filters.date = date;
        this.filters.categoryTags = categoryTag;
        this.filters.flightTags = flightTag;
        this.notifyObserver(false);
    };
    FiltersService.prototype.dateChanged = function (date) {
        this.filters.date = date;
        this.filters.flightTags = [];
        this.mixpanelService.track(mixpanel_event_enum_1.MixpanelEvent.DATE_CHANGE, { selectedDate: date.toISOString() });
        this.notifyObserver(true);
    };
    FiltersService.prototype.previousDate = function () {
        this.dateChanged(this.filters.date.add(-1, 'day'));
    };
    FiltersService.prototype.nextDate = function () {
        this.dateChanged(this.filters.date.add(1, 'day'));
    };
    FiltersService.prototype.selectCategoryTag = function (categoryTag) {
        if (!this.filters.isCategoryTagIsSelected(categoryTag)) {
            this.mixpanelService.track(mixpanel_event_enum_1.MixpanelEvent.FILTER_CATEGORY, { categoryFilterEntered: categoryTag.name });
        }
        this.filters.selectCategoryTag(categoryTag);
        this.notifyObserver(true);
    };
    FiltersService.prototype.selectFlightTag = function (flightTag) {
        if (!this.filters.isFlightTagIsSelected(flightTag)) {
            this.mixpanelService.track(mixpanel_event_enum_1.MixpanelEvent.FILTER_FLIGHT, { filterEntered: flightTag.flightTag.toString() });
        }
        this.filters.selectFlightTag(flightTag);
        this.notifyObserver(true);
    };
    FiltersService.prototype.clearAllFilters = function () {
        this.filters.categoryTags = [];
        this.filters.flightTags = [];
        this.notifyObserver(true);
    };
    FiltersService.prototype.logIsInSelectedFilters = function (log) {
        return this.newLogIsInSelectedDate(log.tagVol.date)
            && this.newLogIsInSelectedFlightTag(log.id.idAsBase64)
            && this.newLogIsInSelectedCategory(log);
    };
    FiltersService.prototype.newLogIsInSelectedDate = function (flightDate) {
        return moment(flightDate).isSame(this.filters.date, "day");
    };
    FiltersService.prototype.newLogIsInSelectedFlightTag = function (threadId) {
        return this.filters.flightTags.length === 0
            || this.filters.flightTags.some(function (ftWithThread) {
                return ftWithThread.threadIds.some(function (filterThreadId) { return filterThreadId === threadId; });
            });
    };
    FiltersService.prototype.newLogIsInSelectedCategory = function (log) {
        return this.filters.categoryTags.length === 0
            || this.filters.categoryTags.some(function (filter) { return filter.isPresentInLog(log); });
    };
    FiltersService.prototype.notifyObserver = function (navigate) {
        var _this = this;
        if (navigate) {
            this.router.navigate(['logs'], {
                queryParams: {
                    tagsFilters: JSON.stringify(this.filters.categoryTags),
                    date: this.filters.date.format("YYYY-MM-DD"),
                    flightFilters: JSON.stringify(this.filters.flightTags)
                }
            });
        }
        this.observerRefreshFilters.forEach(function (observer) { return observer.next(_this.filters); });
    };
    FiltersService.prototype.createListener = function () {
        var _this = this;
        return new rxjs_1.Observable(function (observer) {
            _this.observerRefreshFilters.push(observer);
        });
    };
    FiltersService.ngInjectableDef = i0.defineInjectable({ factory: function FiltersService_Factory() { return new FiltersService(i0.inject(i1.Router), i0.inject(i2.MixpanelService)); }, token: FiltersService, providedIn: "root" });
    return FiltersService;
}());
exports.FiltersService = FiltersService;
