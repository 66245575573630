<div class="inputs">
    <a class="date-before-selection" (click)="selectDateByShortcut('previous')" *ngIf="arrowControl">
        <span class="arrow dln-icon-left"></span>
    </a>
    <a [ngClass]="{'date-selection': true, 'without-control': !arrowControl}" [dlnDateTimeTrigger]="dt">
        <span *ngIf="date" [ngClass]="{'withCross': isNullable && date}">
            {{date?.format('ll')}}
        </span>
        <span *ngIf="!date && !!emptyDateLabel">
            {{emptyDateLabel}}
        </span>
        <i class="dln-icon-calendar" *ngIf="!arrowControl"></i>
        <i *ngIf="isNullable && date" class="icon-cross" (click)="clearDate($event)"></i>
    </a>
    <a class="date-after-selection" (click)="selectDateByShortcut('next')" *ngIf="arrowControl">
        <span class="arrow dln-icon-right"></span>
    </a>
</div>
<input [style.display]="'none'" [dlnDateTime]="dt" (dateTimeChange)="onSelectDate($event)">
<dln-date-time #dt pickerType="calendar" [startAt]="date.toDate()"></dln-date-time>
