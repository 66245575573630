"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var DropdownContentComponent = /** @class */ (function () {
    function DropdownContentComponent(element) {
        this.element = element;
        this.placement = "bottom";
        this.displayOnHover = false;
        this.hoverDelay = 500;
        this.currentTop = 0;
        this.top = -100000;
        this.left = -100000;
        this.isActive = false;
    }
    DropdownContentComponent.prototype.hideListener = function () {
        if (this.displayOnHover) {
            this.hide();
        }
    };
    DropdownContentComponent.prototype.show = function () {
        if (!this.hostElement) {
            return;
        }
        var p = this.positionElements(this.hostElement, this.element.nativeElement.children[0], this.placement);
        this.top = p.top;
        this.left = p.left;
        this.isActive = true;
    };
    DropdownContentComponent.prototype.hide = function () {
        this.top = -100000;
        this.left = -100000;
        this.isActive = false;
    };
    DropdownContentComponent.prototype.positionElements = function (hostEl, targetEl, positionStr, appendToBody) {
        if (appendToBody === void 0) { appendToBody = false; }
        var positionStrParts = positionStr.split("-");
        var pos0 = positionStrParts[0];
        var pos1 = positionStrParts[1] || "center";
        var hostElPos = appendToBody ? DropdownContentComponent.offset(hostEl) : DropdownContentComponent.position(hostEl);
        var targetElWidth = targetEl.offsetWidth;
        var targetElHeight = targetEl.offsetHeight;
        var dropdownMargin = 10;
        var shiftWidth = {
            center: function () { return hostElPos.left + hostElPos.width / 2 - targetElWidth / 2; },
            left: function () { return hostElPos.left; },
            right: function () { return hostElPos.left + hostElPos.width; }
        };
        var shiftHeight = {
            center: function () { return hostElPos.top + hostElPos.height / 2 - targetElHeight / 2; },
            top: function () { return hostElPos.top; },
            bottom: function () { return hostElPos.top + hostElPos.height; }
        };
        var targetElPos;
        switch (pos0) {
            case "right":
                targetElPos = {
                    top: shiftHeight[pos1](),
                    left: shiftWidth[pos0]() + dropdownMargin
                };
                break;
            case "left":
                targetElPos = {
                    top: shiftHeight[pos1](),
                    left: hostElPos.left - targetElWidth - dropdownMargin
                };
                break;
            case "bottom":
                targetElPos = {
                    top: shiftHeight[pos0]() + dropdownMargin,
                    left: shiftWidth[pos1]()
                };
                break;
            default:
                targetElPos = {
                    top: hostElPos.top - targetElHeight - dropdownMargin,
                    left: shiftWidth[pos1]()
                };
                break;
        }
        return targetElPos;
    };
    DropdownContentComponent.position = function (nativeEl) {
        var offsetParentBCR = { top: 0, left: 0 };
        var elBCR = DropdownContentComponent.offset(nativeEl);
        var offsetParentEl = DropdownContentComponent.parentOffsetEl(nativeEl);
        if (offsetParentEl !== window.document) {
            offsetParentBCR = DropdownContentComponent.offset(offsetParentEl);
            offsetParentBCR.top += offsetParentEl.clientTop - offsetParentEl.scrollTop;
            offsetParentBCR.left += offsetParentEl.clientLeft - offsetParentEl.scrollLeft;
        }
        var boundingClientRect = nativeEl.getBoundingClientRect();
        return {
            width: boundingClientRect.width || nativeEl.offsetWidth,
            height: boundingClientRect.height || nativeEl.offsetHeight,
            top: elBCR.top - offsetParentBCR.top,
            left: elBCR.left - offsetParentBCR.left
        };
    };
    DropdownContentComponent.offset = function (nativeEl) {
        var boundingClientRect = nativeEl.getBoundingClientRect();
        return {
            width: boundingClientRect.width || nativeEl.offsetWidth,
            height: boundingClientRect.height || nativeEl.offsetHeight,
            top: boundingClientRect.top + (window.pageYOffset || window.document.documentElement.scrollTop),
            left: boundingClientRect.left + (window.pageXOffset || window.document.documentElement.scrollLeft)
        };
    };
    DropdownContentComponent.parentOffsetEl = function (nativeEl) {
        var offsetParent = nativeEl.offsetParent || window.document;
        while (offsetParent && offsetParent !== window.document && DropdownContentComponent.isStaticPositioned(offsetParent)) {
            offsetParent = offsetParent.offsetParent;
        }
        return offsetParent || window.document;
    };
    DropdownContentComponent.getStyle = function (nativeEl, cssProp) {
        if (nativeEl.currentStyle) {
            return nativeEl.currentStyle[cssProp]; // IE
        }
        if (window.getComputedStyle) {
            return window.getComputedStyle(nativeEl)[cssProp];
        }
        // finally try and get inline style
        return nativeEl.style[cssProp];
    };
    DropdownContentComponent.isStaticPositioned = function (nativeEl) {
        return (DropdownContentComponent.getStyle(nativeEl, "position") || "static") === "static";
    };
    return DropdownContentComponent;
}());
exports.DropdownContentComponent = DropdownContentComponent;
