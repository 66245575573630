import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {
    MatFormFieldModule,
    MatSelectModule,
    MatSliderModule,
    MatCheckboxModule,
    MatSlideToggleModule, MatTabsModule, MatAutocompleteModule, MatRadioModule
} from "@angular/material";
import {CommonModule} from "@angular/common";
import {UserModule} from "./user/user.module";
import {ChangeLogModule} from "../../../settings/src/lib/shared/change-log/change-log.module";
import {WindowRef} from "./service/window.service";
import {DlnCommonModule} from "./common/common.module";
import {CategoryTagComponent} from "./component/category-tag/category-tag.component";
import {NotificationModule} from "./notification/notification.module";
import {DlnInputDateComponent} from "./component/input/input-date/input-date.component";
import {DlnInputTimeComponent} from "./component/input/input-time/input-time.component";
import {DlnInputComponent} from "./component/input/input.component";
import {DlnInputToggleComponent} from "./component/input/input-toggle/input-toggle.component";
import {DlnInputPhoneComponent} from "./component/input/input-phone/input-phone.component";
import {DlnInputAuthoritiesComponent} from "./component/input/input-authorities/input-authorities.component";
import {UploadImageDialogComponent} from "./component/upload-image-dialog/upload-image-dialog.component";
import {AddManualFlightTagModalComponent} from "./component/add-manual-flight-tag/add-manual-flight-tag-modal.component";
import {BadgeLabelComponent} from "./component/badge-label/badge-label.component";
import {DlnInputFlightComponent} from "./component/input-flight/input-flight.component";
import {DlnInputUserSearchComponent} from "./component/input/input-user-search/input-user-search.component";
import {DlnInputCompanyComponent} from "./component/input/input-company/input-company.component";
import {DlnDateTimeModule} from "dln-datetime-picker";
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TextMaskModule} from 'angular2-text-mask';
import {ModalModule} from "./modal/modal.module";
import {LogbookSharedLibsModule} from "./shared-libs.module";
import {LogbookCoreSharedModule} from "@logbook/core/shared/core-shared.module";
import {CardPanelComponent} from "./component/card-panel/card-panel.component";
import {CardPanelBodyDirective} from "./component/card-panel/card-panel-body.directive";
import {CardPanelFooterDirective} from "./component/card-panel/card-panel-footer.directive";
import {CardPanelHeaderComponent} from "./component/card-panel/card-panel-header.component";
import {SwitchTabMenuComponent} from "./component/switch-tab-menu/switch-tab-menu.component";
import {DlnInputDataReferenceComponent} from "./component/input/input-data-reference/input-data-reference.component";
import {SharedPipeModule} from "./pipe/shared-pipe.module";
import {RouterModule} from "@angular/router";
import {TeamSelectDialogComponent} from "./component/team-select-dialog/team-select-dialog.component";
import {NgxDatatableModule} from "@swimlane/ngx-datatable";
import {LyResizingCroppingImageModule} from "@alyle/ui/resizing-cropping-images";
import {LY_THEME, LyThemeModule} from "@alyle/ui";
import {MinimaDark, MinimaLight} from "@alyle/ui/themes/minima";

@NgModule({
    imports: [
        FormsModule, LyResizingCroppingImageModule,
        LyThemeModule.setTheme('minima-light'), ReactiveFormsModule, TextMaskModule,
        ModalModule, LogbookSharedLibsModule, SharedPipeModule,
        RouterModule,
        CommonModule, DlnDateTimeModule,
        UserModule, ChangeLogModule, NotificationModule, DlnCommonModule,
        MatFormFieldModule, MatSelectModule, MatSliderModule, MatCheckboxModule,
        MatSlideToggleModule, MatRadioModule, MatTabsModule, MatAutocompleteModule,
        NgxDatatableModule,
        LogbookCoreSharedModule
    ],
    declarations: [
        CategoryTagComponent,
        DlnInputAuthoritiesComponent, DlnInputTimeComponent,
        DlnInputDateComponent, DlnInputComponent, DlnInputPhoneComponent, DlnInputToggleComponent, DlnInputDataReferenceComponent,
        AddManualFlightTagModalComponent, TeamSelectDialogComponent, UploadImageDialogComponent,
        DlnInputFlightComponent, DlnInputUserSearchComponent, DlnInputCompanyComponent,
        CardPanelComponent, CardPanelBodyDirective, CardPanelFooterDirective, CardPanelHeaderComponent, BadgeLabelComponent,
        SwitchTabMenuComponent,
    ],
    providers: [
        WindowRef,
        { provide: LY_THEME, useClass: MinimaLight, multi: true }, // name: `minima-light`
        { provide: LY_THEME, useClass: MinimaDark, multi: true } // name: `minima-dark`
    ],
    exports: [
        FormsModule,
        ReactiveFormsModule,
        MatCheckboxModule,
        UserModule, ChangeLogModule, NotificationModule, DlnCommonModule,
        DlnDateTimeModule, ModalModule, LogbookSharedLibsModule, SharedPipeModule,
        CategoryTagComponent,
        DlnInputAuthoritiesComponent,
        DlnInputDateComponent, DlnInputComponent, DlnInputPhoneComponent, DlnInputTimeComponent, DlnInputDataReferenceComponent,
        DlnInputFlightComponent, DlnInputUserSearchComponent, DlnInputCompanyComponent,
        DlnInputToggleComponent,
        AddManualFlightTagModalComponent, UploadImageDialogComponent, TeamSelectDialogComponent,
        CardPanelComponent, CardPanelBodyDirective, CardPanelFooterDirective, CardPanelHeaderComponent, BadgeLabelComponent,
        SwitchTabMenuComponent,
    ],
    entryComponents: [
        AddManualFlightTagModalComponent, UploadImageDialogComponent, TeamSelectDialogComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class LogbookSharedModule {

}
