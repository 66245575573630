"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var KeyCode;
(function (KeyCode) {
    KeyCode[KeyCode["Tab"] = 9] = "Tab";
    KeyCode[KeyCode["Enter"] = 13] = "Enter";
    KeyCode[KeyCode["Esc"] = 27] = "Esc";
    KeyCode[KeyCode["Space"] = 32] = "Space";
    KeyCode[KeyCode["ArrowUp"] = 38] = "ArrowUp";
    KeyCode[KeyCode["ArrowDown"] = 40] = "ArrowDown";
    KeyCode[KeyCode["Backspace"] = 8] = "Backspace";
})(KeyCode = exports.KeyCode || (exports.KeyCode = {}));
var NgSelectConfig = /** @class */ (function () {
    function NgSelectConfig() {
        this.notFoundText = '';
        this.typeToSearchText = '';
        this.addTagText = '';
    }
    return NgSelectConfig;
}());
exports.NgSelectConfig = NgSelectConfig;
