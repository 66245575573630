"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = ["[_nghost-%COMP%]   .team-list[_ngcontent-%COMP%]{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;margin-bottom:25px;margin-top:-21px;border:1px solid #cbcbd5}[_nghost-%COMP%]   .team-list[_ngcontent-%COMP%]   .team-item[_ngcontent-%COMP%]{margin:2px 0;padding:5px;cursor:pointer;text-align:center}[_nghost-%COMP%]   .team-list[_ngcontent-%COMP%]   .team-item[_ngcontent-%COMP%]:hover{background-color:#efeff6}[_nghost-%COMP%]   mat-dialog-actions[_ngcontent-%COMP%]{-ms-flex-pack:distribute;-moz-justify-content:space-around;-ms-justify-content:space-around;justify-content:space-around}"];
exports.styles = styles;
