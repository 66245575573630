"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var useful_model_1 = require("./useful.model");
var shared_1 = require("@logbook/shared");
var Message = /** @class */ (function () {
    function Message(message) {
        this.metadata = new Map();
        this.tags = [];
        this.comments = [];
        this.useful = new useful_model_1.Useful();
        if (message) {
            this.messageId = message.messageId;
            this.emetteurId = message.emetteurId;
            this.emitter = message.emitter;
            this.emitter$ = message.emitter$;
            this.editor = message.editor;
            this.edited = message.edited;
            this.corps = message.corps;
            this.dateCreation = message.dateCreation;
            this.typeMessage = message.typeMessage;
            this.metadata = message.metadata;
            this.tags = message.tags;
            this.comments = message.comments ? message.comments.map(function (comment) { return new Message(comment); }) : [];
            this.pieceJointes = message.pieceJointes ? message.pieceJointes.map(function (attachment) { return new shared_1.PieceJointe(attachment); }) : [];
            this.useful = message.useful ? Object.assign(new useful_model_1.Useful(), message.useful) : new useful_model_1.Useful();
        }
    }
    return Message;
}());
exports.Message = Message;
