import {first, startWith} from 'rxjs/operators';
import {Component, HostBinding, OnDestroy, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {ActivatedRouteSnapshot, NavigationEnd, Router} from '@angular/router';
import {NotificationWsService, UserService, PushNotificationsService} from "@logbook/shared";
import {ModalService, ModalConfig} from "@logbook/shared";
import {OnBoardingModalComponent} from "../on-boarding-modal/on-boarding-modal.component";
import {SwitchAppsMenuComponent} from "../switch-apps-menu/switch-apps-menu.component";
import {LocalStorageService} from "ngx-webstorage";
import {Principal} from "../../auth/services/principal.service";
import {AccountService} from "../../auth/services/account.service";
import {Subscription} from "rxjs/internal/Subscription";

declare var ga: Function;

@Component({
    selector: 'dln-main',
    templateUrl: './main.component.html',
    styleUrls: [
        'main.component.scss'
    ]
})
export class DlnMainComponent implements OnInit, OnDestroy {

    public notificationOptions = {
        position: ["top", "right"],
        timeOut: 3000,
        animate: "fromRight"
    };
    hideNavbar = false;
    hideSwitchApps = false;
    hideModal = false;
    cguModalIsShowing = false;
    routerSubscription: Subscription;

    @HostBinding('class.withCollapseMenu') public collapseMenu = false;

    constructor(private titleService: Title, public router: Router, private localStorageService: LocalStorageService,
                private wsService: NotificationWsService, private principal: Principal,
                private userService: UserService, private accountService: AccountService,
                private modalService: ModalService,
                private pushNotificationsService: PushNotificationsService) {
    }

    private getRouterData(routeSnapshot: ActivatedRouteSnapshot, data: string, defaultValue: any): any {
        let dataValue: string = (routeSnapshot.data && routeSnapshot.data[data]) ? routeSnapshot.data[data] : defaultValue;
        if (routeSnapshot.firstChild) {
            dataValue = this.getRouterData(routeSnapshot.firstChild, data, defaultValue) || dataValue;
        }
        return dataValue;
    }

    ngOnInit() {
        const collapseMenu = this.localStorageService.retrieve(SwitchAppsMenuComponent.COLLAPSE_STORAGE_KEY);

        if (collapseMenu != null) {
            this.collapseMenu = collapseMenu;
        }

        this.routerSubscription = this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                setTimeout(() => {
                    this.titleService.setTitle(this.getRouterData(this.router.routerState.snapshot.root, "pageTitle", "Logbook"));
                    this.hideNavbar = this.getRouterData(this.router.routerState.snapshot.root, 'hideNavbar', false);
                    this.hideSwitchApps = this.getRouterData(this.router.routerState.snapshot.root, 'hideSwitchApps', false);
                    this.hideModal = this.getRouterData(this.router.routerState.snapshot.root, 'hideModal', false);
                    ga('set', 'page', event.urlAfterRedirects);
                    ga('send', 'pageview');
                })
            }
        });

        this.principal.getAuthenticationState().pipe(startWith(this.principal.identityDirect())).subscribe((account) => {
            if (account && this.principal.hasAnyAuthorityDirect(["ROLE_USER"])) {
                this.wsService.initStomp();
                this.userService.fetchUsersLogged();
                if (this.principal.hasFeatureEnableDirect(["NOTIFICATION_CENTER"])) {
                    this.pushNotificationsService.subscribeToNotifications();
                }
                if (!this.hideModal && !this.cguModalIsShowing) {
                    this.accountService.isReadCGU().pipe(first()).subscribe((res) => {
                        if (res.status !== 200) {
                            const modalConfig = new ModalConfig(OnBoardingModalComponent, null, null, '80vh', 'custom-on-boarding-modal-panel');
                            this.cguModalIsShowing = true;
                            this.modalService.showModal(modalConfig).pipe(first())
                                .subscribe(() => this.cguModalIsShowing = false);
                        }
                    });
                }
            }
        });

        // To force url for mobile shortcut website
        // checks if you're in app mode
        if ("standalone" in window.navigator && window.navigator['standalone']) {
            // Erase potential logs filters in url
            this.router.navigate([''])
        }
    }

    ngOnDestroy(): void {
        this.wsService.disconnect();

        if (this.routerSubscription) {
            this.routerSubscription.unsubscribe();
        }
    }

    isAuthenticated() {
        return this.principal.isAuthenticated();
    }
}
