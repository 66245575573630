"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var track_js_error_handler_service_1 = require("./track-js-error-handler.service");
var LogbookCoreTrackingModule = /** @class */ (function () {
    function LogbookCoreTrackingModule() {
    }
    LogbookCoreTrackingModule.forRoot = function () {
        return {
            ngModule: LogbookCoreTrackingModule,
            providers: [
                {
                    provide: core_1.ErrorHandler,
                    useClass: track_js_error_handler_service_1.TrackJsErrorHandlerService
                }
            ]
        };
    };
    return LogbookCoreTrackingModule;
}());
exports.LogbookCoreTrackingModule = LogbookCoreTrackingModule;
