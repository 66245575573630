"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
/**
 * Universal avatar flight-report that
 * generates avatar from different sources
 *
 * export
 * class AvatarComponent
 * implements {OnInit}
 */
var AvatarComponent = /** @class */ (function () {
    function AvatarComponent(renderer, elementRef) {
        this.renderer = renderer;
        this.elementRef = elementRef;
        this.round = true;
        this.size = 50;
        this.textSizeRatio = 3;
        this.fgColor = "#FFF";
        this.style = {};
        this.cornerRadius = 0;
        this.initialsSize = 2;
        this.clickOnAvatar = new core_1.EventEmitter();
        this._currentSource = 0;
        this.avatarStyle = {};
        this.hostStyle = {};
    }
    /**
     * Detect inputs change
     *
     * param {{ [propKey: string]: SimpleChange }} changes
     *
     * memberof AvatarComponent
     */
    AvatarComponent.prototype.ngOnChanges = function (changes) {
        // reintialize the avatar flight-report when a source property value has changed
        // the fallback system must be re-invoked with the new values.
        this._initializeAvatar();
    };
    /**
     * Initialize the avatar flight-report and its fallback system
     */
    AvatarComponent.prototype._initializeAvatar = function () {
        this._currentSource = 0;
        // Host style
        this.hostStyle = {
            width: this.size + 'px',
            height: this.size + 'px'
        };
        // Fetch avatar source
        this.fetch();
    };
    /**
     * Fetch avatar source
     *
     * param {any} event
     *
     * memberOf AvatarComponent
     */
    AvatarComponent.prototype.fetch = function (event) {
        this.data = this._getInitials(this.value, this.initialsSize);
        this.src = undefined;
        this.avatarStyle = this._initialsStyle(this.value);
        this._currentSource++;
    };
    /**
     *
     * returns initials style
     *
     * memberOf AvatarComponent
     */
    AvatarComponent.prototype._initialsStyle = function (avatarValue) {
        return tslib_1.__assign({ textAlign: 'center', borderRadius: this.round ? '100%' : this.cornerRadius + 'px', border: this.borderColor ? '1px solid ' + this.borderColor : '', textTransform: 'uppercase', color: this.fgColor, backgroundColor: this.bgColor ? this.bgColor : this.getRandomColor(avatarValue), font: Math.floor(this.size / this.textSizeRatio) + 'px Helvetica, Arial, sans-serif', lineHeight: this.size + 'px' }, this.style);
    };
    /**
     *
     * returns image style
     *
     * memberOf AvatarComponent
     */
    AvatarComponent.prototype._imageStyle = function () {
        return tslib_1.__assign({ maxWidth: '100%', borderRadius: this.round ? '50%' : this.cornerRadius + 'px', border: this.borderColor ? '1px solid ' + this.borderColor : '', width: this.size, height: this.size }, this.style);
    };
    AvatarComponent.prototype.getRandomColor = function (value) {
        if (!value) {
            return 'transparent';
        }
        var asciiCodeSum = this._calculateAsciiCode(value);
        var colors = [
            "#1abc9c",
            "#3498db",
            "#f1c40f",
            "#8e44ad",
            "#e74c3c",
            "#d35400",
            "#2c3e50",
            "#7f8c8d"
        ];
        return colors[asciiCodeSum % colors.length];
    };
    AvatarComponent.prototype._calculateAsciiCode = function (value) {
        return value.split('').map(function (letter) { return letter.charCodeAt(0); })
            .reduce(function (previous, current) { return previous + current; });
    };
    AvatarComponent.prototype._getInitials = function (name, size) {
        if (name) {
            var initials = name.trim().split(" ");
            if (size && size < initials.length) {
                return this._constructInitials(initials.slice(0, size));
            }
            else {
                return this._constructInitials(initials);
            }
        }
        return "";
    };
    AvatarComponent.prototype._constructInitials = function (elements) {
        if (elements && elements.length > 0) {
            return elements.filter(function (element) { return element && element.length > 0; })
                .map(function (element) { return element[0].toUpperCase(); }).join('');
        }
        return '';
    };
    return AvatarComponent;
}());
exports.AvatarComponent = AvatarComponent;
