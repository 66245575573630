"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./error.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("./error.component");
var i3 = require("@angular/router");
var styles_ErrorComponent = [i0.styles];
var RenderType_ErrorComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ErrorComponent, data: {} });
exports.RenderType_ErrorComponent = RenderType_ErrorComponent;
function View_ErrorComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Error Page!"])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [], [[8, "hidden", 0]], null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "alert alert-danger"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", " "])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [["class", "alert alert-danger"]], [[8, "hidden", 0]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["You are not authorized to access the page. "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.errorMessage; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.errorMessage; _ck(_v, 5, 0, currVal_1); var currVal_2 = !_co.error403; _ck(_v, 6, 0, currVal_2); }); }
exports.View_ErrorComponent_0 = View_ErrorComponent_0;
function View_ErrorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "dln-error", [], null, null, null, View_ErrorComponent_0, RenderType_ErrorComponent)), i1.ɵdid(1, 245760, null, 0, i2.ErrorComponent, [i3.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_ErrorComponent_Host_0 = View_ErrorComponent_Host_0;
var ErrorComponentNgFactory = i1.ɵccf("dln-error", i2.ErrorComponent, View_ErrorComponent_Host_0, {}, {}, []);
exports.ErrorComponentNgFactory = ErrorComponentNgFactory;
