import {Component, Input, OnInit, ViewChild} from "@angular/core";
import {DropdownContentComponent} from "./dropdown-content/dropdown-content.component";

@Component({
    selector: 'dln-app-dropdown',
    exportAs: 'dropdown',
    templateUrl: './dropdown.component.html',
    styleUrls: ['./dropdown.scss']
})
export class DropdownComponent implements OnInit {
    @Input() displayOnHover = false;
    @Input() hoverDelay = 500;
    @Input() displayDropdown = true;
    @Input() placement: "top" | "bottom" | "left" | "right" = "bottom";
    @Input() placementArrow: "top" | "bottom" | "left" | "right";
    @ViewChild('dropdownContent') dropdownContent: DropdownContentComponent;

    constructor() {
    }

    ngOnInit() {
        if (!this.placementArrow) {
            this.placementArrow = this.placement;
        }
    }

    closeDropdown() {
        this.dropdownContent.hide();
    }

}
