"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var contact_model_1 = require("../../user/model/contact.model");
var user_model_1 = require("../../../../../../app/core/shared/models/user.model");
var dropdown_component_1 = require("../dropdown/dropdown.component");
var UserInfoComponent = /** @class */ (function () {
    function UserInfoComponent() {
        this.placement = "bottom";
    }
    UserInfoComponent.prototype.ngOnInit = function () {
    };
    UserInfoComponent.prototype.closeDropdown = function () {
        this.dropdown.closeDropdown();
    };
    UserInfoComponent.prototype.isDropdownActive = function () {
        return this.dropdown.dropdownContent.isActive;
    };
    return UserInfoComponent;
}());
exports.UserInfoComponent = UserInfoComponent;
