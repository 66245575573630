"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = ["[_nghost-%COMP%]{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column}[_nghost-%COMP%]   dln-date-time-inline.inline-timer[_ngcontent-%COMP%]     dln-date-time-timer{padding:0 15px;margin:0 0 1px;height:40px;border-radius:5px;border:1px solid #cbcbd5;-ms-flex-pack:start;-moz-justify-content:flex-start;-ms-justify-content:flex-start;justify-content:flex-start}[_nghost-%COMP%]   dln-date-time-inline.inline-timer[_ngcontent-%COMP%]     dln-date-time-timer dln-date-time-timer-box{width:28px}[_nghost-%COMP%]   dln-date-time-inline.inline-timer[_ngcontent-%COMP%]     dln-date-time-timer dln-date-time-timer-box .dln-dt-timer-input{width:20px;border:none;height:40px;background:0 0;font-weight:400;font-size:14px}[_nghost-%COMP%]   dln-date-time-inline.inline-timer[_ngcontent-%COMP%]     dln-date-time-timer dln-date-time-timer-box .actions{display:none}[_nghost-%COMP%]   dln-date-time-inline.inline-timer[_ngcontent-%COMP%]     dln-date-time-timer dln-date-time-timer-box .dln-dt-timer-divider{width:5px;margin-left:0}[_nghost-%COMP%]   dln-date-time-inline.inline-timer[_ngcontent-%COMP%]     dln-date-time-timer dln-date-time-timer-box .dln-dt-timer-divider:after, [_nghost-%COMP%]   dln-date-time-inline.inline-timer[_ngcontent-%COMP%]     dln-date-time-timer dln-date-time-timer-box .dln-dt-timer-divider:before{content:'';display:inline-block;height:2px;width:2px;margin:2px 0;border-radius:50%;transform:translateX(-50%);background-color:#27273b}"];
exports.styles = styles;
