"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var contact_model_1 = require("./contact.model");
exports.CATEGORY_TAG = "tags";
var CATEGORY_TAG_LABEL = "Tag";
var ICON_TAG = "dln-icon-tag";
exports.CATEGORY_COMPANY = "companies";
var CATEGORY_COMPANY_LABEL = "Company";
var ICON_COMPANY = "dln-icon-company";
exports.CATEGORY_LOCATION = "locations";
var CATEGORY_LOCATION_LABEL = "Location";
var ICON_LOCATION = "dln-icon-location";
var ICON_FILTER = "icon-funnel";
var ContactGroup = /** @class */ (function () {
    function ContactGroup(build, contacts) {
        if (build) {
            this.label = build.label;
            if (this.label) {
                this.showLabel = this.label.charAt(0).toUpperCase() + this.label.slice(1);
            }
            this.id = build.id;
            this.count = build.count;
            if (build.members) {
                if (contacts) {
                    this.members = build.members.map(function (member) {
                        var find = contacts.find(function (value) { return value.id === member.id; });
                        if (find) {
                            return find;
                        }
                        else {
                            return new contact_model_1.Contact(member);
                        }
                    });
                }
                else {
                    this.members = build.members.map(function (member) { return new contact_model_1.Contact(member); });
                }
            }
        }
    }
    return ContactGroup;
}());
exports.ContactGroup = ContactGroup;
var ContactFilter = /** @class */ (function () {
    function ContactFilter(build) {
        this.items = [];
        this.isExpanded = false;
        if (build) {
            this.category = build.category;
            switch (this.category) {
                case exports.CATEGORY_TAG:
                    this.iconClass = ICON_TAG;
                    this.categoryLabel = CATEGORY_TAG_LABEL;
                    break;
                case exports.CATEGORY_COMPANY:
                    this.iconClass = ICON_COMPANY;
                    this.categoryLabel = CATEGORY_COMPANY_LABEL;
                    break;
                case exports.CATEGORY_LOCATION:
                    this.iconClass = ICON_LOCATION;
                    this.categoryLabel = CATEGORY_LOCATION_LABEL;
                    break;
                default:
                    this.categoryLabel = this.category.toUpperCase();
                    this.iconClass = ICON_FILTER;
            }
            this.items = build.items.map(function (item) { return Object.assign(new ContactGroup(item)); })
                .sort(function (a, b) {
                if (a.label.toLowerCase() < b.label.toLowerCase()) {
                    return -1;
                }
                if (a.label.toLowerCase() > b.label.toLowerCase()) {
                    return 1;
                }
                return 0;
            });
        }
    }
    return ContactFilter;
}());
exports.ContactFilter = ContactFilter;
var ContactActiveFilters = /** @class */ (function () {
    function ContactActiveFilters() {
    }
    return ContactActiveFilters;
}());
exports.ContactActiveFilters = ContactActiveFilters;
