"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var notification_ws_service_1 = require("../../../../logbook/shared/src/lib/user/service/notification-ws.service");
var community_service_1 = require("./community.service");
var user_service_1 = require("../../../../logbook/shared/src/lib/user/service/user.service");
var auth_jwt_service_1 = require("./auth-jwt.service");
var principal_service_1 = require("./principal.service");
var push_notifications_service_1 = require("../../../../logbook/shared/src/lib/notification/services/push-notifications.service");
var router_1 = require("@angular/router");
var common_1 = require("@angular/common");
var operators_1 = require("rxjs/operators");
var i0 = require("@angular/core");
var i1 = require("./principal.service");
var i2 = require("./auth-jwt.service");
var i3 = require("./community.service");
var i4 = require("../../../../logbook/shared/src/lib/user/service/user.service");
var i5 = require("../../../../logbook/shared/src/lib/user/service/notification-ws.service");
var i6 = require("@angular/common");
var i7 = require("@angular/router");
var i8 = require("../../../../logbook/shared/src/lib/notification/services/push-notifications.service");
var LoginService = /** @class */ (function () {
    function LoginService(principal, authServerProvider, communityService, userService, wsService, location, router, pushNotificationService) {
        this.principal = principal;
        this.authServerProvider = authServerProvider;
        this.communityService = communityService;
        this.userService = userService;
        this.wsService = wsService;
        this.location = location;
        this.router = router;
        this.pushNotificationService = pushNotificationService;
    }
    LoginService.prototype.login = function (credentials, callback) {
        var _this = this;
        var cb = callback || function () {
        };
        return new Promise(function (resolve, reject) {
            _this.authServerProvider.login(credentials).pipe(operators_1.first()).subscribe(function (data) {
                _this.principal.identity(true).then(function (account) {
                    resolve(data);
                    _this.wsService.initStomp();
                    _this.userService.fetchUsersLogged();
                });
                return cb();
            }, function (err) {
                _this.logout();
                reject(err);
                return cb(err);
            });
        });
    };
    LoginService.prototype.signAsUser = function (email, callback) {
        var _this = this;
        var cb = callback || function () {
        };
        this.wsService.disconnect();
        var previousUrl = this.location.path();
        return new Promise(function (resolve, reject) {
            _this.authServerProvider.signAsUser(email, previousUrl).pipe(operators_1.first()).subscribe(function (data) {
                _this.principal.identity(true).then(function (account) {
                    resolve(data);
                    _this.wsService.initStomp();
                    _this.userService.fetchUsersLogged();
                });
                return cb();
            }, function (err) {
                reject(err);
                return cb(err);
            });
        });
    };
    LoginService.prototype.loginWithToken = function (jwt, rememberMe) {
        return this.authServerProvider.loginWithToken(jwt, rememberMe);
    };
    LoginService.prototype.logoutSignAs = function (callback) {
        var _this = this;
        var cb = callback || function () {
        };
        this.wsService.disconnect();
        return new Promise(function (resolve, reject) {
            _this.authServerProvider.logoutSignAs().pipe(operators_1.first()).subscribe(function (prevUrl) {
                _this.principal.identity(true).then(function (account) {
                    resolve(account);
                    _this.wsService.initStomp();
                    _this.userService.fetchUsersLogged();
                    _this.router.navigateByUrl(prevUrl);
                });
            }, function (err) {
                reject(err);
                return cb(err);
            });
        });
    };
    LoginService.prototype.logout = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            if (_this.principal.hasFeatureEnableDirect(["NOTIFICATION_CENTER"])) {
                _this.pushNotificationService.unsubscribeToNotifications(_this.principal.identityDirect())
                    .then(function () {
                    _this.performLogout(resolve);
                }).catch(function () {
                    _this.performLogout(resolve);
                });
            }
            else {
                _this.performLogout(resolve);
            }
        });
    };
    LoginService.prototype.performLogout = function (resolve) {
        this.authServerProvider.logout().pipe(operators_1.first()).subscribe();
        this.wsService.disconnect();
        this.communityService.cleanCommunityCache();
        this.principal.authenticate(null);
        resolve();
    };
    LoginService.ngInjectableDef = i0.defineInjectable({ factory: function LoginService_Factory() { return new LoginService(i0.inject(i1.Principal), i0.inject(i2.AuthServerProvider), i0.inject(i3.CommunityService), i0.inject(i4.UserService), i0.inject(i5.NotificationWsService), i0.inject(i6.Location), i0.inject(i7.Router), i0.inject(i8.PushNotificationsService)); }, token: LoginService, providedIn: "root" });
    return LoginService;
}());
exports.LoginService = LoginService;
