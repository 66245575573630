
export enum RecipientType {
    EMAIL = "EMAIL",
    USER = "USER",
    TEAM = "TEAM",
    CONTACT = "CONTACT",
    GROUP = "GROUP"
}

export class Recipient {
    recipientId: string;
    contactId: string;
    type: RecipientType;

    isUser() {
        return this.type === RecipientType.USER;
    }

    isTeam() {
        return this.type === RecipientType.TEAM;
    }

    isContact() {
        return this.type === RecipientType.CONTACT || this.type === RecipientType.EMAIL;
    }

    isGroup() {
        return this.type === RecipientType.GROUP;
    }
}
