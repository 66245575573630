export interface Authority {
    label: string;
    role: string;
}

export const Authorities: Authority[] = [
    {label: "Super admin", role: "ROLE_ADMIN"},
    {label: "Admin", role: "ROLE_TEAM_MANAGER"},
    {label: "User", role: "ROLE_USER"},
];
