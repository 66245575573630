"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var searchHelper = require("./search-helper");
var ItemsList = /** @class */ (function () {
    function ItemsList() {
        this.items = [];
        this.filteredItems = [];
        this._markedIndex = -1;
        this._selected = [];
        this._multiple = false;
    }
    Object.defineProperty(ItemsList.prototype, "value", {
        get: function () {
            return this._selected;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ItemsList.prototype, "markedItem", {
        get: function () {
            return this.filteredItems[this._markedIndex];
        },
        enumerable: true,
        configurable: true
    });
    ItemsList.prototype.setItems = function (items) {
        this.items = this.mapItems(items);
        this.filteredItems = this.items.slice();
    };
    ItemsList.prototype.setMultiple = function (multiple) {
        this._multiple = multiple;
        this.clearSelected();
    };
    ItemsList.prototype.select = function (item) {
        if (!this._multiple) {
            this.clearSelected();
        }
        this._selected.push(item);
        item.selected = true;
    };
    ItemsList.prototype.findItem = function (value, bindLabel, bindValue) {
        if (bindValue) {
            return this.items.find(function (x) { return x[bindValue] === value; });
        }
        var index = this.items.indexOf(value);
        return index > -1 ? this.items[index] :
            this.items.find(function (x) { return x[bindLabel] === value[bindLabel]; });
    };
    ItemsList.prototype.unselect = function (item) {
        this._selected = this._selected.filter(function (x) { return x.index !== item.index; });
        item.selected = false;
    };
    ItemsList.prototype.unselectLastItem = function () {
        if (this._selected.length === 0) {
            return;
        }
        this._selected[this._selected.length - 1].selected = false;
        this._selected.splice(this._selected.length - 1, 1);
    };
    ItemsList.prototype.addTag = function (item) {
        item.index = this.items.length;
        this.items.push(item);
        this.filteredItems.push(item);
    };
    ItemsList.prototype.clearSelected = function () {
        this._selected.forEach(function (item) {
            item.selected = false;
            item.marked = false;
        });
        this._selected = [];
    };
    ItemsList.prototype.filter = function (term, bindLabel) {
        var filterFuncVal = this.getDefaultFilterFunc(term, bindLabel);
        this.filteredItems = term ? this.items.filter(function (val) { return filterFuncVal(val); }) : this.items;
        this._markedIndex = 0;
    };
    ItemsList.prototype.clearFilter = function () {
        this.filteredItems = this.items.slice();
    };
    ItemsList.prototype.markNextItem = function () {
        this.stepToItem(+1);
    };
    ItemsList.prototype.markPreviousItem = function () {
        this.stepToItem(-1);
    };
    ItemsList.prototype.markItem = function (item) {
        if (item === void 0) { item = null; }
        if (this.filteredItems.length === 0) {
            return;
        }
        item = item || this.lastSelectedItem;
        if (item) {
            this._markedIndex = this.filteredItems.indexOf(item);
        }
        else {
            this._markedIndex = 0;
        }
    };
    ItemsList.prototype.getNextItemIndex = function (steps) {
        if (steps > 0) {
            return (this._markedIndex === this.filteredItems.length - 1) ? 0 : (this._markedIndex + 1);
        }
        else {
            return (this._markedIndex === 0) ? (this.filteredItems.length - 1) : (this._markedIndex - 1);
        }
    };
    ItemsList.prototype.stepToItem = function (steps) {
        if (this.filteredItems.length === 0) {
            return;
        }
        this._markedIndex = this.getNextItemIndex(steps);
        while (this.markedItem.disabled) {
            this.stepToItem(steps);
        }
    };
    ItemsList.prototype.getDefaultFilterFunc = function (term, bindLabel) {
        return function (val) {
            if (val.isParent) {
                return true;
            }
            else {
                return searchHelper.stripSpecialChars(val[bindLabel])
                    .toUpperCase()
                    .indexOf(searchHelper.stripSpecialChars(term).toUpperCase()) > -1;
            }
        };
    };
    Object.defineProperty(ItemsList.prototype, "lastSelectedItem", {
        get: function () {
            return this._selected[this._selected.length - 1];
        },
        enumerable: true,
        configurable: true
    });
    ItemsList.prototype.mapItems = function (items) {
        return items.map(function (item, index) {
            return tslib_1.__assign({ index: index }, item);
        });
    };
    return ItemsList;
}());
exports.ItemsList = ItemsList;
