"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var flight_tag_model_1 = require("../../model/flight-tag.model");
var FlightTagComponent = /** @class */ (function () {
    function FlightTagComponent() {
        this.filterItem = false;
        this.closable = false;
        this.active = false;
        this.showDate = false;
    }
    return FlightTagComponent;
}());
exports.FlightTagComponent = FlightTagComponent;
