import {PieceJointe} from "../common/text-editor/attach-file/model/piece-jointe.model";

export class MessageForm {
    body: string;
    files: Array<PieceJointe>;
    id: any;

    constructor(id?: string) {
        this.body = "";
        this.files = [];
        this.id = "";

        if (id != null) {
            this.id = id;
        }
    }

    isValid() {
        return (this.body.length > 0 || (this.files != null && this.files.length > 0))
            && this.files.filter((file) => file && file.isPosting).length === 0;
    }
}
