"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./back-button.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("./back-button.component");
var i3 = require("@angular/router");
var i4 = require("@angular/common");
var styles_BackButtonComponent = [i0.styles];
var RenderType_BackButtonComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BackButtonComponent, data: {} });
exports.RenderType_BackButtonComponent = RenderType_BackButtonComponent;
function View_BackButtonComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "a", [], [[8, "className", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "dln-icon-retour"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [["class", "desktop"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [["class", "mobile"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), i1.ɵncd(null, 0)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "back-btn ", _co.className, ""); _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.desktopLabel; _ck(_v, 3, 0, currVal_1); var currVal_2 = _co.mobileLabel; _ck(_v, 5, 0, currVal_2); }); }
exports.View_BackButtonComponent_0 = View_BackButtonComponent_0;
function View_BackButtonComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "dln-back-button", [], null, null, null, View_BackButtonComponent_0, RenderType_BackButtonComponent)), i1.ɵdid(1, 49152, null, 0, i2.BackButtonComponent, [i3.Router, i4.Location], null, null)], null, null); }
exports.View_BackButtonComponent_Host_0 = View_BackButtonComponent_Host_0;
var BackButtonComponentNgFactory = i1.ɵccf("dln-back-button", i2.BackButtonComponent, View_BackButtonComponent_Host_0, { className: "className", url: "url", desktopLabel: "desktopLabel", mobileLabel: "mobileLabel", onClick: "onClick" }, {}, ["*"]);
exports.BackButtonComponentNgFactory = BackButtonComponentNgFactory;
