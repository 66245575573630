import {HttpClient, HttpParams} from "@angular/common/http";
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {LogbookConfig} from "../../../../../../../app/core/shared/config/logbook-config";
import {map} from "rxjs/operators";

@Injectable()
export class ReferenceDataApiService {

    constructor(private http: HttpClient, private config: LogbookConfig) {
    }

    transcodeAirline(airlineCode: string): Observable<any> {
        const options: HttpParams = new HttpParams()
            .set('code', airlineCode);

        return this.http.get(`${this.config.LOGBOOK_API}/api/reference-data-api/airline`, {params: options});
    }

    searchAirline(airlineCode: string): Observable<string[]> {
        const options: HttpParams = new HttpParams()
            .set('filter', airlineCode ? airlineCode : '');
        return this.http.get(`${this.config.LOGBOOK_API}/api/reference-data-api/airlines`, {params: options})
            .pipe(
                map((i) => [].concat(i))
            );
    }

    searchAirport(airportCode: string): Observable<string[]> {
        const options: HttpParams = new HttpParams()
            .set('filter', airportCode ? airportCode : '');
        return this.http.get(`${this.config.LOGBOOK_API}/api/reference-data-api/airports`, {params: options})
            .pipe(
                map((i) => [].concat(i))
            );
    }

}
