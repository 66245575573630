"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var momentImported = require("moment");
var moment = momentImported;
var mixpanel_service_1 = require("../../../../../../../app/core/tracking/mixpanel.service");
var mixpanel_event_enum_1 = require("../../../../../../../app/core/tracking/mixpanel-event.enum");
var DlnInputDateComponent = /** @class */ (function () {
    function DlnInputDateComponent(mixpanelService) {
        this.mixpanelService = mixpanelService;
        this.date = null;
        this.isNullable = false;
        this.emptyDateLabel = null;
        this.arrowControl = true;
        this.dateChanged = new core_1.EventEmitter();
        this.shouldDisplayDatePicker = false;
        this.datePicker = null;
    }
    DlnInputDateComponent.prototype.ngOnChanges = function (changes) {
        if (!changes.date.previousValue || changes.date.currentValue !== changes.date.previousValue) {
            if (changes.date.currentValue === null) {
                this.datePicker = moment.utc().toDate();
            }
            else {
                this.datePicker = changes.date.currentValue.toDate();
            }
        }
    };
    DlnInputDateComponent.prototype.selectDateByShortcut = function (shortcut) {
        if (this.date === null) {
            this.date = moment.utc();
        }
        else {
            // Clone momentjs otherwise angular change detection not working
            this.date = this.date.clone();
        }
        switch (shortcut) {
            case "next":
                this.date.add(1, 'day');
                this.datePicker = this.date.toDate();
                this.emitDateChanged();
                break;
            case "previous":
                this.date.add(-1, 'day');
                this.datePicker = this.date.toDate();
                this.emitDateChanged();
                break;
        }
    };
    DlnInputDateComponent.prototype.displayDatePicker = function () {
        this.shouldDisplayDatePicker = !this.shouldDisplayDatePicker;
        if (this.shouldDisplayDatePicker) {
            this.mixpanelService.track(mixpanel_event_enum_1.MixpanelEvent.DATE_PICKER_OPEN);
        }
    };
    DlnInputDateComponent.prototype.onSelectDate = function (event) {
        this.shouldDisplayDatePicker = false;
        this.date = moment(event.value).utc(true);
        this.emitDateChanged();
    };
    DlnInputDateComponent.prototype.clearDate = function (event) {
        event.stopPropagation();
        this.date = null;
        this.emitDateChanged();
    };
    DlnInputDateComponent.prototype.emitDateChanged = function () {
        this.dateChanged.emit(this.date);
    };
    return DlnInputDateComponent;
}());
exports.DlnInputDateComponent = DlnInputDateComponent;
