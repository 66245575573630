"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var operators_1 = require("rxjs/operators");
var http_1 = require("@angular/common/http");
var login_service_1 = require("@logbook/core/auth/services/login.service");
var AuthExpiredInterceptor = /** @class */ (function () {
    function AuthExpiredInterceptor(injector) {
        this.injector = injector;
    }
    AuthExpiredInterceptor.prototype.intercept = function (request, next) {
        var _this = this;
        return next.handle(request).pipe(operators_1.tap(function (event) {
        }, function (err) {
            if (err instanceof http_1.HttpErrorResponse) {
                if (err.status === 401) {
                    var loginService = _this.injector.get(login_service_1.LoginService);
                    loginService.logout();
                }
            }
        }));
    };
    return AuthExpiredInterceptor;
}());
exports.AuthExpiredInterceptor = AuthExpiredInterceptor;
