"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var notification_center_service_1 = require("@logbook/notification-center/shared/service/notification.center.service");
var shared_1 = require("@logbook/shared");
var moment = require("moment");
var router_1 = require("@angular/router");
var operators_1 = require("rxjs/operators");
var mixpanel_service_1 = require("@logbook/core/tracking/mixpanel.service");
var mixpanel_event_enum_1 = require("@logbook/core/tracking/mixpanel-event.enum");
var ngx_device_detector_1 = require("ngx-device-detector");
var NotificationCenterComponent = /** @class */ (function () {
    function NotificationCenterComponent(notificationCenterService, router, deviceDetectorService, changeDetector, mixpanelService) {
        this.notificationCenterService = notificationCenterService;
        this.router = router;
        this.deviceDetectorService = deviceDetectorService;
        this.changeDetector = changeDetector;
        this.mixpanelService = mixpanelService;
        this.closePanel = new core_1.EventEmitter();
        this.noMoreContent = false;
        this.notifications = [];
        this.loadingNotifications = false;
        this.PAGE_SIZE = 20;
    }
    NotificationCenterComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.datesShowed = new Set();
        this.pageable = new shared_1.Pageable(-1, this.PAGE_SIZE, "createdAt,desc");
        this.fetchNextPage();
        this.newNotifSubscribtion = this.notificationCenterService.onNewNotification()
            .subscribe(function (notif) {
            notif.isFirstNotificationForDay = true;
            _this.notifications = _this.notifications.map(function (i) {
                if (moment.utc(i.createdAt).startOf('day').toISOString() === moment.utc(notif.createdAt).startOf('day').toISOString()) {
                    i.isFirstNotificationForDay = false;
                }
                return i;
            });
            _this.notifications.unshift(notif);
        });
    };
    NotificationCenterComponent.prototype.fetchNextPage = function () {
        var _this = this;
        this.pageable.page++;
        this.loadingNotifications = true;
        this.notificationCenterService.getAll(this.pageable)
            .pipe(operators_1.first())
            .subscribe(function (notifications) {
            _this.loadingNotifications = false;
            notifications.forEach(function (notification) {
                return _this.setIfShowDate(notification);
            });
            _this.notifications = _this.notifications.concat(notifications);
            if (!notifications || !notifications.length) {
                _this.noMoreContent = true;
            }
            _this.changeDetector.markForCheck();
        });
    };
    NotificationCenterComponent.prototype.displayNotification = function (notification) {
        this.markAsRead(notification);
        this.router.navigate(notification.getLink());
        this.mixpanelService.track(mixpanel_event_enum_1.MixpanelEvent.NOTIFICATION.CENTER.NOTIFICATION_CLICKED, {
            notificationId: notification.id,
            notificationType: notification.category
        });
    };
    NotificationCenterComponent.prototype.markAsRead = function (notification) {
        if (!notification.readAt) {
            this.notificationCenterService.markAsRead(notification);
            this.mixpanelService.track(mixpanel_event_enum_1.MixpanelEvent.NOTIFICATION.CENTER.NOTIFICATION_READ, {
                notificationId: notification.id,
                notificationType: notification.category
            });
        }
    };
    NotificationCenterComponent.prototype.markAllAsRead = function () {
        this.notificationCenterService.markAllAsRead();
        var count = 0;
        this.notifications
            .forEach(function (notification) {
            count++;
            return notification.readAt = new Date();
        });
        this.mixpanelService.track(mixpanel_event_enum_1.MixpanelEvent.NOTIFICATION.CENTER.MARK_ALL_AS_READ, {
            readNumber: count
        });
    };
    NotificationCenterComponent.prototype.delete = function (notification) {
        var _this = this;
        this.notifications = this.notifications.filter(function (notif) { return notif.id !== notification.id; });
        this.notificationCenterService.remove(notification.id).pipe(operators_1.first()).subscribe();
        this.datesShowed = new Set();
        this.notifications.forEach(function (notif) { return _this.setIfShowDate(notif); });
        this.mixpanelService.track(mixpanel_event_enum_1.MixpanelEvent.NOTIFICATION.CENTER.NOTIFICATION_DELETED, {
            notificationId: notification.id,
            notificationType: notification.category
        });
    };
    NotificationCenterComponent.prototype.close = function () {
        this.closePanel.emit();
    };
    NotificationCenterComponent.prototype.setIfShowDate = function (notification) {
        var day = moment.utc(notification.createdAt).startOf('day').toISOString();
        if (!this.datesShowed.has(day)) {
            notification.isFirstNotificationForDay = true;
            this.datesShowed.add(day);
        }
        else {
            notification.isFirstNotificationForDay = false;
        }
    };
    NotificationCenterComponent.prototype.ngOnDestroy = function () {
        if (this.newNotifSubscribtion) {
            this.newNotifSubscribtion.unsubscribe();
        }
    };
    return NotificationCenterComponent;
}());
exports.NotificationCenterComponent = NotificationCenterComponent;
