import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ImportParamsService} from "./service/import-params.service";
import {UserFilterPipe} from "./pipe/user-filter.pipe";
import {UserOrderPipe} from "./pipe/user-order.pipe";
import {SocieteService} from "./service/societe.service";
import {ContactService} from "./service/contact.service";
import {RecipientsService} from "./service/recipients.service";
import {InvitationService} from "./service/invitation.service";

@NgModule({
    imports: [
        CommonModule,
    ],
    providers: [
        ImportParamsService, InvitationService,
        SocieteService, ContactService, RecipientsService,
        UserFilterPipe
    ],
    declarations: [
        UserFilterPipe, UserOrderPipe
    ],
    exports: [
        UserFilterPipe, UserOrderPipe,
    ]
})
export class UserModule { }
