
import {tap} from 'rxjs/operators';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Observable} from 'rxjs';
import { TrackJS } from 'trackjs';

export class ErrorHandlerInterceptor implements HttpInterceptor {

    constructor() {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(tap((event: HttpEvent<any>) => {
        }, (err: any) => {
            if (err instanceof HttpErrorResponse) {
                if (!(err.status === 401 && (err.message === '' || (err.url && err.url.indexOf('/api/account') === 0)))) {

                }
            }
            console.log("HTTP Error : " + err.message + " : " + JSON.stringify(err.error));
            console.log("HTTP body : " + JSON.stringify(request.body, ErrorHandlerInterceptor.hidePassword));
            console.log("HTTP params : " + request.params);
            TrackJS.track(request.method + " " + err.status + ": " + request.url);
        }));

    }

    private static hidePassword(key, value) {
        if (key && key.toLowerCase().indexOf("password") >= 0) {
            return '*******'
        } else {
            return value
        }
    }
}
