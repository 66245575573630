import {HttpClient, HttpParams} from "@angular/common/http";
import {Injectable} from '@angular/core';
import {LOGBOOK_API} from "../../../../../app.constants";
import {CustomHttpEncoder} from "@logbook/core/shared/utils/http-custom-converter";

@Injectable()
export class CommentApiService {

    constructor(private http: HttpClient) {
    }

    postComment(threadId, messageId, formData, notify) {
        const options: HttpParams = new HttpParams({encoder: new CustomHttpEncoder()})
            .set('notify', notify);
        return this.http.post(`${LOGBOOK_API}/api/comment/${threadId}/message/${messageId}`, formData, {params: options});
    }

    editComment(threadId, messageId, commentId, formData) {
        return this.http.post(LOGBOOK_API + '/api/comment/' + threadId + '/message/' + messageId
            + '/edit/' + commentId, formData);
    }

    removeComment(threadId, messageId, commentId) {
        return this.http.delete(
            LOGBOOK_API + '/api/comment/' + threadId + '/message/' + messageId + '/remove/' + commentId,
        );
    }

}
