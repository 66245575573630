import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    DoCheck,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewEncapsulation
} from '@angular/core';
import {FormControl} from "@angular/forms";
import * as momentImported from 'moment'; const moment = momentImported;

@Component({
    selector: 'dln-input',
    templateUrl: './input.component.html',
    styleUrls: [
        './input.component.scss'
    ],
    encapsulation: ViewEncapsulation.None,
})
export class DlnInputComponent implements OnInit, AfterViewInit, OnDestroy, DoCheck {
    @Input() type = 'text';
    @Input() inputName: string;
    @Input() label: string;
    @Input() defaultValue: string;
    @Input() control = new FormControl();
    @Input('required') isRequired: boolean;
    @Input() tabIndex: number;
    @Input() asterisk = true;
    @Input() placeholder = '';
    @Input() hideErrors = false;
    @Input() inline = false;
    @Input() autocomplete;
    @Input() icon: string;
    @Output() dateChangeEvent = new EventEmitter<any>();

    @Input() set errorMessages(val) {
        this._errorMessages = Object.assign({
            required: "The field is required",
            phoneInvalid: "Phone number is invalid"
        }, val);
    };

    id: string;
    hasError = false;
    timeAsString = null;

    private _errorMessages: any;

    get errorMessages() {
        return this._errorMessages
    }

    ngOnInit() {
        this.id = this.inputName || "input-" + Math.floor(Math.random() * (1000));
        if (this.type === 'time' && this.control && this.control.value) {
            this.timeAsString = moment.utc(this.control.value).format("HH:mm");
        }
    }

    ngAfterViewInit() {
    }

    dateChanged(event) {
        this.control.setValue(event);
        this.dateChangeEvent.emit(event);
    }

    ngOnDestroy(): void {

    }

    checkValidation() {
        if (this.control) {
            this.control.updateValueAndValidity();
        }
    }

    checkError() {
        this.hasError = this.control && this.control.touched && this.control.invalid;
    }

    ngDoCheck() {
        this.checkError();
    }
}
