"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var rxjs_1 = require("rxjs");
var operators_1 = require("rxjs/operators");
var http_1 = require("@angular/common/http");
var societe_model_1 = require("../../../../../../app/core/shared/models/societe.model");
var community_service_1 = require("../../../../../../app/core/auth/services/community.service");
var logbook_config_1 = require("../../../../../../app/core/shared/config/logbook-config");
var model_1 = require("../../model");
var SocieteService = /** @class */ (function () {
    function SocieteService(http, communityService, config) {
        this.http = http;
        this.communityService = communityService;
        this.config = config;
        this.societeByTeamCache = new Map();
        this.resourceUrl = config.LOGBOOK_API + '/api/societe';
    }
    SocieteService.prototype.findById = function (societeId) {
        return this.http.get(this.resourceUrl + "/" + societeId)
            .pipe(operators_1.map(function (res) { return Object.assign(new societe_model_1.Societe(), res); }));
    };
    SocieteService.prototype.findByTeamId = function (teamId) {
        var _this = this;
        if (!this.societeByTeamCache.has(teamId)) {
            // fetch other communities from societe for team passed in parameter
            this.societeByTeamCache.set(teamId, this.http.get(this.resourceUrl + "/team/" + teamId).pipe(operators_1.mergeMap(function (societeFromApi) {
                var societe = new societe_model_1.Societe(societeFromApi);
                // Set teams
                return rxjs_1.forkJoin([rxjs_1.of(societe), rxjs_1.forkJoin(societe.teamIds
                        // .filter((otherTeamId) => otherTeamId !== teamId) //FIXME: TC: j'ai désactivé car on ne
                        // voyait pas les contacts quand on a qu'une seul team dans notre societe, à revoir
                        .map(function (otherTeamId) {
                        return _this.communityService.getCommunityById(otherTeamId)
                            .pipe(operators_1.catchError(function (err) { return rxjs_1.of(null); }));
                    }))]);
            }), operators_1.map(function (res) {
                var societe = Object.assign(new societe_model_1.Societe(), res[0]);
                societe.teams = Object.assign([], res[1]).filter(function (team) { return team != null; });
                return societe;
            }), operators_1.catchError(function () { return rxjs_1.of(null); }), operators_1.publishReplay(1), operators_1.refCount()));
        }
        return this.societeByTeamCache.get(teamId);
    };
    SocieteService.prototype.findAll = function (pageable, query) {
        var options = new http_1.HttpParams()
            .set('page', pageable.page.toString())
            .set('size', pageable.size.toString())
            .set('sort', pageable.sort);
        if (query) {
            options = options.set("search", query);
        }
        return this.http.get("" + this.resourceUrl, { params: options }).pipe(operators_1.map(function (res) {
            res = new model_1.Page(res);
            res.content = res.content.map(function (societe) { return new societe_model_1.Societe(societe); });
            return res;
        }));
    };
    SocieteService.prototype.save = function (societe) {
        return this.http.post(this.resourceUrl + "/", societe).pipe(operators_1.map(function (societeFromApi) { return new societe_model_1.Societe(societeFromApi); }));
    };
    SocieteService.prototype.suggestSocieteByEmail = function (email) {
        if (email) {
            return this.http.get(this.resourceUrl + "/suggest/" + email)
                .pipe(operators_1.map(function (res) { return res.map(function (societe) { return new societe_model_1.Societe(societe); }); }));
        }
    };
    return SocieteService;
}());
exports.SocieteService = SocieteService;
