"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var message_model_1 = require("../logs/message.model");
var user_model_1 = require("@logbook/core/shared/models/user.model");
var MessagePublie = /** @class */ (function () {
    function MessagePublie() {
        this.metadata = new Map();
        this.tags = [];
    }
    MessagePublie.toLog = function (messagePublie) {
        var emitter = new user_model_1.User();
        emitter.userId = messagePublie.emetteurId.id;
        var message = new message_model_1.Message();
        message.emetteurId = messagePublie.emetteurId;
        message.edited = false;
        message.corps = messagePublie.corps;
        message.dateCreation = messagePublie.instant;
        message.metadata = messagePublie.metadata;
        message.tags = messagePublie.tags;
        return {
            id: messagePublie.filInformations.id,
            nom: '',
            emitter: emitter,
            createdOn: messagePublie.instant,
            createdBy: messagePublie.emetteurId.id,
            lastModified: null,
            lastModifiedBy: null,
            participantsAutorises: [],
            tagVol: messagePublie.tagVol,
            messages: message,
            isNew: true
        };
    };
    return MessagePublie;
}());
exports.MessagePublie = MessagePublie;
