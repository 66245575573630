import {Log} from "../logs/log.model";
import {Message, MessageID} from "../logs/message.model";
import {User} from "@logbook/core/shared/models/user.model";

export class MessagePublie {
    instant: Date;
    messageId: MessageID;
    emetteurId: ID;
    filInformations: any;
    metadata: Map<string, string> = new Map();
    tags: string[] = [];
    corps: string;
    objet: string;
    tagVol: any;

    static toLog(messagePublie: MessagePublie): Log {
        const emitter = new User();
        emitter.userId = messagePublie.emetteurId.id;

        const message = new Message();
        message.emetteurId = messagePublie.emetteurId;
        message.edited = false;
        message.corps = messagePublie.corps;
        message.dateCreation = messagePublie.instant;
        message.metadata = messagePublie.metadata;
        message.tags = messagePublie.tags;

        return {
            id: messagePublie.filInformations.id,
            nom: '',
            emitter,
            createdOn: messagePublie.instant,
            createdBy: messagePublie.emetteurId.id,
            lastModified: null,
            lastModifiedBy: null,
            participantsAutorises: [],
            tagVol: messagePublie.tagVol,
            messages: message,
            isNew: true
        }
    }
}

export interface ID {
    id: string;
}
