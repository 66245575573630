"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("@angular/common/http");
var app_constants_1 = require("../../../../../app.constants");
var ShareApiService = /** @class */ (function () {
    function ShareApiService(http) {
        this.http = http;
    }
    ShareApiService.prototype.shareLog = function (recipients, threadId, messageId, type) {
        return this.http.post(app_constants_1.LOGBOOK_API + "/api/share/thread/" + threadId + "/message/" + messageId + "/" + type, recipients);
    };
    return ShareApiService;
}());
exports.ShareApiService = ShareApiService;
