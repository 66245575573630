"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var operators_1 = require("rxjs/operators");
var core_1 = require("@angular/core");
var flight_tag_search_service_1 = require("../../common/flight-tag/service/flight.tag.search.service");
var modal_service_1 = require("../../modal/service/modal.service");
var ng_select_component_1 = require("../../common/ng-select/ng-select.component");
var modal_config_model_1 = require("../../modal/model/modal.config.model");
var add_manual_flight_tag_modal_component_1 = require("../add-manual-flight-tag/add-manual-flight-tag-modal.component");
var mixpanel_service_1 = require("../../../../../../app/core/tracking/mixpanel.service");
var mixpanel_event_enum_1 = require("../../../../../../app/core/tracking/mixpanel-event.enum");
var community_service_1 = require("../../../../../../app/core/auth/services/community.service");
var rxjs_1 = require("rxjs");
var DlnInputFlightComponent = /** @class */ (function () {
    function DlnInputFlightComponent(flightTagSearchService, mixpanelService, modalService, communityService) {
        this.flightTagSearchService = flightTagSearchService;
        this.mixpanelService = mixpanelService;
        this.modalService = modalService;
        this.communityService = communityService;
        this.selectedFlightTags = [];
        this.showFlightDate = false;
        this.onSelectFlight = new core_1.EventEmitter();
        this.onRemoveFlight = new core_1.EventEmitter();
        this.flightTagsSearchControl = new core_1.EventEmitter();
    }
    DlnInputFlightComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.flightTagsSuggestion$ = this.flightTagsSearchControl.pipe(operators_1.distinctUntilChanged(), operators_1.debounceTime(150), operators_1.flatMap(function (val) { return (val && val.length) ? _this.searchFlight(val) : rxjs_1.of([]); }));
        this.communityService.getCommunity().pipe(operators_1.first()).subscribe(function (community) {
            _this.userProfile = JSON.stringify(community.profile.map(function (item) {
                return {
                    airlines: item.airlines,
                    airports: item.airports
                };
            }));
        });
    };
    DlnInputFlightComponent.prototype.ngOnChanges = function (changes) {
        if (changes.suggestionDate) {
            this.flightTagsSearchControl.emit("");
        }
    };
    DlnInputFlightComponent.prototype.selectFlight = function (flightTag) {
        this.mixpanelService.track(mixpanel_event_enum_1.MixpanelEvent.FLIGHT_TAG_SUGGEST_SELECTED, {
            flightTag: flightTag.params.toString(),
        });
        this.onSelectFlight.emit(flightTag.params);
    };
    DlnInputFlightComponent.prototype.removeFlight = function (flightTag) {
        this.onRemoveFlight.emit(flightTag.params);
    };
    DlnInputFlightComponent.prototype.searchFlight = function (flightTagSearch) {
        this.mixpanelService.track(mixpanel_event_enum_1.MixpanelEvent.FLIGHT_TAG_SUGGEST_SEARCH, {
            query: flightTagSearch,
        });
        return this.flightTagSearchService.suggestFlights(flightTagSearch, 'search', this.suggestionDate.format("DD/MM/YYYY"), this.suggestionDate.format("DD/MM/YYYY"), this.userProfile).pipe(operators_1.map(function (flights) { return flights
            .filter(function (flight) { return flight.airlineCode !== 'TVX'; })
            .map(function (flight) { return DlnInputFlightComponent.convertToNgSelectItem(flight); }); }));
    };
    DlnInputFlightComponent.prototype.openNewFlightTagModal = function () {
        var _this = this;
        var modalConfig = new modal_config_model_1.ModalConfig(add_manual_flight_tag_modal_component_1.AddManualFlightTagModalComponent, {
            flightDate: this.suggestionDate,
            searchText: this.ngSelect.filterValue
        });
        this.modalService.showModal(modalConfig).subscribe(function (flightTag) {
            if (flightTag) {
                _this.ngSelect.filterValue = '';
                _this.ngSelect.select(DlnInputFlightComponent.convertToNgSelectItem(flightTag));
                _this.onSelectFlight.emit(flightTag);
            }
        });
    };
    DlnInputFlightComponent.convertToNgSelectItem = function (flightTag) {
        return {
            name: flightTag.toString(),
            id: flightTag.toString(),
            isParent: false,
            params: flightTag
        };
    };
    return DlnInputFlightComponent;
}());
exports.DlnInputFlightComponent = DlnInputFlightComponent;
