"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("@angular/common/http");
var logbook_config_1 = require("../../../../../../app/core/shared/config/logbook-config");
var ImportParamsService = /** @class */ (function () {
    function ImportParamsService(http, config) {
        this.http = http;
        this.config = config;
        this.resourceUrl = this.config.LOGBOOK_API + '/api/import';
    }
    ImportParamsService.prototype.saveParams = function (importParams) {
        return this.http.post(this.resourceUrl + "/params", importParams);
    };
    ImportParamsService.prototype.deleteParams = function (id) {
        return this.http.delete(this.resourceUrl + "/params" + "/" + id);
    };
    return ImportParamsService;
}());
exports.ImportParamsService = ImportParamsService;
