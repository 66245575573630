"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var operators_1 = require("rxjs/operators");
var core_1 = require("@angular/core");
var platform_browser_1 = require("@angular/platform-browser");
var router_1 = require("@angular/router");
var shared_1 = require("@logbook/shared");
var shared_2 = require("@logbook/shared");
var on_boarding_modal_component_1 = require("../on-boarding-modal/on-boarding-modal.component");
var switch_apps_menu_component_1 = require("../switch-apps-menu/switch-apps-menu.component");
var ngx_webstorage_1 = require("ngx-webstorage");
var principal_service_1 = require("../../auth/services/principal.service");
var account_service_1 = require("../../auth/services/account.service");
var DlnMainComponent = /** @class */ (function () {
    function DlnMainComponent(titleService, router, localStorageService, wsService, principal, userService, accountService, modalService, pushNotificationsService) {
        this.titleService = titleService;
        this.router = router;
        this.localStorageService = localStorageService;
        this.wsService = wsService;
        this.principal = principal;
        this.userService = userService;
        this.accountService = accountService;
        this.modalService = modalService;
        this.pushNotificationsService = pushNotificationsService;
        this.notificationOptions = {
            position: ["top", "right"],
            timeOut: 3000,
            animate: "fromRight"
        };
        this.hideNavbar = false;
        this.hideSwitchApps = false;
        this.hideModal = false;
        this.cguModalIsShowing = false;
        this.collapseMenu = false;
    }
    DlnMainComponent.prototype.getRouterData = function (routeSnapshot, data, defaultValue) {
        var dataValue = (routeSnapshot.data && routeSnapshot.data[data]) ? routeSnapshot.data[data] : defaultValue;
        if (routeSnapshot.firstChild) {
            dataValue = this.getRouterData(routeSnapshot.firstChild, data, defaultValue) || dataValue;
        }
        return dataValue;
    };
    DlnMainComponent.prototype.ngOnInit = function () {
        var _this = this;
        var collapseMenu = this.localStorageService.retrieve(switch_apps_menu_component_1.SwitchAppsMenuComponent.COLLAPSE_STORAGE_KEY);
        if (collapseMenu != null) {
            this.collapseMenu = collapseMenu;
        }
        this.routerSubscription = this.router.events.subscribe(function (event) {
            if (event instanceof router_1.NavigationEnd) {
                setTimeout(function () {
                    _this.titleService.setTitle(_this.getRouterData(_this.router.routerState.snapshot.root, "pageTitle", "Logbook"));
                    _this.hideNavbar = _this.getRouterData(_this.router.routerState.snapshot.root, 'hideNavbar', false);
                    _this.hideSwitchApps = _this.getRouterData(_this.router.routerState.snapshot.root, 'hideSwitchApps', false);
                    _this.hideModal = _this.getRouterData(_this.router.routerState.snapshot.root, 'hideModal', false);
                    ga('set', 'page', event.urlAfterRedirects);
                    ga('send', 'pageview');
                });
            }
        });
        this.principal.getAuthenticationState().pipe(operators_1.startWith(this.principal.identityDirect())).subscribe(function (account) {
            if (account && _this.principal.hasAnyAuthorityDirect(["ROLE_USER"])) {
                _this.wsService.initStomp();
                _this.userService.fetchUsersLogged();
                if (_this.principal.hasFeatureEnableDirect(["NOTIFICATION_CENTER"])) {
                    _this.pushNotificationsService.subscribeToNotifications();
                }
                if (!_this.hideModal && !_this.cguModalIsShowing) {
                    _this.accountService.isReadCGU().pipe(operators_1.first()).subscribe(function (res) {
                        if (res.status !== 200) {
                            var modalConfig = new shared_2.ModalConfig(on_boarding_modal_component_1.OnBoardingModalComponent, null, null, '80vh', 'custom-on-boarding-modal-panel');
                            _this.cguModalIsShowing = true;
                            _this.modalService.showModal(modalConfig).pipe(operators_1.first())
                                .subscribe(function () { return _this.cguModalIsShowing = false; });
                        }
                    });
                }
            }
        });
        // To force url for mobile shortcut website
        // checks if you're in app mode
        if ("standalone" in window.navigator && window.navigator['standalone']) {
            // Erase potential logs filters in url
            this.router.navigate(['']);
        }
    };
    DlnMainComponent.prototype.ngOnDestroy = function () {
        this.wsService.disconnect();
        if (this.routerSubscription) {
            this.routerSubscription.unsubscribe();
        }
    };
    DlnMainComponent.prototype.isAuthenticated = function () {
        return this.principal.isAuthenticated();
    };
    return DlnMainComponent;
}());
exports.DlnMainComponent = DlnMainComponent;
