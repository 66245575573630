"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var router_1 = require("@angular/router");
var common_1 = require("@angular/common");
var BackButtonComponent = /** @class */ (function () {
    function BackButtonComponent(router, location) {
        var _this = this;
        this.router = router;
        this.location = location;
        this.onClick = function () {
            if (_this.url) {
                _this.router.navigate([_this.url]);
            }
            else {
                _this.location.back();
            }
        };
    }
    return BackButtonComponent;
}());
exports.BackButtonComponent = BackButtonComponent;
